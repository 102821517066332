var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import Phase1 from './Phase1';
import Phase2 from './Phase2';
import Phase3 from './Phase3';
import Phase4 from './Phase4';
import aannekouluLogo from '../../../assets/images/aannekoulu-logo.png';
import { slugify, dateOfBirthFromSsn, ageFromDateOfBirth, } from '../../../functions/conversions';
import PhaseWrapper from './PhaseWrapper';
var CreateAccount = function () {
    var _a;
    var ageLimit = 16;
    // Get invitation id
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var invitationId = urlParams.get('id');
    var _b = __read(useState({
        invitationId: invitationId,
        account: false,
    }), 2), accountInfo = _b[0], setAccountInfo = _b[1];
    var _c = __read(useState(0), 2), phase = _c[0], setPhase = _c[1];
    var _d = __read(useState(undefined), 2), age = _d[0], setAge = _d[1];
    function nextPhase() {
        setPhase(phase + 1);
    }
    function phase1(ssn, account, patientId) {
        // check invitation from backend (ssn + invitationId) -> patient._id or error
        var patient = {
            ssn: ssn,
            dateOfBirth: dateOfBirthFromSsn(ssn),
            patientId: patientId,
        };
        setAccountInfo(__assign(__assign({}, accountInfo), { account: account, patient: patient }));
        setAge(ageFromDateOfBirth(patient.dateOfBirth));
        nextPhase();
    }
    function phase2(userinfo) {
        var account = __assign({}, accountInfo);
        var patient = accountInfo.patient
            ? __assign({}, accountInfo.patient) : undefined;
        if (age && age >= ageLimit) {
            if (patient) {
                patient.firstName = userinfo.firstName;
                patient.lastName = userinfo.lastName;
            }
            account.patient = patient;
        }
        else {
            if (patient) {
                patient.firstName = userinfo.patientFirstName;
                patient.lastName = userinfo.patientLastName;
            }
            account.patient = patient;
            account.parent = {
                firstName: userinfo.firstName,
                lastName: userinfo.lastName,
            };
        }
        account.email = userinfo.email;
        account.phone = userinfo.phone;
        var username = slugify(userinfo.firstName) + '.' + slugify(userinfo.lastName);
        account.username = username;
        setAccountInfo(account);
        nextPhase();
    }
    function phase3() {
        nextPhase();
    }
    var header = "Vaihe ".concat(phase + 1, " / ").concat(4);
    switch (phase) {
        default:
            return (_jsx(PhaseWrapper, __assign({ header: _jsx("figure", __assign({ className: "m-auto" }, { children: _jsx("img", { alt: "\u00C4\u00E4nnekoulu", src: aannekouluLogo, className: "sm:max-w-lg" }) })) }, { children: _jsx(Phase1, { next: function (ssn, account, patientId) { return phase1(ssn, account, patientId); } }, "phase1") })));
        case 1:
            return (_jsx(PhaseWrapper, __assign({ header: header }, { children: _jsx(Phase2, { adult: age && age >= ageLimit ? true : false, account: accountInfo.account, ssn: (_a = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.patient) === null || _a === void 0 ? void 0 : _a.ssn, next: function (userinfo) { return phase2(userinfo); } }, "phase2") })));
        case 2:
            return (_jsx(PhaseWrapper, { children: _jsx(Phase3, { accountInfo: accountInfo, next: function () { return phase3(); } }, "phase3") }));
        case 3:
            return (_jsx(PhaseWrapper, __assign({ header: header }, { children: _jsx(Phase4, {}, "phase4") })));
    }
};
export default CreateAccount;
