var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Translate } from '../../translation/Translate';
import { getTherapistPeriod, setValidUntil, } from '../../../actions/periodActions';
import { setPeriod } from '../../../reducers/periodSlice';
import LaunchIcon from '@mui/icons-material/Launch';
import useUser from '../../../hooks/useUser';
import { moduleTotalSessions } from '../../../config/periodSessions';
var PeriodInfo = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var period = _a.period;
    var dispatch = useDispatch();
    var user = useUser();
    var _h = __read(React.useState(false), 2), editUntil = _h[0], setEditUntil = _h[1];
    var setEndingDateTranslate = Translate({ term: 'period.set_ending_date' });
    var cancelTranslate = Translate({ term: 'actions.cancel' });
    var periodDurationTranslate = Translate({ term: 'period.period_duration' });
    var changePeriodDurationTranslate = Translate({
        term: 'period.change_period_duration',
    });
    var instructionsURL = ("https://nhealthtechnologyoy.sharepoint.com/:x:/r/sites/Aannekoulunyhteiset/Shared%20Documents/[…]363135631a43cbb52cd9ba79f4efed&csf=1&web=1&e=AKiSXV");
    //'https://docs.google.com/document/d/1nDJGm6pE0Dw4yKPmqIF_Sow3G75v44nR2NNIX694XFQ/edit';
    function getTherapistPeriodFunction() {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!((user === null || user === void 0 ? void 0 : user.session) && period)) return [3 /*break*/, 2];
                        return [4 /*yield*/, getTherapistPeriod(period._id, user === null || user === void 0 ? void 0 : user.session)];
                    case 1:
                        data = _a.sent();
                        if (data) {
                            dispatch(setPeriod(data));
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    function changeValidUntil(date) {
        if (period && date) {
            setValidUntil(period._id, new Date(date), user === null || user === void 0 ? void 0 : user.session)
                .then(function (data) {
                if (data && data.data) {
                    getTherapistPeriodFunction();
                }
            })
                .catch(function (err) {
                //console.error(err);
            });
        }
    }
    return (_jsxs("div", __assign({ className: "bg-white py-4 px-8 rounded-md shadow-md relative grid lg:grid-cols-3 gap-3" }, { children: [_jsxs("div", __assign({ className: "grid-span-2" }, { children: [_jsx("h2", __assign({ className: "text-xl" }, { children: Translate({ term: 'nav.period' }) })), period ? (_jsx("div", { children: period.started ? (_jsxs("div", __assign({ className: "mt-5 relative mt-2 text-gray-600" }, { children: [_jsx("span", { children: periodDurationTranslate }), _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: period.started })), _jsx("span", __assign({ className: "mx-1" }, { children: "-" })), _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: period.validUntil }))] }))) : ('') })) : ('')] })), _jsxs("div", __assign({ className: "text-gray-600 space-y-2" }, { children: [_jsx("p", { children: ((_b = period === null || period === void 0 ? void 0 : period.module) === null || _b === void 0 ? void 0 : _b.name)
                            ? period.module.name.fi || period.module.name.se
                            : '' }), (period === null || period === void 0 ? void 0 : period.categories) && _jsx("p", { children: (_c = period === null || period === void 0 ? void 0 : period.categories) === null || _c === void 0 ? void 0 : _c.toString() }), _jsxs("p", { children: [Translate({ term: 'home.phase' }), ":", ' ', "".concat((_d = period === null || period === void 0 ? void 0 : period.sessions) !== null && _d !== void 0 ? _d : '0', "/").concat(((_e = period === null || period === void 0 ? void 0 : period.module) === null || _e === void 0 ? void 0 : _e._id)
                                ? (_g = (_f = period === null || period === void 0 ? void 0 : period.module) === null || _f === void 0 ? void 0 : _f.sessions) !== null && _g !== void 0 ? _g : String(moduleTotalSessions[period.module._id])
                                : '0')] })] })), _jsxs("div", __assign({ className: "flex flex-col space-y-2 text-gray-600" }, { children: [period ? (_jsxs("div", { children: [_jsxs("span", __assign({ className: "cursor-pointer text-blue", onClick: function () { return setEditUntil(!editUntil); } }, { children: [_jsx("span", __assign({ className: "underline underline-offset-2" }, { children: changePeriodDurationTranslate })), ' ', _jsx(AddCircleIcon, { sx: { fontSize: 24 } })] })), editUntil ? (_jsxs("div", __assign({ className: "absolute z-50 p-4 bg-white border border-gray-300" }, { children: [_jsx("h3", { children: setEndingDateTranslate }), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterMoment }, { children: _jsx(StaticDatePicker, { displayStaticWrapperAs: "desktop", value: period.validUntil, onChange: function (newValue) {
                                                changeValidUntil(newValue);
                                                setEditUntil(false);
                                            }, renderInput: function (params) { return _jsx(TextField, __assign({}, params)); } }) })), _jsx("div", __assign({ className: "grid grid-cols-2" }, { children: _jsx("div", { children: _jsx("button", __assign({ onClick: function () { return setEditUntil(false); } }, { children: cancelTranslate })) }) }))] }))) : ('')] })) : (''), _jsxs("div", { children: [Translate({ term: 'period.paying_party' }), ":", ' ', period && period.zone && period.zone.name
                                ? period.zone.name
                                : 'Ei alueen nimeä'] }), _jsxs("a", __assign({ href: instructionsURL, target: "_blank", className: "flex flex-row text-blue cursor-pointer space-x-1", rel: "noreferrer" }, { children: [_jsx("p", __assign({ className: "underline underline-offset-2" }, { children: Translate({ term: 'period.zone_instructions' }) })), _jsx(LaunchIcon, {})] }))] }))] })));
};
export default PeriodInfo;
