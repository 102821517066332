var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { Translate } from '../../translation/Translate';
import ZoneForm from './ZoneForm';
import { setIsLoading, setValidationErrors, setZoneFormDataField, } from '../../../reducers/adminLicenseSlice';
import { updateAdminZone } from '../../../actions/adminActions';
import useUser from '../../../hooks/useUser';
var ZoneDetails = function () {
    var dispatch = useDispatch();
    var user = useUser();
    var newZoneUpdatedTranslate = Translate({
        term: 'messages.zone_updated_success',
    });
    var nameMissingTranslate = Translate({
        term: 'error.name',
    });
    var emailMissingTranslate = Translate({
        term: 'error.email',
    });
    var firstNameMissingTranslate = Translate({
        term: 'error.last_name',
    });
    var lastNameMissingTranslate = Translate({
        term: 'error.lastname_missing',
    });
    var updateFailedTranslate = Translate({
        term: 'messages.zone_updated_failed',
    });
    var municipalityMissingTranslate = Translate({
        term: 'common.municipality_missing',
    });
    var zoneFormData = useSelector(function (state) { return state.adminLicense.zoneFormData; });
    var isLoading = useSelector(function (state) { return state.adminLicense.isLoading; });
    var firstName = zoneFormData.firstName, lastName = zoneFormData.lastName, email = zoneFormData.email, municipality = zoneFormData.municipality, therapist = zoneFormData.therapist, name = zoneFormData.name;
    var formIsValid = function () {
        var errorMessages = [];
        if (!name)
            errorMessages.push(nameMissingTranslate);
        if (!email)
            errorMessages.push(emailMissingTranslate);
        if (!firstName)
            errorMessages.push(firstNameMissingTranslate);
        if (!lastName)
            errorMessages.push(lastNameMissingTranslate);
        if (!municipality)
            errorMessages.push(municipalityMissingTranslate);
        return errorMessages;
    };
    var showSuccessToastMessage = function (zoneName) {
        var _a;
        toast((_a = newZoneUpdatedTranslate + ' ' + zoneName) !== null && _a !== void 0 ? _a : '', {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 5000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
    };
    var showFailedToastMessage = function () {
        toast(updateFailedTranslate, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 5000,
            toastId: 'failedId',
            className: 'toast-message-warning',
        });
    };
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errorMessages, updatedZone;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        return __generator(this, function (_q) {
            switch (_q.label) {
                case 0:
                    dispatch(setIsLoading(true));
                    if (!user.session) {
                        dispatch(setIsLoading(false));
                        return [2 /*return*/];
                    }
                    dispatch(setValidationErrors([]));
                    errorMessages = formIsValid();
                    if (errorMessages.length > 0) {
                        dispatch(setValidationErrors(errorMessages));
                        dispatch(setIsLoading(false));
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, updateAdminZone(zoneFormData, user.session)];
                case 1:
                    updatedZone = _q.sent();
                    if (updatedZone) {
                        dispatch(setZoneFormDataField({
                            _id: updatedZone._id,
                            name: updatedZone.name,
                            firstName: (_c = (_b = (_a = updatedZone.contacts) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.firstName) !== null && _c !== void 0 ? _c : '',
                            lastName: (_f = (_e = (_d = updatedZone.contacts) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.lastName) !== null && _f !== void 0 ? _f : '',
                            email: (_j = (_h = (_g = updatedZone.contacts) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.email) !== null && _j !== void 0 ? _j : '',
                            phone: (_l = (_k = updatedZone.contacts) === null || _k === void 0 ? void 0 : _k[0]) === null || _l === void 0 ? void 0 : _l.phone,
                            municipality: (_m = updatedZone.municipalities) === null || _m === void 0 ? void 0 : _m[0],
                            notes: (_p = (_o = updatedZone.notes) === null || _o === void 0 ? void 0 : _o[0]) !== null && _p !== void 0 ? _p : '',
                        }));
                        showSuccessToastMessage(updatedZone.name);
                    }
                    else {
                        showFailedToastMessage();
                    }
                    dispatch(setIsLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx(ZoneForm, { children: _jsx("div", __assign({ className: "flex justify-end gap-4 mt-6" }, { children: _jsx("button", __assign({ onClick: onSubmit, className: "border-2 border-white bg-blue text-white", disabled: therapist || isLoading }, { children: Translate({ term: 'actions.modify' }) })) })) })] }));
};
export default ZoneDetails;
