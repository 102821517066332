var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/therapist/Header';
import { Translate, LanguageContext, } from '../../components/translation/Translate';
import { searchPeriodMaterials } from '../../actions/periodMaterialActions';
import { getModules } from '../../actions/moduleActions';
import { setModules, setPeriodMaterialsList, setUpdateTime, } from '../../reducers/periodMaterialsSlice';
import { getTherapistPeriod } from '../../actions/periodActions';
import SearchMaterial from '../../components/therapist/materials/SearchMaterial';
import MaterialList from '../../components/therapist/materials/MaterialList';
import useUser from '../../hooks/useUser';
var Materials = function () {
    var language = useContext(LanguageContext);
    var dispatch = useDispatch();
    var user = useUser();
    var materials = useSelector(function (state) { return state.materials; });
    var _a = __read(useState(''), 2), activeModule = _a[0], setActiveModule = _a[1];
    var _b = __read(useState(''), 2), activeType = _b[0], setActiveType = _b[1];
    var _c = __read(useState(''), 2), activeLanguage = _c[0], setActiveLanguage = _c[1];
    var _d = __read(useState(1), 2), activePage = _d[0], setActivePage = _d[1];
    var _e = __read(useState(10), 2), activeMaxPage = _e[0], setActiveMaxPage = _e[1];
    var _f = __read(useState(0), 2), activeTotalCount = _f[0], setActiveTotalCount = _f[1];
    var _g = __read(useState(false), 2), isSearching = _g[0], setIsSearching = _g[1];
    var _h = __read(useState(''), 2), textSearch = _h[0], setTextSearch = _h[1];
    var moduleSelector = useRef(null);
    var typeSelector = useRef(null);
    var languageSelector = useRef(null);
    var freeTextInput = useRef(null);
    var _j = __read(useState(''), 2), firstName = _j[0], setFirstName = _j[1];
    var _k = __read(useState(''), 2), lastName = _k[0], setLastName = _k[1];
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var periodId = urlParams.get('periodId');
    var clearSelections = function () {
        if (moduleSelector.current)
            moduleSelector.current.value = '';
        setActiveModule('');
        if (typeSelector.current)
            typeSelector.current.value = '';
        setActiveType('');
        if (languageSelector.current)
            languageSelector.current.value = '';
        setActiveLanguage('');
        if (freeTextInput.current)
            freeTextInput.current.value = '';
        setTextSearch('');
        setActivePage(1);
        loadMaterials();
    };
    var searchMaterials = function () {
        var _a, _b, _c, _d, _e, _f;
        setActivePage(1);
        setActiveType('');
        setActiveLanguage('');
        setActiveModule('');
        setTextSearch('');
        if ((_a = languageSelector.current) === null || _a === void 0 ? void 0 : _a.value) {
            setActiveLanguage(languageSelector.current.value);
        }
        if ((_b = typeSelector.current) === null || _b === void 0 ? void 0 : _b.value) {
            setActiveType(typeSelector.current.value);
        }
        if (((_c = freeTextInput.current) === null || _c === void 0 ? void 0 : _c.value) && ((_d = freeTextInput.current) === null || _d === void 0 ? void 0 : _d.value) !== '') {
            setTextSearch(freeTextInput.current.value);
        }
        if ((_e = moduleSelector.current) === null || _e === void 0 ? void 0 : _e.value) {
            setActiveModule((_f = moduleSelector.current) === null || _f === void 0 ? void 0 : _f.value);
        }
        setIsSearching(true);
    };
    function loadModules() {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user.session) return [3 /*break*/, 2];
                        return [4 /*yield*/, getModules(user.session)];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            dispatch(setModules(response));
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    function loadMaterials(moduleId) {
        return __awaiter(this, void 0, void 0, function () {
            var searchParams, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        searchParams = {
                            language: activeLanguage,
                            type: activeType,
                            modules: moduleId || activeModule,
                            page: activePage,
                            freeText: textSearch,
                        };
                        if (!user.session) return [3 /*break*/, 2];
                        return [4 /*yield*/, searchPeriodMaterials(user.session, searchParams)];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            dispatch(setPeriodMaterialsList(response.docs));
                            setActiveMaxPage(response.totalPages);
                            setActiveTotalCount(response.totalDocs);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    React.useEffect(function () {
        dispatch(setUpdateTime(new Date()));
        loadModules();
        if (periodId && lastName === '') {
            getTherapistPeriod(periodId, user.session).then(function (data) {
                var _a;
                if (data) {
                    if (data.module && data.module._id)
                        setActiveModule(data.module._id);
                    if (data.patient) {
                        if (data.patient.firstName)
                            setFirstName(data.patient.firstName);
                        if (data.patient.lastName)
                            setLastName(data.patient.lastName);
                    }
                    ((_a = data.module) === null || _a === void 0 ? void 0 : _a._id) ? loadMaterials(data.module._id) : null;
                }
            });
        }
        else {
            loadMaterials();
        }
    }, []);
    React.useEffect(function () {
        if (isSearching) {
            loadMaterials();
            setIsSearching(false);
        }
    }, [isSearching]);
    React.useEffect(function () {
        loadMaterials();
    }, [activePage]);
    function switchPage(pageNumber) {
        setActivePage(pageNumber);
    }
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: Translate({ term: 'nav.material_library' }) }), _jsx(SearchMaterial, { activeModule: activeModule, setActiveModule: setActiveModule, moduleSelector: moduleSelector, languageSelector: languageSelector, typeSelector: typeSelector, freeTextInput: freeTextInput, materials: materials, language: language, periodId: periodId, searchMaterials: searchMaterials, clearSelections: clearSelections }), _jsx(MaterialList, { materials: materials, activeTotalCount: activeTotalCount, activePage: activePage, activeMaxPage: activeMaxPage, switchPage: switchPage, periodId: periodId })] }));
};
export default Materials;
