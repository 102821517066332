var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { Translate } from '../../translation/Translate';
import { clearFormState, setLicenseFormDataField, setValidationErrors, } from '../../../reducers/adminLicenseSlice';
import { createAdminLicense, getAdminTherapists, } from '../../../actions/adminActions';
import { Select } from '../../therapist/utils/Select';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { getModulesAdmin } from '../../../actions/moduleActions';
import { sortBy } from 'lodash';
import add from 'date-fns/add';
import { setApiError } from '../../../reducers/apiErrorSlice';
import { getZones } from '../../../actions/zoneActions';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MSelect from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import useUser from '../../../hooks/useUser';
var CreateLicenses = function () {
    var successToastMessage = Translate({
        term: 'admin.license.new_licenses_created',
    });
    var failedToastMessage = Translate({
        term: 'admin.license.new_licenses_created_failed',
    });
    var licenseCountErrorMessage = Translate({ term: 'error.license_count' });
    var zoneErrorMessage = Translate({ term: 'error.zone' });
    var modulesErrorMessage = Translate({ term: 'error.modules' });
    var licenseValidUntilErrorMessage = Translate({
        term: 'error.license_valid_until',
    });
    var monthsMessage = Translate({ term: 'common.months' });
    var validUntilOptions = [
        { value: 12, optionText: 12 + ' ' + monthsMessage },
        { value: 24, optionText: 24 + ' ' + monthsMessage },
        { value: 36, optionText: 36 + ' ' + monthsMessage },
    ];
    var dispatch = useDispatch();
    var user = useUser();
    var apiErrors = useSelector(function (state) { return state.apiError.errors; });
    var validationErrors = useSelector(function (state) { return state.adminLicense.validationErrors; });
    var licenseFormData = useSelector(function (state) { return state.adminLicense.licenseFormData; });
    var zone = licenseFormData.zone, modules = licenseFormData.modules, licenseCount = licenseFormData.licenseCount, licenseIsFor = licenseFormData.licenseIsFor, therapist = licenseFormData.therapist, licenseValidUntil = licenseFormData.licenseValidUntil;
    var _a = __read(useState([]), 2), moduleOptions = _a[0], setModuleOptions = _a[1];
    var _b = __read(useState([]), 2), zoneOptions = _b[0], setZoneOptions = _b[1];
    var _c = __read(useState([]), 2), therapistOptions = _c[0], setTherapistOptions = _c[1];
    var _d = __read(useState(false), 2), isSaving = _d[0], setIsSaving = _d[1];
    var getModules = function () { return __awaiter(void 0, void 0, void 0, function () {
        var modulesData, modulesOptions, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getModulesAdmin(user.session)];
                case 1:
                    modulesData = _a.sent();
                    if (modulesData) {
                        modulesOptions = modulesData.map(function (module) {
                            return {
                                value: module._id,
                                optionText: module.name.fi || module.name.se,
                            };
                        });
                        setModuleOptions(sortBy(modulesOptions, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getZoneOptions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var zonesData, zonesArray, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getZones(user.session)];
                case 1:
                    zonesData = _a.sent();
                    if (zonesData) {
                        zonesArray = zonesData.map(function (item) {
                            return {
                                value: item._id,
                                optionText: item.name,
                            };
                        });
                        setZoneOptions(sortBy(zonesArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getTherapistOptions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var therapistsData, therapistsArray, err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!user.session)
                        return [2 /*return*/];
                    return [4 /*yield*/, getAdminTherapists(user.session)];
                case 1:
                    therapistsData = _a.sent();
                    if (therapistsData) {
                        therapistsArray = therapistsData.map(function (user) {
                            return {
                                value: user.userId,
                                optionText: (user.firstName || '') + ' ' + (user.lastName || ''),
                            };
                        });
                        setTherapistOptions(sortBy(therapistsArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_3 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var showSuccessToastMessage = function () {
        toast(successToastMessage, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 5000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
    };
    var showFailedToastMessage = function () {
        toast(failedToastMessage, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 5000,
            toastId: 'failedId',
            className: 'toast-message-warning',
        });
    };
    var formIsValid = function () {
        var errorMessages = [];
        if (licenseCount < 1)
            errorMessages.push(licenseCountErrorMessage);
        if (!zone && !therapist)
            errorMessages.push(zoneErrorMessage);
        if (modules.length < 1)
            errorMessages.push(modulesErrorMessage);
        if (!licenseValidUntil)
            errorMessages.push(licenseValidUntilErrorMessage);
        return errorMessages;
    };
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errorMessages, createdLicenses, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    setIsSaving(true);
                    dispatch(setValidationErrors([]));
                    errorMessages = formIsValid();
                    if (errorMessages.length > 0) {
                        dispatch(setValidationErrors(errorMessages));
                        setIsSaving(false);
                        return [2 /*return*/];
                    }
                    if (!(validationErrors.length === 0 && user.session)) return [3 /*break*/, 2];
                    return [4 /*yield*/, createAdminLicense({
                            licenseCount: licenseCount,
                            zone: zone,
                            therapist: therapist,
                            modules: modules,
                            licenseValidUntil: add(new Date(), { months: licenseValidUntil }),
                        }, user.session)];
                case 1:
                    createdLicenses = _a.sent();
                    if (createdLicenses && (createdLicenses === null || createdLicenses === void 0 ? void 0 : createdLicenses.length)) {
                        showSuccessToastMessage();
                        dispatch(clearFormState());
                    }
                    else {
                        showFailedToastMessage();
                    }
                    _a.label = 2;
                case 2:
                    setIsSaving(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    setIsSaving(false);
                    showFailedToastMessage();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getModules();
        getZoneOptions();
        getTherapistOptions();
        // Reset possible api errors if page is reloaded. This is because store is persisted.
        dispatch(setApiError([]));
        dispatch(setValidationErrors([]));
    }, []);
    return (_jsxs("div", __assign({ className: "mt-6", style: { width: '650px' } }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx("h2", __assign({ className: "text-2xl font-semibold" }, { children: Translate({ term: 'admin.license.create_new_license' }) })), _jsxs("p", __assign({ className: "flex justify-end text-gray-600 mt-4 -mb-2" }, { children: ["*", Translate({ term: 'common.required' })] })), _jsxs("form", __assign({ onSubmit: function (e) {
                    e.preventDefault();
                    onSubmit();
                }, onReset: function (e) {
                    e.preventDefault();
                    dispatch(clearFormState());
                } }, { children: [_jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-5/12 mt-2" }, { children: [Translate({ term: 'admin.license.license_count' }), "*"] })), _jsx("input", { type: "number", onChange: function (e) {
                                    return dispatch(setLicenseFormDataField({ licenseCount: e.target.value }));
                                }, value: licenseCount, className: "h-10 w-7/12", disabled: isSaving })] })), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-5/12 mt-2" }, { children: [Translate({ term: 'admin.license.license_is_for' }), "*"] })), _jsxs(RadioGroup, __assign({ row: true, "aria-labelledby": "demo-row-radio-buttons-group-label", name: "row-radio-buttons-group" }, { children: [_jsx(FormControlLabel, { disabled: isSaving, control: _jsx(Radio, { checked: licenseIsFor === 'zone', onChange: function (e) {
                                                return dispatch(setLicenseFormDataField({
                                                    licenseIsFor: e.target.value,
                                                    therapist: '',
                                                }));
                                            }, value: 'zone' }), label: Translate({ term: 'zone.zone' }) }), _jsx(FormControlLabel, { disabled: isSaving, control: _jsx(Radio, { checked: licenseIsFor === 'therapist', onChange: function (e) {
                                                return dispatch(setLicenseFormDataField({
                                                    licenseIsFor: e.target.value,
                                                    zone: '',
                                                }));
                                            }, value: 'therapist' }), label: Translate({ term: 'home.therapist' }) })] }))] })), licenseIsFor === 'therapist' && (_jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-5/12 mt-2" }, { children: [Translate({ term: 'home.therapist' }), "*"] })), _jsx(Select, { selectProps: {
                                    id: 'therapist-select',
                                    labelId: 'therapist-select-label',
                                    label: Translate({ term: 'admin.license.select_therapist' }),
                                    value: therapist,
                                    onChange: function (_a) {
                                        var target = _a.target;
                                        return dispatch(setLicenseFormDataField({ therapist: target.value }));
                                    },
                                }, datas: therapistOptions !== null && therapistOptions !== void 0 ? therapistOptions : [] })] }))), licenseIsFor === 'zone' && (_jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-5/12 mt-2" }, { children: [Translate({ term: 'zone.zone' }), "*"] })), _jsx(Select, { selectProps: {
                                    id: 'zone-select',
                                    labelId: 'zone-select-label',
                                    label: Translate({ term: 'therapy.select_zone' }),
                                    value: zone,
                                    onChange: function (_a) {
                                        var target = _a.target;
                                        return dispatch(setLicenseFormDataField({ zone: target.value }));
                                    },
                                }, datas: zoneOptions !== null && zoneOptions !== void 0 ? zoneOptions : [] })] }))), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-5/12 mt-2" }, { children: [Translate({ term: 'therapy.module' }), "*"] })), _jsxs(FormControl, __assign({ sx: { width: 300 } }, { children: [_jsx(InputLabel, __assign({ id: "module-select-label" }, { children: Translate({ term: 'therapy.select_modules' }) })), _jsx(MSelect, __assign({ labelId: "module-select-label", id: "module-select", multiple: true, value: modules, onChange: function (_a) {
                                            var target = _a.target;
                                            return dispatch(setLicenseFormDataField({ modules: target.value }));
                                        }, input: _jsx(OutlinedInput, { id: "select-multiple-module", label: Translate({ term: 'therapy.select_modules' }) }), renderValue: function (selected) { return (_jsx(Box, __assign({ sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }, { children: selected.map(function (moduleId) {
                                                var _a, _b;
                                                return (_jsx(Chip, { label: (_b = (_a = moduleOptions === null || moduleOptions === void 0 ? void 0 : moduleOptions.find(function (e) { return e.value === moduleId; })) === null || _a === void 0 ? void 0 : _a.optionText) !== null && _b !== void 0 ? _b : '' }, moduleId));
                                            }) }))); } }, { children: moduleOptions === null || moduleOptions === void 0 ? void 0 : moduleOptions.map(function (module) { return (_jsx(MenuItem, __assign({ value: module.value }, { children: module.optionText }), module.value)); }) }))] }))] })), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-5/12 mt-2" }, { children: [Translate({ term: 'licenses.license_valid' }), "*"] })), _jsx(Select, { selectProps: {
                                    id: 'license-valid',
                                    labelId: 'license-valid-label',
                                    label: Translate({
                                        term: 'admin.license.select_license_valid_until',
                                    }),
                                    value: licenseValidUntil,
                                    onChange: function (_a) {
                                        var target = _a.target;
                                        return dispatch(setLicenseFormDataField({ licenseValidUntil: target.value }));
                                    },
                                }, datas: validUntilOptions !== null && validUntilOptions !== void 0 ? validUntilOptions : [] })] })), validationErrors &&
                        validationErrors.length > 0 &&
                        validationErrors.map(function (errorMessage) { return (_jsx("div", __assign({ className: "text-warning-600 px-4 py-2 bg-white border border-warning-600 rounded-md mt-3 mb-3" }, { children: errorMessage }), errorMessage)); }), apiErrors &&
                        apiErrors.length > 0 &&
                        apiErrors.map(function (errorMessage, i) { return (_jsx("div", __assign({ className: "text-warning-600 px-4 py-2 bg-white border border-warning-600 rounded-md mt-3 mb-3" }, { children: Translate({ term: errorMessage }) }), i)); }), _jsxs("div", __assign({ className: "flex justify-end gap-4 mt-6" }, { children: [_jsx("button", __assign({ type: "submit", disabled: isSaving }, { children: Translate({ term: 'admin.license.create_license' }) })), _jsx("button", __assign({ type: "reset", className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300", disabled: isSaving }, { children: Translate({ term: 'actions.clear' }) }))] }))] }))] })));
};
export default CreateLicenses;
