var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from "../../../translation/Translate";
var Info = function () {
    var message = Translate({ term: "start_questionnaire.info.message" });
    var message2 = Translate({ term: "start_questionnaire.info.message2" });
    return (_jsxs("div", __assign({ className: "bg-white" }, { children: [_jsx("p", { children: message }), _jsx("p", { children: message2 })] })));
};
export default Info;
