export var THERAPY_PERIODS_TABS;
(function (THERAPY_PERIODS_TABS) {
    THERAPY_PERIODS_TABS[THERAPY_PERIODS_TABS["ONLINE_THERAPY"] = 0] = "ONLINE_THERAPY";
    THERAPY_PERIODS_TABS[THERAPY_PERIODS_TABS["THERAPISTS_OWN_PATIENTS"] = 1] = "THERAPISTS_OWN_PATIENTS";
    THERAPY_PERIODS_TABS[THERAPY_PERIODS_TABS["ALL_THERAPIES"] = 2] = "ALL_THERAPIES";
})(THERAPY_PERIODS_TABS || (THERAPY_PERIODS_TABS = {}));
export var PERIOD_FILTERS;
(function (PERIOD_FILTERS) {
    PERIOD_FILTERS["FINISHED"] = "finishedPeriods";
    PERIOD_FILTERS["SOON_TO_END"] = "soonToEndPeriods";
    PERIOD_FILTERS["PASSIVE"] = "passivePeriods";
    PERIOD_FILTERS["UNREAD_MESSAGES"] = "unreadMessages";
    PERIOD_FILTERS["NEW"] = "newPeriods";
    PERIOD_FILTERS["THERAPIST"] = "therapistTypePeriods";
})(PERIOD_FILTERS || (PERIOD_FILTERS = {}));
export var THERAPY_TYPES;
(function (THERAPY_TYPES) {
    THERAPY_TYPES["ALL"] = "";
    THERAPY_TYPES["ONLINE_THERAPY"] = "621c99462b8a5f60e78756c6";
    THERAPY_TYPES["THERAPISTS_OWN_PATIENTS"] = "622201bc2b8a5f60e7893fb9";
})(THERAPY_TYPES || (THERAPY_TYPES = {}));
export var QUESTIONNAIRE_TYPES;
(function (QUESTIONNAIRE_TYPES) {
    QUESTIONNAIRE_TYPES["START_QUESTIONNAIRE"] = "startQuestionnaire";
    QUESTIONNAIRE_TYPES["EVALUATION_QUESTIONNAIRE"] = "evaluationQuestionnaire";
    QUESTIONNAIRE_TYPES["SERVICE_NEED_ASSESSMENTS"] = "serviceNeedAssessments";
    QUESTIONNAIRE_TYPES["INTERIM_SURVEY"] = "interimSurvey";
    QUESTIONNAIRE_TYPES["FINAL_SURVEY"] = "finalSurvey";
})(QUESTIONNAIRE_TYPES || (QUESTIONNAIRE_TYPES = {}));
export var THERAPY_MODULE_TYPES;
(function (THERAPY_MODULE_TYPES) {
    THERAPY_MODULE_TYPES["KIELELLINEN_TIETOISUUS"] = "61f0ffc86fcb1a2901ede7f1";
    THERAPY_MODULE_TYPES["SANAVARASTO"] = "61f0fb706fcb1a2901ede7e6";
    THERAPY_MODULE_TYPES["VARHAINEN_KIELENKEHITYS"] = "61f1007f6fcb1a2901ede7f7";
    THERAPY_MODULE_TYPES["\u00C4\u00C4NNEVIRHEET"] = "61f01cbc6fcb1a2901ede7e1";
    THERAPY_MODULE_TYPES["VALIKOIVASY\u00D6J\u00C4"] = "61f0fff06fcb1a2901ede7f3";
    THERAPY_MODULE_TYPES["MONIKIELISYYS"] = "61f1002e6fcb1a2901ede7f5";
    THERAPY_MODULE_TYPES["PALVELUTARPEEN_ARVIO"] = "622202c82b8a5f60e7894027";
    THERAPY_MODULE_TYPES["MVP"] = "61f0ff8f6fcb1a2901ede7ef";
    THERAPY_MODULE_TYPES["PUHEEN_YMM\u00C4RT\u00C4MINEN"] = "61f0fbb76fcb1a2901ede7e8";
    THERAPY_MODULE_TYPES["KERRONTA"] = "61f0ff3b6fcb1a2901ede7ed";
    THERAPY_MODULE_TYPES["\u00C4\u00C4NNEKOULUN_\u00C4RR\u00C4KURSSI"] = "6221fb122b8a5f60e7893d12";
    THERAPY_MODULE_TYPES["\u00C4\u00C4NNEKOULUN_\u00C4LL\u00C4KURSSI"] = "6221fb252b8a5f60e7893d1d";
    THERAPY_MODULE_TYPES["\u00C4\u00C4NNEKOULUN_R_KLINIKAN_\u00C4RR\u00C4KURSSI"] = "6221fdb42b8a5f60e7893e1d";
    THERAPY_MODULE_TYPES["TERAPEUTIN_OMAT_ASIAKKAAT"] = "622201bc2b8a5f60e7893fb9";
})(THERAPY_MODULE_TYPES || (THERAPY_MODULE_TYPES = {}));
export var PERIOD_MATERIALS;
(function (PERIOD_MATERIALS) {
    PERIOD_MATERIALS["K_\u00C4\u00C4NNE"] = "64d0aefa9cde14d64c7164f5";
    PERIOD_MATERIALS["R_\u00C4\u00C4NNE"] = "64d0aefa9cde14d64c7164f8";
    PERIOD_MATERIALS["L_\u00C4\u00C4NNE"] = "64d0aefa9cde14d64c7164fd";
    PERIOD_MATERIALS["S_\u00C4\u00C4NNE"] = "64d0aefa9cde14d64c7164f4";
    PERIOD_MATERIALS["SANAVARASTO"] = "650aa4278be4769e64cf6c76";
    PERIOD_MATERIALS["NEUVOJA_HARJOITTELUN_ALOITTAMISEEN"] = "64d0aefa9cde14d64c7164f0";
    PERIOD_MATERIALS["LAPSEN_MOTIVOINTI_HARJOITTELUUN"] = "64d0aefa9cde14d64c7164f1";
    PERIOD_MATERIALS["N\u00C4IN_OHJAAT_LASTASI_\u00C4\u00C4NTEEN_HARJOITTELUSSA"] = "64d0aefa9cde14d64c7164e2";
    PERIOD_MATERIALS["HARJOITTELUM\u00C4\u00C4R\u00C4T"] = "64d0aefa9cde14d64c7164f2";
    PERIOD_MATERIALS["KANNUSTUSMATERIAALIA"] = "64d0aefa9cde14d64c7164f3";
    PERIOD_MATERIALS["N\u00C4IN_KUVAAT_VIDEON_PUHETERAPEUTILLE"] = "650a975a8be4769e64cf6aa3";
})(PERIOD_MATERIALS || (PERIOD_MATERIALS = {}));
/**
 * Different options for table listings where periods can be hidden from.
 */
export var PERIOD_VISIBILITY;
(function (PERIOD_VISIBILITY) {
    PERIOD_VISIBILITY["PASSIVE"] = "PASSIVE";
    PERIOD_VISIBILITY["SOON_TO_END"] = "SOON_TO_END";
    PERIOD_VISIBILITY["FINISHED"] = "FINISHED";
})(PERIOD_VISIBILITY || (PERIOD_VISIBILITY = {}));
// TODO: Do we need translates for these option texts?
export var QUESTIONNAIRE_LIST = [
    {
        value: QUESTIONNAIRE_TYPES.START_QUESTIONNAIRE,
        optionText: 'Alkukartoituskysely',
    },
    {
        value: QUESTIONNAIRE_TYPES.FINAL_SURVEY,
        optionText: 'Loppukysely',
    },
    {
        value: QUESTIONNAIRE_TYPES.EVALUATION_QUESTIONNAIRE,
        optionText: 'Palvelutarpeen arviot',
    },
    {
        value: QUESTIONNAIRE_TYPES.INTERIM_SURVEY,
        optionText: 'Välikysely',
    },
];
