var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from '../../translation/Translate';
import { clearLicenses, setIsLoading, setLicenses, setModalState, setSearchParamsField, setSelectAllLicenses, setValidationErrors, setZoneLicenseState, } from '../../../reducers/adminLicenseSlice';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { deleteAdminZoneLicenses, getAdminZoneLicenses, updateAdminZoneLicenses, } from '../../../actions/adminActions';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Pagination } from '../../common/Pagination';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import ZoneLicensesTable from './ZoneLicensesTable';
import ConfirmModal from '../../common/ConfirmModal';
import useUser from '../../../hooks/useUser';
var ZoneLicenses = function () {
    var _a;
    var id = useParams().id;
    var user = useUser();
    var mountedRef = useRef(true);
    var dispatch = useDispatch();
    var saveSuccessTranslate = Translate({ term: 'common.save_success' });
    var saveFailedTranslate = Translate({ term: 'common.save_failed' });
    var apiErrors = useSelector(function (state) { return state.apiError.errors; });
    var validationErrors = useSelector(function (state) { return state.adminLicense.validationErrors; });
    var licenseValidUntilTranslate = Translate({
        term: 'error.license_valid_until',
    });
    var zoneFormData = useSelector(function (state) { return state.adminLicense.zoneFormData; });
    var therapist = zoneFormData.therapist;
    var searchParams = useSelector(function (state) { return state.adminLicense.searchParams; });
    var paginatedLicenses = useSelector(function (state) { return state.adminLicense.paginatedLicenses; });
    var selectedLicenses = useSelector(function (state) { return state.adminLicense.selectedLicenses; });
    var zoneLicenseState = useSelector(function (state) { return state.adminLicense.zoneLicenseState; });
    var modalState = useSelector(function (state) { return state.adminLicense.modalState; });
    var isUpdating = useSelector(function (state) { return state.adminLicense.isLoading; });
    var sort = searchParams.sort, order = searchParams.order, page = searchParams.page;
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsFetching = _b[1];
    var onSearch = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var licenses;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsFetching(true);
                    if (!user.session)
                        return [2 /*return*/];
                    if (!id)
                        return [2 /*return*/];
                    return [4 /*yield*/, getAdminZoneLicenses(__assign(__assign(__assign({ _id: id, therapist: therapist ? 1 : 0 }, (sort && { sort: sort })), (order && { order: order })), { page: page, limit: 10 }), user.session)];
                case 1:
                    licenses = _a.sent();
                    if (licenses) {
                        dispatch(setLicenses(licenses));
                    }
                    setIsFetching(false);
                    return [2 /*return*/];
            }
        });
    }); }, [(_a = user.session) === null || _a === void 0 ? void 0 : _a.accessToken, searchParams, dispatch]);
    useEffect(function () {
        onSearch();
        // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function. https://stackoverflow.com/questions/56450975/to-fix-cancel-all-subscriptions-and-asynchronous-tasks-in-a-useeffect-cleanup-f
        return function () {
            mountedRef.current = false;
            dispatch(clearLicenses());
        };
    }, [page, sort, order]);
    var showSuccessToastMessage = function () {
        toast(saveSuccessTranslate, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 5000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
    };
    var showFailedToastMessage = function () {
        toast(saveFailedTranslate, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 5000,
            toastId: 'failedId',
            className: 'toast-message-warning',
        });
    };
    var formIsValid = function () {
        var errorMessages = [];
        if (selectedLicenses.some(function (license) {
            return !license._id ||
                license.licenseValidUntil === undefined ||
                license.licenseValidUntil === null;
        }))
            errorMessages.push(licenseValidUntilTranslate);
        return errorMessages;
    };
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errorMessages, updatedZoneLicensesData, updatedZoneLicensesData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch(setIsLoading(true));
                    errorMessages = formIsValid();
                    if (errorMessages.length > 0) {
                        dispatch(setValidationErrors(errorMessages));
                        dispatch(setIsLoading(false));
                        return [2 /*return*/];
                    }
                    if (!selectedLicenses || !user.session)
                        return [2 /*return*/];
                    if (!(zoneLicenseState === 'editing')) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateAdminZoneLicenses(selectedLicenses, user.session)];
                case 1:
                    updatedZoneLicensesData = _a.sent();
                    if (updatedZoneLicensesData) {
                        showSuccessToastMessage();
                        dispatch(setSelectAllLicenses([]));
                        dispatch(setZoneLicenseState('listing'));
                        onSearch();
                    }
                    else {
                        showFailedToastMessage();
                    }
                    return [3 /*break*/, 4];
                case 2:
                    if (!(zoneLicenseState === 'deleting')) return [3 /*break*/, 4];
                    return [4 /*yield*/, deleteAdminZoneLicenses(selectedLicenses, user.session)];
                case 3:
                    updatedZoneLicensesData = _a.sent();
                    if (updatedZoneLicensesData) {
                        showSuccessToastMessage();
                        dispatch(setSelectAllLicenses([]));
                        dispatch(setZoneLicenseState('listing'));
                        onSearch();
                    }
                    else {
                        showFailedToastMessage();
                    }
                    _a.label = 4;
                case 4:
                    dispatch(setIsLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(ToastContainer, { limit: 1 }), _jsxs("div", __assign({ className: "flex flex-row gap-7 justify-end mt-6" }, { children: [zoneLicenseState === 'editing' && (_jsxs(_Fragment, { children: [_jsx("button", __assign({ type: "button", className: "mt-3 -ml-4 border-2 border-gray-200 bg-white text-gray-500", onClick: function () { return dispatch(setZoneLicenseState('listing')); }, disabled: isUpdating }, { children: Translate({ term: 'actions.cancel' }) })), _jsx("button", __assign({ type: "button", className: "mt-3 -ml-4 border-2", disabled: selectedLicenses.length < 1 || isUpdating, onClick: onSubmit }, { children: Translate({ term: 'actions.save' }) }))] })), zoneLicenseState === 'listing' && (_jsxs(_Fragment, { children: [_jsx("button", __assign({ type: "button", className: "mt-3 -ml-4 border-2", disabled: selectedLicenses.length < 1 || isUpdating, onClick: function () { return dispatch(setZoneLicenseState('editing')); } }, { children: Translate({ term: 'actions.modify' }) })), _jsx("button", __assign({ type: "button", className: "mt-3 -ml-4 border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300", disabled: selectedLicenses.length < 1 || isUpdating, onClick: function () {
                                    dispatch(setZoneLicenseState('deleting'));
                                    dispatch(setModalState('deleting'));
                                } }, { children: Translate({ term: 'actions.delete' }) }))] }))] })), validationErrors &&
                validationErrors.length > 0 &&
                validationErrors.map(function (errorMessage) { return (_jsx("div", __assign({ className: "text-warning-600 px-4 py-2 bg-white border border-warning-600 rounded-md mt-3 mb-3" }, { children: errorMessage }), errorMessage)); }), apiErrors &&
                apiErrors.length > 0 &&
                apiErrors.map(function (errorMessage, i) { return (_jsx("div", __assign({ className: "text-warning-600 px-4 py-2 bg-white border border-warning-600 rounded-md mt-3 mb-3" }, { children: Translate({ term: errorMessage }) }), i)); }), isLoading && (_jsx(Box, __assign({ sx: { width: '100%', maxWidth: '72rem' } }, { children: _jsx(LinearProgress, {}) }))), !isLoading && _jsx(ZoneLicensesTable, {}), _jsx("div", __assign({ className: "w-full max-w-6xl mt-8" }, { children: _jsx(Pagination, { activePage: paginatedLicenses.page, maxPage: paginatedLicenses.totalPages, switchPage: function (page) { return dispatch(setSearchParamsField({ page: page })); } }) })), _jsx(ConfirmModal, { open: selectedLicenses.length > 0 &&
                    zoneLicenseState === 'deleting' &&
                    modalState === 'deleting', onClose: function () { return dispatch(setModalState('listing')); }, message: [Translate({ term: 'licenses.remove_licenses_confirmation' })], deleteSelectedItem: onSubmit })] }));
};
export default ZoneLicenses;
