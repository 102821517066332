var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainMenu from '../client/MainMenu/MainMenu';
var ClientLayout = function () {
    var _a = __read(useState(true), 2), show = _a[0], setShow = _a[1];
    var periods = useSelector(function (state) { return state.periods.periods; });
    return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "layout client-layout" }, { children: [periods &&
                    periods.some(function (period) {
                        var _a;
                        return ((_a = period.startQuestionnaire) === null || _a === void 0 ? void 0 : _a.questionnaire) !== '' &&
                            !period.started &&
                            !period.startVideo;
                    }) ? (_jsx(_Fragment, {})) : (_jsx(MainMenu, { setShow: setShow, show: show })), show ? (_jsx("div", __assign({ className: "page mt-2 pt-2 bg-gray-50 rounded-tl-3xl" }, { children: _jsx(Outlet, {}) }))) : null] })) }));
};
export default ClientLayout;
