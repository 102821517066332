var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Moment from 'react-moment';
import CloseIcon from '@mui/icons-material/Close';
import { Translate } from '../../../translation/Translate';
var PatientInfo = function (_a) {
    var patient = _a.patient, expanded = _a.expanded, setSelectedPeriod = _a.setSelectedPeriod;
    var dateFormatTranslate = Translate({ term: 'common.date_format' });
    var birthdayTranslate = Translate({ term: 'profile.birthday' });
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "flex flex-row justify-between" }, { children: [_jsxs("div", __assign({ className: "flex gap-3" }, { children: [_jsx("img", { className: "w-28 h-28 min-w-28 min-h-28", src: "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg" }), _jsxs("div", __assign({ className: "flex flex-col justify-between" }, { children: [_jsxs("h2", __assign({ className: "text-xl mt-6" }, { children: [patient === null || patient === void 0 ? void 0 : patient.firstName, " ", patient === null || patient === void 0 ? void 0 : patient.lastName] })), _jsx("p", __assign({ className: "mb-6" }, { children: "Diarium-numero" }))] }))] })), _jsx("div", __assign({ className: "cursor-pointer mt-2 ml-1", onClick: function () { return setSelectedPeriod(null); } }, { children: _jsx(CloseIcon, {}) }))] })), _jsxs("div", __assign({ className: "flex flex-row mt-1" }, { children: [_jsx("p", __assign({ className: expanded ? 'w-1/6 -ml-1' : 'w-1/3' }, { children: birthdayTranslate })), patient && patient.dateOfBirth ? (_jsx("p", __assign({ className: "border rounded-md px-2 py-0.5 text-gray-600" }, { children: _jsx(Moment, __assign({ format: dateFormatTranslate }, { children: patient === null || patient === void 0 ? void 0 : patient.dateOfBirth })) }))) : (_jsx("p", __assign({ className: "text-gray-600" }, { children: "-" })))] }))] }));
};
export default PatientInfo;
