var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '../../translation/Translate';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ListAlt from '@mui/icons-material/ListAlt';
import { Chip } from '@mui/material';
import { isPeriodValid } from '../../../functions/periods';
import useAppSelector from '../../../hooks/useAppSelector';
var PeriodTitle = function (_a) {
    var assignmentCount = _a.assignmentCount, messageStyle = _a.messageStyle, assingmentStyle = _a.assingmentStyle, children = _a.children;
    var navigate = useNavigate();
    var t = useTranslate();
    var selectedPeriod = useAppSelector(function (state) { return state.periods.active; });
    return (_jsxs("div", __assign({ className: "flex flex-col items-center justify-center gap-3 -mt-6 lg:mt-0" }, { children: [_jsx("div", __assign({ className: "flex-auto" }, { children: _jsx("h1", __assign({ className: "text-blue" }, { children: t('therapy.types.therapy_session') })) })), children && children, _jsxs("div", __assign({ className: "relative flex flex-row space-x-0.5" }, { children: [Number(assignmentCount) > 0 && (_jsx(Chip, { className: "absolute -top-1 -right-1", style: {
                            backgroundColor: '#FFB35F',
                            color: 'black',
                            fontSize: 'bold',
                        }, label: assignmentCount, size: "small" })), _jsxs("button", __assign({ onClick: function () { return navigate("/period/".concat(selectedPeriod._id)); }, className: messageStyle }, { children: [t('nav.messages'), " ", _jsx(ChatBubbleOutlineOutlinedIcon, {})] })), _jsxs("button", __assign({ onClick: function () { return navigate('/period/assignments'); }, className: assingmentStyle, disabled: !isPeriodValid(selectedPeriod) }, { children: [t('nav.assignments'), " ", _jsx(ListAlt, {})] }))] }))] })));
};
export default PeriodTitle;
