var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, createContext } from 'react';
import locales from './ILocales';
import { defaultLanguange } from '../../config/languageSettings';
export var LanguageContext = createContext(defaultLanguange);
export var Translate = function (_a) {
    var term = _a.term, value = _a.value;
    var userLang = useContext(LanguageContext);
    //const userLang = useSelector((state: AppState) => state.settings.language);
    var localeLang = locales.find(function (a) { return a.id === userLang; });
    var defaultState = {
        locales: locales,
        currentLocale: localeLang || locales[0],
    };
    var LocaleContext = React.createContext(defaultState);
    var termArray = term.split('.');
    var locale = useContext(LocaleContext).currentLocale.lang;
    var translation = __assign({}, locale);
    function finder(t, keys) {
        if (t.hasOwnProperty(keys[0])) {
            var obj = __assign({}, t)[keys[0]];
            keys.shift();
            if (keys.length > 0 && typeof obj === 'object') {
                return finder(obj, keys);
            }
            else if (typeof obj === 'object') {
                return JSON.stringify(obj);
            }
            else {
                return obj;
            }
        }
    }
    var t = finder(translation, termArray);
    return t;
};
export function useTranslate() {
    var userLang = useContext(LanguageContext);
    var locale = locales.find(function (locale) { return locale.id === userLang; });
    var translation = locale ? locale.lang : locales[0].lang;
    function t(term) {
        var termArray = term.split('.');
        function finder(t, keys) {
            if (t.hasOwnProperty(keys[0])) {
                var obj = __assign({}, t)[keys[0]];
                keys.shift();
                if (keys.length > 0 && typeof obj === 'object') {
                    return finder(obj, keys);
                }
                else if (typeof obj === 'object') {
                    return JSON.stringify(obj);
                }
                else {
                    return obj;
                }
            }
        }
        return finder(translation, termArray);
    }
    return t;
}
;
