export var langFi = {
    site: {
        name: 'Äännekoulu',
        name_short: 'ÄK',
        secondary_name: 'Terapiapolku',
        logo: '/assets/images/aannekoulu-logo.png',
        logo_square: '/assets/images/ak-logo.png',
    },
    nav: {
        modules: 'Moduulit',
        search: 'Hae asiakkaan nimellä',
        home: 'Koti',
        periods: 'Terapiajaksot',
        active_periods: 'Aktiiviset terapiajaksot',
        new_clients: 'Uudet asiakkaat',
        new_periods: 'Uudet jaksot',
        end_reports: 'Loppuyhteenvetoa odottavat jaksot',
        periodhistory: 'Jaksohistoria',
        period: 'Terapiajakso',
        evaluations: 'Palvelutarpeen kartoitukset',
        questioinnaires: 'Kyselyt',
        assignments: 'Harjoitukset',
        material_library: 'Materiaalikirjasto',
        plan_assignments: 'Suunnittele harjoitukset',
        messages: 'Viestit',
        links: 'Linkit',
        share_license: 'Jaa lisenssi',
        quick_help: 'Pikahelppi',
        instructions: 'Ohjeet',
        customer_service: 'Asiakaspalvelu',
        profile: 'Profiili',
        log_out: 'Kirjaudu ulos',
        close: 'Sulje',
        users: 'Sisäiset käyttäjät',
    },
    login: {
        welcome: 'Kirjaudu nettipuheterapiaan',
        enter_username: 'Anna käyttäjätunnus',
        username: 'Käyttäjätunnus',
        enter_password: 'Anna salasana',
        password: 'salasana',
        login: 'Kirjaudu sisään',
        forgotten_password: 'Olen unohtanut salasanani',
        login_no_match: 'Käyttätunnus ja salasana eivät täsmää',
        login_failed: 'Kirjautuminen epäonnistui',
        username_missing: 'Kirjautumiseen vaaditaan käyttäjätunnus',
        password_missing: 'Kirjautumiseen vaaditaan salasana',
        confirm_username: 'Vahvista käyttäjä tunnus',
    },
    home: {
        licence_controller: 'Lisenssin hallitsija',
        admin: 'Admin',
        therapists: 'Terapeutit',
        loading_user_information: 'Ladataan käyttäjätietoja',
        welcome: 'Tervetuloa nettipuheterapiaan',
        therapist_announcements: 'Puheterapeutti tiedottaa',
        your_therapist: 'Puheterapeuttinne',
        your_own_therapist: 'Terapeuttinne',
        your_periods: 'Terapiajaksonne',
        common_practice_instructions: 'Yleiset ohjeet harjoitteluun',
        today: 'Tänään on',
        you_have: 'Sinulla on',
        view_all: 'Katso kaikki',
        new_clients: 'Uutta asiakasta',
        new_messages: 'Uutta viestiä',
        end_reports: 'Loppuyhteenvetoa odottavaa jaksoa',
        periods_new: "Uutta jaksoa",
        periods_passive: 'Passiivista jaksoa',
        periods_ended: 'Päättynyttä jaksoa',
        own_free: 'Omat vapaat asiakaspaikat',
        total_free: 'Vapaat paikat koko Äännekoulu',
        free: 'Vapaat asiakaspaikat',
        active: 'Käynnissä olevat jaksot',
        write_end_reports: 'Kirjoita yhteenvedot',
        client: 'Asiakas',
        therapist: 'Terapeutti',
        content: 'Sisältö',
        phase: 'Ohjauskerrat',
        last_message: 'Viimeisin viesti',
        sent_time: 'Lähetysaika',
        message: 'Viesti',
        current: 'Ajankohtaista',
        front_page: 'Etusivu',
        new_msg_practice_info: 'Teille on uusia viestejä ja harjoituksia',
        info: 'Tiedotteet',
    },
    period: {
        message_date_format: "DD.MM.YYYY HH:mm",
        delete_message_confirmation: 'Haluatko varmasti poistaa viestin?',
        update_therapist_note: 'Muokkaa muistiinpanoja',
        last_ended_period: 'Viimeisin päättynyt terapiajaksonne',
        active_period: 'Aktiivinen terapiajakso',
        ended_periods: 'Päättyneet terapiajaksot',
        end_date: 'Jakso päättyy',
        add_new_guardian: 'Lisää toinen huoltaja',
        search_result: 'Hakutulokset',
        started: 'Aloitus pvm',
        ended: 'Päättymis pvm',
        customers_without_period: 'Asiakkaat ilman käynnissä olevaa terapiajaksoa',
        all_periods: 'Kaikki jaksot',
        only_active_periods: 'Vain käynnissä olevat jaksot',
        only_all_ended_periods: 'Vain päättyneet jaksot',
        only_reasently_ended_periods: 'Vain päättyneet jaksot (viimeiset 3kk)',
        period_time: 'Terapiajakson kesto',
        sessions_completed_start: 'Kerta',
        sessions_completed_end: 'ohjauskertaa käytetty',
        mark_session_completed: 'Merkitse kerta käytetyksi',
        mark_session_completed_confirmation: 'Haluatko varmasti merkitä kerran käytetyksi?',
        paying_party: 'Maksajataho',
        zone_instructions: 'Alueen ohjeet',
        active_assignments: 'Aktiiviset harjoitukset',
        assignment_history: 'Tehdyt harjoitukset',
        assignments_waiting: 'Odottavat harjoitukset',
        assignments_history: 'Harjoitushistoria',
        feedback_surveys: 'Palautekyselyt',
        add_assignment: 'Lisää harjoitus',
        edit_duration: 'Muokkaa kestoa',
        set_ending_date: 'Valitse päättymispäivä',
        response_dates: 'Terapeutin vastauspäivä(t)',
        all_assignments_done: 'Kaikki harjoitukset tehty!',
        no_new_messages: 'Ei uusia viestejä',
        no_new_assignments: 'Ei uusia harjoituksia',
        no_new_msg_or_assignments: 'Ei uusia viestejä tai harjoituksia',
        no_new_posts: 'Ei uusia tiedotteita',
        end_report: 'Pian päättyvää jaksoa',
        start_questionnaire_done: 'Alkukartoitus tehty',
        confirm_period: 'Vahvista terapiajakson aloitus',
        confirm_period_text: 'Vahvista jakson aloitus',
        start_questionnaire: 'alkukartoituskysely',
        cancel_period_starting: 'Peruuta aloitus',
        confirm_cancel_period_starting: 'Haluatko varmasti peruuttaa aloituksen?',
        assignment_has_been_added: 'Harjoitus lisättiin terapiajaksoon!',
        assignment_is_already_added: 'Harjoitus on jo terapiajaksossa!',
        assginment_returned: 'Harjoitus palautettu',
        assignment_return_error: 'Harjoituksen palautus epäonnistui',
        back_to_period: 'Takaisin terapiajaksoon',
        search_all: 'Hae koko Äännekoulusta',
        write_diarium_number_start_period: 'Kirjoita diarium numero ja aloita jakso.',
        diarium_number: 'Diarium numero',
        select_module_category: 'Valitse moduulin kategoria',
        select_category: 'Valitse kategoria',
        start_period: 'Aloita jakso.',
        error_messages: {
            select_category: 'Valitse kategoria.',
            write_diarium_number: 'Kirjoita diarium numero',
        },
        period_duration: 'Jakson kesto: ',
        change_period_duration: 'Muokkaa jakson kestoa',
        share_query: 'Jaa kysely',
        query_shared: 'Kysely jaettu onnistuneesti!',
        message_deleted: 'Viesti poistettu',
        period_count: 'Jaksojen määrä',
        latest_sent_message: "Viimeisin lähetetty viesti",
    },
    periods: {
        period_time: 'Jakson kesto',
        search: 'Hae terapiajaksoja',
        not_found: 'ei löytynyt terapiajaksoja',
        periods_passive: 'Passiiviset jaksot',
    },
    client: {
        period_history: 'Aiemmat terapiajaksot',
        guardians: 'Huoltajat',
        guardian: 'Huoltaja',
        ageGroupType: {
            ageGroupType1: '3v0kk-3v11kk',
            ageGroupType2: '4v0kk-4v11kk',
            ageGroupType3: '5v0kk-5v11kk',
            ageGroupType4: '6v0kk-6v11kk',
            ageGroupType5: 'Koulu-ikäiset',
            ageGroupType6: 'Varhaiskasvatuksen kuvaus lapsesta puheterapeutille',
        },
    },
    zone: {
        notes: 'Alueen muistiinpanot',
        create_zone: 'Luo alue',
        zone_name: 'Kunta',
        zone: 'Alue',
        area: 'Työskentelyalueet',
        evaluation_zones: 'Palvelutarpeenarvio alueet',
    },
    assignment: {
        approved: 'Hyväksytty',
        to_be_completed: 'Suoritettava',
        by: 'mennessä',
    },
    evaluations: {
        sent_licenses: 'Lähetetyt lisenssit',
        waiting_evaluation: 'Odottaa käsittelyä',
        evaluated: 'Käsitellyt',
        evaluate: 'Käsitelty',
        age_group: 'Ikäryhmä',
        remove_reservation: 'Poista varaus',
        created_at: 'Täytetty',
    },
    evaluation: {
        evaluate: 'Tee palvelutarpeen arvio',
        evaluations_answers: 'Lomakkeen vastaukset',
        notes: 'Terapeutin muistiinpanot',
        responsible_therapist: 'Vastuuterapeutti',
        directed: 'Ohjattu',
        submit_evaluation: 'Merkitse käsitellyksi',
        submit_evaluation_confirmation: 'Haluatko varmasti merkitä käsitellyksi',
        cancel_evaluation: 'Peru käsittely',
        cancel_evaluation_confirmation: 'Haluatko varmasti perua käsittelyn',
        questionnaire_selection: {
            text1: 'Tällä arviolomakkeella kartoitamme lapsenne puheterapiatarpeen.',
            text2: 'Valitkaa tästä lapsenne ikä, ohjaamme teidät oikean arvion äärelle.',
            text3: 'Valitse kysely.',
            text4: 'Arvioon',
        },
    },
    messages: {
        mark_read: 'Merkitse luetuksi',
        post_for_therapists: 'Uusi tiedote luotiin terapeuteille',
        post_updated: 'Tiedote on muokattu!',
        post_removed: 'Tiedote on poistettu!',
        new_zone_created: 'Uusi alue luotu!',
        topic: 'Aihe',
        new_message: 'Uusi viesti',
        write_message: 'Kirjoita viesti',
        new_message_template: 'Uusi viestipohja',
        new_messages: 'Uudet viestit',
        attachments: 'Liitteet',
        admin_messages: 'Viestit admin',
        sending_group_messages: 'Ryhmäviestien lähetys',
        templates: 'Viestipohjat',
        template: 'Viestipohja',
        select_message_template: 'Valitse viestipohja',
        select_client_groups: 'Valitse asiakasryhmät',
        zone_templates: 'Alueiden viestipohjat',
        common_templates: 'Yhteiset viestipohjat',
        personal_templates: 'Omat viestipohjat',
        general_templates: 'Yhteiset viestipohjat',
        no_messages: 'Ei viestejä',
        type: 'Tyyppi',
        message_content: 'Viestin sisältö',
        client_group: 'Valitse asiakasryhmä',
        send_message_through_platform: 'Lähetä omille asiakkaille viesti alustan kautta',
        message_sent: 'Viesti lähetetty',
        messages_subject: 'Viestin aihe',
        own_customers: 'Kaikki omat asiakkaat',
        ends: 'päättyy',
        post_created: 'Tiedote lähetetty',
        post_content: 'Tiedotteen sisältö',
        sended: 'Lähetetty',
        no_guardian_found: 'Ei löytynyt huoltajaa näillä tiedoilla, lisääminen epäonnistui',
        message_history: 'Viestihistoria',
        zone_updated_success: 'Alueen päivitys onnistui.',
        zone_updated_failed: 'Alueen päivitys epäonnistui.',
    },
    profile: {
        customer_updated_successfully: 'Käyttäjä muokattiin onnistuneesti!',
        is_active: 'Käyttäjän tili on aktiivinen',
        user_active: 'Käyttäjätili on nyt aktiivinen',
        user_deactive: 'Käyttäjätili on otettu pois käytöstä',
        email_again: 'Sähköposti uudelleen',
        user_created: 'Käyttäjä luotu',
        user: 'Käyttäjä',
        email_address: 'Sähköpostiosoite',
        user_names: 'Käyttäjän etu- ja sukunimi',
        first_and_last_name: 'Etu- ja sukunimi',
        birth_year: 'Syntymävuosi',
        name: 'Nimi',
        first_name: 'Etunimi',
        last_name: 'Sukunimi',
        ssn: 'Henkilötunnus',
        ssn_example: '010101-123D',
        email: 'Sähköposti',
        phone: 'Puhelinnumero',
        personal_availability: 'Oma käytettävyys nettiterapiassa',
        clients: 'asiakasta',
        response_days: 'Vastauspäivät',
        response_day: 'Vastauspäivä',
        therapist_introduction: 'Terapeutin esittelyteksti',
        therapist_intro_video: 'Terapeutin esittelyvideo',
        absence_notice: 'Poissaloilmoitus',
        absence_time: 'Poissaoloaika',
        starting_day: 'Aloituspäivä',
        ending_day: 'Päättymispäivä',
        absence_message: 'Poissaoloviesti',
        autoreply: 'Automaattinen vastaus',
        message_sending: 'Viestin lähetys',
        confirm_absence: 'Vahvista poissaolo',
        no_absence_notice: 'Ei poissaloilmoitusta',
        change_password: 'Vaihda salasana/käyttäjätunnus',
        extend_therapy_periods: 'Pidennä asiakkaiden terapiajaksoja',
        days: 'päivää',
        edit_profile: 'Muokkaa profiilia',
        edit_info: 'Muokkaa tietoja',
        nickname: 'Nimimerkki',
        guardian_info: 'Huoltajan tiedot',
        edit_password: 'Salasanan vaihto',
        old_password: 'Vanha salasana',
        new_password: 'Uusi salasana',
        new_password_again: 'Uusi salasana uudestaan',
        save_password: 'Tallenna salasana',
        show_password: 'Näytä salasana',
        birthday: 'Syntymäaika',
        open: 'Avaa profiili',
        select_language: 'Valitse asiakkaan kieli',
        admin: 'Ylläpitäjä',
        status: {
            s61d42db9ad4a0c39168efe80: 'Ylläpitäjä',
            s61d42db9ad4a0c39168efe72: 'Lisenssin hallitsija',
            s61cdd00ead4a0c39168daee1: 'Terapeutti',
        },
        write_email: 'Kirjoita sähköpostiosoitteesi',
        incorrect_email: 'Virheellinen sähköposti!',
        success_email: 'Viesti lähetettiin vastaanottajalle. Saat pian sähköpostiisi viestin. Jos et saa viestiä, varmista, että olet antanut saman sähköpostiosoitteen kuin rekisteröitymisessä. Yritä uudelleen.',
        change_passw: 'Vaihda salasana',
        different_passwords: 'Salasanat eivät täsmää!',
        password_change_success: 'Salasana vaihdettu onnistuneesti!',
        password_change_failed: 'Salasanan vaihto epäonnistui. Syynä saattaa olla vanhentunut linkki',
        passw_change_failed: 'Salasanan vaihto epäonnistui.',
        password_change_info: 'Salasana vaatii vähintään 14 merkkiä, pienen kirjaimen, suuren kirjaimen, numeron ja erikoismerkin.',
        password_change_error: 'Jotain meni vikaan. Varmista, että salasanassa on vähintään 14 merkkiä, pieni kirjain, suuri kirjain, numero ja erikoismerkki.',
    },
    languages: {
        finnish: 'suomeksi',
        swedish: 'ruotsiksi',
    },
    material_library: {
        material_removed: 'Materiaali on poistettu',
        material_updated: 'Materiaali muokattiin onnistuneesti!',
        vimeo_link: 'Vimeo linkki',
        game_id: 'Pelin ID',
        material_name: 'Materiaalin nimi',
        search_by_name: 'Etsi materiaalin nimellä',
        get_materials: 'Hae ja selaa materiaaleja',
        choose_category: 'Valitse kategoria',
        write_tags: 'Kirjoita uusi tagi...',
        choose_material_type: 'Valitse materiaalin tyyppi',
        material_link: 'Linkki materiaaliin',
        description: 'Kuvaus',
        search_materials: 'Selaa materiaaleja',
        upload_new_material: 'Lataa uutta materiaalia',
        open_material: 'Avaa materiaali',
        open_material_to_new_window: 'Avaa materiaali uuteen ikkunaan',
        open_game_to_new_window: 'Avaa peli uuteen ikkunaan',
        content: 'Sisältö',
        assignment: 'Harjoitus',
        client_instructions: 'Asiakkaan ohje',
        instructions: 'Ohje',
        share_assignment: 'Jaa harjoitus',
        select_customer: 'Valitse asiakas',
        wide_search: 'Laaja haku',
        module: 'Moduuli',
        category: 'Kategoria',
        phase: 'Terapian vaihe',
        language: 'Kieli',
        material_type: 'Materiaalityyppi',
        tags: 'Tagit',
        hierarchies: 'Hierarkiat',
        free_text_search: 'Vapaa tekstihaku',
        game: 'Peli',
        video: 'Video',
        text: 'Teksti',
        all: 'Kaikki',
        search_results: 'hakutulosta',
        materials: 'Materiaalit',
        upload_new_material_message: 'Lataa uutta materiaalia palvelimelle',
        update_material: 'Muokkaa materiaalia',
        upload_info: {
            video: 'Videoiden täytyy olla vimeo linkki',
            game: 'Pelin ID täytyy olla numero',
            text: 'Tekstitiedostot voivat olla pdf, doc, docx, odt, txt, rtf, md, ods, xls tai xml-tyyppiä.',
            choose_link_or_game: 'Valittuasi materiaalin tyyppi, valitse vimeo linkki, tekstitiedosto tai pelin ID',
        },
        upload_wargning_messages: {
            name_required: 'Materiaalin nimi on pakollinen',
            type_required: 'Materiaalin tyyppi on pakollinen',
            module_required: 'Materiaalin moduuli on pakollinen',
            option_info: 'Valitse joko materiaalin linkki tai tiedosto koneelta',
            wrong_file_format: 'Väärä tiedostomuoto',
            something_went_wrong: 'Jotain meni vikaan, yritä uudestaan',
            success: 'Materiaali lisätty!',
            game_id_is_not_number: 'Pelin ID ei ole numero',
            link_or_game_id: 'Linkki tai pelin ID',
        },
    },
    start_questionnaire: {
        info: {
            message: 'Voit nyt jatkaa videoiden ja alkukartoituksen tekemiseen. Varaathan niiden tekemiseen riittävästi aikaa (20-30) minuuttia.',
            message2: 'Videoiden kuvaamisen ja alkukartoitukseen vastaamisen jälkeen puheterapeutti ottaa teihin yhteyttä viikon sisällä. Saatte häneltä myös ensimmäiset kotiharjoitukset.',
        },
        all_done_message: {
            message: 'Kaikki valmista!',
            message2: 'Puheterapeutti ottaa teihin yhteyttä viikon sisällä!',
        },
    },
    licenses: {
        create_new_licensezone: 'Luo uusi lisenssialue',
        create_licences: 'Luo lisenssejä',
        search_licences: 'Selaa lisenssejä',
        control_licences: 'Lisenssien hallinta',
        licenses: 'Lisenssit',
        share_license_to_client: 'Jaa asiakkaalle lisenssi',
        pick_license: 'Valitse lisenssi',
        license_code: 'Lisenssikoodi',
        valid_until: 'Varaus päättyy',
        free_licenses: 'Vapaat lisenssit',
        reserved_licenses: 'Varatut lisenssit',
        used_licenses: 'Käytetyt lisenssit',
        license_id: 'LisenssiId',
        license_key: 'Lisenssi avain',
        license_valid: 'Lisenssi voimassa',
        reserved_till: 'Varattu asti',
        invitation_valid_until: 'Kutsun voimassaoloaika (päivinä)',
        used: 'Käytetty',
        print: 'Tulosta ohjeet',
        license_shared_message: 'Lisenssi jaettu onnistuneesti!',
        remove_license_confirmation: 'Haluatko varmasti poistaa lisenssin?',
        remove_licenses_confirmation: 'Haluatko varmasti poistaa lisenssit?',
        license_type: 'Lisenssin tyyppi',
        reserver_email: 'Varaajan sähköposti',
        license_left: 'Lisenssiä jäljellä',
        extension_license: 'Jatkolisenssi',
        evaluation_error_message: 'Palvelutarpeen arvio lisenssille ei voida luoda jatkolisenssiä.',
        general_license_error_message: 'Jotain meni vikaan. Varmista että henkilötunnus on oikein ja että asiakkaalla on olemassa oleva periodi, jolle jatkolisenssi luodaan.',
    },
    therapy: {
        therapy_types: 'Terapiamuodot',
        module: 'Moduuli',
        therapy_type: 'Terapiamuoto',
        select_therapy_type: 'Valitse terapiamuoto',
        select_module: 'Valitse moduuli',
        select_modules: 'Valitse moduulit',
        select_zone: 'Valitse alue',
        type: 'Terapiatyyppi',
        select_therapy_module: 'Valitse terapiamoduuli',
        types: {
            all_periods: 'Kaikki jaksot',
            all_customers: 'Kaikki asiakkaat',
            new_periods: 'Uudet jaksot',
            all: 'Kaikki',
            online: 'Nettiterapia',
            hybrid: 'Hybriditerapia',
            remote: 'Terapeutin omat asiakkaat',
            onsite: 'Lähiterapia',
            evaluations: 'Palvelutarpeen arvioinnit',
            pt_valuation: 'Pt-arvio',
            maternity_clinic_follow_up: 'Neuvolan seuranta',
            therapy_session: 'Terapiajakso',
            specialized_medical_care: 'Erikoissairaanhoito',
            no_actions: 'Ei toimenpiteitä',
        },
        modules: {
            m61f01cbc6fcb1a2901ede7e1: 'Äännevirheet',
            m61f0fb706fcb1a2901ede7e6: 'Sanavarasto',
            m61f0ffc86fcb1a2901ede7f1: 'Kielellinen tietoisuus',
            m61f0fff06fcb1a2901ede7f3: 'Valikoiva syöjä',
            m61f1002e6fcb1a2901ede7f5: 'Monikielisyys',
            m61f1007f6fcb1a2901ede7f7: 'Varhainen kielenkehitys',
            m622202c82b8a5f60e7894027: 'Palvelutarpeen arvio',
            m61f0ff8f6fcb1a2901ede7ef: 'MVP',
            m61f0fbb76fcb1a2901ede7e8: 'Puheen ymmärtäminen',
            m61f0ff3b6fcb1a2901ede7ed: 'Kerronta',
            m6221fb122b8a5f60e7893d12: 'Äännekoulun ärräkurssi',
            m6221fb252b8a5f60e7893d1d: 'Äännekoulun älläkurssi',
            m6221fdb42b8a5f60e7893e1d: 'Äännekoulun R-klinikan Ärräkurssi',
            m622201bc2b8a5f60e7893fb9: 'Etäterapia',
            basic_introductions: {
                // Same for all online modules
                title1: 'Videointi:',
                text1: 'Tehkää seuraavat videot lapsestanne. Katsokaa, että lapsen koko kasvot näkyvät edestäpäin ja katsokaa että kasvot ovat hyvin valaistut. Kuvatkaa kasvoja hieman alaviistosta. Pitäkää videon pituus mahdollisimman lyhyenä.',
                text2: 'Ennen kuvaamista suosittelemme tarkistamaan videointiasetuksenne. Videon tarkkuus voi olla pienempi, esimerkiksi 320x240, kuin HD-laatuinen. Näin videon koko on myös pienempi ja se on helpompi liittää ja lähettää. Tarkkuuttaa voitte pienetää puhelimen videointiasetuksistanne. Kayttäkää etukameraa videoimiseen. Tämä pienentää videon kokoa koska etukamera on resoluutioltaan pienempi. Tällä sivulla tarkempia ohjeita koon muuttamiseen: ',
                text3: 'Lähettäkää video alla olevalla viestipalvelulla valitsemalla tiedosto. Viestin lähetätte kirjoittamalla viesti viestikenttään ja painamalla "Lähetä". Lähettäessänne videon saatte viikon sisällä vastausvideon, jossa puheterapeuttimme kertoo soveltuvuudestanne Äännekouluun. Mikäli vastausviestiä ei kuulu viikon sisällä ottakaa yhteyttä asiakaspalvelu@aannekoulu.fi niin selvitämme asian.',
                text4: 'Jos videonne ovat isoja niiden latautuminen saattaa kestää jonkin aikaa. Malttakaa siis odottaa kunnes teidät ohjataan "Kaikki valmista" sivulle. Näin voitte olla varmoja että video on tullut perille.',
                text5: 'Riippuen formaatista videot konvertoidaan ja ne voidaan katsoa tästä suoraan selaimesta riippuen myöhemmin. Tuetut formaatit ovat mp4, mov, wmv, avi, m4v, ogg, 3gp, webm, mkv, mpg. Mikäli tämä ei onnistunut, ottakaa yhteyttä asiakaspalveluun osoitteessa asiakaspalvelu@aannekoulu.fi ja me autamme teitä eteenpäin.',
                text6: 'Videot kuvataan yhdessä',
                text7: 'Videointiohjeet',
                text8: 'Lataa video',
            },
            pronunciation_errors: {
                // Äännevirheet
                title1: 'Alkuarvioon tuleva videointitehtävä:',
                text1: 'Muroharjoitus: Katsokaa seuraava video ja kuvatkaa lapsenne tekemässä kyseinen harjoitus. Muron sijasta voi käyttää vaikka pientä litteää leipäpalaa.',
                text2: 'Videoi lapsesi sanomassa seuraavat sanat. Pyydä lasta toistamaan sanat aikuisen perässä, vaikka lapsi osaisikin lukea ne itse: Vauva, mummu, piippu, alla, ulla, aada, dino, aijaa, aiai',
                text3: 'Ässä Ysi Isi Saa Suu Juusto Veitsi Sohva Valas Luu Laalaa Pöllö Palapeli Askel Ruusu Ärrä Yrre Kurkku Orava Vihreä Varpaat Karkki Kaa Kiikkaa Kukkuu Akka Yksi Katti Tyyny Ötökkä Joulu Jänis Ajaa Lahja',
                text4: 'Lapsen puhetta spontaanisti. Lapsi voi kertoa esim. omasta harrastuksestaan tai jostain mielenkiinnon kohteesta',
            },
            vocabulary: {
                // Sanavarasto
                title1: 'OHJEET ALKUKARTOITUSVIDEOIDEN TEKEMISEEN:',
                title2: 'Kuvaa seuraavat kaksi videota puheterapeutillenne ja lähetä ne viestien kautta:',
                text1: 'Kuvaa video, jossa lapsi kertoo itsestään. Voit kysyä lapselta: mistä leikeistä sinä tykkäät? Keitä sinun perheeseesi kuuluu? Kerro lempilelustasi, minkälainen se on? Mitä haluaisit kysyä puheterapeutilta?',
                text2: 'Kuvaa toinen video, jossa lapsi kertoo jostain kirjan kuvasta, mitä siinä tapahtuu. Voit rohkaista nimeämään kuvasta löytyviä asioita ja kysyä lapselta lisäkysymyksiä: mitä lapset tekevät? Mitä lapset ajattelevat?',
                text3: 'Katso tästä tervetuloa -video:',
            },
            linguistic_awareness: {
                // kielellinen tietoisuus
                title1: 'Tervetuloa kielellisen tietoisuuden taitojen kuntoutukseen!',
                title2: 'Harjoittelu aloitetaan näin:',
                text1: 'Klikkaa VIESTIT (puhekupla). Täältä lähetetään viestit puheterapeutille myös jatkossa.',
                text2: 'Täyttäkää aluksi alkukartoituskysely, joka löytyy heti "VIESTIT"-osiosta alhaalta kohdasta "KYSELYT"  (kysely löytyy nimellä “Kielellisen tietoisuuden ja lukitaitojen alkukartoitus")',
                text3: 'Kyselyn täyttämisen jälkeen tehkää alkukartoitusvideot. Ohjeet alkukartoitusvideoihin löydätte kyselystä.',
                text4: 'Ladatkaa videot "VIESTIT" -osioon, kohdasta "Lataa tiedosto"',
                text5: 'Puheterapeutti vastaa teille, kun on katsonut kyselyvastauksenne ja videot. Saatte vastauksen noin viikon kuluessa. Saatte häneltä silloin ensimmäiset tehtävät.',
                text6: 'Saatte sähköpostiinne viestin, kun puheterapeutti on vastannut teille.',
            },
            picky_eater: {
                // 'Valikoiva syöjä'
                title1: 'Tervetuloa Äännekoulun Valikoiva syöjä -verkkokuntoutukseen!',
                title2: 'Tutustu puheterapeutteihin alla olevista videoista:',
                title3: 'Mitä verkkokuntoutus tarkoittaa?',
                title4: 'Ensimmäiset tehtävät - aloita tästä',
                text1: 'Hienoa, että olet löytänyt tiesi tähän verkkokuntoutukseen. Tavoitteemme tässä kuntoutuksessa on, että perheesi ruokailutilanteet olisivat yleisesti ottaen miellyttäviä yhdessäolon hetkiä, ja että lapsellasi olisi niissä luottavainen ja turvallinen olo.',
                text2: 'Jotta ruokailutilanteista voi tulla mukavia hetkiä, pyrimme yhdessä siihen, että lapsesi pelot ja ennakkoluulot ruokailua ja uusia ruokia kohtaan lieventyvät, vähenevät tai poistuvat aikuisen tai aikuisten avulla. Me puheterapeutit autamme sinua tässä tehtävässä koko kuntoutusjakson ajan.',
                text3: 'Verkkokuntoutus tarkoittaa sitä, että viestittely tapahtuu ainoastaan videoiden avulla - tietoturvallisesti Äännekoulun verkkokuntoutusympäristössä.Kun olette lähettäneet puheterapeutille alkuarviovideot (näistä seuraa ohjeet jäljempänä), puheterapeutti vastaa teille videolla ja kertoo, miten kuntoutus etenee. Hän antaa teille uudet tehtävät, jotka raportoitte taas seuraavalla viikolla joko videoilla tai viestillä. Puheterapeutti vastaa teille jälleen videolla. Jakso jatkuu tällä tavalla 3 kuukauden ajan.',
                text4: 'Jakso sisältää 11 moduulia, jotka kannattaa jokainen käydä läpi. Näin saat runsaasti tietoa siitä, mitä syitä lapsesi valikoivuuden taustalla voi olla, ja mihin asioihin kotona on mahdollista vaikuttaa. Kuntoutus tarjoaa sinulle myös ymmärrystä, jotta osaisit toimia lapsesi kanssa tavalla, joka vähentää lapsen syömistilanteissa kokemaa ahdistusta ja pelkoa uusia ruokia kohtaan. Tiedon lisäksi jokainen moduuli sisältää myös konkreettisia vinkkejä arkeen.',
                text5: '1. Mikäli lapsesi jo puhuu: kuvaa lapsestasi lyhyt, korkeintaan 2 min. video (voit kuvata vaikka puhelimella), jossa kuuluu lapsen spontaania puhetta. Voit esimerkiksi pyytää lastasi kertomaan jostain, mistä hän tykkää tai mitä hän on tehnyt eilen/tänään/viikonloppuna.',
                text6: '2. Kuvaa lapsesi ruokailutilanteesta korkeintaan 5 min. video siten, että lapsen kasvot ja ylävartalo näkyvät hyvin. Videolla olisi hyvä näkyä sekä syömistä että juomista.',
            },
            early_language_development: {
                title1: 'Tervetuloa varhaisen kielenkehityksen nettiterapiaan!',
                title2: 'Ensimmäiset tehtävät:',
                text1: 'Kuvaa video jossa lapsi leikkii sinun kanssasi.',
                text2: 'Video voi olla noin 1-2 minuuttia pitkä.',
                text3: 'Voit jutella lapselle ja kysellä kysymyksiä.',
                text4: 'Katso tästä tervetuloa-video:',
            },
        },
        categories: {
            R: 'R-äänne',
            S: 'S-äänne',
            L: 'L-äänne',
            K: 'K-äänne',
            M_P: 'M- ja P-äänteet',
            J: 'J-äänne',
            front_vowels: 'Etuvokaalit',
            V: 'V-äänne',
        },
    },
    customer_service: {
        contact_info: 'Asiakaspalvelun yhteystiedot',
        chat: 'Chat',
        excercise_books: 'Harjoituskirjat',
        links: 'Linkkejä',
        feedback: 'Anna palautetta',
        service_time: 'arkisin klo',
        tech_suport: 'tekninen tuki',
        address: 'osoitteessa',
        read_more: 'Lue lisää',
        appointment: 'Ajanvaraus',
        blog: 'Blogi',
        facebook_site: 'Äännekoulun Facebook',
    },
    common: {
        remove_user: 'Poista käyttäjätili käytöstä',
        sure: 'Oletko varma?',
        municipality_missing: 'kunta puuttuu',
        file_size_too_large: 'Tiedoston maksimikoko 1 GB ylittyi',
        firstname_missing: 'Etunimi puuttuu',
        lastname_missing: 'Sukunimi puuttuu',
        email_missing: 'Sähköposti puuttuu',
        phone_missing: 'Puhelinnumero puuttuu',
        attachment_missing: 'Liitetiedosto puuttuu',
        wrong_file_format: 'Väärä tiedostomuoto',
        emails_dont_match: 'sähköpostit eivät täsmää',
        ended: "Päättynyt",
        contact_person_phone: 'Yhteyshenkilön puh. numero',
        contact_person_email: 'Yhteyshenkilön sähköposti',
        contact_person_name: 'Yhteyshenkilön nimi',
        new_licence_controller_was_created: 'Uusi lisenssin hallitsija luotiin onnistuneesti',
        new_admin_was_created: 'Uusi ylläpitäjä luotiin onnistuneesti',
        creating_failed: 'Käyttäjän luominen epäonnistui, yritä uudelleen!',
        fill_form: 'Täytä kaikki pakolliset kentät',
        create_new_therapist: 'Luo uusi terapeutti-käyttäjä',
        create_new_admin: 'Luo uusi ylläpitäjä',
        create_new_licence_controller: 'Luo uusi lisenssin hallitsija',
        new_therapist_was_created: 'Uusi terapeutti luotiin onnistuneesti',
        choose_zone: 'Valitse työskentelyalue',
        choose_evaluation_zone: 'Valitse palvelutarpeenarvio alue',
        search_users: 'Selaa käyttäjiä',
        therapist_instructions: 'Ohjeet terapeutille',
        customer_instructions: 'Ohjeet asiakkaalle',
        required: 'pakollinen',
        search: 'Haku',
        search_result: 'Hakutuloksilla',
        yes: 'Kyllä',
        no: 'Ei',
        confirm: 'Vahvista',
        period: "Jakso",
        remove_post: 'Poista tiedote',
        post_are_you_sure: 'Oletko varma että haluat poistaa tiedotteen?',
        want_to_continue: 'Oletko varma että haluat jatkaa',
        are_you_sure: 'Oletko varma että haluat keskeyttää harjoituksen lähettämisen?',
        date_format: 'DD.MM.YYYY',
        date_mask: '__.__.____',
        date_time_format: 'DD.MM.YYYY HH:mm',
        on: 'Päällä',
        off: 'Poissa',
        gone: 'Olen poissa',
        instructions: 'Ohjeet',
        optional: 'valinnainen',
        contact_info: 'Yhteystiedot',
        last_modified: 'Muokattu viimeksi',
        page: 'Sivu',
        days: {
            mon: 'Maanantai',
            tue: 'Tiistai',
            wed: 'Keskiviikko',
            thu: 'Torstai',
            fri: 'Perjantai',
            sat: 'Lauantai',
            sun: 'Sunnuntai',
        },
        days_short: {
            mon: 'MA',
            tue: 'TI',
            wed: 'KE',
            thu: 'TO',
            fri: 'PE',
            sat: 'LA',
            sun: 'SU',
        },
        save_success: 'Tallennus onnistui',
        save_failed: 'Tallennus epäonnistui',
        delete_success: 'Poisto onnistui',
        delete_failed: 'Poisto epäonnistui',
        select_all: 'Valitse kaikki',
        months: 'kuukautta',
        send_failed: 'Lähetys epäonnistui',
        send_failed_contact_customer_service: 'Lähetys epäonnistui. Ota yhteyttä: asiakaspalvelu (at) aannekoulu.fi',
        hide: 'Piilota',
    },
    account: {
        users: 'Käyttäjät',
        welcome: 'Tervetuloa Äännekoulun nettipuheterapiaan',
        enter_license_code: 'Syötä lisenssikoodi',
        license_code: 'Lisenssikoodi',
        enter_ssn: 'Syötä terapiajakson salasana',
        wrong_ssn: 'Väärä salasana',
        ssn: 'Ohjeet sähköpostikutsussa',
        i_already_have_account: 'Minulla on jo tunnukset',
        child_first_name: 'Lapsen etunimi',
        child_last_name: 'Lapsen sukunimi',
        parent_first_name: 'Huoltajan etunimi',
        parent_last_name: 'Huoltajan sukunimi',
        client_first_name: 'Asiakkaan etunimi',
        client_last_name: 'Asiakkaan sukunimi',
        enter_contact_info: 'Täytä yhteystiedot',
        create_account: 'Luo tunnukset nettipuheterapiaan',
        select_username: 'Valitse käyttäjätunnus',
        select_password: 'Valitse salasana',
        password_again: 'Salasana uudestaan',
        approve_usage: 'Hyväksyn, että lähettämiäni videoita voidaan jakaa Äännekoulun terapeuttien välillä',
        approve_terms: 'Hyväksyn käyttöehdot',
        registration_success: 'Kiitos rekisteröitymisestä!',
        registration_success_instruction: 'Voit nyt jatkaa videoiden ja/tai alkukartoituksen tekemiseen kirjautumalla sisään palveluun.',
        unqualified_username: 'Käyttäjä tunnus ei kelpaa!',
        new_guardian_added: 'Uusi huoltaja lisätty',
        approve: 'Hyväksy',
    },
    actions: {
        search_by_name: 'Etsi käyttäjän nimellä',
        create_user: 'Luo käyttäjä',
        look: 'Etsi',
        select_file: 'Valitse tiedosto',
        upload_material: 'Liitä tiedostot',
        stop: 'Keskeytä',
        cancel: 'Peruuta',
        clear: 'Tyhjennä',
        clear_selections: 'Poista valinnat',
        send: 'Lähetä',
        search: 'Hae',
        save: 'Tallenna',
        continue: 'Jatka',
        create_account: 'Luo tunnukset',
        read_terms: 'Lue käyttöehdot',
        download: 'Lataa',
        delete: 'Poista',
        next: 'Seuraava',
        previous: 'Edellinen',
        return_assignment: 'Palauta harjoitus',
        return: 'Lähetä',
        back: 'Takaisin',
        create_new_therapist_post: 'Luo uusi tiedote terapeuteille',
        create_post: 'Luo tiedote',
        modify: 'Muokkaa',
        open: 'Avaa',
        answer: 'Vastaa',
        delete_video: 'Haluatko varmasti poistaa videon?',
        move: 'Siirrä',
        delete_defaultMessage: 'Haluatko varmasti poistaa viestin?',
    },
    questionnaire: {
        no_questions: 'Ei kysymyksiä',
        unanswered_questions: 'Sinulla on vastaamaton kysely',
    },
    questionnaireTypes: {
        startQuestionnaire: 'Alkukartoituskysely',
        evaluationQuestionnaire: 'Palvelutarpeen arviot',
        interimSurvey: 'Välikysely',
        finalSurvey: 'Loppukysely',
    },
    admin: {
        inquiry: {
            questionnaires: 'Kyselyt',
            browse_questionnaires: 'Selaa kyselyitä',
            upload_new_inquiry_to_server: 'Lataa uusi kysely',
            update_inquiry_to_server: 'Muokkaa kyselyä',
            select_inquiry_type: 'Valitse kyselyn tyyppi',
            link_to_inquiry: 'Linkki kyselyyn',
            write_material_url: 'Kirjoita tähän materiaalin URL-osoite',
            generated_inquiry: 'Generaattorilla tehty kysely',
            upload_inquiry: 'Lataa kysely',
            answer_the_survey_more_than_once: 'Kyselyyn voi vastata useamman kerran',
            link_to_query_required: 'Linkki kyselyyn on pakollinen. Tarkasta myös, että URL alkaa http:// tai https://',
            search_questionnaires: 'Hae kyselyitä',
            search_by_name: 'Etsi kyselyn nimellä',
            delete_questionnaire: 'Poista kysely',
            delete_questionnaire_confirmation: 'Oletko varma, että haluat poistaa kyselyn',
            open_survey_template: 'Avaa kyselypohja',
        },
        license: {
            zone_name_generated: 'Alueen tunnus määräytyy kunnan nimen mukaan.',
            create_new_license: 'Luo uusi lisenssi',
            create_license: 'Luo lisenssi',
            license_is_for: 'Kenelle lisenssi luodaan',
            license_count: 'Lisenssien määrä',
            times_of_use: 'Käyttökerrat',
            new_licenses_created: 'Uudet lisenssit on luotu',
            new_licenses_created_failed: 'Uusien lisenssien luonti epäonnistui',
            select_therapist: 'Valitse terapeutti',
            license_created: 'Lisenssi luotu',
            free: 'Vapaa',
            reserved: 'Varattu',
            used: 'Käytetty',
            zone_therapist: 'Alueen ID / Terapeutin nimi',
            zone_name: 'Alueen ID',
            select_state: 'Valitse lisenssin status',
            created_licenses: 'Luodut lisenssit',
            zone_info: 'HUOM! Luo ensin alue ja yhteyshenkilö ja sen jälkeen lisenssin jakaja, jolla on oikeus jakaa luotuja lisenssejä asiakkaille.',
            select_license_valid_until: 'Valitse voimassaolo',
            zone_details: 'Alueen tiedot',
            zone_licenses: 'Alueen lisenssit',
            license_valid_until: 'Voimassaolo päättyy',
            license_code: 'Lisenssikoodi',
            status: 'Status',
        },
        periods: {
            move_periods: 'Siirrä terapiajaksoja',
            select_therapist: 'Valitse terapeutti jolle terapiajaksot siirretään',
        },
        therapist: {
            is_overflow_therapist: 'Ylivuotoasiakkaiden terapeutti',
        },
    },
    error: {
        municipality: 'Kunta -kenttä puuttuu',
        first_name: 'Etunimi -kenttä puuttuu',
        last_name: 'Sukunimi -kenttä puuttuu',
        email: 'Sähköposti -kenttä puuttuu',
        phone: 'Puhelinnumero -kenttä puuttuu',
        license_count: 'Lisenssien määrä pitää olla enemmän kuin 0',
        zone: 'Alue/terapeutti -kenttä puuttuu',
        modules: 'Moduuli -kenttä puuttuu',
        license_valid_until: 'Voimassaolo -kenttä puuttuu',
        name_reserved: 'Nimi on jo käytössä. Valitse toinen',
        name: 'Nimi -kenttä puuttuu',
        periods_count: 'Valittujen terapiajaksojen määrä pitää olla enemmän kuin 0',
        empty_result: 'Ei tuloksia',
    },
    help_chat: {
        send_help_chat_message: 'Lähetä liitteet pikahelppiin',
        success_message: 'Liitteet lähetetty onnistuneesti',
        help_chat_messages: 'Uutta pikehelppi viestiä',
    },
};
