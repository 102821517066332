var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { GlobalStyles, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { Translate } from '../../../translation/Translate';
import moment from 'moment';
import { deleteMaterial, updateMaterial, } from '../../../../actions/periodMaterialActions';
import ConfirmModal from '../../../common/ConfirmModal';
import MaterialModalFields from './MaterialModalFields';
import getMaterialLink from './GetMaterialLink';
import { clearFormState } from '../../../../reducers/adminMaterialsSlice';
import useUser from '../../../../hooks/useUser';
var MaterialModal = function (_a) {
    var showToastMessage = _a.showToastMessage, searchAllMaterials = _a.searchAllMaterials;
    var dispatch = useDispatch();
    var user = useUser();
    var lastModifiedTranslate = Translate({ term: 'common.last_modified' });
    var materialRemovedTranslate = Translate({
        term: 'material_library.material_removed',
    });
    var materialUpdatedTranslate = Translate({
        term: 'material_library.material_updated',
    });
    var _b = __read(useState(false), 2), open = _b[0], setOpen = _b[1];
    var material = useSelector(function (state) { return state.adminMaterials.formData; });
    var deleteSelectedMaterial = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(material && material._id)) return [3 /*break*/, 2];
                    return [4 /*yield*/, deleteMaterial(material._id, user.session)];
                case 1:
                    data = _a.sent();
                    if (data) {
                        showToastMessage(materialRemovedTranslate);
                        searchAllMaterials();
                        dispatch(clearFormState());
                        setOpen(false);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var modifySelectedMaterial = function () { return __awaiter(void 0, void 0, void 0, function () {
        var filter, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(material && material._id)) return [3 /*break*/, 2];
                    filter = {
                        id: material._id,
                        language: material.language,
                        name: material.name,
                        type: material.type,
                        description: material.description,
                        therapistInstructions: material.therapistInstructions,
                        patientInstructions: material.patientInstructions,
                        modules: material.modules,
                    };
                    return [4 /*yield*/, updateMaterial(filter, user.session)];
                case 1:
                    data = _a.sent();
                    if (data) {
                        showToastMessage(materialUpdatedTranslate);
                        searchAllMaterials();
                        dispatch(clearFormState());
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var _c = getMaterialLink(material), link = _c.link, materialIcon = _c.materialIcon;
    return (_jsx(Modal, __assign({ open: (material === null || material === void 0 ? void 0 : material._id) !== null, onClose: function () { return dispatch(clearFormState()); }, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", disableAutoFocus: true, disableEnforceFocus: true }, { children: _jsxs("div", __assign({ className: "absolute top-1/2 left-1/2 px-6 py-8 border-2 border-cyan rounded-lg p-4 bg-white shadow-lg", style: { width: '800px', transform: 'translate(-50%, -50%)' } }, { children: [_jsx(ConfirmModal, { open: open, onClose: function () { return setOpen(false); }, message: [
                        'Poista materiaali',
                        'Oletko varma että haluat poistaa materiaalin?',
                    ], deleteSelectedItem: deleteSelectedMaterial }), _jsxs("div", __assign({ className: "flex flex-row" }, { children: [_jsxs("div", __assign({ className: "w-4/12" }, { children: [_jsxs("div", __assign({ className: "flex flex-row space-x-4" }, { children: [materialIcon === '' ? null : (_jsx("div", __assign({ className: "mt-4" }, { children: materialIcon }))), link] })), _jsxs("p", __assign({ className: "text-sm text-gray-600 mt-4" }, { children: [lastModifiedTranslate, ' ', (material === null || material === void 0 ? void 0 : material.updatedAt)
                                            ? moment(material.updatedAt).format('DD.MM.YYYY')
                                            : null] }))] })), _jsx(GlobalStyles, { styles: {
                                '.ck.ck-balloon-panel': {
                                    zIndex: '1400 !important', // Put a higher value that your MUI Dialog (generaly 1300)
                                },
                            } }), _jsx(MaterialModalFields, { modifySelectedMaterial: modifySelectedMaterial, setOpen: setOpen }), _jsx("div", __assign({ onClick: function () { return dispatch(clearFormState()); }, className: "flex justify-end w-1/12 -mt-5 -mr-3 cursor-pointer" }, { children: _jsx(CloseIcon, {}) }))] }))] })) })));
};
export default MaterialModal;
