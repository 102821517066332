import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    ownTherapist: null,
};
var TherapistSlice = createSlice({
    name: "evaluations",
    initialState: initialState,
    reducers: {
        setOwnTherapist: function (state, action) {
            if (action.payload === null) {
                state.ownTherapist = [];
            }
            else {
                state.ownTherapist = action.payload;
            }
        },
    }
});
export var setOwnTherapist = TherapistSlice.actions.setOwnTherapist;
export default TherapistSlice;
