var defaultMessage1 = {
    name: 'Tekniset ongelmat ja harjoittelumotivaatio',
    message: 'En ole kuullut teistä pitkään aikaan. Onko harjoittelumotivaatiossa ollut pulmia tai onko teillä ollut teknisiä ongelmia?',
    language: 'fi',
    generalDefaultMessage: true,
};
var defaultMessage2 = {
    name: 'Pääsittekö alkuun teksti',
    message: 'Hei, En ole kuullut teistä pitkään aikaan. Löytyikö harjoitteluvideot ja pääsittekö harjoittelussa alkuun?',
    language: 'fi',
    generalDefaultMessage: true,
};
export var generalDefaultMessages = [
    defaultMessage1,
    defaultMessage2,
];
