var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Translate } from '../../../translation/Translate';
var GuardianPeriodView = function (_a) {
    var guardians = _a.guardians;
    var guardianTranslate = Translate({ term: 'client.guardian' });
    var usernameTranslate = Translate({ term: 'login.username' });
    var firstLastNameTranslate = Translate({
        term: 'profile.first_and_last_name',
    });
    var phoneTranslate = Translate({ term: 'profile.phone' });
    var emailTranslate = Translate({ term: 'profile.email' });
    return (_jsx("div", { children: guardians.map(function (g, key) { return (_jsxs("div", __assign({ className: "leading-7 mt-3" }, { children: [_jsxs("p", __assign({ className: "underline underline-offset-4 mb-3" }, { children: [guardianTranslate, " ", guardians.length] })), _jsxs("div", __assign({ className: "flex flex-row" }, { children: [_jsx("p", __assign({ className: "w-1/3" }, { children: usernameTranslate })), g.email ? (_jsx("p", __assign({ className: "border rounded-md px-2 py-0.5 text-gray-600" }, { children: g.userId.username }))) : (_jsx("p", __assign({ className: "text-gray-600" }, { children: "-" })))] })), _jsxs("div", __assign({ className: "flex flex-row mt-1" }, { children: [_jsx("p", __assign({ className: "w-1/3" }, { children: firstLastNameTranslate })), _jsxs("div", __assign({ className: "flex flex-col" }, { children: [g.firstName ? (_jsx("p", __assign({ className: "border rounded-md px-2 py-0.5 text-gray-600" }, { children: g.firstName }))) : (_jsx("p", __assign({ className: "text-gray-600" }, { children: "-" }))), g.lastName ? (_jsx("p", __assign({ className: "border rounded-md px-2 py-0.5 text-gray-600 mt-1" }, { children: g.lastName }))) : (_jsx("p", __assign({ className: "text-gray-600 mt-1" }, { children: "-" })))] }))] })), _jsxs("div", __assign({ className: "flex flex-row mt-1" }, { children: [_jsx("p", __assign({ className: "w-1/3" }, { children: phoneTranslate })), g.phone ? (_jsx("p", __assign({ className: "border rounded-md px-2 py-0.5 text-gray-600" }, { children: g.phone }))) : (_jsx("p", __assign({ className: "text-gray-600" }, { children: "-" })))] })), _jsxs("div", __assign({ className: "flex flex-row mt-1" }, { children: [_jsx("p", __assign({ className: "w-1/3" }, { children: emailTranslate })), g.email ? (_jsx("p", __assign({ className: "border rounded-md px-2 py-0.5 text-gray-600" }, { children: g.email }))) : (_jsx("p", __assign({ className: "text-gray-600" }, { children: "-" })))] }))] }), key)); }) }));
};
export default GuardianPeriodView;
