var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useTranslate } from '../../translation/Translate';
import { PERIOD_MATERIALS, THERAPY_MODULE_TYPES } from '../../common/enums';
import usePeriods from '../../../hooks/usePeriods';
var CommonHelp = function () {
    var t = useTranslate();
    var latestPeriod = usePeriods().latestPeriod;
    function createMaterialLink(materialId) {
        return "/materials/".concat(materialId);
    }
    var content = (_jsx("p", { children: "Harjoittelu meill\u00E4 on intensiivist\u00E4 mutta palkitsevaa, tulosta syntyy ihan varmasti! Saat viett\u00E4\u00E4 laatuaikaa lapsen kanssa ja samalla vahvistaa suhdetta lapseen yhteisten teht\u00E4vien avulla. Teht\u00E4v\u00E4t auttavat n\u00E4kem\u00E4\u00E4n arjen tilanteita lapsen n\u00E4k\u00F6vinkkelist\u00E4, ja antavat keinoja tukea lapsen kielenkehitykseen." }));
    switch (latestPeriod === null || latestPeriod === void 0 ? void 0 : latestPeriod.module._id) {
        case THERAPY_MODULE_TYPES.ÄÄNNEVIRHEET:
            content = (_jsxs(_Fragment, { children: [_jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.NEUVOJA_HARJOITTELUN_ALOITTAMISEEN) }, { children: "Neuvoja harjoittelun aloittamiseen" })), _jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.NÄIN_OHJAAT_LASTASI_ÄÄNTEEN_HARJOITTELUSSA) }, { children: "N\u00E4in ohjaat lastasi \u00E4\u00E4nteen harjoittelussa" })), _jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.HARJOITTELUMÄÄRÄT) }, { children: "Harjoittelum\u00E4\u00E4r\u00E4t" })), _jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.KANNUSTUSMATERIAALIA) }, { children: "Kannustusmateriaalia" })), _jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.NÄIN_KUVAAT_VIDEON_PUHETERAPEUTILLE) }, { children: "N\u00E4in kuvaat videon puheterapeutille" }))] }));
            break;
        case THERAPY_MODULE_TYPES.SANAVARASTO:
            content = (_jsxs(_Fragment, { children: [_jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.NEUVOJA_HARJOITTELUN_ALOITTAMISEEN) }, { children: "Neuvoja harjoittelun aloittamiseen" })), _jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.LAPSEN_MOTIVOINTI_HARJOITTELUUN) }, { children: "Lapsen motivointi harjoitteluun" })), _jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.KANNUSTUSMATERIAALIA) }, { children: "Kannustusmateriaalia" })), _jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.NÄIN_KUVAAT_VIDEON_PUHETERAPEUTILLE) }, { children: "N\u00E4in kuvaat videon puheterapeutille" }))] }));
            break;
        case THERAPY_MODULE_TYPES.KIELELLINEN_TIETOISUUS:
            content = (_jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.NÄIN_KUVAAT_VIDEON_PUHETERAPEUTILLE) }, { children: "N\u00E4in kuvaat videon puheterapeutille" })));
            break;
        case THERAPY_MODULE_TYPES.VALIKOIVASYÖJÄ:
            content = (_jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.NÄIN_KUVAAT_VIDEON_PUHETERAPEUTILLE) }, { children: "N\u00E4in kuvaat videon puheterapeutille" })));
            break;
        case THERAPY_MODULE_TYPES.VARHAINEN_KIELENKEHITYS:
            content = (_jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.NÄIN_KUVAAT_VIDEON_PUHETERAPEUTILLE) }, { children: "N\u00E4in kuvaat videon puheterapeutille" })));
            break;
    }
    return (_jsxs("div", __assign({ className: "bg-white border border-cyan px-3 py-4 rounded-md shadow-lg" }, { children: [_jsxs("h3", __assign({ className: "mb-2 font-semibold text-xl text-grey-600" }, { children: [t('home.common_practice_instructions'), ":"] })), _jsx("div", __assign({ className: "flex flex-col gap-2" }, { children: content }))] })));
};
export default CommonHelp;
