var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './postSwiper.css';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Translate } from '../../translation/Translate';
import Moment from 'react-moment';
import { useState } from 'react';
var PostsInfo = function () {
    var _a = __read(useState(false), 2), show = _a[0], setShow = _a[1];
    var posts = useSelector(function (state) { return state.posts.posts; });
    var ownTherapist = useSelector(function (state) { return state.therapist.ownTherapist; });
    var unavailable = ownTherapist === null || ownTherapist === void 0 ? void 0 : ownTherapist.therapistInfo.unavailable;
    var dateFormat = Translate({ term: 'common.date_format' });
    var announcementsTranslate = Translate({
        term: 'home.therapist_announcements',
    });
    var goneTranslate = Translate({ term: 'common.gone' }) + ' ';
    var postArray = [];
    var dataInfo = _jsx("div", {});
    if (unavailable !== undefined && unavailable.absenceMessage) {
        dataInfo = (_jsxs("div", { children: [_jsxs("div", __assign({ className: "text-center" }, { children: [_jsxs("h3", __assign({ className: "mb-2 font-semibold text-center text-xl text-grey-600" }, { children: [announcementsTranslate, ":"] })), _jsxs("p", __assign({ onClick: function () { return setShow(!show); }, className: "cursor-pointer text-center" }, { children: [goneTranslate, _jsx(Moment, __assign({ format: dateFormat }, { children: unavailable.start })), ' - ', _jsx(Moment, __assign({ format: dateFormat }, { children: unavailable.end }))] })), _jsx("p", __assign({ onClick: function () { return setShow(!show); }, className: "cursor-pointer text-center truncate .." }, { children: unavailable.absenceMessage }))] })), !show ? null : (_jsx("p", __assign({ onClick: function () { return setShow(!show); }, className: "cursor-pointer text-center" }, { children: unavailable.absenceMessage })))] }));
        postArray.push(dataInfo);
    }
    if (posts) {
        for (var i = 0; i < (posts === null || posts === void 0 ? void 0 : posts.length); i++) {
            postArray.push(_jsxs("div", __assign({ className: "text-center" }, { children: [_jsxs("h3", __assign({ className: "mb-2 font-semibold text-xl text-grey-600" }, { children: [posts[i].subject, ":"] })), _jsx("p", { children: posts[i].message })] })));
        }
        return (_jsxs("div", { children: [_jsx("div", __assign({ className: "-mb-3 block sm:hidden" }, { children: _jsx(Swiper, __assign({ spaceBetween: 10, slidesPerView: 1, style: {
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            paddingBottom: '10px',
                        }, centeredSlides: true, onSlideChange: function () { return (show ? setShow(false) : null); }, pagination: {
                            dynamicBullets: true,
                            clickable: true,
                            type: 'bullets',
                        }, modules: [Autoplay, Pagination, Navigation], className: "" }, { children: postArray.map(function (post, index) {
                            return (_jsx(SwiperSlide, __assign({ className: "mb-10" }, { children: post }), index));
                        }) })) })), _jsx("div", __assign({ className: "-mb-3 hidden sm:block" }, { children: _jsx(Swiper, __assign({ spaceBetween: 50, slidesPerView: 1, style: {
                            paddingLeft: '50px',
                            paddingRight: '50px',
                            paddingBottom: '10px',
                        }, centeredSlides: true, navigation: true, onSlideChange: function () { return (show ? setShow(false) : null); }, pagination: {
                            dynamicBullets: true,
                            clickable: true,
                            type: 'bullets',
                        }, modules: [Autoplay, Pagination, Navigation], className: "" }, { children: postArray.map(function (post, index) {
                            return (_jsx(SwiperSlide, __assign({ className: "mb-10" }, { children: post }), index));
                        }) })) }))] }));
    }
    return (_jsx("div", { children: dataInfo === _jsx("div", {}) ? (_jsx("div", { children: _jsx("p", __assign({ className: "text-center mt-4" }, { children: Translate({ term: 'period.no_new_posts' }) })) })) : (dataInfo) }));
};
export default PostsInfo;
