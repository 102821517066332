var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Chip from '@mui/material/Chip';
var ButtonWithCount = function (_a) {
    var id = _a.id, title = _a.title, amount = _a.amount, active = _a.active, action = _a.action;
    var textClass = ["leading-tight"];
    var buttonClass = ["flex gap-2 px-3 py-2 justify-items-center items-center cursor-pointer"];
    if (active) {
        textClass.push("text-blue");
        buttonClass.push("px-4 bg-lightBlue border-b-2 border-blue");
    }
    else {
        buttonClass.push("px-4 hover:bg-lightBlue hover:bg-opacity-50 duration-300");
    }
    return (_jsxs("div", __assign({ className: buttonClass.join(" "), onClick: function () { action && action(id); } }, { children: [_jsx("div", __assign({ className: "text-center w-10" }, { children: _jsx(Chip, { style: { cursor: 'pointer' }, label: amount, color: active ? "primary" : undefined, size: "small" }) })), _jsx("div", __assign({ className: textClass.join(" ") }, { children: title }))] })));
};
export default ButtonWithCount;
