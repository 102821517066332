var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import Home from '../Home';
import Invitation from '../Invitation';
import InvitationPhase0 from '../InvitationPhase0';
import EvaluationInvitation from '../EvaluationInvitationPage';
import ChangePasswordRoute from '../ChangePasswordRoute';
import CreateUserForExtensionPeriod from '../CreateUserForExtensionPeriod';
export var MenuItems = [];
export var DefaultRoutes = function () {
    return (_jsx(Suspense, __assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(Routes, { children: _jsxs(Route, __assign({ path: "/", element: _jsx(DefaultLayout, {}) }, { children: [_jsx(Route, { index: true, element: _jsx(Home, {}) }), _jsx(Route, { path: "aanneklinikka/", element: _jsx(InvitationPhase0, {}) }), _jsx(Route, { path: "invitation/", element: _jsx(Invitation, {}) }), _jsx(Route, { path: "invitation/:id", element: _jsx(Invitation, {}) }), _jsx(Route, { path: "evaluationinvitation/", element: _jsx(EvaluationInvitation, {}) }), _jsx(Route, { path: "evaluationinvitation/:id", element: _jsx(EvaluationInvitation, {}) }), _jsx(Route, { path: "extension-period-invitation/", element: _jsx(CreateUserForExtensionPeriod, {}) }), _jsx(Route, { path: "extension-period-invitation/:id", element: _jsx(CreateUserForExtensionPeriod, {}) }), _jsx(Route, { path: "change-password/", element: _jsx(ChangePasswordRoute, {}) }), _jsx(Route, { path: "change-password/:id", element: _jsx(ChangePasswordRoute, {}) })] })) }) })));
};
