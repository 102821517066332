var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ToastContainer, toast, Zoom } from 'react-toastify';
var useToast = function (params) {
    var defaultParams = {
        position: 'top-center',
        hideProgressBar: true,
        transition: Zoom,
        closeButton: false,
        autoClose: 2000,
    };
    var showToastMessage = function (text, type) {
        if (type === void 0) { type = 'success'; }
        var options = __assign(__assign(__assign({}, defaultParams), (params !== null && params !== void 0 ? params : {})), { toastId: type === 'warning' ? 'warningId' : 'successId', className: type === 'warning' ? 'toast-message-warning' : 'toast-message-success' });
        toast(text, options);
    };
    var toastContainer = _jsx(ToastContainer, { limit: 1 });
    return {
        showToastMessage: showToastMessage,
        toastContainer: toastContainer,
    };
};
export default useToast;
