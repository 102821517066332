var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SearchIcon from '@mui/icons-material/Search';
import { Translate } from '../../../translation/Translate';
var SearchDiv = function (_a) {
    var searchName = _a.searchName, setSearchName = _a.setSearchName, setPage = _a.setPage, searchAllUsers = _a.searchAllUsers;
    return (_jsxs("div", __assign({ className: "flex justify-between max-w-6xl" }, { children: [_jsx("h2", __assign({ className: "text-2xl font-semibold" }, { children: Translate({ term: 'common.search_users' }) })), _jsxs("div", __assign({ className: "flex flex-row gap-6" }, { children: [_jsxs("div", __assign({ style: {
                            border: '1px solid #e0e0e0',
                            backgroundColor: 'white',
                            paddingLeft: '.5em',
                            borderRadius: '6px',
                        } }, { children: [_jsx(SearchIcon, {}), _jsx("input", { type: "text", onChange: function (e) { return setSearchName(e.target.value); }, value: searchName, className: "h-12 w-60", style: { border: 'none', outline: 'none' }, placeholder: Translate({ term: 'actions.search_by_name' }) })] })), _jsx("div", __assign({ className: "flex ml-2" }, { children: _jsx("button", __assign({ onClick: function () {
                                setPage(1);
                                searchAllUsers();
                            }, className: "h-12" }, { children: Translate({ term: 'actions.look' }) })) })), _jsx("div", __assign({ className: "flex" }, { children: _jsx("button", __assign({ onClick: function () { return setSearchName(''); }, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: Translate({ term: 'actions.clear' }) })) }))] }))] })));
};
export default SearchDiv;
