var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { setPeriods, setPeriod } from '../../../reducers/periodSlice';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { useTranslate } from '../../../components/translation/Translate';
import PeriodMessages from '../../../components/common/period/messages/PeriodMessages';
import AccordionPanel from '../../../components/common/AccordionPanel';
import LanguageSelector from '../../../components/common/LanguageSelector';
import PatientPeriodAssignments from '../../../components/common/assignments/PatientPeriodsAssignments';
import Questionnaires from '../../../components/client/questionnaires/questionnaires';
import PeriodTitle from '../../../components/client/period/PeriodTitle';
import PatientPeriodsMessageEditor from '../../../components/client/period/PatientPeriodsMessageEditor';
import { getWindowDimensions } from '../../../functions/screen';
import { isPeriodValid } from '../../../functions/periods';
import useUser from '../../../hooks/useUser';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import { moduleTotalSessions } from '../../../config/periodSessions';
import { getAllPeriods, getAndSetReadByMessagesActivePeriods, } from '../../../actions/periodActions';
import { useNavigate, useParams } from 'react-router-dom';
var MOBILE_VIEW_WIDTH = 1024;
var PatientPeriods = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var t = useTranslate();
    var user = useUser();
    var navigate = useNavigate();
    var params = useParams();
    var dispatch = useAppDispatch();
    var periods = useAppSelector(function (state) { return state.periods.periods; });
    var selected = useAppSelector(function (state) { return state.periods.active; });
    var assignmentCount = 0;
    var _h = __read(useState(null), 2), selectedAssignment = _h[0], setSelectedAssignment = _h[1];
    var _j = __read(useState(null), 2), reply = _j[0], setReply = _j[1];
    var _k = __read(useState(getWindowDimensions().width >= MOBILE_VIEW_WIDTH), 2), showMobileView = _k[0], setShowMobileView = _k[1];
    function handleResize() {
        var wd = getWindowDimensions();
        if (wd.width >= MOBILE_VIEW_WIDTH)
            setShowMobileView(false);
        if (wd.width < MOBILE_VIEW_WIDTH)
            setShowMobileView(true);
    }
    function getOwnAllPeriods() {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getAllPeriods(user.session)];
                    case 1:
                        response = _a.sent();
                        if (!response) {
                            return [2 /*return*/];
                        }
                        dispatch(setPeriods(response));
                        return [2 /*return*/];
                }
            });
        });
    }
    function setReadByMessage() {
        var _a, _b;
        var name = ((_a = user.userinfo) === null || _a === void 0 ? void 0 : _a.firstName) + ' ' + ((_b = user.userinfo) === null || _b === void 0 ? void 0 : _b.lastName);
        if (selected && selected._id) {
            var periodId = selected._id;
            getAndSetReadByMessagesActivePeriods(periodId, name, user.session);
        }
    }
    function showToastMessage(info) {
        toast(info, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 2000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
    }
    function setAssignment(id, assignments) {
        var assignment = assignments.find(function (a) { return id === a.id; });
        assignment
            ? setSelectedAssignment(assignment)
            : setSelectedAssignment(null);
    }
    function selectPeriod() {
        if (!(params.id && periods)) {
            return;
        }
        var period = periods.find(function (p) { return p._id === params.id; });
        if (!period) {
            return;
        }
        dispatch(setPeriod(period));
    }
    // On first render
    useEffect(function () {
        getOwnAllPeriods();
        selectPeriod();
        setReadByMessage();
        handleResize();
    }, []);
    // On screen resize
    useEffect(function () {
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, [getWindowDimensions()]);
    // On period change
    useEffect(function () {
        selectPeriod();
    }, [params.id]);
    if (!periods) {
        return _jsx(_Fragment, { children: "ei periodeja" });
    }
    if (!selected) {
        return (_jsx(_Fragment, { children: _jsx("div", { children: "loading..." }) }));
    }
    var assignments = (_a = selected.assignments) === null || _a === void 0 ? void 0 : _a.map(function (assignment) {
        return assignment;
    });
    if (assignments) {
        for (var i = 0; i < assignments.length; i++) {
            if (!assignments[i].approved && assignments[i].deadline)
                assignmentCount++;
        }
    }
    var questionnaires = (_c = (_b = selected.questionnaires) === null || _b === void 0 ? void 0 : _b.filter(function (questionnaire) {
        if (!questionnaire.answers || questionnaire.answerTheSurveyMoreThanOnce)
            return questionnaire;
    })) !== null && _c !== void 0 ? _c : [];
    return (_jsxs("main", __assign({ className: "px-3 md:px-8 py-4" }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx("div", __assign({ className: "max-w-3xl lg:max-w-7xl mx-auto" }, { children: showMobileView ? (
                // Mobile view
                _jsxs("div", __assign({ className: "lg:hidden" }, { children: [_jsx(PeriodTitle, __assign({ assignmentCount: assignmentCount, messageStyle: 'btn-sm rounded-2xl rounded-r-none border-t-8 border-l-8 border-b-8 border-cyan', assingmentStyle: 'btn-sm rounded-2xl rounded-l-none border-t-8 border-r-8 border-b-8 border-white hover:border-cyan duration-300' }, { children: periods.length > 1 && (_jsx("div", __assign({ className: "flex flex-row gap-1 w-full overflow-x-scroll rounded-md" }, { children: periods.map(function (period, index) { return (_jsxs("button", __assign({ className: "w-max min-w-max period-tab", onClick: function () { return navigate("/period/".concat(period._id)); }, disabled: params.id === period._id }, { children: ["".concat(t('common.period'), " ").concat(index + 1), !isPeriodValid(period) && " (".concat(t('common.ended'), ")")] }), period._id)); }) }))) })), _jsx("div", __assign({ className: "grid gap-6 mt-4 md:mt-6" }, { children: _jsx("div", __assign({ className: "col-span-3" }, { children: _jsxs("div", __assign({ className: "grid gap-2" }, { children: [_jsx(PeriodMessages, { messages: selected.messages ? selected.messages : [], client: true, periodId: selected._id ? selected._id : '1', replyToMessage: function (message) {
                                                setReply(message);
                                            }, sessionsDone: selected.sessions ? String(selected.sessions) : '0', sessions: ((_d = selected.module) === null || _d === void 0 ? void 0 : _d.sessions)
                                                ? String(selected.module.sessions)
                                                : String(moduleTotalSessions[(_e = selected.module) === null || _e === void 0 ? void 0 : _e._id]), periodValidUntil: selected.validUntil }), reply === null ? (_jsx(_Fragment, {})) : (_jsxs("div", __assign({ className: "flex flex-row mt-3 space-x-2 bg-white py-1 px-3 md:px-8 rounded-md border border-gray-200 relative" }, { children: [_jsx("div", __assign({ className: "p-1 flex items-center" }, { children: _jsx(ChatBubbleOutlineOutlinedIcon, {}) })), _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("p", { children: [t('home.message'), ":"] }), _jsx("p", __assign({ className: "truncate .." }, { children: reply }))] }))] }))), isPeriodValid(selected) && (_jsx(PatientPeriodsMessageEditor, { periodId: selected._id ? selected._id : '1', getPeriods: getOwnAllPeriods, reply: reply, setReply: setReply }))] })) })) }))] }))) : (
                // Desktop view
                _jsxs("div", __assign({ className: "hidden lg:flex lg:flex-col gap-1" }, { children: [_jsxs("div", __assign({ className: "hidden sm:flex sm:justify-between" }, { children: [_jsx("h1", __assign({ className: "text-blue" }, { children: t('therapy.types.therapy_session') })), _jsx(LanguageSelector, {})] })), _jsx("div", __assign({ className: "flex flex-row gap-1 w-full overflow-x-scroll rounded-md" }, { children: periods.length > 1 &&
                                periods.map(function (period, index) { return (_jsxs("button", __assign({ className: "rounded-b-xl w-max min-w-max period-tab", onClick: function () { return navigate("/period/".concat(period._id)); }, disabled: params.id === period._id }, { children: ["".concat(t('common.period'), " ").concat(index + 1), !isPeriodValid(period) && " (".concat(t('common.ended'), ")")] }), period._id)); }) })), _jsxs("div", __assign({ className: "flex flex-row gap-6 justify-between mt-4 md:mt-6" }, { children: [_jsxs("div", __assign({ className: "space-y-3 w-1/2" }, { children: [_jsx("h3", __assign({ className: "text-xl text-left font-semibold text-grey-800 mb-0 md:mb-3" }, { children: t('nav.messages') })), _jsx(PeriodMessages, { readonly: !isPeriodValid(selected), messages: selected.messages ? selected.messages : [], client: true, periodId: selected._id ? selected._id : '1', replyToMessage: function (message) {
                                                setReply(message);
                                            }, sessionsDone: selected.sessions ? String(selected.sessions) : '0', sessions: ((_f = selected.module) === null || _f === void 0 ? void 0 : _f.sessions)
                                                ? String(selected.module.sessions)
                                                : String(moduleTotalSessions[(_g = selected.module) === null || _g === void 0 ? void 0 : _g._id]), periodValidUntil: selected.validUntil }), reply === null ? (_jsx(_Fragment, {})) : (_jsxs("div", __assign({ className: "flex flex-row mt-3 space-x-2 bg-white py-1 px-3 md:px-8 rounded-md border border-gray-200 relative" }, { children: [_jsx("div", __assign({ className: "p-1 flex items-center" }, { children: _jsx(ChatBubbleOutlineOutlinedIcon, {}) })), _jsxs("div", __assign({ className: "flex flex-col truncate .." }, { children: [_jsxs("p", { children: [t('home.message'), ":"] }), _jsx("p", __assign({ className: "truncate .." }, { children: reply }))] }))] }))), isPeriodValid(selected) && (_jsx(PatientPeriodsMessageEditor, { periodId: selected._id, getPeriods: getOwnAllPeriods, reply: reply, setReply: setReply }))] })), isPeriodValid(selected) && (_jsxs("div", __assign({ className: "w-1/2" }, { children: [_jsx("h3", __assign({ className: "text-xl text-right font-semibold text-grey-800 mb-0 md:mb-3" }, { children: t('therapy.types.therapy_session') })), _jsxs("div", __assign({ className: "grid gap-2 md:gap-3" }, { children: [_jsx("div", __assign({ className: "bg-lightBlue px-4 py-2 rounded-md" }, { children: _jsx(PatientPeriodAssignments, { open: function (id) {
                                                            return setAssignment(id, assignments ? assignments : []);
                                                        }, assignments: assignments ? assignments : [], isDone: false, showToastMessage: showToastMessage }) })), _jsx(AccordionPanel, __assign({ summary: _jsx("div", { children: t('period.assignment_history') }) }, { children: _jsx(PatientPeriodAssignments, { assignments: assignments ? assignments : [], isDone: true }) })), _jsx(AccordionPanel, __assign({ open: questionnaires.length > 0, summary: _jsx("div", { children: t('period.feedback_surveys') }) }, { children: _jsx(Questionnaires, { questionnaires: questionnaires, periodId: selected._id, showToastMessage: showToastMessage }) }))] }))] })))] }))] }))) }))] })));
};
export default PatientPeriods;
