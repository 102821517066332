var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Moment from 'react-moment';
import BasicModal from '../../common/modals/BasicModal';
import { Translate } from '../../translation/Translate';
var SinglePostModal = function (_a) {
    var activePost = _a.activePost, setActivePost = _a.setActivePost;
    return (_jsxs(BasicModal, __assign({ handleClose: function () { return setActivePost(null); }, open: activePost !== null }, { children: [_jsx("label", __assign({ className: "block text-sm py-2" }, { children: _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: activePost === null || activePost === void 0 ? void 0 : activePost.created })) })), _jsx("label", __assign({ className: "block text-sm py-2" }, { children: activePost === null || activePost === void 0 ? void 0 : activePost.subject })), _jsx("label", __assign({ className: "block text-sm py-2" }, { children: _jsx("textarea", { readOnly: true, rows: 8, value: activePost === null || activePost === void 0 ? void 0 : activePost.message, className: "text-base mt-1 block bg-white border border-gray-300 rounded-md px-2 py-1 w-full" }) })), _jsx("div", __assign({ className: "grid grid-cols-2 mt-4" }, { children: _jsx("div", { className: "text-right" }) }))] })));
};
export default SinglePostModal;
