var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NavLink } from 'react-router-dom';
import { useTranslate } from '../../translation/Translate';
import Moment from 'react-moment';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import useAppSelector from '../../../hooks/useAppSelector';
var PeriodHistoryInfo = function (_a) {
    var classname = _a.classname;
    var t = useTranslate();
    var endedPeriods = useAppSelector(function (state) { return state.periods.periodHistory; });
    if (endedPeriods && (endedPeriods === null || endedPeriods === void 0 ? void 0 : endedPeriods.length) < 1) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", __assign({ className: classname }, { children: [_jsx("h3", __assign({ className: "mb-2 font-semibold text-xl text-grey-600" }, { children: t('period.last_ended_period') })), endedPeriods === null || endedPeriods === void 0 ? void 0 : endedPeriods.map(function (_a) {
                var _id = _a._id, name = _a.name, started = _a.started, validUntil = _a.validUntil;
                return (_jsx("div", __assign({ className: "leading-6" }, { children: _jsx(NavLink, __assign({ to: "/period/".concat(_id), className: "cursor-pointer" }, { children: _jsxs("p", { children: [name, ' ', _jsx(Moment, __assign({ format: "DD/MM/YYYY" }, { children: started })), ' - ', _jsx(Moment, __assign({ format: "DD/MM/YYYY" }, { children: validUntil })), _jsx(ArrowCircleRightOutlinedIcon, { style: {
                                        fill: '#4B4B4B',
                                        marginBottom: '4px',
                                        marginLeft: '4px',
                                    } })] }) })) }), _id));
            })] })));
};
export default PeriodHistoryInfo;
