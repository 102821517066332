var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { deleteAbsence, getUserinfo } from '../../../actions/userActions';
import { setUser } from '../../../reducers/userSlice';
import AccordionPanel from '../../common/AccordionPanel';
import { Translate } from '../../translation/Translate';
import AbsenceNotice from './AbsenceNotice';
import useUser from '../../../hooks/useUser';
var AbsenceMessage = function () {
    var _a, _b, _c, _d;
    var dispatch = useDispatch();
    var user = useUser();
    var userUnavailableInfo = ((_b = (_a = user.userinfo) === null || _a === void 0 ? void 0 : _a.therapistInfo) === null || _b === void 0 ? void 0 : _b.unavailable)
        ? user.userinfo.therapistInfo.unavailable
        : null;
    var absenceTime = ((_d = (_c = user.userinfo) === null || _c === void 0 ? void 0 : _c.therapistInfo) === null || _d === void 0 ? void 0 : _d.unavailable)
        ? "".concat(moment(user.userinfo.therapistInfo.unavailable.start).format(Translate({ term: 'common.date_format' })), " - ").concat(moment(user.userinfo.therapistInfo.unavailable.end).format(Translate({ term: 'common.date_format' })))
        : '';
    function deleteAbcenceMessage() {
        return __awaiter(this, void 0, void 0, function () {
            var deleteAbsenseMessageRequest, response, userData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deleteAbsenseMessageRequest = {
                            deleteAbsence: 'deleteAbsence',
                        };
                        return [4 /*yield*/, deleteAbsence(deleteAbsenseMessageRequest, user.session)];
                    case 1:
                        response = _a.sent();
                        if (!response) return [3 /*break*/, 3];
                        return [4 /*yield*/, getUserinfo(user.session)];
                    case 2:
                        userData = _a.sent();
                        if (userData) {
                            dispatch(setUser(userData));
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    return (_jsx("div", { children: (userUnavailableInfo === null || userUnavailableInfo === void 0 ? void 0 : userUnavailableInfo.absenceMessage) ? (_jsxs(AccordionPanel, __assign({ actions: [
                _jsx("button", __assign({ onClick: function () { return deleteAbcenceMessage(); } }, { children: Translate({ term: 'actions.delete' }) }), "12434343432"),
            ], summary: _jsx("h3", { children: Translate({ term: 'profile.absence_notice' }) }), summaryClosed: _jsxs("div", { children: [_jsx("div", { children: absenceTime }), _jsx("div", { children: userUnavailableInfo.absenceMessage })] }) }, { children: [_jsx("div", { children: absenceTime }), _jsx("div", { children: userUnavailableInfo.absenceMessage })] }))) : (_jsx(AccordionPanel, __assign({ summary: _jsx("h3", { children: Translate({ term: 'profile.absence_notice' }) }), summaryClosed: _jsx("div", { children: Translate({ term: 'profile.no_absence_notice' }) }) }, { children: _jsx(AbsenceNotice, { session: user.session }) }))) }));
};
export default AbsenceMessage;
