var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactPlayer from 'react-player';
import { GAME_URL } from '../../../../config/constants';
import PeriodMaterialIcon from '../../../common/period/PeriodMaterialIcon';
import { Translate } from '../../../translation/Translate';
var getMaterialLink = function (material) {
    var link = '';
    var openMaterialTranslate = Translate({
        term: 'material_library.open_material',
    });
    var downloadMaterialTranslate = Translate({
        term: 'actions.upload_material',
    });
    var materialIcon = material && material.type !== 'video' ? (_jsx(PeriodMaterialIcon, { type: material.type })) : ('');
    if (material && material.type === 'game') {
        if (material.settings &&
            material.settings.assignmentId &&
            material.settings.assignmentId[0] &&
            material.settings.gameMotor) {
            link = (_jsx("div", __assign({ className: "mt-4" }, { children: _jsx("a", __assign({ href: GAME_URL + '/game.php?peli=m' +
                        material.settings.gameMotor +
                        '&laksynId=' +
                        material.settings.assignmentId[0], target: "_blank", className: "btn", rel: "noreferrer" }, { children: openMaterialTranslate })) })));
        }
        else if (material.idNumber && material.idNumber[0]) {
            if (material.language === 'fi') {
                link = (_jsx("div", __assign({ className: "mt-4" }, { children: _jsx("a", __assign({ href: GAME_URL + '/game.php?peli=' + material.idNumber[0], target: "_blank", className: "btn", rel: "noreferrer" }, { children: openMaterialTranslate })) })));
            }
        }
    }
    if (material && material.url !== undefined) {
        if (material.type === 'video') {
            link = (_jsx(ReactPlayer, { url: material.url, width: "100%", height: "150px", controls: true }));
        }
        else if (material.type === 'text') {
            link = (_jsx("div", __assign({ className: "mt-4" }, { children: _jsx("a", __assign({ href: material.url, target: "_blank", className: "btn", rel: "noreferrer", download: true }, { children: downloadMaterialTranslate })) })));
        }
    }
    return { link: link, materialIcon: materialIcon };
};
export default getMaterialLink;
