import { createSlice } from '@reduxjs/toolkit';
var initialState = {};
var UserSlice = createSlice({
    name: 'language',
    initialState: initialState,
    reducers: {
        setLanguage: function (state, action) {
            if (action.payload === null) {
                state.session = null;
            }
            else {
                state.language = action.payload;
            }
        },
    },
});
export var setLanguage = UserSlice.actions.setLanguage;
export default UserSlice;
