var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import TabButton from '../../components/admin/materials/TabButton';
import ListQuery from '../../components/admin/query/ListQuery';
import UploadQuery from '../../components/admin/query/UploadQuery';
import Header from '../../components/licenseController/Header';
import { Translate } from '../../components/translation/Translate';
var QUERY_TABS;
(function (QUERY_TABS) {
    QUERY_TABS[QUERY_TABS["UPLOAD"] = 0] = "UPLOAD";
    QUERY_TABS[QUERY_TABS["LIST"] = 1] = "LIST";
})(QUERY_TABS || (QUERY_TABS = {}));
var Query = function () {
    var _a = __read(useState(QUERY_TABS.UPLOAD), 2), selectedTab = _a[0], setSelectedTab = _a[1];
    var questionnairesTranslate = Translate({
        term: 'admin.inquiry.questionnaires',
    });
    var uploadQuestionnaireTranslate = Translate({
        term: 'admin.inquiry.upload_new_inquiry_to_server',
    });
    var browseQuestionnairesTranslate = Translate({
        term: 'admin.inquiry.browse_questionnaires',
    });
    var tabs = [
        {
            id: QUERY_TABS.UPLOAD,
            title: uploadQuestionnaireTranslate,
            content: _jsx(UploadQuery, {}),
        },
        {
            id: QUERY_TABS.LIST,
            title: browseQuestionnairesTranslate,
            content: _jsx(ListQuery, {}),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Header, { title: questionnairesTranslate }), _jsxs("main", __assign({ className: "px-9 py-4" }, { children: [_jsx("div", __assign({ className: "mt-3 flex" }, { children: tabs.map(function (tab, index) { return (_jsx(TabButton, { id: Number(tab.id), title: tab.title, selectedTab: selectedTab, setSelectedTab: setSelectedTab }, index)); }) })), tabs[Number(selectedTab)].content] }))] }));
};
export default Query;
