var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Translate } from '../../translation/Translate';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { validateSsn } from '../../../functions/validations';
var ValidateExtensionInvitation = function (_a) {
    var ssn = _a.ssn, setSsn = _a.setSsn, validateExtensionInvitation = _a.validateExtensionInvitation;
    var _b = __read(useState(false), 2), valid = _b[0], setValid = _b[1];
    function handleSsnChange(ssn) {
        setValid(validateSsn(ssn));
        setSsn(ssn);
    }
    var welcomeHeaderTranslate = Translate({ term: 'account.welcome' });
    var enterSsnTranslate = Translate({ term: 'account.enter_ssn' });
    var ssnTranslate = Translate({ term: 'account.ssn' });
    var continueTranslate = Translate({ term: 'actions.continue' });
    return (_jsxs("form", { children: [_jsx("h2", __assign({ className: "text-xl pb-2" }, { children: welcomeHeaderTranslate })), _jsxs("div", { children: [_jsx("label", __assign({ className: "text-center" }, { children: enterSsnTranslate })), _jsxs("div", __assign({ className: "relative block w-64 m-auto" }, { children: [valid && (_jsx("span", __assign({ className: "absolute inset-y-0 left-0 flex items-center pl-2" }, { children: _jsx("div", __assign({ className: "h-5 w-5 text-gray-500 -mt-1" }, { children: _jsx(CheckCircleIcon, { className: "text-green" }) })) }))), _jsx("input", { className: valid ? 'icon-start w-full' : 'w-full', type: "text", defaultValue: ssn, onChange: function (e) { return handleSsnChange(e.target.value); }, placeholder: ssnTranslate })] }))] }), _jsx("div", __assign({ className: "text-center pt-2" }, { children: _jsx("button", __assign({ disabled: !valid, className: !valid ? 'opacity-25 cursor-not-allowed' : '', onClick: function (event) {
                        event.preventDefault(), validateExtensionInvitation();
                    } }, { children: continueTranslate })) }))] }));
};
export default ValidateExtensionInvitation;
