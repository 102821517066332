var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslate } from '../translation/Translate';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getPatientPeriodSortParam } from './utils/SortTablesByTherapyType';
import { Chip } from '@mui/material';
import Avatar from '../common/Avatar';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { moduleTotalSessions } from '../../config/periodSessions';
import { Link } from 'react-router-dom';
import { hidePeriodFrom } from '../../actions/periodActions';
import { PERIOD_FILTERS, PERIOD_VISIBILITY } from '../common/enums';
import useUser from '../../hooks/useUser';
import { getFilteredTherapistsPatientPeriodsCount } from '../../actions/therapistActions';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setPeriodCountByFilter } from '../../reducers/periodSlice';
var PatientPeriodTable = function (_a) {
    var patients = _a.patients, columns = _a.columns, setSortParams = _a.setSortParams, refetch = _a.refetch;
    var t = useTranslate();
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var user = useUser();
    var userinfo = useSelector(function (state) { return state.user.userinfo; });
    var _b = __read(React.useState(null), 2), sort = _b[0], setSort = _b[1];
    var _c = __read(React.useState(false), 2), reverse = _c[0], setReverse = _c[1];
    if (!patients) {
        return _jsx("span", { children: "..." });
    }
    function handleSetSort(id) {
        if (sort && id === sort) {
            setReverse(!reverse);
        }
        else {
            setSort(id);
            setReverse(!reverse);
        }
        if (setSortParams) {
            setSortParams({
                sort: getPatientPeriodSortParam(id),
                order: reverse ? 'asc' : 'desc',
            });
        }
    }
    function getModuleName(data) {
        var e_1, _a;
        var moduleName = [];
        try {
            for (var _b = __values(data.periods), _c = _b.next(); !_c.done; _c = _b.next()) {
                var period = _c.value;
                if (period.moduleName) {
                    moduleName.push(period.moduleName.fi || period.moduleName.se);
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return moduleName.length === 0
            ? 'Ei moduulin nimeä'
            : moduleName.toString();
    }
    function userHasReadMessage(message) {
        if (message.readBy.find(function (_a) {
            var user = _a.user;
            return user === (userinfo === null || userinfo === void 0 ? void 0 : userinfo.userId);
        }) || message.createdBy === (userinfo === null || userinfo === void 0 ? void 0 : userinfo.userId)) {
            return true;
        }
        return false;
    }
    function countUnreadMsgForPeriod(data) {
        var e_2, _a, e_3, _b;
        var _c;
        var count = 0;
        try {
            for (var _d = __values(data.periods), _e = _d.next(); !_e.done; _e = _d.next()) {
                var period = _e.value;
                try {
                    for (var _f = (e_3 = void 0, __values((_c = period.messages) !== null && _c !== void 0 ? _c : [])), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var message = _g.value;
                        if (!userHasReadMessage(message)) {
                            count++;
                        }
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return count;
    }
    function getLatestMessage(periods) {
        var e_4, _a, e_5, _b;
        var latestMessage = null;
        try {
            for (var periods_1 = __values(periods), periods_1_1 = periods_1.next(); !periods_1_1.done; periods_1_1 = periods_1.next()) {
                var period = periods_1_1.value;
                if (!period.messages) {
                    continue;
                }
                try {
                    for (var _c = (e_5 = void 0, __values(period.messages)), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var message = _d.value;
                        var isMoreRecent = latestMessage === null || message.created > latestMessage.created;
                        if (isMoreRecent) {
                            latestMessage = message;
                        }
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (periods_1_1 && !periods_1_1.done && (_a = periods_1.return)) _a.call(periods_1);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return latestMessage;
    }
    function hideFromSoonToEnd(periods) {
        return __awaiter(this, void 0, void 0, function () {
            var periods_2, periods_2_1, period, e_6_1;
            var e_6, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(user.session && refetch)) {
                            return [2 /*return*/];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        periods_2 = __values(periods), periods_2_1 = periods_2.next();
                        _b.label = 2;
                    case 2:
                        if (!!periods_2_1.done) return [3 /*break*/, 5];
                        period = periods_2_1.value;
                        return [4 /*yield*/, hidePeriodFrom(user.session, period.periodId, PERIOD_VISIBILITY.SOON_TO_END)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        periods_2_1 = periods_2.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_6_1 = _b.sent();
                        e_6 = { error: e_6_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (periods_2_1 && !periods_2_1.done && (_a = periods_2.return)) _a.call(periods_2);
                        }
                        finally { if (e_6) throw e_6.error; }
                        return [7 /*endfinally*/];
                    case 8:
                        getCountFor(PERIOD_FILTERS.SOON_TO_END, [PERIOD_VISIBILITY.SOON_TO_END]);
                        refetch();
                        return [2 /*return*/];
                }
            });
        });
    }
    function hideFinished(periods) {
        return __awaiter(this, void 0, void 0, function () {
            var periods_3, periods_3_1, period, e_7_1;
            var e_7, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(user.session && refetch)) {
                            return [2 /*return*/];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        periods_3 = __values(periods), periods_3_1 = periods_3.next();
                        _b.label = 2;
                    case 2:
                        if (!!periods_3_1.done) return [3 /*break*/, 5];
                        period = periods_3_1.value;
                        return [4 /*yield*/, hidePeriodFrom(user.session, period.periodId, PERIOD_VISIBILITY.FINISHED)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        periods_3_1 = periods_3.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_7_1 = _b.sent();
                        e_7 = { error: e_7_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (periods_3_1 && !periods_3_1.done && (_a = periods_3.return)) _a.call(periods_3);
                        }
                        finally { if (e_7) throw e_7.error; }
                        return [7 /*endfinally*/];
                    case 8:
                        getCountFor(PERIOD_FILTERS.FINISHED, [PERIOD_VISIBILITY.FINISHED]);
                        refetch();
                        return [2 /*return*/];
                }
            });
        });
    }
    function hidePassive(periods) {
        return __awaiter(this, void 0, void 0, function () {
            var periods_4, periods_4_1, period, e_8_1;
            var e_8, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(user.session && refetch)) {
                            return [2 /*return*/];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        periods_4 = __values(periods), periods_4_1 = periods_4.next();
                        _b.label = 2;
                    case 2:
                        if (!!periods_4_1.done) return [3 /*break*/, 5];
                        period = periods_4_1.value;
                        return [4 /*yield*/, hidePeriodFrom(user.session, period.periodId, PERIOD_VISIBILITY.PASSIVE)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        periods_4_1 = periods_4.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_8_1 = _b.sent();
                        e_8 = { error: e_8_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (periods_4_1 && !periods_4_1.done && (_a = periods_4.return)) _a.call(periods_4);
                        }
                        finally { if (e_8) throw e_8.error; }
                        return [7 /*endfinally*/];
                    case 8:
                        getCountFor(PERIOD_FILTERS.PASSIVE, [PERIOD_VISIBILITY.PASSIVE]);
                        refetch();
                        return [2 /*return*/];
                }
            });
        });
    }
    // Copy pasted from src/pages/therapist/Home.tsx
    // TODO: Refactor the code to remove this duplicate
    var getCountFor = function (filter, hideFrom) { return __awaiter(void 0, void 0, void 0, function () {
        var response, count;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getFilteredTherapistsPatientPeriodsCount(user.session, {
                        filter: filter,
                        hideFrom: hideFrom
                    })];
                case 1:
                    response = _b.sent();
                    count = (_a = response === null || response === void 0 ? void 0 : response.periods) !== null && _a !== void 0 ? _a : 0;
                    dispatch(setPeriodCountByFilter({ filter: filter, count: count }));
                    return [2 /*return*/];
            }
        });
    }); };
    var headCells = [
        // NAME
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: t("home.client"),
            content: function (data) {
                var unreadMessages = countUnreadMsgForPeriod(data);
                return (_jsxs("div", __assign({ className: "flex flex-row" }, { children: [_jsx(Avatar, { firstName: data.firstName, lastName: data.lastName, image: _jsx(PersonOutlineOutlinedIcon, { style: { width: '100%', height: '100%' } }) }), unreadMessages > 0 && (_jsx(Chip, { style: {
                                cursor: 'pointer',
                                marginLeft: '10px',
                                marginTop: '7px',
                            }, color: 'primary', label: unreadMessages, size: "small" }))] })));
            },
        },
        // CONTENT
        {
            id: 'content',
            numeric: false,
            disablePadding: false,
            label: t("home.content"),
            content: function (data) { return getModuleName(data); },
        },
        // PHASE
        {
            id: 'phase',
            numeric: true,
            disablePadding: false,
            label: t("home.phase"),
            content: function (_a) {
                var _b;
                var periods = _a.periods;
                var latest = periods[periods.length - 1];
                var maxSessions = moduleTotalSessions[latest.moduleId];
                var sessions = (_b = latest.sessions) !== null && _b !== void 0 ? _b : 0;
                return _jsx("p", { children: "".concat(sessions === 0 ? '-' : sessions, " / ").concat(maxSessions) });
            },
            align: 'right',
        },
        // LAST MESSAGE
        {
            id: 'last_message',
            numeric: true,
            disablePadding: false,
            label: t("home.last_message"),
            content: function (_a) {
                var _b;
                var periods = _a.periods;
                var latestMessage = getLatestMessage(periods);
                if (!latestMessage) {
                    return t("messages.no_messages");
                }
                return (_jsx(Moment, __assign({ format: t("common.date_time_format") }, { children: (_b = latestMessage.created) !== null && _b !== void 0 ? _b : '' })));
            },
            align: 'right',
        },
        // PERIOD ENDS
        {
            id: "period_ends",
            label: t("period.end_date"),
            content: function (_a) {
                var periods = _a.periods;
                var descending = function (a, b) {
                    if (a > b)
                        return -1;
                    if (a < b)
                        return 1;
                    return 0;
                };
                var sorted = periods.map(function (period) { return period.validUntil; }).sort(descending);
                var first = sorted[0];
                return _jsx(Moment, __assign({ format: t("common.date_format") }, { children: first }));
            }
        },
        // UNREAD RECEIVED
        {
            id: 'unread_received',
            numeric: true,
            disablePadding: false,
            label: t("home.set_time"),
            content: function (data) {
                var latestMessage = getLatestMessage(data.periods);
                if (!latestMessage) {
                    return _jsx("div", { className: "truncate max-w-xs" });
                }
                return (_jsx(Moment, __assign({ format: t("common.date_time_format") }, { children: latestMessage.created })));
            },
        },
        // START QUESTIONNAIRE DONE
        {
            id: 'start_questionnaire_done',
            numeric: true,
            disablePadding: false,
            label: t("period.start_questionnaire_done"),
            content: function (data) {
                var doneDate = data.startQuestionnaireFillDate;
                if (!doneDate) {
                    return _jsx(_Fragment, { children: "-" });
                }
                return _jsx(Moment, __assign({ format: t("common.date_time_format") }, { children: doneDate }));
            },
        },
        // PERIOD COUNT
        {
            id: 'period_count',
            numeric: true,
            disablePadding: false,
            label: t("period.period_count"),
            content: function (data) {
                return String(data.periods.length);
            },
        },
        // ACTION
        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: '',
        },
        // HIDE SOON TO END
        {
            id: "hide_soon_to_end",
            clickable: true,
            numeric: false,
            disablePadding: false,
            label: t("common.hide"),
            content: function (data) {
                return (_jsx("button", __assign({ onClick: function () { return hideFromSoonToEnd(data.periods); } }, { children: t("common.hide") })));
            }
        },
        // HIDE FINISHED
        {
            id: "hide_finished",
            clickable: true,
            numeric: false,
            label: t("common.hide"),
            content: function (data) {
                return (_jsx("button", __assign({ onClick: function () { return hideFinished(data.periods); } }, { children: t("common.hide") })));
            }
        },
        // HIDE PASSIVE
        {
            id: "hide_passive",
            clickable: true,
            label: t("common.hide"),
            content: function (data) {
                return (_jsx("button", __assign({ onClick: function () { return hidePassive(data.periods); } }, { children: t("common.hide") })));
            }
        }
    ];
    var selectPeriod = function (event, id) {
        navigate("/patient/".concat(id, "/periods"));
    };
    return (_jsxs("table", __assign({ className: "border-collapse bg-white rounded-md shadow-md w-full" }, { children: [_jsx("thead", { children: _jsx("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2" }, { children: headCells.map(function (headCell, key) {
                        if (headCell.id !== 'action' &&
                            (!columns || columns.includes(headCell.id))) {
                            var classes = [
                                'px-4 py-4 text-left border-b border-gray-400 cursor-pointer',
                            ];
                            var aClasses = ['text-blue ml-1'];
                            if (sort && sort === headCell.id) {
                                classes.push('');
                                aClasses.push('opacity-100');
                            }
                            else {
                                aClasses.push('opacity-25');
                            }
                            var sortIcon = sort && sort === headCell.id && reverse === true ? (_jsx(ArrowUpwardIcon, { className: aClasses.join(' '), fontSize: "small" })) : (_jsx(ArrowDownwardIcon, { className: aClasses.join(' '), fontSize: "small" }));
                            return (_jsxs("th", __assign({ className: classes.join(' '), onClick: function () { return handleSetSort(headCell.id); } }, { children: [headCell.label, sortIcon] }), key));
                        }
                    }) })) }), _jsx("tbody", { children: patients &&
                    patients.docs &&
                    patients.docs.map(function (patient) {
                        return (_jsx("tr", __assign({ className: "text-sm relative hover:bg-gray-100 cursor-pointer" }, { children: headCells.map(function (headCell, cellKey) {
                                if (headCell.id !== 'action' &&
                                    (!columns || columns.includes(headCell.id))) {
                                    var tProps = {};
                                    var content = headCell.content ? (headCell.content(patient)) : (_jsx(_Fragment, {}));
                                    if (headCell.align) {
                                        tProps = __assign(__assign({}, tProps), { align: headCell.align });
                                    }
                                    return (_jsx("td", __assign({ className: "p-0 border-gray-300 border-b" }, { children: !headCell.clickable ?
                                            _jsx(Link, __assign({ className: "text-left cursor-pointer", to: "/patient/".concat(patient.patientId, "/periods") }, { children: _jsx("div", __assign({ className: "px-4 py-2 w-full h-full" }, { children: content })) })) : content }), cellKey));
                                }
                                return null;
                            }) }), patient.patientId));
                    }) })] })));
};
export default PatientPeriodTable;
