var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearFormState, setFormField, setQuestionnaires, setSearchParamsField, } from '../../../reducers/adminQuerySlice';
import SearchBarQuery from './SearchBarQuery';
import TableDiv from './TableDiv';
import { Pagination } from '../../common/Pagination';
import { Translate } from '../../translation/Translate';
import { searchQuestionnaires } from '../../../actions/questionnaireActions';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../common/toastStyle.css';
import ModalQuery from './ModalQuery';
import format from 'date-fns/format';
import useUser from '../../../hooks/useUser';
var ListQuery = function () {
    var _a;
    var dispatch = useDispatch();
    var user = useUser();
    var _b = __read(useState(false), 2), open = _b[0], setOpen = _b[1];
    var nameTranslate = Translate({ term: 'profile.name' });
    var typeTranslate = Translate({ term: 'messages.type' });
    var moduleTranslate = Translate({ term: 'therapy.module' });
    var modifiedTranslate = Translate({ term: 'common.last_modified' });
    var _c = __read(useState(null), 2), periodId = _c[0], setPeriodId = _c[1];
    var paginatedQuestionnaires = useSelector(function (state) { return state.adminQuery.questionnaires; });
    var searchParams = useSelector(function (state) { return state.adminQuery.searchParams; });
    var sort = searchParams.sort, order = searchParams.order, page = searchParams.page;
    var handleSetSort = function (id) {
        dispatch(setSearchParamsField({ sort: id, order: order === 1 ? -1 : 1 }));
    };
    var onSelectedQuestionnaireClick = function (questionnaire) {
        var _a, _b;
        dispatch(setFormField(__assign(__assign({}, questionnaire), { moduleId: (_b = (((_a = questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.modules) === null || _a === void 0 ? void 0 : _a.length) &&
                (questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.modules)[0]._id)) !== null && _b !== void 0 ? _b : '', queryURL: (questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.questions) || '' })));
        setOpen(true);
    };
    var closeModal = function () {
        dispatch(clearFormState());
        setPeriodId(null);
        setOpen(false);
    };
    var onSearch = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var questionnaires;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!user.session)
                        return [2 /*return*/];
                    return [4 /*yield*/, searchQuestionnaires(__assign(__assign({}, searchParams), { limit: 10 }), user.session)];
                case 1:
                    questionnaires = _a.sent();
                    if (questionnaires) {
                        dispatch(setQuestionnaires(questionnaires));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [(_a = user.session) === null || _a === void 0 ? void 0 : _a.accessToken, searchParams, dispatch]);
    useEffect(function () {
        onSearch();
    }, [onSearch, page]);
    var showToastMessage = function (info) {
        toast(info, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 2000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
    };
    var headCells = [
        {
            id: 'name',
            label: nameTranslate,
            content: function (questionnaire) {
                return _jsx("div", __assign({ className: "truncate .." }, { children: questionnaire.name }));
            },
        },
        {
            id: 'type',
            label: typeTranslate,
            content: function (questionnaire) {
                return _jsx("div", { children: questionnaire.type });
            },
        },
        {
            id: 'modules',
            label: moduleTranslate,
            content: function (questionnaire) {
                return (_jsx("div", __assign({ className: "truncate .." }, { children: questionnaire.modules &&
                        questionnaire.modules.length > 0 &&
                        questionnaire.modules[0].name
                        ? questionnaire.modules[0].name.fi ||
                            questionnaire.modules[0].name.se
                        : '' })));
            },
        },
        {
            id: 'modified.time',
            label: modifiedTranslate,
            content: function (questionnaire) {
                var _a;
                return (_jsx("div", { children: (questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.modified) &&
                        ((_a = questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.modified) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                        questionnaire.modified[0].time
                        ? format(new Date(questionnaire.modified[questionnaire.modified.length - 1].time), 'dd.MM.yyyy')
                        : '-' }));
            },
        },
    ];
    return (_jsxs("div", __assign({ className: "mt-6" }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx(SearchBarQuery, { onSearch: onSearch }), _jsx(TableDiv, { sort: sort, reverse: order === -1, handleSetSort: handleSetSort, materials: paginatedQuestionnaires.docs, setSelectedMaterial: onSelectedQuestionnaireClick, headCells: headCells }), _jsx("div", __assign({ className: "w-full max-w-6xl mt-8" }, { children: _jsx(Pagination, { activePage: paginatedQuestionnaires.page, maxPage: paginatedQuestionnaires.totalPages, switchPage: function (page) { return dispatch(setSearchParamsField({ page: page })); } }) })), _jsx(ModalQuery, { closeModal: closeModal, open: open, periodId: periodId, setPeriodId: setPeriodId, showToastMessage: showToastMessage })] })));
};
export default ListQuery;
