var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from '../../translation/Translate';
var Questionnaire = function (_a) {
    var setOpenModal = _a.setOpenModal, setSelectedQuestionnaire = _a.setSelectedQuestionnaire, questionnaire = _a.questionnaire;
    var openTranslate = Translate({
        term: 'actions.open',
    });
    return (_jsxs("div", __assign({ className: "my-0.5 md:my-1.5 border bg-white rounded-md p-4 shadow-md mb-4" }, { children: [_jsx("div", __assign({ className: "mb-4" }, { children: questionnaire.name })), _jsx("div", __assign({ className: "flex justify-end content-end" }, { children: _jsx("button", __assign({ onClick: function () {
                        setOpenModal(true), setSelectedQuestionnaire(questionnaire);
                    } }, { children: openTranslate })) }))] })));
};
export default Questionnaire;
