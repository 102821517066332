var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate } from '../../translation/Translate';
import Moment from 'react-moment';
import { moduleTotalSessions } from '../../../config/periodSessions';
import useAppSelector from '../../../hooks/useAppSelector';
var TherapyPeriod = function (_a) {
    var _b;
    var classname = _a.classname;
    var t = useTranslate();
    var periods = useAppSelector(function (state) { return state.periods.periods; });
    var ownTherapist = useAppSelector(function (state) { return state.therapist.ownTherapist; });
    var responseDatesTranslate = t('period.response_dates');
    var periodEndsTranslate = t('nav.period') + ' ' + t('messages.ends');
    var phaseTranslate = t('home.phase');
    var latestPeriod = periods ? periods[periods.length - 1] : null;
    if (!latestPeriod) {
        return _jsx(_Fragment, {});
    }
    var _c = getPeriodData(latestPeriod, ownTherapist), endDate = _c.endDate, categories = _c.categories, days = _c.days, sessionsDone = _c.sessionsDone;
    return (_jsxs("div", __assign({ className: classname }, { children: [_jsx("h3", __assign({ className: "mb-2 font-semibold text-xl text-grey-600" }, { children: t('home.your_periods') })), _jsxs("div", __assign({ className: "leading-6" }, { children: [_jsxs("p", { children: [t("therapy.modules.m".concat(latestPeriod.module._id)), categories ? _jsxs(_Fragment, { children: [": ", categories] }) : null] }), _jsxs("p", { children: [responseDatesTranslate, ": ", days] }), _jsxs("p", { children: [periodEndsTranslate, ": ", endDate] }), _jsxs("p", { children: [phaseTranslate, ": ", sessionsDone || '0', "/", moduleTotalSessions[(_b = latestPeriod.module) === null || _b === void 0 ? void 0 : _b._id] || '12'] })] }))] })));
};
export default TherapyPeriod;
var weekdaysFi = [
    'Maanantai',
    'Tiistai',
    'Keskiviikko',
    'Torstai',
    'Perjantai',
    'Lauantai',
    'Sunnuntai',
];
var weekdaysSe = [
    'Måndag',
    'Tisdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lördag',
    'Söndag',
];
var getPeriodData = function (period, ownTherapist) {
    var _a, _b, _c;
    var days = (_a = ownTherapist === null || ownTherapist === void 0 ? void 0 : ownTherapist.therapistInfo.defaultAnswerDays) === null || _a === void 0 ? void 0 : _a.map(function (day) {
        var _a, _b, _c;
        return ((_a = period === null || period === void 0 ? void 0 : period.module) === null || _a === void 0 ? void 0 : _a.name) && ((_c = (_b = period === null || period === void 0 ? void 0 : period.module) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.se)
            ? weekdaysSe[day]
            : weekdaysFi[day];
    });
    var translatedDays = days === null || days === void 0 ? void 0 : days.join(', ');
    var endDate = _jsx(Moment, __assign({ format: "DD/MM/YYYY" }, { children: period.validUntil }));
    var categories = ((_b = period.categories) === null || _b === void 0 ? void 0 : _b.join(', ')) || '';
    return {
        endDate: endDate,
        categories: categories,
        days: translatedDays,
        sessionsDone: period.sessions,
        sessions: ((_c = period === null || period === void 0 ? void 0 : period.module) === null || _c === void 0 ? void 0 : _c.sessions) ? period.module.sessions : '',
    };
};
