var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
var BasicModal = function (_a) {
    var handleClose = _a.handleClose, open = _a.open, children = _a.children;
    return (_jsx(Modal, __assign({ open: open ? open : false, onClose: handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsx("div", __assign({ className: "absolute left-1/2 top-1/2 flex w-full max-w-5xl", style: { transform: 'translate(-50%, -50%)' } }, { children: _jsxs("div", __assign({ className: "relative p-8 bg-white rounded-md w-11/12 m-auto" }, { children: [_jsx("div", __assign({ className: "p-4 absolute top-0 right-0 cursor-pointer", onClick: handleClose }, { children: _jsx(CloseIcon, {}) })), children] })) })) })));
};
export default BasicModal;
