var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Chip } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import usePeriods from "../../../hooks/usePeriods";
import { useTranslate } from "../../translation/Translate";
var MessagePractiseAnnouncements = function (_a) {
    var count = _a.count, name = _a.name;
    var t = useTranslate();
    var _b = __read(useState(false), 2), isHover = _b[0], setIsHover = _b[1];
    var navigate = useNavigate();
    var latestPeriod = usePeriods().latestPeriod;
    return (_jsx("div", __assign({ onClick: function () { var _a; return navigate("/period/".concat((_a = latestPeriod === null || latestPeriod === void 0 ? void 0 : latestPeriod._id) !== null && _a !== void 0 ? _a : "")); }, onMouseEnter: function () { return setIsHover(true); }, onMouseLeave: function () { return setIsHover(false); }, className: "cursor-pointer relative flex items-center justify-center border-warning-200 hover:bg-warning-200 duration-300 border-4 rounded-xl w-full shadow-lg" }, { children: _jsxs("span", { children: [_jsx("button", __assign({ style: { marginLeft: '0px', backgroundColor: isHover ? '#FFB35F' : '#F9FAFB', color: isHover ? 'white' : '#4B4B4B', transitionDuration: '300ms' } }, { children: t(name) })), _jsx(Chip, { className: "absolute -top-3 -right-1", style: { backgroundColor: '#FFB35F', color: 'black', fontSize: 'bold', fontWeight: 'bold', cursor: 'pointer' }, label: count, size: "small" })] }) })));
};
export default MessagePractiseAnnouncements;
