var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DescriptionIcon from '@mui/icons-material/Description';
import Moment from 'react-moment';
import { Translate } from '../../translation/Translate';
var TextMaterial = function (_a) {
    var material = _a.material;
    return (_jsxs("div", { children: [_jsxs("h2", __assign({ className: "text-blue" }, { children: [_jsx(DescriptionIcon, {}), " ", material.name] })), _jsx("div", __assign({ className: "lg:col-span-2" }, { children: _jsx("div", __assign({ className: "mt-2" }, { children: _jsxs("p", __assign({ className: "text-sm text-gray-500" }, { children: [Translate({ term: 'common.last_modified' }), ' ', _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: material.modified || material.created }))] })) })) }))] }));
};
export default TextMaterial;
