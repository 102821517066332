var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    session: undefined,
    userinfo: undefined,
    language: undefined,
};
var UserSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser: function (state, action) {
            if (!action.payload) {
                state.userinfo = null;
            }
            else {
                var info = __assign({}, action.payload);
                if (info.userType) {
                    info.userType.name = action.payload.userType.name;
                }
                state.userinfo = info;
            }
        },
        setSession: function (state, action) {
            if (!action.payload) {
                state.session = null;
                state.userinfo = null;
            }
            else {
                state.session = action.payload;
            }
        },
        setAccessToken: function (state, action) {
            if (!action.payload) {
                state.session = null;
                state.userinfo = null;
            }
            else {
                state.session = __assign(__assign({}, state.session), { accessToken: action.payload });
            }
        },
        setLanguage: function (state, action) {
            if (!action.payload) {
                state.language = null;
            }
            else {
                state.language = action.payload;
            }
        },
    },
});
export var setUser = (_a = UserSlice.actions, _a.setUser), setSession = _a.setSession, setLanguage = _a.setLanguage, setAccessToken = _a.setAccessToken;
export default UserSlice;
