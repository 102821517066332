var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Header from '../../components/therapist/Header';
import { Translate } from '../../components/translation/Translate';
import OwnAvailability from '../../components/therapist/profile/OwnAvailability';
import AbsenceMessage from '../../components/therapist/profile/AbsenceMessage';
import ChangePassword from '../../components/common/profile/ChangePassword';
import UpdateUserInfo from '../../components/therapist/profile/UpdateUserInfo';
var Profile = function () {
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: Translate({ term: 'nav.profile' }) }), _jsxs("main", __assign({ className: "px-8 py-4 flex flex-col gap-6" }, { children: [_jsx(UpdateUserInfo, {}), _jsxs("div", __assign({ className: "grid xl:grid-cols-2 gap-6" }, { children: [_jsx(OwnAvailability, {}), _jsx(AbsenceMessage, {})] })), _jsx("div", __assign({ className: "grid xl:grid-cols-2 gap-6" }, { children: _jsx(ChangePassword, {}) }))] }))] }));
};
export default Profile;
