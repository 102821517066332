var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Translate } from '../../translation/Translate';
import { useNavigate } from 'react-router-dom';
var Phase4 = function () {
    var navigate = useNavigate();
    function navigateToFrontPage() {
        navigate("/", { replace: true });
    }
    return (_jsxs(React.Fragment, { children: [_jsx("h2", __assign({ className: "text-xl" }, { children: Translate({ term: 'account.registration_success' }) })), _jsxs("div", __assign({ className: "grid gap-4" }, { children: [_jsx("div", __assign({ className: "flex-auto text-center" }, { children: Translate({ term: 'account.registration_success_instruction' }) })), _jsx("div", __assign({ className: "text-center" }, { children: _jsx("button", __assign({ onClick: function () { return navigateToFrontPage(); } }, { children: Translate({ term: 'login.welcome' }) })) }))] }))] }));
};
export default Phase4;
