var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from '../../translation/Translate';
import ChatMessageAttachmentList from './chatMessageAttachmentList';
import ChatMessageTextAndDeleteMessage from './chatMessageTextAndDeleteMessage';
import ChatMessageTimeAndSenderInfo from './chatMessageTimeAndSenderInfo';
var ChatMessage = function (_a) {
    var chatMessage = _a.chatMessage, userId = _a.userId, softDeleteHelpChatMessageFunction = _a.softDeleteHelpChatMessageFunction;
    var messageDeletedTranslate = Translate({
        term: 'period.message_deleted',
    });
    var isOwnMessage = chatMessage.createdBy._id === userId ? true : false;
    // Own messages to right side of the chat other messages to left side.
    var messageClass = isOwnMessage
        ? 'flex flex-col mb-4 items-end'
        : 'flex flex-col mb-4';
    return (_jsxs("div", __assign({ className: messageClass }, { children: [_jsx(ChatMessageTimeAndSenderInfo, { isOwnMessage: isOwnMessage, chatMessage: chatMessage }), _jsx("div", __assign({ className: "px-3 py-2 bg-white rounded-2xl w-2/3 mt-1 border border-cyan max-w-xs sm:max-w-sm md:max-w-md xl:max-w-lg 2xl:max-w-xl" }, { children: chatMessage.deleted ? (_jsx("p", { children: messageDeletedTranslate })) : (_jsxs(_Fragment, { children: [_jsx(ChatMessageTextAndDeleteMessage, { isOwnMessage: isOwnMessage, chatMessage: chatMessage, softDeleteHelpChatMessageFunction: softDeleteHelpChatMessageFunction }), _jsx(ChatMessageAttachmentList, { attachmentList: chatMessage.attachments })] })) }))] })));
};
export default ChatMessage;
