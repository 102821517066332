var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import { getPeriodAttachment } from '../../../../actions/periodActions';
import ReactPlayer from 'react-player';
import useUser from '../../../../hooks/useUser';
var Attachment = function (_a) {
    var _b, _c, _d;
    var periodId = _a.periodId, attachment = _a.attachment, preload = _a.preload;
    var user = useUser();
    var link = useRef(null);
    var url = attachment.file ? attachment.file : '';
    var attachmentConfig = {
        file: {
            attributes: {
                preload: preload ? "auto" : "metadata",
            },
        },
    };
    function enLargeImg() {
        // TODO: change img id from name to some id, now if you post 2 same pictures, it doenst resize new one.
        var img = document.getElementById(attachment.name);
        if (img) {
            if (img.style.transform !== 'scale(1.8)') {
                img.style.transform = 'scale(1.8)';
                img.style.marginRight = '54%';
                img.style.cursor = 'zoom-out';
                img.style.transition = 'transform 0.25s ease';
            }
            else {
                img.style.transform = 'scale(1)';
                img.style.cursor = 'zoom-in';
                img.style.marginRight = '0%';
                img.style.transition = 'transform 0.25s ease';
            }
        }
    }
    if (attachment && attachment.file) {
        if ((_b = attachment.mimetype) === null || _b === void 0 ? void 0 : _b.includes('video'))
            return (_jsx(ReactPlayer, { url: url, width: "100%", height: "240px", controls: true, poster: url, config: attachmentConfig }));
        if ((_c = attachment.mimetype) === null || _c === void 0 ? void 0 : _c.includes('application'))
            return (_jsx("div", { children: _jsx("a", __assign({ className: "underline", href: url, target: "_blank", rel: "noreferrer" }, { children: attachment.name })) }));
        if ((_d = attachment.mimetype) === null || _d === void 0 ? void 0 : _d.includes('image'))
            return (_jsx("img", { style: { cursor: 'zoom-in' }, id: attachment.name, onClick: enLargeImg, src: url }));
        if (attachment.file.includes('aannekoulu.fi') ||
            attachment.file.includes('http://') ||
            attachment.file.includes('https://')) {
            return (_jsx(ReactPlayer, { url: url, width: "100%", height: "240px", controls: true, poster: url, config: attachmentConfig }));
        }
    }
    function handleClick() {
        if ((link.current && link.current.href) || !attachment || !attachment.file)
            return;
        var parts = attachment.file.split('/');
        if (parts[0] === periodId && parts[1]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getPeriodAttachment(periodId, parts[1], user.session).then(function (data) {
                if (data) {
                    var imageBlob = new Blob([new Uint8Array(data.content[0].data)], {
                        type: data.contentType,
                    });
                    var href = URL.createObjectURL(imageBlob);
                    if (link.current) {
                        link.current.download = attachment.name;
                        link.current.href = href;
                        link.current.click();
                    }
                }
            });
        }
    }
    return (_jsx("a", __assign({ ref: link, onClick: function () { return handleClick(); }, className: "cursor-pointer bg-gray-100 w-full rounded-md py-1 px-3 text-sm" }, { children: _jsx("p", __assign({ style: {
                maxWidth: '100%',
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                hyphens: 'auto',
            } }, { children: attachment.name })) })));
};
export default Attachment;
