var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from '../../translation/Translate';
import BasicModal from './BasicModal';
var ConfirmationModalWithFunctionParams = function (_a) {
    var headerText = _a.headerText, opentConfirmationModal = _a.opentConfirmationModal, setOpenConfirmationModal = _a.setOpenConfirmationModal, idValue = _a.idValue, actionFunction = _a.actionFunction;
    return (_jsx(BasicModal, __assign({ open: opentConfirmationModal, handleClose: function () { return setOpenConfirmationModal(false); } }, { children: _jsx("div", __assign({ className: "flex justify-center" }, { children: _jsxs("div", __assign({ className: "flex-none" }, { children: [_jsx("h3", { children: headerText }), _jsxs("div", __assign({ className: "justify-items-center justify-center items-center whitespace-nowrap flex space-x-10 pt-4" }, { children: [_jsx("button", __assign({ onClick: function () {
                                    actionFunction(idValue), setOpenConfirmationModal(false);
                                }, className: "bg-white hover:bg-blue duration-300 hover:text-white text-black border-2 border-blue rounded-md" }, { children: Translate({ term: 'common.yes' }) })), _jsx("button", __assign({ onClick: function () { return setOpenConfirmationModal(false); }, className: "bg-white hover:bg-blue duration-300 hover:text-white text-black border-2 border-blue rounded-md" }, { children: Translate({ term: 'common.no' }) }))] }))] })) })) })));
};
export default ConfirmationModalWithFunctionParams;
