var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { openPeriodAssignment } from '../../../reducers/periodSlice';
import AssignmentDetails from '../../common/assignments/AssignmentDetails';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
var AssignmentModal = function (_a) {
    var modalOpen = _a.modalOpen, assignment = _a.assignment;
    var dispatch = useDispatch();
    var handleClose = function () { return dispatch(openPeriodAssignment(null)); };
    return (_jsx(Modal, __assign({ open: modalOpen, onClose: handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsx("div", __assign({ className: "absolute left-1/2 top-1/2 flex w-full max-w-5xl", style: { transform: 'translate(-50%, -50%)' } }, { children: _jsxs("div", __assign({ className: "relative p-8 bg-white rounded-md w-11/12 m-auto" }, { children: [_jsx("div", __assign({ className: "p-4 absolute top-0 right-0 cursor-pointer", onClick: handleClose }, { children: _jsx(CloseIcon, {}) })), assignment ? (_jsxs("div", __assign({ className: "md:flex md:flex-initial gap-8" }, { children: [_jsx("div", __assign({ className: "max-w-md" }, { children: _jsx(AssignmentDetails, __assign({}, assignment)) })), _jsx("div", __assign({ className: "mt-8 border-t pt-8 md:border-t-0 md:border-l md:pl-8" }, { children: "Toiminnot" }))] }))) : ('')] })) })) })));
};
export default AssignmentModal;
