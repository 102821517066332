var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from '../../translation/Translate';
var CreatePost = function (_a) {
    var postData = _a.postData, setPostData = _a.setPostData, sendMessage = _a.sendMessage;
    return (_jsxs("div", __assign({ style: { width: '550px' }, className: "space-y-3" }, { children: [_jsxs("form", __assign({ className: "flex flex-col mt-5 space-y-3" }, { children: [_jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", { children: Translate({ term: 'messages.topic' }) }), _jsx("input", { type: "text", onChange: function (e) {
                                    return setPostData(__assign(__assign({}, postData), { subject: e.target.value }));
                                }, value: postData.subject, className: "w-96" })] })), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", { children: Translate({ term: 'home.content' }) }), _jsx("textarea", { rows: 6, value: postData.message, className: "bg-white border border-gray-300 rounded-md px-2 py-1 w-96", onChange: function (e) {
                                    return setPostData(__assign(__assign({}, postData), { message: e.target.value }));
                                } })] }))] })), _jsxs("div", __assign({ className: "flex justify-end space-x-3" }, { children: [_jsx("button", __assign({ onClick: sendMessage }, { children: Translate({ term: 'actions.create_post' }) })), _jsx("button", __assign({ onClick: function () { return setPostData(__assign(__assign({}, postData), { subject: '', message: '' })); }, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: Translate({ term: 'actions.clear' }) }))] }))] })));
};
export default CreatePost;
