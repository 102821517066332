var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable indent */
import { Modal } from '@mui/material';
import { useState } from 'react';
import { activateUser, deactivateUser, } from '../../../../actions/userActions';
import ConfirmModal from '../../../common/ConfirmModal';
import { Translate } from '../../../translation/Translate';
import UserInfo from './UserInfo';
import UserUpdate from './UserUpdate';
import useUser from '../../../../hooks/useUser';
var UsersModal = function (_a) {
    var _b;
    var showToastMessage = _a.showToastMessage, selectedUser = _a.selectedUser, setSelectedUser = _a.setSelectedUser, searchAllUsers = _a.searchAllUsers;
    var user = useUser();
    var _c = __read(useState(false), 2), open = _c[0], setOpen = _c[1];
    var _d = __read(useState(selectedUser && selectedUser.active ? true : false), 2), active = _d[0], setActive = _d[1];
    var userActiveTranslate = Translate({ term: 'profile.user_active' });
    var userDectiveTranslate = Translate({ term: 'profile.user_deactive' });
    var sureTranslate = Translate({ term: 'common.sure' });
    var removeUserTranslate = Translate({ term: 'common.remove_user' });
    var isActiveTranslate = Translate({ term: 'profile.is_active' });
    var isActiveSelectedUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var activeData, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(selectedUser && selectedUser._id)) return [3 /*break*/, 5];
                    activeData = {
                        email: selectedUser.email,
                        id: selectedUser._id,
                    };
                    if (!!active) return [3 /*break*/, 2];
                    return [4 /*yield*/, activateUser(activeData, user.session)
                            .then(function (data) {
                            if (data) {
                                setActive(true);
                                open ? setOpen(false) : null;
                                showToastMessage(userActiveTranslate);
                            }
                        })
                            .catch(function (err) {
                            // console.error(err);
                        })];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, deactivateUser(activeData, user.session)
                        .then(function (data) {
                        if (data) {
                            setActive(false);
                            open ? setOpen(false) : null;
                            showToastMessage(userDectiveTranslate);
                        }
                    })
                        .catch(function (err) {
                        // console.error(err);
                    })];
                case 3:
                    _a = _b.sent();
                    _b.label = 4;
                case 4:
                    _a;
                    _b.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Modal, __assign({ open: selectedUser !== null, onClose: function () { return setSelectedUser(null); }, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", disableAutoFocus: true }, { children: _jsx("div", __assign({ className: "fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center", style: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add a semi-transparent background overlay
            } }, { children: _jsxs("div", __assign({ className: "bg-white p-5 border rounded-md max-h-[80vh] w-[650px] overflow-y-auto relative", style: {
                    maxHeight: '80vh',
                    width: '650px',
                } }, { children: [_jsx(ConfirmModal, { open: open, onClose: function () { return setOpen(false); }, message: [removeUserTranslate, sureTranslate], deleteSelectedItem: isActiveSelectedUser }), _jsxs("div", __assign({ className: "p-5 relative h-full flex flex-col justify-between" }, { children: [selectedUser ? (_jsxs("div", __assign({ className: active ? '' : 'opacity-40 cursor-default' }, { children: [_jsx(UserInfo, { selectedUser: selectedUser, setSelectedUser: setSelectedUser }), selectedUser.status === 'admin' ? (_jsx("div", { className: "mt-10" })) : (_jsx(UserUpdate, { showToastMessage: showToastMessage, searchAllUsers: searchAllUsers, setSelectedUser: setSelectedUser, active: active, therapyModels: selectedUser.therapyModels
                                            ? selectedUser.therapyModels
                                            : {
                                                online: false,
                                                onsite: false,
                                                remote: false,
                                                hybrid: false,
                                                evaluations: false,
                                            }, isOverflowTherapist: (_b = selectedUser.isOverflowTherapist) !== null && _b !== void 0 ? _b : false, id: selectedUser._id, status: selectedUser.status, moduleArrTemp: selectedUser.modules, zoneArrTemp: selectedUser.zone, evaluationZonesArrTemp: selectedUser.evaluationZones }))] }))) : null, _jsx("div", __assign({ className: "absolute bottom-2 left-2" }, { children: _jsxs("div", __assign({ className: "flex flex-row gap-4" }, { children: [_jsxs("label", __assign({ className: "switch" }, { children: [_jsx("input", { checked: active, type: "checkbox", onChange: function () {
                                                        active ? setOpen(true) : isActiveSelectedUser();
                                                    } }), _jsx("span", { className: "slider round" })] })), _jsx("p", __assign({ className: "mt-1" }, { children: isActiveTranslate }))] })) }))] }))] })) })) })));
};
export default UsersModal;
