var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
var PatientSearch = function () {
    var navigate = useNavigate();
    var periods = useSelector(function (state) { return state.periods; });
    var createOptionsArray = function (originalArray) {
        var e_1, _a;
        var _b, _c;
        var options = [];
        var counter = {}; // keeps track of duplicate labels
        var reversedOriginalArray = originalArray.slice(); // create a copy of the original array
        reversedOriginalArray.reverse(); // reverse the array to apply correct order for count
        try {
            for (var reversedOriginalArray_1 = __values(reversedOriginalArray), reversedOriginalArray_1_1 = reversedOriginalArray_1.next(); !reversedOriginalArray_1_1.done; reversedOriginalArray_1_1 = reversedOriginalArray_1.next()) {
                var period = reversedOriginalArray_1_1.value;
                if (period.patient) {
                    var label = "".concat(period.patient.firstName, " ").concat((_b = period.patient.lastName) !== null && _b !== void 0 ? _b : '');
                    var count = (_c = counter[label]) !== null && _c !== void 0 ? _c : 0; // get current count or default to 0
                    counter[label] = count + 1; // increment count for this label
                    var value = period._id;
                    options.push({
                        value: value,
                        label: count === 0 ? label : "".concat(label, " (").concat(count + 1, ")"),
                    });
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (reversedOriginalArray_1_1 && !reversedOriginalArray_1_1.done && (_a = reversedOriginalArray_1.return)) _a.call(reversedOriginalArray_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return orderBy(options, function (option) { return option.label; });
    };
    var userPeriods = createOptionsArray((periods === null || periods === void 0 ? void 0 : periods.periods) || []);
    var usersOptions = {
        options: userPeriods,
        getOptionLabel: function (option) { return option.label; },
        isOptionEqualToValue: function (option, value) {
            return option.value === value.value;
        },
    };
    return (_jsx("div", __assign({ className: "mt-3" }, { children: _jsxs("label", __assign({ className: "relative block" }, { children: [_jsx("span", __assign({ className: "absolute inset-y-0 left-0 flex items-center pl-2" }, { children: _jsx("div", __assign({ className: "h-5 w-5 text-gray-500 -mt-1" }, { children: _jsx(SearchIcon, {}) })) })), _jsx(Autocomplete, __assign({}, usersOptions, { style: { width: '95%' }, className: "bg-white border border-gray-300 rounded-md p-2 pl-8", id: "disable-close-on-select", onChange: function (event, newValue) {
                        if (newValue && newValue.value) {
                            navigate('/periods/' + newValue.value, { replace: true });
                        }
                    }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { variant: "standard", className: "border-0" }))); } }))] })) })));
};
export default PatientSearch;
