var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getMaterials, } from '../../../actions/periodMaterialActions';
import { Translate } from '../../translation/Translate';
import MaterialModal from '../modals/materials/MaterialModal';
import SearchDiv from './SearchDiv';
import SelectDiv from './SelectDiv';
import TableDiv from './TableDiv';
import { Pagination } from '../../common/Pagination';
import { ToastContainer } from 'react-toastify';
import { setFormField } from '../../../reducers/adminMaterialsSlice';
import useUser from '../../../hooks/useUser';
import useToast from '../../../hooks/useToast';
var SearchMaterial = function () {
    var dispatch = useDispatch();
    var allTranslate = Translate({ term: 'material_library.all' });
    var user = useUser();
    var _a = __read(useState({
        materials: [],
        lastPage: 1,
    }), 2), materials = _a[0], setMaterials = _a[1];
    var _b = __read(useState(''), 2), materialName = _b[0], setMaterialName = _b[1];
    var _c = __read(useState({
        value: '0',
        optionText: allTranslate,
    }), 2), module = _c[0], setModule = _c[1];
    var _d = __read(useState(allTranslate), 2), category = _d[0], setCategory = _d[1];
    var _e = __read(useState(true), 2), reverse = _e[0], setReverse = _e[1];
    var _f = __read(useState('updatedAt'), 2), sort = _f[0], setSort = _f[1];
    var _g = __read(useState(1), 2), page = _g[0], setPage = _g[1];
    var materialNameTranslate = Translate({
        term: 'material_library.material_name',
    });
    var typeTranslate = Translate({ term: 'messages.type' });
    var moduleTranslate = Translate({ term: 'therapy.module' });
    var categoryTranslate = Translate({ term: 'material_library.category' });
    var modifiedTranslate = Translate({ term: 'common.last_modified' });
    useEffect(function () {
        searchAllMaterials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sort, reverse]);
    var searchAllMaterials = function () { return __awaiter(void 0, void 0, void 0, function () {
        var getMaterialsData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    getMaterialsData = {
                        page: page,
                        sort: sort,
                        reverse: reverse ? -1 : 1,
                        limit: 6,
                        materialName: materialName,
                        module: module.value === '0' ? '' : module.value,
                        category: category === allTranslate ? '' : category,
                    };
                    return [4 /*yield*/, getMaterials(getMaterialsData, user.session)
                            .then(function (data) {
                            if (data) {
                                setMaterials({
                                    materials: data.docs,
                                    lastPage: data.totalPages === 0 ? 1 : data.totalPages,
                                });
                            }
                        })
                            .catch(function (err) {
                            //console.error(err);
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    function handleSetSort(id) {
        if (sort && id === sort) {
            setReverse(!reverse);
        }
        else {
            setSort(id);
            setReverse(!reverse);
        }
    }
    var clearSearch = function () {
        setModule({
            value: '0',
            optionText: allTranslate,
        });
        setCategory(allTranslate);
        setMaterialName('');
    };
    var showToastMessage = useToast().showToastMessage;
    var headCells = [
        {
            id: 'name',
            label: materialNameTranslate,
            content: function (material) {
                return _jsx("div", __assign({ className: "truncate .." }, { children: material.name }));
            },
        },
        {
            id: 'type',
            label: typeTranslate,
            content: function (material) {
                return _jsx("div", { children: material.type });
            },
        },
        {
            id: 'module',
            label: moduleTranslate,
            content: function (material) {
                return (_jsx("div", { children: material.modules &&
                        material.modules.length > 0 &&
                        material.modules
                            .map(function (module) { return module.name.fi || module.name.se; })
                            .join(', ') }));
            },
        },
        {
            id: 'category',
            label: categoryTranslate,
            content: function (material) {
                return (_jsx("div", __assign({ className: "truncate .." }, { children: material.modules &&
                        material.modules.length > 0 &&
                        material.modules[0].categories &&
                        material.modules[0].categories.length > 0
                        ? material.modules[0].categories[0].fi ||
                            material.modules[0].categories[0].se
                        : '' })));
            },
        },
        {
            id: 'updatedAt',
            label: modifiedTranslate,
            content: function (material) {
                return _jsx("div", { children: moment(material.updatedAt).format('DD.MM.YYYY') });
            },
        },
    ];
    return (_jsxs("div", __assign({ className: "mt-6" }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx(MaterialModal, { showToastMessage: showToastMessage, searchAllMaterials: searchAllMaterials }), _jsx(SearchDiv, { materialName: materialName, setMaterialName: setMaterialName }), _jsx(SelectDiv, { module: module, category: category, setModule: setModule, setCategory: setCategory, setPage: setPage, searchAllMaterials: searchAllMaterials, clearSearch: clearSearch }), _jsx(TableDiv, { sort: sort, reverse: reverse, handleSetSort: handleSetSort, materials: materials.materials ? materials.materials : [], setSelectedMaterial: function (material) {
                    var _a, _b;
                    return dispatch(setFormField(__assign(__assign({}, material), { modules: (_b = (_a = material === null || material === void 0 ? void 0 : material.modules) === null || _a === void 0 ? void 0 : _a.map(function (module) { return module._id; })) !== null && _b !== void 0 ? _b : [] })));
                }, headCells: headCells }), _jsx(Pagination, { activePage: page, maxPage: materials.lastPage, switchPage: setPage })] })));
};
export default SearchMaterial;
