var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { Translate } from "../../components/translation/Translate";
var DefaultLayout = function () {
    var header = _jsxs("header", __assign({ className: "header absolute top-0 left-0" }, { children: [_jsx("div", __assign({ className: "logo" }, { children: _jsx(Link, __assign({ to: "/" }, { children: Translate({ term: "site.name" }) })) })), _jsx("nav", __assign({ className: "main-nav" }, { children: _jsx("ul", {}) }))] }));
    return (_jsx(React.Fragment, { children: _jsx("main", { children: _jsx(Outlet, {}) }) }));
};
export default DefaultLayout;
