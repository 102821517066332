var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setEvaluation, setEvaluationInvitations, } from '../../reducers/evaluationSlice';
import Header from '../../components/therapist/Header';
import { Translate } from '../../components/translation/Translate';
import { getEvaluationInvitations } from '../../actions/invitationActions';
import { getZonesEvaluations } from '../../actions/evaluationActions';
import WaitingEvaluationsTable from '../../components/therapist/Evaluations/WaitingEvaluationsTable';
import ProsessedEvaluationsTable from '../../components/therapist/Evaluations/ProcessedEvaluationsTable';
import EvaluationsInvitationsTable from '../../components/therapist/Evaluations/EvaluationInvitationsTable';
import ButtonWithCount from '../../components/common/ButtonWithCount';
import useUser from '../../hooks/useUser';
var Messages = function () {
    var dispatch = useDispatch();
    var user = useUser();
    var _a = __read(useState(0), 2), activeTab = _a[0], setActiveTab = _a[1];
    var evaluationInvitations = useSelector(function (state) { return state.evaluations.evaluationInvitations; });
    var evaluations = useSelector(function (state) { return state.evaluations.evaluations; });
    var _b = __read(useState(1), 2), page = _b[0], setPage = _b[1];
    var _c = __read(useState(1), 2), lastPage = _c[0], setLastPage = _c[1];
    var pageTranslate = Translate({ term: 'common.page' });
    var nextTranslate = Translate({ term: 'actions.next' });
    var previousTranslate = Translate({ term: 'actions.previous' });
    useEffect(function () {
        // TODO: here get periods by page
    }, [page]);
    var next = function () {
        if (page < lastPage)
            setPage(page + 1);
    };
    var prev = function () {
        if (page >= 2)
            setPage(page - 1);
    };
    function loadEvaluationInvitations() {
        getEvaluationInvitations(user.session)
            .then(function (response) {
            if (response) {
                dispatch(setEvaluationInvitations(response));
            }
        })
            .catch(function (error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            //console.error(errorCode, errorMessage);
        });
    }
    function loadEvaluations() {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getZonesEvaluations(user.session)];
                    case 1:
                        response = _a.sent();
                        if (response)
                            dispatch(setEvaluation(response));
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        loadEvaluationInvitations();
        loadEvaluations();
    }, []);
    var readyEvaluations = [];
    var unReadyEvaluations = [];
    if (evaluations) {
        readyEvaluations = evaluations.filter(function (evaluation) { return evaluation.ready; });
        unReadyEvaluations = evaluations.filter(function (evaluation) { return !evaluation.ready; });
    }
    var selectors = [
        {
            id: 0,
            amount: evaluationInvitations === null || evaluationInvitations === void 0 ? void 0 : evaluationInvitations.length,
            title: Translate({ term: 'evaluations.sent_licenses' }),
            content: evaluationInvitations,
            // columns: ["name", "area", "licenseCode"]
        },
        {
            id: 1,
            amount: unReadyEvaluations.length,
            title: Translate({ term: 'evaluations.waiting_evaluation' }),
            content: unReadyEvaluations,
            // columns: ["name", "ageGroupType", "zone"]
        },
        {
            id: 2,
            amount: readyEvaluations.length,
            title: Translate({ term: 'evaluations.evaluated' }),
            content: readyEvaluations,
            // columns: ["name", "ageGroupType", "zone"]
        },
    ];
    var tableSettings = function (index) {
        setActiveTab(index);
    };
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: Translate({ term: 'nav.evaluations' }) }), _jsxs("main", __assign({ className: "px-8 py-4" }, { children: [_jsx("div", __assign({ className: "my-4 flex" }, { children: selectors.map(function (s, key) { return (_jsx(ButtonWithCount, { id: s.id, amount: s.amount, title: s.title, active: activeTab === s.id, action: tableSettings }, key)); }) })), _jsx("div", { children: (function () {
                            if (activeTab === 0) {
                                return (_jsx("div", __assign({ className: "col-span-3" }, { children: _jsx(EvaluationsInvitationsTable, {}) })));
                            }
                            else if (activeTab === 1) {
                                return (_jsx("div", __assign({ className: "col-span-3" }, { children: _jsx(WaitingEvaluationsTable, { evaluations: selectors[activeTab].content }) })));
                            }
                            else {
                                return (_jsx("div", __assign({ className: "col-span-3" }, { children: _jsx(ProsessedEvaluationsTable, { evaluations: selectors[activeTab].content }) })));
                            }
                        })() }), _jsxs("div", __assign({ className: "flex flex-row justify-between w-full px-4" }, { children: [page > 1 ? (_jsx("button", __assign({ onClick: prev, className: "mt-3 -ml-4 border-2 border-gray-200 bg-white text-black hover:bg-gray-200 duration-300" }, { children: previousTranslate }))) : (_jsx("button", __assign({ className: "cursor-default mt-3 -ml-4 border-2 border-gray-200 bg-white text-gray-500" }, { children: previousTranslate }))), _jsxs("div", __assign({ className: "mt-6 flex flex-row" }, { children: [pageTranslate, ' ', _jsxs("p", __assign({ className: "ml-1 font-bold" }, { children: [page, "/", lastPage] }))] })), page < lastPage ? (_jsx("button", __assign({ onClick: next, className: "mt-3 -mr-4 border-2 border-gray-200 bg-white text-black hover:bg-gray-200 duration-300" }, { children: nextTranslate }))) : (_jsx("button", __assign({ className: "cursor-default mt-3 -mr-4 border-2 border-gray-200 bg-white text-gray-500" }, { children: nextTranslate })))] }))] }))] }));
};
export default Messages;
