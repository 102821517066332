var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    patientPeriods: {},
};
var PatientSlice = createSlice({
    name: 'patients',
    initialState: initialState,
    reducers: {
        setPatientPeriods: function (state, action) {
            state.patientPeriods[action.payload.patientId] = action.payload.periods;
        },
        setAllPatientPeriods: function (state, action) {
            state.patientPeriods = action.payload;
        },
        resetPatientPeriods: function (state) {
            state.patientPeriods = {};
        },
    },
});
export var setPatientPeriods = (_a = PatientSlice.actions, _a.setPatientPeriods), setAllPatientPeriods = _a.setAllPatientPeriods, resetPatientPeriods = _a.resetPatientPeriods;
export default PatientSlice;
