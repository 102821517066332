var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Translate } from '../translation/Translate';
import { generalDefaultMessages } from '../therapist/Posts/GeneralDefaultMessages';
var PickDefaultMessage = function (_a) {
    var _b, _c;
    var useDefaultMessage = _a.useDefaultMessage, defaultMessage = _a.defaultMessage, user = _a.user;
    var messageTemplates = generalDefaultMessages;
    if ((_c = (_b = user === null || user === void 0 ? void 0 : user.userinfo) === null || _b === void 0 ? void 0 : _b.therapistInfo) === null || _c === void 0 ? void 0 : _c.defaultMessages) {
        messageTemplates = messageTemplates.concat(user.userinfo.therapistInfo.defaultMessages);
    }
    return (_jsxs(_Fragment, { children: [_jsxs("label", __assign({ className: "col-start-1" }, { children: [Translate({ term: 'messages.template' }), ' '] })), _jsx("div", __assign({ className: "md:col-span-2" }, { children: _jsxs("select", __assign({ onChange: useDefaultMessage, ref: defaultMessage }, { children: [_jsx("option", { children: Translate({ term: 'messages.select_message_template' }) +
                                ' (' +
                                Translate({ term: 'common.optional' }) +
                                ')' }), messageTemplates.map(function (defaultMessage, key) { return (_jsx("option", __assign({ value: JSON.stringify({
                                subject: defaultMessage.name,
                                message: defaultMessage.message,
                            }) }, { children: defaultMessage.name }), key)); })] })) }))] }));
};
export default PickDefaultMessage;
