var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useRef } from 'react';
import { allowedMimeTypes } from '../../../../actions/fileActions';
import useToast from '../../../../hooks/useToast';
import { Translate } from '../../../translation/Translate';
var AddAttachmentButton = function (_a) {
    var attachmentList = _a.attachmentList, setAttachmentList = _a.setAttachmentList;
    var showToastMessage = useToast().showToastMessage;
    var attachment = useRef(null);
    var invalidFileFormatErrorTranslate = Translate({
        term: 'common.wrong_file_format',
    });
    var fileSelector = function () {
        if (attachment && attachment.current) {
            attachment.current.click();
        }
    };
    var handleFileSelection = function (event) {
        var files = event.target.files;
        if (files && files.length > 0) {
            if (!allowedMimeTypes.all.includes(files[0].type)) {
                showToastMessage(invalidFileFormatErrorTranslate, 'warning');
                return;
            }
            // 1 000 000 000 === 1gt
            if (files[0].size > 1000000000) {
                // TODO: add error message
                //showToastMessage('warning', fileSizeTooLargeTranslate);
                return;
            }
            var file = {
                name: files[0].name,
                file: files[0],
            };
            var newAttachmentList = __spreadArray(__spreadArray([], __read(attachmentList), false), [file], false);
            setAttachmentList(newAttachmentList);
        }
    };
    return (_jsxs("div", __assign({ className: "space-x-3 flex flex-row" }, { children: [_jsx("div", __assign({ className: "flex-initial bg-lightBlue p-1.5 rounded-full text-blue cursor-pointer", onClick: fileSelector }, { children: _jsx(AttachFileIcon, { style: { marginTop: '2px' }, className: "rotate-45" }) })), _jsx("input", { type: "file", name: "file[1]", className: "hidden", ref: attachment, onChange: handleFileSelection })] })));
};
export default AddAttachmentButton;
