var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { LanguageContext, Translate } from '../../translation/Translate';
import PatientSearch from './PatientSearch';
import akLogo from '../../../assets/images/ak-logo.png';
import asLogo from '../../../assets/images/as-logo.png';
var MenuHeader = function (_a) {
    var iconsOnly = _a.iconsOnly;
    var language = useContext(LanguageContext);
    return (_jsxs("div", __assign({ className: "mb-4 flex flex-col gap-3 px-6" }, { children: [_jsx(NavLink, __assign({ to: '/' }, { children: _jsxs("div", __assign({ className: "flex items-center gap-2 flex-none" }, { children: [_jsx("div", __assign({ className: "w-8 h-8 bg-white border border-blue border-opacity-25 p-1 shadow-md rounded-md overflow-hidden flex justify-items-center items-center" }, { children: _jsx("img", { alt: Translate({ term: 'site.name_short' }), src: language === 'se' ? asLogo : akLogo }) })), iconsOnly ? ('') : (_jsx("div", __assign({ className: "text-lg text-blue font-semibold" }, { children: Translate({ term: 'site.name' }) })))] })) })), iconsOnly ? '' : _jsx(PatientSearch, {})] })));
};
export default MenuHeader;
