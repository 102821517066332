var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from '../translation/Translate';
var SelectLanguage = function (_a) {
    var selectedLanguage = _a.selectedLanguage, setSelectedLanguage = _a.setSelectedLanguage, style = _a.style;
    var finnishTranslate = Translate({
        term: 'languages.finnish',
    });
    var swedishTranslate = Translate({
        term: 'languages.swedish',
    });
    return (_jsxs("div", __assign({ className: style }, { children: [_jsx("label", { children: _jsx("div", __assign({ className: "text-left" }, { children: Translate({ term: 'profile.select_language' }) })) }), _jsxs("select", __assign({ value: selectedLanguage, onChange: function (e) { return setSelectedLanguage(e.target.value); } }, { children: [_jsx("option", __assign({ value: "fi" }, { children: finnishTranslate })), _jsx("option", __assign({ value: "se" }, { children: swedishTranslate }))] }))] }), 'language'));
};
export default SelectLanguage;
