var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
/* MUI elements start */
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
/* MUI elements end */
import Header from '../../components/therapist/Header';
import { Translate } from '../../components/translation/Translate';
import DefaultMessages from '../../components/therapist/Posts/DefaultMessages';
import GroupMessage from '../../components/therapist/Posts/GroupMessage';
import MessagesTable from '../../components/therapist/Posts/MessagesTable';
import useUser from '../../hooks/useUser';
var Messages = function () {
    var user = useUser();
    var _a = __read(useState(0), 2), activeTab = _a[0], setActiveTab = _a[1];
    var handleTabSelection = function (event, newValue) {
        setActiveTab(newValue);
    };
    var tabs = [
        {
            title: Translate({ term: 'messages.admin_messages' }),
            content: (_jsx("div", __assign({ className: "bg-white pt-4 p-8 rounded-md shadow-md mt-6" }, { children: _jsx(MessagesTable, {}) }))),
        },
        {
            title: Translate({ term: 'messages.sending_group_messages' }),
            content: (_jsx("div", __assign({ className: "bg-white pt-4 p-8 rounded-md shadow-md mt-6" }, { children: _jsx(GroupMessage, { session: user.session }) }))),
        },
        {
            title: Translate({ term: 'messages.templates' }),
            content: (_jsx("div", __assign({ className: "mt-6" }, { children: _jsx(DefaultMessages, {}) }))),
        },
    ];
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: Translate({ term: 'nav.messages' }) }), _jsxs("main", __assign({ className: "px-8 py-4" }, { children: [_jsx("div", __assign({ className: "flex overflow-x-auto" }, { children: _jsx(Tabs, __assign({ value: activeTab, onChange: handleTabSelection, "aria-label": "basic tabs example", className: "flex-shrink-0" }, { children: tabs.map(function (t, key) { return (_jsx(Tab, { label: t.title, className: "min-w-[120px]" }, key)); }) })) })), _jsx("div", __assign({ className: "mt-6" }, { children: tabs[activeTab].content }))] }))] }));
};
export default Messages;
