var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { changePasswordWithLink } from '../actions/userActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckIcon from '@mui/icons-material/Check';
import { Translate } from '../components/translation/Translate';
import { useNavigate } from 'react-router-dom';
var ChangePasswordRoute = function () {
    var navigate = useNavigate();
    var _a = __read(useState(''), 2), newPassword = _a[0], setNewPassword = _a[1];
    var _b = __read(useState(''), 2), newPasswordAgain = _b[0], setNewPasswordAgain = _b[1];
    var _c = __read(useState('password'), 2), inputType = _c[0], setInputType = _c[1];
    var _d = __read(useState(null), 2), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = __read(useState(null), 2), successMessage = _e[0], setSuccessMessage = _e[1];
    var _f = __read(useState(false), 2), newPasswordValid = _f[0], setNewPasswordValid = _f[1];
    var _g = __read(useState(false), 2), newPasswordAgainValid = _g[0], setNewPasswordAgainValid = _g[1];
    var _h = __read(useState(false), 2), loginSuccess = _h[0], setLoginSuccess = _h[1];
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var passwordResetToken = urlParams.get('id');
    var differentPassWordText = Translate({
        term: 'profile.different_passwords',
    });
    var passwordChangeSuccess = Translate({
        term: 'profile.password_change_success',
    });
    var passwordChangeFailed = Translate({
        term: 'profile.password_change_failed',
    });
    var passwordChangeInfo = Translate({
        term: 'profile.password_change_info',
    });
    function validatePassword(password, setPassword, setNewPass) {
        setNewPass(password);
        if (password.length < 14 ||
            !password.match(/[a-z]/g) ||
            !password.match(/[A-Z]/g) ||
            !password.match(/[0-9]/g) ||
            !password.match(/[^a-zA-Z\d]/g)) {
            setPassword(false);
        }
        else {
            setPassword(true);
        }
    }
    function navigateToFrontPage() {
        navigate("/", { replace: true });
    }
    function savePassword(e) {
        return __awaiter(this, void 0, void 0, function () {
            var passwordData, updatePasswordData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        if (!(newPassword === newPasswordAgain)) return [3 /*break*/, 2];
                        passwordData = {
                            newPassword: newPassword,
                            newPasswordAgain: newPasswordAgain,
                        };
                        return [4 /*yield*/, changePasswordWithLink(passwordData, passwordResetToken)];
                    case 1:
                        updatePasswordData = _a.sent();
                        if (updatePasswordData) {
                            setSuccessMessage(passwordChangeSuccess);
                            setErrorMessage(null);
                            setNewPassword('');
                            setNewPasswordAgain('');
                            setLoginSuccess(true);
                        }
                        else {
                            setErrorMessage(passwordChangeFailed);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        setErrorMessage(differentPassWordText);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    return (_jsx("div", __assign({ className: "flex flex-col justify-center text-center items-center my-12", style: { minHeight: '80vh' } }, { children: _jsxs("form", __assign({ className: "bg-cyan px-8 md:px-16 py-8 rounded-md shadow-md text-center grid gap-8" }, { children: [_jsx("h2", __assign({ className: "mb-6" }, { children: Translate({ term: 'profile.change_passw' }) })), _jsx("div", __assign({ className: "text-success-600 px-4 mb-6 py-2 bg-white border border-success-600 rounded-md" }, { children: _jsx("p", { children: passwordChangeInfo }) })), _jsxs("div", { children: [_jsxs("div", __assign({ className: "mb-6" }, { children: [_jsxs("label", __assign({ htmlFor: "newPassword" }, { children: [Translate({ term: 'profile.new_password' }), "*"] })), _jsxs("div", __assign({ className: "relative" }, { children: [_jsx("input", { type: inputType, id: "newPassword", autoComplete: "off", style: { paddingLeft: '15px', paddingRight: '15px' }, required: true, onChange: function (e) {
                                                return validatePassword(e.target.value, setNewPasswordValid, setNewPassword);
                                            } }), newPasswordValid && (_jsx(CheckIcon, { style: {
                                                position: 'absolute',
                                                right: '-15px',
                                                color: 'green',
                                            } }))] }))] })), _jsxs("div", __assign({ className: "mb-6" }, { children: [_jsxs("label", __assign({ htmlFor: "newPasswordAgain" }, { children: [Translate({ term: 'profile.new_password_again' }), "*"] })), _jsxs("div", __assign({ className: "relative" }, { children: [_jsx("input", { type: inputType, id: "newPasswordAgain", autoComplete: "off", style: { paddingLeft: '15px', paddingRight: '15px' }, required: true, onChange: function (e) {
                                                return validatePassword(e.target.value, setNewPasswordAgainValid, setNewPasswordAgain);
                                            } }), newPasswordAgainValid && (_jsx(CheckIcon, { style: {
                                                position: 'absolute',
                                                right: '-15px',
                                                color: 'green',
                                            } }))] }))] })), _jsx("div", __assign({ className: "flex justify-center space-x-5 mb-6" }, { children: _jsx("div", __assign({ onClick: function () {
                                    inputType === 'password'
                                        ? setInputType('text')
                                        : setInputType('password');
                                }, className: "cursor-pointer" }, { children: inputType === 'password' ? (_jsx(VisibilityOffIcon, {})) : (_jsx(VisibilityIcon, {})) })) })), errorMessage && (_jsx("div", __assign({ className: "text-warning-600 px-4 mb-6 py-2 bg-white border border-warning-600 rounded-md" }, { children: _jsx("p", { children: errorMessage }) }))), successMessage && (_jsx("div", __assign({ className: "text-success-600 px-4 mb-6 py-2 bg-white border border-success-600 rounded-md" }, { children: _jsx("p", { children: successMessage }) }))), _jsx("div", { children: loginSuccess ? (_jsx("button", __assign({ onClick: function () { return navigateToFrontPage(); } }, { children: Translate({ term: 'login.welcome' }) }))) : (_jsx("button", __assign({ onClick: savePassword, disabled: !newPasswordValid || !newPasswordAgainValid, className: newPasswordValid && newPasswordAgainValid
                                    ? ''
                                    : 'cursor-default bg-opacity-50' }, { children: Translate({ term: 'profile.save_password' }) }))) })] })] })) })));
};
export default ChangePasswordRoute;
