var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AssignmentModal from '../../components/therapist/period/AssignmentModal';
import { setPeriod } from '../../reducers/periodSlice';
import { getTherapistPeriod } from '../../actions/periodActions';
import { findIndexFromArray } from '../../functions/finders';
import PeriodContext from '../../components/common/period/PeriodContext';
import { PeriodContent } from '../../components/therapist/period/PeriodContent';
import useUser from '../../hooks/useUser';
var Period = function () {
    var dispatch = useDispatch();
    var period = useSelector(function (state) { return state.periods.active; });
    var drafts = useSelector(function (state) { return state.periods.drafts; });
    var draft = undefined;
    var periodId = useParams().id;
    var user = useUser();
    var activeAssignment = undefined;
    useEffect(function () {
        dispatch(setPeriod(null));
        if (periodId) {
            getTherapistPeriod(periodId, user.session)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then(function (data) {
                if (data)
                    dispatch(setPeriod(data));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodId]);
    if (drafts && periodId) {
        var draftIndex = findIndexFromArray(drafts, 'periodId', periodId);
        if (draftIndex !== undefined)
            draft = drafts[draftIndex];
    }
    if ((period === null || period === void 0 ? void 0 : period.assignments) &&
        (period === null || period === void 0 ? void 0 : period.assignments.length) > 0 &&
        (period === null || period === void 0 ? void 0 : period.openAssignment)) {
        var assingmentIndex = findIndexFromArray(period.assignments, 'id', period.openAssignment);
        if (assingmentIndex !== undefined)
            activeAssignment = period.assignments[assingmentIndex];
    }
    return (_jsxs(PeriodContext.Provider, __assign({ value: { draft: draft, period: period } }, { children: [_jsx(PeriodContent, { userInfo: user.userinfo }), _jsx(AssignmentModal, { modalOpen: (period === null || period === void 0 ? void 0 : period.openAssignment) ? true : false, assignment: activeAssignment })] })));
};
export default Period;
