var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CreateLicenses from '../../components/admin/licenses/CreateLicenses';
import CreateZone from '../../components/admin/licenses/CreateZone';
import SearchLicenses from '../../components/admin/licenses/SearchLicenses';
import TabButton from '../../components/admin/materials/TabButton';
import Header from '../../components/licenseController/Header';
import { Translate } from '../../components/translation/Translate';
import { setLicenseTab } from '../../reducers/adminLicenseSlice';
var Licenses = function () {
    var dispatch = useDispatch();
    var selectedTab = useSelector(function (state) { return state.adminLicense.licenseTab; });
    var tabs = [
        {
            id: 'zone',
            title: Translate({ term: 'zone.create_zone' }),
            content: _jsx(CreateZone, {}),
        },
        {
            id: 'license',
            title: Translate({ term: 'licenses.create_licences' }),
            content: _jsx(CreateLicenses, {}),
        },
        {
            id: 'search',
            title: Translate({ term: 'licenses.search_licences' }),
            content: _jsx(SearchLicenses, {}),
        },
    ];
    var index = tabs.findIndex(function (tab) { return tab.id === selectedTab; });
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: Translate({ term: 'licenses.control_licences' }) }), _jsxs("main", __assign({ className: "px-9 py-4" }, { children: [_jsx("div", __assign({ className: "mt-3 flex" }, { children: tabs.map(function (tab, index) { return (_jsx(TabButton, { id: tab.id, title: tab.title, selectedTab: selectedTab, setSelectedTab: function (tab) { return dispatch(setLicenseTab(tab)); } }, index)); }) })), tabs[index].content] }))] }));
};
export default Licenses;
