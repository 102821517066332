var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var TabButton = function (_a) {
    var id = _a.id, title = _a.title, selectedTab = _a.selectedTab, setSelectedTab = _a.setSelectedTab;
    return (_jsx("div", __assign({ className: id === selectedTab
            ? 'flex gap-2 px-4 py-3 justify-items-center items-center cursor-pointer bg-lightBlue border-b-2 border-blue'
            : 'flex gap-2 px-4 py-3 justify-items-center items-center cursor-pointer hover:bg-lightBlue hover:bg-opacity-50 duration-300', onClick: function () {
            setSelectedTab(id);
        } }, { children: _jsx("div", __assign({ className: id === selectedTab ? 'leading-tight text-blue' : 'leading-tight' }, { children: title })) })));
};
export default TabButton;
