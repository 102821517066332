var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { BrowserRouter } from "react-router-dom";
import React from 'react';
import { useSelector } from "react-redux";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TailwindConfig from '../tailwind.config';
import { defaultLanguange } from './config/languageSettings';
import { LanguageContext } from "./components/translation/Translate";
import "./aannekoulu.css";
// MainMenu,
import { CreateRoutes } from "./pages/PageRouter";
import store from "./store"; //, {persistor}
var Router = BrowserRouter;
var App = function () {
    var userLang = useSelector(function (state) { return state.settings.language; });
    var theme = createTheme({
        palette: {
            primary: {
                main: TailwindConfig.theme.colors.blue,
            },
            secondary: {
                main: TailwindConfig.theme.colors.lightBlue,
            }
        },
        typography: {
            fontFamily: [
                'Comfortaa',
                'Helvetica',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
    });
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(LanguageContext.Provider, __assign({ value: userLang ? userLang : defaultLanguange }, { children: _jsx(CreateRoutes, {}) })) })));
};
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(Router, { children: _jsx(App, {}) }) })) }), document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
