var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import useAppSelector from '../../../hooks/useAppSelector';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useTranslate } from '../../translation/Translate';
import ReactPlayer from 'react-player';
import Avatar from '../../common/Avatar';
var OwnTherapistInfo = function () {
    var _a, _b, _c;
    var t = useTranslate();
    var ownTherapist = useAppSelector(function (state) { return state.therapist.ownTherapist; });
    var therapistInfo = ownTherapist ? (_jsxs("div", { children: [_jsx("p", { children: ownTherapist.firstName }), _jsx("p", { children: ownTherapist.lastName }), ownTherapist.therapistInfo.introText && (_jsx("p", __assign({ className: "py-2", style: {
                    maxWidth: '100%',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    hyphens: 'auto',
                } }, { children: ownTherapist.therapistInfo.introText
                    .split('\n')
                    .map(function (paragraph, index) { return (_jsxs(React.Fragment, { children: [paragraph, ownTherapist.therapistInfo.introText &&
                            index !==
                                ownTherapist.therapistInfo.introText.split('\n').length -
                                    1 && _jsx("br", {})] }, index)); }) }))), _jsx(ReactPlayer, { url: ownTherapist.therapistInfo.introVideoUrl, width: "100%", height: "200px", controls: true })] })) : null;
    var containerStyles = {
        minHeight: '400px',
        minWidth: '300px',
        padding: '0.75rem',
    };
    return (_jsxs("div", __assign({ className: "flex flex-col items-start border border-cyan bg-white rounded-md shadow-lg w-full md:w-full max-w-lg", style: __assign({}, containerStyles) }, { children: [_jsx(Avatar, { image: (_a = ownTherapist === null || ownTherapist === void 0 ? void 0 : ownTherapist.profileImageUrl) !== null && _a !== void 0 ? _a : 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg', firstName: (_b = ownTherapist === null || ownTherapist === void 0 ? void 0 : ownTherapist.firstName) !== null && _b !== void 0 ? _b : '', lastName: (_c = ownTherapist === null || ownTherapist === void 0 ? void 0 : ownTherapist.lastName) !== null && _c !== void 0 ? _c : '', name: false, size: "xl" }), _jsxs("div", __assign({ className: "ml-3 sm:ml-0 sm:py-2 flex-grow" }, { children: [_jsxs("div", { children: [_jsx("h2", __assign({ className: "mb-1 font-semibold text-xl text-grey-600" }, { children: t("home.your_own_therapist") })), therapistInfo] }), ownTherapist && (_jsxs("div", __assign({ className: "mt-3" }, { children: [t("profile.open"), ' ', _jsx("a", __assign({ href: "https://aannekoulu.fi/puheterapeutit/", target: "_blank", rel: "noreferrer" }, { children: _jsx(ArrowCircleRightOutlinedIcon, { style: {
                                        backgroundColor: '#FFFFFF',
                                        fill: '#4B4B4B',
                                        cursor: 'pointer',
                                        marginBottom: '4px',
                                    } }) }))] })))] }))] })));
};
export default OwnTherapistInfo;
