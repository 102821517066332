var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from '../../translation/Translate';
import format from 'date-fns/format';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { setSearchParamsField, setSelectAllLicenses, setSelectedLicenses, setSelectedLicensesFormField, } from '../../../reducers/adminLicenseSlice';
import DateInput from '../../common/DateInput';
var ZoneLicensesTable = function () {
    var dispatch = useDispatch();
    var paginatedLicenses = useSelector(function (state) { return state.adminLicense.paginatedLicenses; });
    var selectedLicenses = useSelector(function (state) { return state.adminLicense.selectedLicenses; });
    var isUpdating = useSelector(function (state) { return state.adminLicense.isLoading; });
    var zoneLicenseState = useSelector(function (state) { return state.adminLicense.zoneLicenseState; });
    var searchParams = useSelector(function (state) { return state.adminLicense.searchParams; });
    var sort = searchParams.sort, order = searchParams.order;
    var setSortParam = function (sort) {
        dispatch(setSearchParamsField({
            sort: sort,
            order: order === 1 ? -1 : 1,
        }));
    };
    var onSelectAllLicenses = function (e) {
        var checked = e.target.checked;
        if (checked) {
            dispatch(setSelectAllLicenses(paginatedLicenses.docs
                .filter(function (lic) { return lic.state === 'free'; })
                .map(function (license) { return ({
                _id: license._id,
                key: license.key,
                licenseValidUntil: license.licenseValidUntil,
            }); })));
        }
        else {
            dispatch(setSelectAllLicenses([]));
        }
    };
    var onDateChange = function (license, licenseValidUntil) {
        var selectedLicense = selectedLicenses.find(function (item) { return item._id === license._id; });
        if (selectedLicense &&
            (licenseValidUntil !== undefined || licenseValidUntil !== null)) {
            dispatch(setSelectedLicensesFormField(__assign(__assign({}, selectedLicense), { licenseValidUntil: licenseValidUntil })));
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("table", __assign({ className: "table-responsive border-collapse rounded-md shadow-md w-full max-w-6xl mt-8" }, { children: [_jsx("caption", __assign({ className: "text-left text-2xl font-semibold mb-4" }, { children: Translate({ term: 'admin.license.zone_licenses' }) })), _jsx("thead", { children: _jsxs("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2", style: { height: '60px' } }, { children: [_jsx("th", { children: _jsx("input", { type: "checkbox", onChange: function (e) { return onSelectAllLicenses(e); } }) }), _jsx("th", { children: Translate({ term: 'therapy.module' }) }), _jsx("th", { children: Translate({ term: 'admin.license.license_code' }) }), _jsx("th", { children: Translate({ term: 'admin.license.zone_therapist' }) }), _jsxs("th", __assign({ className: "px-4 py-4 text-left border-b border-gray-400 cursor-pointer", onClick: function () { return setSortParam('state'); } }, { children: [sort === 'state' && order === 1 ? (_jsx(ArrowUpwardIcon, { className: 'text-blue ml-1 opacity-100', fontSize: "small" })) : (_jsx(ArrowDownwardIcon, { className: "text-blue ml-1 ".concat(sort === 'state' ? 'opacity-100' : 'opacity-25'), fontSize: "small" })), Translate({ term: 'admin.license.status' })] })), _jsxs("th", __assign({ className: "px-4 py-4 text-left border-b border-gray-400 cursor-pointer", onClick: function () { return setSortParam('licenseValidUntil'); } }, { children: [sort === 'licenseValidUntil' && order === 1 ? (_jsx(ArrowUpwardIcon, { className: 'text-blue ml-1 opacity-100', fontSize: "small" })) : (_jsx(ArrowDownwardIcon, { className: "text-blue ml-1 ".concat(sort === 'licenseValidUntil' ? 'opacity-100' : 'opacity-25'), fontSize: "small" })), Translate({ term: 'admin.license.license_valid_until' })] }))] })) }), _jsx("tbody", { children: paginatedLicenses.docs.length > 0 &&
                        paginatedLicenses.docs.map(function (license) {
                            var _a, _b, _c, _d;
                            return (_jsxs("tr", __assign({ className: "text-sm center hover:bg-gray-100" }, { children: [_jsx("td", __assign({ className: "px-4 py-4 text-left border-b border-gray-300 cursor-pointer", onClick: function () {
                                            if (license.state !== 'free' || isUpdating)
                                                return;
                                            dispatch(setSelectedLicenses({
                                                _id: license._id,
                                                key: license.key,
                                                licenseValidUntil: license.licenseValidUntil,
                                            }));
                                        } }, { children: _jsx("input", { type: "checkbox", checked: selectedLicenses.some(function (item) { return item._id === license._id; }), readOnly: true, disabled: license.state !== 'free' || isUpdating }) })), _jsx("td", __assign({ className: "px-4 py-4 text-left border-b border-gray-300" }, { children: license.modules &&
                                            license.modules.length > 0 &&
                                            license.modules
                                                .map(function (module) { return module.name.fi || module.name.se; })
                                                .join(', ') })), _jsx("td", __assign({ className: "px-4 py-4 text-left border-b border-gray-300" }, { children: license.key })), _jsx("td", __assign({ className: "px-4 py-4 text-left border-b border-gray-300" }, { children: license.zone
                                            ? license.zone.name
                                            : ((_a = license.therapist) === null || _a === void 0 ? void 0 : _a.firstName) +
                                                ' ' +
                                                ((_b = license.therapist) === null || _b === void 0 ? void 0 : _b.lastName) })), _jsx("td", __assign({ className: "px-4 py-4 text-left border-b border-gray-300" }, { children: (_c = license.state) !== null && _c !== void 0 ? _c : '' })), _jsxs("td", __assign({ className: "px-4 py-4 text-left border-b border-gray-300 justify-between" }, { children: [_jsx("p", { children: license.licenseValidUntil
                                                    ? format(new Date(license.licenseValidUntil), 'dd.MM.yyyy')
                                                    : '' }), zoneLicenseState === 'editing' &&
                                                selectedLicenses.some(function (item) { return item._id === license._id; }) && (_jsx(DateInput, { value: (_d = selectedLicenses.find(function (item) { return item._id === license._id; })) === null || _d === void 0 ? void 0 : _d.licenseValidUntil, onChange: function (licenseValidUntil) {
                                                    return onDateChange(license, licenseValidUntil);
                                                }, disabled: isUpdating }))] }))] }), license.key));
                        }) })] })) }));
};
export default ZoneLicensesTable;
