var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { toast, Zoom } from 'react-toastify';
import { getAdminTherapists, updateAdminPeriodsTherapist, } from '../../../actions/adminActions';
import BasicModal from '../../common/modals/BasicModal';
import { Select } from '../../therapist/utils/Select';
import { Translate } from '../../translation/Translate';
import { sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setPeriodState } from '../../../reducers/adminPeriodsSlice';
import { setApiError } from '../../../reducers/apiErrorSlice';
import useUser from '../../../hooks/useUser';
var SelectTherapistModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, searchAllPeriods = _a.searchAllPeriods;
    var dispatch = useDispatch();
    var user = useUser();
    var successToastMessage = Translate({
        term: 'common.save_success',
    });
    var failedToastMessage = Translate({
        term: 'common.save_failed',
    });
    var apiErrors = useSelector(function (state) { return state.apiError.errors; });
    var selectedPeriods = useSelector(function (state) { return state.adminPeriods.selectedPeriods; });
    var _b = __read(useState([]), 2), therapistOptions = _b[0], setTherapistOptions = _b[1];
    var _c = __read(useState(''), 2), selectedTherapist = _c[0], setSelectedTherapist = _c[1];
    var showSuccessToastMessage = function () {
        toast(successToastMessage, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 5000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
    };
    var showFailedToastMessage = function () {
        toast(failedToastMessage, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 5000,
            toastId: 'failedId',
            className: 'toast-message-warning',
        });
    };
    var onSubmit = function () {
        if (!user.session)
            return;
        if (selectedPeriods.length < 1 || selectedTherapist === '')
            return;
        updateAdminPeriodsTherapist({
            periodIds: selectedPeriods,
            newTherapist: selectedTherapist,
        }, user.session)
            .then(function (responseSuccess) {
            if (responseSuccess) {
                setSelectedTherapist('');
                showSuccessToastMessage();
                onClose();
                dispatch(setPeriodState('listing'));
                searchAllPeriods();
            }
        })
            .catch(function (err) {
            showFailedToastMessage();
            //console.error(err);
        });
    };
    var getTherapistOptions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var therapistsData, therapistsArray, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!user.session)
                        return [2 /*return*/];
                    return [4 /*yield*/, getAdminTherapists(user.session)];
                case 1:
                    therapistsData = _a.sent();
                    if (therapistsData) {
                        therapistsArray = therapistsData.map(function (user) {
                            return {
                                value: user.userId,
                                optionText: (user.firstName || '') + ' ' + (user.lastName || ''),
                            };
                        });
                        setTherapistOptions(sortBy(therapistsArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getTherapistOptions();
        // Reset possible api errors if page is reloaded. This is because store is persisted.
        dispatch(setApiError([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(BasicModal, __assign({ open: open, handleClose: onClose }, { children: _jsx("div", __assign({ className: "flex justify-center" }, { children: _jsxs("div", __assign({ className: "flex-none" }, { children: [_jsx("h3", __assign({ className: "mb-4" }, { children: Translate({ term: 'admin.periods.select_therapist' }) })), _jsx(Select, { selectProps: {
                            id: 'therapist-select',
                            labelId: 'therapist-select-label',
                            label: Translate({ term: 'admin.license.select_therapist' }),
                            value: selectedTherapist,
                            onChange: function (_a) {
                                var target = _a.target;
                                return setSelectedTherapist(target.value);
                            },
                        }, datas: therapistOptions !== null && therapistOptions !== void 0 ? therapistOptions : [] }), _jsxs("div", __assign({ className: "justify-items-center justify-center items-center whitespace-nowrap flex space-x-10 pt-4" }, { children: [_jsx("button", __assign({ type: "button", disabled: selectedTherapist === '', onClick: onSubmit, className: "bg-blue hover:bg-blue duration-300 hover:text-white text-white border-2 border-blue rounded-md" }, { children: Translate({ term: 'common.yes' }) })), _jsx("button", __assign({ type: "button", onClick: function () {
                                    onClose();
                                    setSelectedTherapist('');
                                }, className: "bg-white hover:bg-blue duration-300 hover:text-white text-black border-2 border-blue rounded-md" }, { children: Translate({ term: 'common.no' }) }))] })), apiErrors &&
                        apiErrors.length > 0 &&
                        apiErrors.map(function (errorMessage, i) { return (_jsx("div", __assign({ className: "text-warning-600 px-4 py-2 bg-white border border-warning-600 rounded-md mt-3 mb-3" }, { children: Translate({ term: errorMessage }) }), i)); })] })) })) })));
};
export default SelectTherapistModal;
