var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { createAdmin } from '../../../actions/userActions';
import { Translate } from '../../translation/Translate';
import '../periods/periodsStyle.css';
import useUser from '../../../hooks/useUser';
var Admin = function () {
    var user = useUser();
    var creatingFailedTranslate = Translate({ term: 'common.creating_failed' });
    var newAdminCreatedTranslate = Translate({
        term: 'common.new_admin_was_created',
    });
    var _a = __read(useState(''), 2), emailVerify = _a[0], setEmailVerify = _a[1];
    var _b = __read(useState(''), 2), infoText = _b[0], setInfoText = _b[1];
    var _c = __read(useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    }), 2), newAdmin = _c[0], setNewAdmin = _c[1];
    var firstnameMissingTranslate = Translate({
        term: 'common.firstname_missing',
    });
    var lastnameMissingTranslate = Translate({
        term: 'common.lastname_missing',
    });
    var emailMissingTranslate = Translate({
        term: 'common.email_missing',
    });
    var phoneMissingTranslate = Translate({
        term: 'common.phone_missing',
    });
    var emailsDontMatchTranslate = Translate({
        term: 'common.emails_dont_match',
    });
    var formIsValid = function () {
        var errorMessages = [];
        newAdmin.firstName === ''
            ? errorMessages.push(firstnameMissingTranslate)
            : null;
        newAdmin.lastName === ''
            ? errorMessages.push(lastnameMissingTranslate)
            : null;
        newAdmin.email === '' ? errorMessages.push(emailMissingTranslate) : null;
        newAdmin.phone === '' ? errorMessages.push(phoneMissingTranslate) : null;
        newAdmin.email !== emailVerify
            ? errorMessages.push(emailsDontMatchTranslate)
            : null;
        return errorMessages;
    };
    var createNewAdmin = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errorMessages;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    errorMessages = formIsValid();
                    if (!(errorMessages.length === 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, createAdmin(newAdmin, user.session)
                            .then(function (data) {
                            if (data) {
                                clearFields();
                                showToastMessage();
                            }
                            else {
                                setInfoText(creatingFailedTranslate);
                            }
                        })
                            .catch(function (err) {
                            //console.error(err);
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    setInfoText(errorMessages.join(', '));
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var showToastMessage = function () {
        toast(newAdminCreatedTranslate, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 2000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
    };
    var clearFields = function () {
        setNewAdmin({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
        });
        setEmailVerify('');
        setInfoText('');
    };
    return (_jsxs("div", __assign({ className: "mt-6", style: { width: '700px' } }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx("h2", __assign({ className: "text-2xl font-semibold" }, { children: Translate({ term: 'common.create_new_admin' }) })), _jsxs("p", __assign({ className: "flex justify-end text-gray-600 mt-4 -mb-2" }, { children: ["*", Translate({ term: 'common.required' })] })), _jsxs("div", __assign({ className: "flex justify-between mt-4" }, { children: [_jsxs("label", __assign({ className: "w-4/12 mt-2" }, { children: [Translate({ term: 'profile.user_names' }), "*"] })), _jsxs("div", __assign({ className: "flex justify-between w-8/12 gap-4" }, { children: [_jsx("input", { type: "text", onChange: function (e) {
                                    return setNewAdmin(__assign(__assign({}, newAdmin), { firstName: e.target.value }));
                                }, value: newAdmin.firstName, className: "h-10 w-4/12" }), _jsx("input", { type: "text", onChange: function (e) {
                                    return setNewAdmin(__assign(__assign({}, newAdmin), { lastName: e.target.value }));
                                }, value: newAdmin.lastName, className: "h-10 w-7/12" })] }))] })), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-4/12 mt-2" }, { children: [Translate({ term: 'profile.email_address' }), "*"] })), _jsx("input", { type: "text", onChange: function (e) { return setNewAdmin(__assign(__assign({}, newAdmin), { email: e.target.value })); }, value: newAdmin.email, className: "h-10 w-6/12" })] })), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-4/12 mt-2" }, { children: [Translate({ term: 'profile.email_again' }), "*"] })), _jsx("input", { type: "text", onChange: function (e) { return setEmailVerify(e.target.value); }, value: emailVerify, className: "h-10 w-6/12" })] })), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-4/12 mt-2" }, { children: [Translate({ term: 'profile.phone' }), "*"] })), _jsx("input", { type: "text", onChange: function (e) { return setNewAdmin(__assign(__assign({}, newAdmin), { phone: e.target.value })); }, value: newAdmin.phone, className: "h-10 w-4/12" })] })), _jsx("p", __assign({ style: { color: 'red' }, className: "text-right mt-4 text-red-600" }, { children: infoText })), _jsxs("div", __assign({ className: "flex justify-end gap-4 mt-6" }, { children: [_jsx("button", __assign({ onClick: createNewAdmin }, { children: Translate({ term: 'actions.create_user' }) })), _jsx("button", __assign({ onClick: clearFields, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: Translate({ term: 'actions.clear' }) }))] }))] })));
};
export default Admin;
