var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from "../../../../translation/Translate";
var PickyEater = function () {
    var title1 = Translate({ term: "therapy.modules.picky_eater.title1" });
    var title2 = Translate({ term: "therapy.modules.picky_eater.title2" });
    var title3 = Translate({ term: "therapy.modules.picky_eater.title2" });
    var title4 = Translate({ term: "therapy.modules.picky_eater.title2" });
    var text1 = Translate({ term: "therapy.modules.picky_eater.text1" });
    var text2 = Translate({ term: "therapy.modules.picky_eater.text2" });
    var text3 = Translate({ term: "therapy.modules.picky_eater.text3" });
    var text4 = Translate({ term: "therapy.modules.picky_eater.text4" });
    var text5 = Translate({ term: "therapy.modules.picky_eater.text5" });
    var text6 = Translate({ term: "therapy.modules.picky_eater.text6" });
    return (_jsxs("div", __assign({ className: "pt-4" }, { children: [_jsx("h3", __assign({ className: "pb-4" }, { children: title1 })), _jsx("p", { children: text1 }), _jsx("p", { children: text2 }), _jsx("h4", __assign({ className: "pt-4" }, { children: title2 })), _jsxs("div", __assign({ className: "pt-4" }, { children: [_jsx("video", __assign({ controls: true, width: "300", height: "150" }, { children: _jsx("source", { src: "https://player.vimeo.com/video/505565942", type: "video/mp4" }) })), _jsx("p", { children: "Marjo Valtonen" })] })), _jsxs("div", __assign({ className: "pt-4" }, { children: [_jsx("video", __assign({ controls: true, width: "300", height: "150" }, { children: _jsx("source", { src: "https://player.vimeo.com/video/516602902", type: "video/mp4" }) })), _jsx("p", { children: "Reetta Baldin" })] })), _jsx("h3", __assign({ className: "pt-4" }, { children: title3 })), _jsx("p", __assign({ className: "pt-4" }, { children: text3 })), _jsx("p", __assign({ className: "pt-4" }, { children: text4 })), _jsx("h4", __assign({ className: "pt-4" }, { children: title4 })), _jsx("p", __assign({ className: "pt-4" }, { children: text5 })), _jsx("p", __assign({ className: "pt-4" }, { children: text6 }))] })));
};
export default PickyEater;
