var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { fi, sv } from 'date-fns/locale';
var DateInput = function (_a) {
    var onChange = _a.onChange, value = _a.value, label = _a.label, disabled = _a.disabled;
    var userinfoLang = useSelector(function (state) { var _a; return (_a = state.settings) === null || _a === void 0 ? void 0 : _a.language; });
    var locale = userinfoLang === 'fi' ? fi : sv;
    return (_jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDateFns, adapterLocale: locale }, { children: _jsx(DatePicker, { label: label, value: value, onChange: function (date) { return date && onChange(date); }, renderInput: function (params) { return _jsx(TextField, __assign({ size: "small" }, params)); }, disabled: disabled }) })));
};
export default DateInput;
