var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import './periodStyle.css';
import { Select } from '../../components/therapist/utils/Select';
import { setPeriodCountByTherapyType, setShowEnded, setTherapyTypes, } from '../../reducers/periodSlice';
import { getTherapyTypes } from '../../actions/therapyTypeActions';
import Header from '../../components/therapist/Header';
import { LanguageContext, useTranslate, } from '../../components/translation/Translate';
import ButtonWithCount from '../../components/common/ButtonWithCount';
import { THERAPY_MODULE_TYPES, THERAPY_PERIODS_TABS, THERAPY_TYPES, } from '../../components/common/enums';
import { setModules } from '../../reducers/periodMaterialsSlice';
import { getModules } from '../../actions/moduleActions';
import useUser from '../../hooks/useUser';
import TherapistFilteredPatientPeriodsTable from '../../components/common/TherapistFilteredPatientPeriodsTable';
import { getFilteredTherapistsPatientPeriodsCount } from '../../actions/therapistActions';
import useAppDispatch from '../../hooks/useAppDispatch';
import { Button } from '@mui/material';
var Periods = function () {
    var _a;
    var t = useTranslate();
    var dispatch = useAppDispatch();
    var user = useUser();
    var language = useContext(LanguageContext);
    var modules = useSelector(function (state) { return state.materials.modules; });
    var periodCounts = useSelector(function (state) { return state.periods.periodCountByTherapyType; });
    var showEnded = useSelector(function (state) { return state.periods.showEnded; });
    var _b = __read(useState('null'), 2), selectedModule = _b[0], setSelectedModule = _b[1];
    var _c = __read(useState(THERAPY_PERIODS_TABS.ONLINE_THERAPY), 2), tab = _c[0], setTab = _c[1];
    function getCountFor(type) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, count;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, getFilteredTherapistsPatientPeriodsCount(user.session, {
                            filter: 'therapyTypePeriods',
                            therapyType: type,
                        })];
                    case 1:
                        response = _b.sent();
                        count = (_a = response === null || response === void 0 ? void 0 : response.periods) !== null && _a !== void 0 ? _a : 0;
                        dispatch(setPeriodCountByTherapyType({ type: type, count: count }));
                        return [2 /*return*/];
                }
            });
        });
    }
    function loadTherapyTypes() {
        return __awaiter(this, void 0, void 0, function () {
            var therapyTypesData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user.session) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, getTherapyTypes(user.session)];
                    case 1:
                        therapyTypesData = _a.sent();
                        if (therapyTypesData) {
                            return [2 /*return*/];
                        }
                        dispatch(setTherapyTypes(therapyTypesData));
                        return [2 /*return*/];
                }
            });
        });
    }
    function fetchModules() {
        return __awaiter(this, void 0, void 0, function () {
            var modulesData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user.session) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, getModules(user.session)];
                    case 1:
                        modulesData = _a.sent();
                        if (!modulesData) {
                            return [2 /*return*/];
                        }
                        dispatch(setModules(modulesData));
                        return [2 /*return*/];
                }
            });
        });
    }
    // On init
    useEffect(function () {
        getCountFor(THERAPY_TYPES.ALL);
        getCountFor(THERAPY_TYPES.ONLINE_THERAPY);
        getCountFor(THERAPY_TYPES.THERAPISTS_OWN_PATIENTS);
        loadTherapyTypes();
        fetchModules();
    }, []);
    // Don't show Therapists own patients in the module options
    var moduleOptions = ((_a = modules === null || modules === void 0 ? void 0 : modules.map(function (module) { return ({
        value: module._id,
        optionText: module.name[language],
    }); })) !== null && _a !== void 0 ? _a : []).filter(function (mod) { return mod.value !== THERAPY_MODULE_TYPES.TERAPEUTIN_OMAT_ASIAKKAAT; });
    moduleOptions.push({
        value: 'null',
        optionText: t('therapy.types.all_customers'),
    });
    var tabs = [
        {
            id: THERAPY_PERIODS_TABS.ONLINE_THERAPY,
            title: t('therapy.types.online'),
            buttonWithCount: (_jsx(ButtonWithCount, { id: THERAPY_PERIODS_TABS.ONLINE_THERAPY.toString(), amount: periodCounts[THERAPY_TYPES.ONLINE_THERAPY], title: t('therapy.types.online'), active: THERAPY_PERIODS_TABS.ONLINE_THERAPY === tab, action: function () {
                    setTab(THERAPY_PERIODS_TABS.ONLINE_THERAPY);
                } })),
            content: (_jsx(TherapistFilteredPatientPeriodsTable, { showEndedPeriods: showEnded, filter: 'therapyTypePeriods', columns: ['name', 'content', 'phase', 'last_message', 'period_count'], phase: selectedModule, therapyType: THERAPY_TYPES.ONLINE_THERAPY, docsPerPage: 10 })),
        },
        {
            id: THERAPY_PERIODS_TABS.THERAPISTS_OWN_PATIENTS,
            title: t('therapy.types.remote'),
            buttonWithCount: (_jsx(ButtonWithCount, { id: THERAPY_PERIODS_TABS.THERAPISTS_OWN_PATIENTS.toString(), amount: periodCounts[THERAPY_TYPES.THERAPISTS_OWN_PATIENTS], title: t('therapy.types.remote'), active: THERAPY_PERIODS_TABS.THERAPISTS_OWN_PATIENTS === tab, action: function () {
                    setTab(THERAPY_PERIODS_TABS.THERAPISTS_OWN_PATIENTS);
                } })),
            content: (_jsx(TherapistFilteredPatientPeriodsTable, { showEndedPeriods: showEnded, filter: 'therapyTypePeriods', columns: ['name', 'content', 'phase', 'last_message', 'period_count'], phase: selectedModule, therapyType: THERAPY_TYPES.THERAPISTS_OWN_PATIENTS, docsPerPage: 10 })),
        },
        {
            id: THERAPY_PERIODS_TABS.ALL_THERAPIES,
            title: t('material_library.all'),
            buttonWithCount: (_jsx(ButtonWithCount, { id: THERAPY_PERIODS_TABS.ALL_THERAPIES.toString(), amount: periodCounts[THERAPY_TYPES.ALL], title: t('therapy.types.all'), active: THERAPY_PERIODS_TABS.ALL_THERAPIES === tab, action: function () {
                    setTab(THERAPY_PERIODS_TABS.ALL_THERAPIES);
                } })),
            content: (_jsx(TherapistFilteredPatientPeriodsTable, { showEndedPeriods: showEnded, docsPerPage: 10, filter: 'therapistPeriods', phase: selectedModule, columns: ['name', 'content', 'phase', 'last_message', 'period_count'] })),
        },
    ];
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: t('nav.periods') }), _jsxs("main", __assign({ className: "px-8 py-4" }, { children: [_jsx("div", __assign({ className: "my-4 flex" }, { children: tabs.map(function (t) { return (_jsx("div", { children: t.buttonWithCount }, t.id)); }) })), _jsxs("div", __assign({ className: "my-4 flex gap-5" }, { children: [tab === THERAPY_PERIODS_TABS.ONLINE_THERAPY && (_jsx(Select, { selectProps: {
                                    id: 'phase-select',
                                    labelId: 'phase-select-label',
                                    value: selectedModule,
                                    onChange: function (_a) {
                                        var target = _a.target;
                                        setSelectedModule(target.value);
                                    },
                                }, datas: moduleOptions })), _jsx("div", __assign({ className: "flex gap-1 items-center" }, { children: _jsxs(Button, __assign({ variant: "outlined", onClick: function () {
                                        dispatch(setShowEnded(!showEnded));
                                    } }, { children: ["N\u00E4ytet\u00E4\u00E4n: ", showEnded ? 'Loppuneet' : 'Voimassaolevat'] })) }))] })), tabs[tab].content] }))] }));
};
export default Periods;
