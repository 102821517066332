var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import DescriptionIcon from '@mui/icons-material/Description';
import SportsEsportsSharpIcon from '@mui/icons-material/SportsEsportsSharp';
var PeriodMaterialIcon = function (_a) {
    var type = _a.type;
    var materialIcon = '';
    if (type && typeof type === 'string') {
        switch (type) {
            case 'video': {
                materialIcon = _jsx(OndemandVideoIcon, {});
                break;
            }
            case 'text': {
                materialIcon = _jsx(DescriptionIcon, {});
                break;
            }
            case 'game': {
                materialIcon = _jsx(SportsEsportsSharpIcon, {});
                break;
            }
            default: {
                materialIcon = type + ' - ';
                break;
            }
        }
    }
    return _jsx("span", __assign({ className: "period-material-icon" }, { children: materialIcon }));
};
export default PeriodMaterialIcon;
