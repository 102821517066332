var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate, useTranslate } from '../../translation/Translate';
import { useSelector, useDispatch } from 'react-redux';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Avatar from '../../common/Avatar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { setSelectAllPeriods, setSelectedPeriods, } from '../../../reducers/adminPeriodsSlice';
import format from 'date-fns/format';
import clsx from 'clsx';
import Moment from 'react-moment';
var TablePeriods = function (_a) {
    var sort = _a.sort, reverse = _a.reverse, handleSetSort = _a.handleSetSort, periods = _a.periods, getSelectedPeriodFunction = _a.getSelectedPeriodFunction, isLoading = _a.isLoading;
    var dispatch = useDispatch();
    var t = useTranslate();
    var selectedPeriods = useSelector(function (state) { return state.adminPeriods.selectedPeriods; });
    var periodState = useSelector(function (state) { return state.adminPeriods.periodState; });
    var clientTranslate = Translate({ term: 'home.client' });
    var moduleTranslate = Translate({ term: 'therapy.module' });
    var birthyearTranslate = Translate({ term: 'profile.birth_year' });
    var startedTranslate = Translate({ term: 'period.started' });
    var endedTranslate = Translate({ term: 'period.ended' });
    var licenseKeyTranslate = Translate({ term: 'licenses.license_key' });
    var headCells = [
        {
            id: 'checkbox',
            label: '',
            content: function (period) {
                return (_jsx("input", { type: "checkbox", checked: selectedPeriods.some(function (periodId) { return periodId === period._id; }), readOnly: true, disabled: false }));
            },
        },
        {
            id: 'name',
            label: clientTranslate,
            content: function (period) {
                var _a, _b, _c, _d;
                return (_jsx(Avatar, { firstName: (_b = (_a = period.patient) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : '', lastName: (_d = (_c = period.patient) === null || _c === void 0 ? void 0 : _c.lastName) !== null && _d !== void 0 ? _d : '' }));
            },
        },
        {
            id: 'module',
            label: moduleTranslate,
            content: function (period) {
                return (_jsx("div", __assign({ className: "truncate .." }, { children: period.module && period.module.name
                        ? period.module.name.fi || period.module.name.se
                        : '' })));
            },
        },
        {
            id: 'therapist',
            label: 'Terapeutti',
            content: function (period) {
                return (_jsx("div", __assign({ className: "md:truncate .." }, { children: period.therapist &&
                        period.therapist.firstName +
                            ' ' +
                            period.therapist.lastName })));
            },
        },
        {
            id: 'started',
            label: startedTranslate,
            content: function (period) {
                return (_jsx("div", { children: period.started
                        ? format(new Date(period.started), 'dd.MM.yyyy')
                        : '' }));
            },
        },
        {
            id: 'validUntil',
            label: endedTranslate,
            content: function (period) {
                return (_jsx("div", { children: period.validUntil
                        ? format(new Date(period.validUntil), 'dd.MM.yyyy')
                        : '' }));
            },
        },
        {
            id: 'license_key',
            label: licenseKeyTranslate,
            content: function (period) {
                var _a, _b;
                return _jsx("div", { children: (_b = (_a = period.license) === null || _a === void 0 ? void 0 : _a.key) !== null && _b !== void 0 ? _b : '' });
            },
        },
        {
            id: 'latestMessageDate',
            label: t('period.latest_sent_message'),
            content: function (period) {
                return period.latestMessageDate ? (_jsx(Moment, __assign({ format: t('period.message_date_format') }, { children: period.latestMessageDate }))) : (_jsx("span", { children: "Ei viestej\u00E4" }));
            },
        },
    ];
    return (_jsx("div", __assign({ className: "overflow-x-scroll rounded-md shadow-md" }, { children: isLoading ? (_jsx(Box, __assign({ sx: { width: '100%', maxWidth: '72rem' } }, { children: _jsx(LinearProgress, {}) }))) : (_jsxs("table", __assign({ className: "border-collapse w-full max-w-6xl" }, { children: [_jsx("thead", { children: _jsx("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2", style: { height: '60px' } }, { children: headCells.map(function (headCell, key) {
                            if (headCell.id === 'started' ||
                                headCell.id === 'validUntil' ||
                                headCell.id === 'name') {
                                var sortIcon = sort === headCell.id && reverse ? (_jsx(ArrowUpwardIcon, { className: sort === headCell.id
                                        ? 'text-blue ml-1 opacity-100'
                                        : 'text-blue ml-1 opacity-25', fontSize: "small" })) : (_jsx(ArrowDownwardIcon, { className: sort === headCell.id
                                        ? 'text-blue ml-1 opacity-100'
                                        : 'text-blue ml-1 opacity-25', fontSize: "small" }));
                                return (_jsxs("th", __assign({ onClick: function () {
                                        return handleSetSort(headCell.id === 'name'
                                            ? 'patient.lastName'
                                            : headCell.id);
                                    }, className: clsx('w-36 border-b px-4 py-3 text-left border-gray-400 cursor-pointer', headCell.id === 'name' ? 'w-12' : 'w-1/6') }, { children: [headCell.label, " ", sortIcon] }), key));
                            }
                            if (headCell.id === 'checkbox' && periodState === 'listing')
                                return null;
                            if (headCell.id === 'checkbox' && periodState === 'editing') {
                                return (_jsx("th", __assign({ className: "w-20" }, { children: _jsx("input", { type: "checkbox", onChange: function (e) {
                                            if (e.target.checked) {
                                                dispatch(setSelectAllPeriods(periods.map(function (period) { return period._id; })));
                                            }
                                            else {
                                                dispatch(setSelectAllPeriods([]));
                                            }
                                        } }) }), key));
                            }
                            if (headCell.id === 'birthyear') {
                                return (_jsx("th", __assign({ className: "md:w-22 lg:w-32 border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: headCell.label }), key));
                            }
                            return (_jsx("th", __assign({ className: "border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: headCell.label }), key));
                        }) })) }), _jsx("tbody", { children: periods.map(function (period, index) {
                        return (_jsx("tr", __assign({ style: { height: '60px' }, className: "text-sm relative hover:bg-gray-100 cursor-pointer" }, { children: headCells.map(function (headCell, key) {
                                if (headCell.id === 'checkbox' && periodState === 'listing')
                                    return null;
                                var content = headCell.content
                                    ? headCell.content(period)
                                    : '';
                                return (_jsx("td", __assign({ className: "px-4 py-4 ".concat(headCell.id !== 'checkbox'
                                        ? 'text-left'
                                        : 'text-center', " border-b border-gray-300 cursor-pointer"), onClick: function () {
                                        headCell.id !== 'checkbox'
                                            ? getSelectedPeriodFunction(period._id)
                                            : dispatch(setSelectedPeriods(period._id));
                                    } }, { children: content }), key));
                            }) }), index));
                    }) })] }))) })));
};
export default TablePeriods;
