var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Translate } from '../../translation/Translate';
import { updatePassword } from '../../../actions/userActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useUser from '../../../hooks/useUser';
var ChangePassword = function () {
    var user = useUser();
    var _a = __read(useState(false), 2), content = _a[0], setContent = _a[1];
    var _b = __read(useState(''), 2), oldPassword = _b[0], setOldPassword = _b[1];
    var _c = __read(useState(''), 2), newPassword = _c[0], setNewPassword = _c[1];
    var _d = __read(useState(''), 2), newPasswordAgain = _d[0], setNewPasswordAgain = _d[1];
    var _e = __read(useState('password'), 2), inputType = _e[0], setInputType = _e[1];
    var _f = __read(useState(null), 2), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = __read(useState(false), 2), newPasswordValid = _g[0], setNewPasswordValid = _g[1];
    var _h = __read(useState(false), 2), newPasswordAgainValid = _h[0], setNewPasswordAgainValid = _h[1];
    var _j = __read(useState(false), 2), info = _j[0], setInfo = _j[1];
    var passwordChangeFailedText = Translate({
        term: 'profile.passw_change_failed',
    });
    var passwordDontMatchText = Translate({
        term: 'profile.different_passwords',
    });
    var passwordChangeInfoText = Translate({
        term: 'profile.password_change_info',
    });
    var passwordChangeErrorText = Translate({
        term: 'profile.password_change_error',
    });
    var showHideContent = function () {
        setContent(!content);
        setErrorMessage(null);
    };
    var showHideInfo = function () {
        setInfo(!info);
        setErrorMessage(null);
    };
    var savePassword = function () { return __awaiter(void 0, void 0, void 0, function () {
        var passwordData, updatePasswordData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(newPassword === newPasswordAgain)) return [3 /*break*/, 2];
                    passwordData = {
                        oldPassword: oldPassword,
                        newPassword: newPassword,
                        newPasswordAgain: newPasswordAgain,
                    };
                    return [4 /*yield*/, updatePassword(passwordData, user.session)];
                case 1:
                    updatePasswordData = _a.sent();
                    if (updatePasswordData) {
                        setContent(false);
                        setErrorMessage(null);
                    }
                    else {
                        setErrorMessage(passwordChangeFailedText);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    setErrorMessage(passwordDontMatchText);
                    _a.label = 3;
                case 3:
                    if (info)
                        setInfo(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var ft_NewPassword = function (nP) {
        setNewPassword(nP);
        if (nP.length < 14 ||
            !nP.match(/[a-z]/g) ||
            !nP.match(/[A-Z]/g) ||
            !nP.match(/[0-9]/g) ||
            !nP.match(/[^a-zA-Z\d]/g)) {
            setNewPasswordValid(false);
        }
        else {
            setNewPasswordValid(true);
        }
    };
    var ft_NewPasswordAgain = function (nPa) {
        setNewPasswordAgain(nPa);
        if (nPa.length < 14 ||
            !nPa.match(/[a-z]/g) ||
            !nPa.match(/[A-Z]/g) ||
            !nPa.match(/[0-9]/g) ||
            !nPa.match(/[^a-zA-Z\d]/g)) {
            setNewPasswordAgainValid(false);
        }
        else {
            setNewPasswordAgainValid(true);
        }
    };
    var dataContent = (_jsxs("div", __assign({ className: "space-y-2 mt-4" }, { children: [_jsxs("div", __assign({ className: "sm:flex sm:flex-row sm:justify-between" }, { children: [_jsxs("p", { children: [Translate({ term: 'profile.old_password' }), "*"] }), _jsx("input", { type: inputType, id: "oldPassword", autoComplete: "off", style: { paddingRight: '30px' }, required: true, onChange: function (e) { return setOldPassword(e.target.value); } })] })), _jsxs("div", __assign({ className: "sm:flex sm:flex-row sm:justify-between" }, { children: [_jsxs("p", { children: [Translate({ term: 'profile.new_password' }), "*"] }), _jsxs("div", __assign({ className: "relative" }, { children: [newPasswordValid === true && (_jsx(CheckIcon, { style: { position: 'absolute', right: '4px', color: 'green' } })), _jsx("input", { type: inputType, id: "newPassword", autoComplete: "off", style: { paddingRight: '30px' }, required: true, onChange: function (e) { return ft_NewPassword(e.target.value); } })] }))] })), _jsxs("div", __assign({ className: "sm:flex sm:flex-row sm:justify-between" }, { children: [_jsxs("p", { children: [Translate({ term: 'profile.new_password_again' }), "*"] }), _jsxs("div", __assign({ className: "relative" }, { children: [newPasswordAgainValid === true && (_jsx(CheckIcon, { style: { position: 'absolute', right: '4px', color: 'green' } })), _jsx("input", { type: inputType, id: "newPasswordAgain", autoComplete: "off", style: { paddingRight: '30px' }, required: true, onChange: function (e) { return ft_NewPasswordAgain(e.target.value); } })] }))] })), _jsxs("div", __assign({ className: "flex flex-row sm:justify-end space-x-2" }, { children: [_jsx("div", __assign({ onClick: function () {
                            inputType === 'password'
                                ? setInputType('text')
                                : setInputType('password');
                        }, className: "cursor-pointer flex justify-start sm:justify-end space-x-2" }, { children: inputType === 'password' ? (_jsx(VisibilityOffIcon, {})) : (_jsx(VisibilityIcon, {})) })), _jsx("div", __assign({ onClick: showHideInfo, className: "cursor-pointer" }, { children: _jsx(InfoOutlinedIcon, {}) }))] })), errorMessage && (_jsx("div", __assign({ className: "flex justify-start sm:justify-end" }, { children: _jsx("p", __assign({ style: { color: 'rgb(185 28 28)' } }, { children: passwordChangeErrorText })) }))), info && (_jsx("div", __assign({ className: "flex justify-start sm:justify-end" }, { children: _jsx("p", __assign({ style: { color: 'rgb(34 197 94)' } }, { children: passwordChangeInfoText })) }))), _jsx("div", __assign({ className: "flex justify-end" }, { children: newPasswordValid === true && newPasswordAgainValid === true ? (_jsx("button", __assign({ onClick: savePassword }, { children: Translate({ term: 'profile.save_password' }) }))) : (_jsx("button", __assign({ className: "cursor-default bg-opacity-50" }, { children: Translate({ term: 'profile.save_password' }) }))) }))] })));
    return (_jsxs("div", __assign({ className: "bg-white border-2 border-cyan p-4 md:p-6 rounded-lg shadow-lg" }, { children: [_jsx("div", __assign({ className: "flex flex-col" }, { children: _jsxs("button", __assign({ onClick: showHideContent, className: "width-auto flex justify-between p-0", style: { backgroundColor: 'white' } }, { children: [_jsx("p", __assign({ className: "font-semibold text-lg", style: { color: '#4B4B4B' } }, { children: Translate({ term: 'profile.edit_password' }) })), content === false ? (_jsx(KeyboardArrowDownIcon, { style: { fill: '#4B4B4B', marginTop: '-3px' } })) : (_jsx(KeyboardArrowUpIcon, { style: { fill: '#475467', marginTop: '-3px' } }))] })) })), content && _jsx(_Fragment, { children: dataContent })] })));
};
export default ChangePassword;
