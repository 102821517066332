var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import MainMenu from '../therapist/mainMenu/MainMenu';
import PeriodsContext from '../common/period/PeriodsContext';
var TherapistLayout = function () {
    var periods = useSelector(function (state) { return state.periods.periods; });
    var therapyTypes = useSelector(function (state) { return state.periods.therapyTypes; });
    return (_jsx(PeriodsContext.Provider, __assign({ value: {
            periods: periods,
            therapyTypes: therapyTypes,
        } }, { children: _jsxs("div", __assign({ className: "layout therapist-layout flex bg-cyan" }, { children: [_jsx(MainMenu, {}), _jsx("div", __assign({ className: "page overflow-auto flex-auto mt-2 pt-2 bg-gray-50 rounded-tl-3xl" }, { children: _jsx(Outlet, {}) }))] })) })));
};
export default TherapistLayout;
