var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useUser from '../../hooks/useUser';
import AssignmentModal from '../../components/therapist/period/AssignmentModal';
import PatientPeriodContent from '../../components/therapist/period/PatientPeriodContent';
import PeriodContext from '../../components/common/period/PeriodContext';
import useAppSelector from '../../hooks/useAppSelector';
import { findIndexFromArray } from '../../functions/finders';
export default function PatientPeriods() {
    var user = useUser();
    var period = useAppSelector(function (state) { return state.periods.active; });
    var drafts = useAppSelector(function (state) { return state.periods.drafts; });
    function getPeriodDraft() {
        if (!(drafts && period)) {
            return undefined;
        }
        var draftIndex = findIndexFromArray(drafts, 'periodId', period._id);
        if (isNaN(draftIndex)) {
            return undefined;
        }
        return drafts[draftIndex];
    }
    function getActiveAssignment() {
        if (!period) {
            return undefined;
        }
        if (!(period.assignments && period.assignments.length > 0 &&
            period.openAssignment)) {
            return undefined;
        }
        var assignmentIndex = findIndexFromArray(period.assignments, "id", period.openAssignment);
        if (!assignmentIndex) {
            return undefined;
        }
        return period.assignments[assignmentIndex];
    }
    return (_jsxs(PeriodContext.Provider, __assign({ value: { draft: getPeriodDraft(), period: period } }, { children: [_jsx(PatientPeriodContent, { userInfo: user.userinfo }), _jsx(AssignmentModal, { modalOpen: (period === null || period === void 0 ? void 0 : period.openAssignment) ? true : false, assignment: getActiveAssignment() })] })));
}
