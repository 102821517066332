var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactPlayer from 'react-player';
var ChatMessageAttachment = function (_a) {
    var attachment = _a.attachment;
    function zoomImage() {
        var img = document.getElementById(attachment.name);
        if (img) {
            if (img.style.transform !== 'scale(1.8)') {
                img.style.transform = 'scale(1.8)';
                img.style.marginRight = '54%';
                img.style.cursor = 'zoom-out';
                img.style.transition = 'transform 0.25s ease';
            }
            else {
                img.style.transform = 'scale(1)';
                img.style.cursor = 'zoom-in';
                img.style.marginRight = '0%';
                img.style.transition = 'transform 0.25s ease';
            }
        }
    }
    return (_jsxs("div", { children: [attachment.mimetype.includes('video') && (_jsx(ReactPlayer, { url: attachment.filePath, width: "100%", height: "240px", controls: true, poster: attachment.filePath })), attachment.mimetype.includes('application') && (_jsx("div", { children: _jsx("a", __assign({ className: "underline", href: attachment.filePath, target: "_blank", rel: "noreferrer" }, { children: attachment.name })) })), attachment.mimetype.includes('image') && (_jsx("div", { children: _jsx("img", { style: { cursor: 'zoom-in' }, id: attachment.name, onClick: zoomImage, src: attachment.filePath }) }))] }));
};
export default ChatMessageAttachment;
