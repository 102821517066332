var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import QuestionnaireModal from './QuestionnaireModal';
import { Translate } from '../../translation/Translate';
var Questionnaires = function (_a) {
    var questionnaires = _a.questionnaires, periodId = _a.periodId, showToastMessage = _a.showToastMessage;
    var _b = __read(useState(false), 2), openModal = _b[0], setOpenModal = _b[1];
    var _c = __read(useState(null), 2), selectedQuestionnaire = _c[0], setSelectedQuestionnaire = _c[1];
    var answerTranslate = Translate({
        term: 'actions.answer',
    });
    var unansweredQuestionsTranslate = Translate({
        term: 'questionnaire.unanswered_questions',
    });
    if (questionnaires.length > 0) {
        return (_jsxs("div", __assign({ className: "flex flex-col my-2" }, { children: [_jsxs("p", { children: [unansweredQuestionsTranslate, ":"] }), questionnaires.map(function (questionnaire, key) { return (_jsxs("div", __assign({ className: "my-0.5 md:my-1.5 border bg-white rounded-md p-4 shadow-md mb-4" }, { children: [_jsx("div", __assign({ className: "mb-4" }, { children: questionnaire.name })), _jsx("div", __assign({ className: "flex justify-end content-end" }, { children: _jsx("button", __assign({ onClick: function () {
                                    setOpenModal(true), setSelectedQuestionnaire(questionnaire);
                                } }, { children: answerTranslate })) }))] }), key)); }), _jsx(QuestionnaireModal, { openModal: openModal, setOpenModal: setOpenModal, questionnaire: selectedQuestionnaire, periodId: periodId, showToastMessage: showToastMessage })] })));
    }
    else
        return _jsx("div", {});
};
export default Questionnaires;
