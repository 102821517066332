var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Translate } from '../../translation/Translate';
import { setValidationErrors, setZoneFormDataField, } from '../../../reducers/adminLicenseSlice';
import { setApiError } from '../../../reducers/apiErrorSlice';
var ZoneForm = function (_a) {
    var children = _a.children;
    var dispatch = useDispatch();
    var id = useParams().id;
    var validationErrors = useSelector(function (state) { return state.adminLicense.validationErrors; });
    var apiErrors = useSelector(function (state) { return state.apiError.errors; });
    var isLoading = useSelector(function (state) { return state.adminLicense.isLoading; });
    var zoneFormData = useSelector(function (state) { return state.adminLicense.zoneFormData; });
    var firstName = zoneFormData.firstName, lastName = zoneFormData.lastName, email = zoneFormData.email, emailVerify = zoneFormData.emailVerify, phone = zoneFormData.phone, municipality = zoneFormData.municipality, notes = zoneFormData.notes, therapist = zoneFormData.therapist, name = zoneFormData.name;
    useEffect(function () {
        // Reset possible api errors if page is reloaded. This is because store is persisted.
        dispatch(setApiError([]));
        dispatch(setValidationErrors([]));
    }, []);
    return (_jsxs("div", __assign({ className: "mt-6", style: { width: '650px' } }, { children: [_jsx("h2", __assign({ className: "text-2xl font-semibold" }, { children: id === undefined
                    ? Translate({ term: 'licenses.create_new_licensezone' })
                    : Translate({ term: 'licenses.zone_details' }) })), id === undefined && (_jsx("p", __assign({ className: "ml-4 mt-4" }, { children: Translate({ term: 'admin.license.zone_info' }) }))), _jsxs("p", __assign({ className: "flex justify-end text-gray-600 mt-4 -mb-2" }, { children: ["*", Translate({ term: 'common.required' })] })), id !== undefined && (_jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-5/12 mt-2" }, { children: [Translate({ term: 'admin.license.zone_therapist' }), "*"] })), _jsx("input", { type: "text", onChange: function (e) {
                            return dispatch(setZoneFormDataField({ name: e.target.value }));
                        }, value: name, className: "h-10 w-7/12", disabled: therapist || isLoading })] }))), _jsxs("div", __assign({ className: "flex justify-between mt-4" }, { children: [_jsxs("label", __assign({ className: "w-5/12 mt-2" }, { children: [Translate({ term: 'common.contact_person_name' }), "*"] })), _jsxs("div", __assign({ className: "flex justify-between w-7/12 gap-4" }, { children: [_jsx("input", { type: "text", onChange: function (e) {
                                    return dispatch(setZoneFormDataField({ firstName: e.target.value }));
                                }, value: firstName, className: "h-10 w-4/12", disabled: therapist || isLoading }), _jsx("input", { type: "text", onChange: function (e) {
                                    return dispatch(setZoneFormDataField({ lastName: e.target.value }));
                                }, value: lastName, className: "h-10 w-7/12", disabled: therapist || isLoading })] }))] })), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-5/12 mt-2" }, { children: [Translate({ term: 'common.contact_person_email' }), "*"] })), _jsx("input", { type: "text", onChange: function (e) {
                            return dispatch(setZoneFormDataField({ email: e.target.value }));
                        }, value: email, className: "h-10 w-7/12", disabled: therapist || isLoading })] })), id === undefined && (_jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-5/12 mt-2" }, { children: [Translate({ term: 'profile.email_again' }), "*"] })), _jsx("input", { type: "text", onChange: function (e) {
                            return dispatch(setZoneFormDataField({ emailVerify: e.target.value }));
                        }, value: emailVerify, className: "h-10 w-7/12", disabled: therapist || isLoading })] }))), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsx("label", __assign({ className: "w-5/12 mt-2" }, { children: Translate({ term: 'common.contact_person_phone' }) })), _jsx("input", { type: "text", onChange: function (e) {
                            return dispatch(setZoneFormDataField({ phone: e.target.value }));
                        }, value: phone, className: "h-10 w-5/12", disabled: therapist || isLoading })] })), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-5/12 mt-2" }, { children: [Translate({ term: 'zone.zone_name' }), "*"] })), _jsx("input", { type: "text", onChange: function (e) {
                            return dispatch(setZoneFormDataField({ municipality: e.target.value }));
                        }, value: municipality, className: "h-10 w-6/12", disabled: therapist || isLoading })] })), id === undefined && (_jsxs("div", __assign({ className: "flex flex-row mt-1 -mb-2" }, { children: [_jsx("label", { className: "w-5/12" }), _jsx("p", __assign({ className: "w-6/12  text-gray-600" }, { children: Translate({ term: 'admin.license.zone_name_generated' }) }))] }))), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsx("label", __assign({ className: "w-5/12 mt-2" }, { children: Translate({ term: 'zone.notes' }) })), _jsx("textarea", { onChange: function (e) {
                            return dispatch(setZoneFormDataField({ notes: e.target.value }));
                        }, value: notes, className: "flex h-32 w-7/12 border border-gray-300 rounded-md p-2 text-base", rows: 4, disabled: therapist || isLoading })] })), validationErrors &&
                validationErrors.length > 0 &&
                validationErrors.map(function (errorMessage) { return (_jsx("div", __assign({ className: "text-warning-600 px-4 py-2 bg-white border border-warning-600 rounded-md mt-3 mb-3" }, { children: errorMessage }), errorMessage)); }), apiErrors &&
                apiErrors.length > 0 &&
                apiErrors.map(function (errorMessage, i) { return (_jsx("div", __assign({ className: "text-warning-600 px-4 py-2 bg-white border border-warning-600 rounded-md mt-3 mb-3" }, { children: Translate({ term: errorMessage }) }), i)); }), children] })));
};
export default ZoneForm;
