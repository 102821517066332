var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAdminQuestionnaire, updateAdminQuestionnaire, } from '../../../actions/adminActions';
import { getModulesAdmin } from '../../../actions/moduleActions';
import { setFormField, clearFormState, setModalState, } from '../../../reducers/adminQuerySlice';
import { QUESTIONNAIRE_LIST } from '../../common/enums';
import TextInput from '../../common/TextInput';
import { Select } from '../../therapist/utils/Select';
import { Translate } from '../../translation/Translate';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../common/toastStyle.css';
import { setApiError } from '../../../reducers/apiErrorSlice';
import { SurveyCreatorWidget } from './SurveyCreator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
var UploadQuery = function () {
    var dispatch = useDispatch();
    var user = useUser();
    var apiErrors = useSelector(function (state) { return state.apiError.errors; });
    var modalState = useSelector(function (state) { return state.adminQuery.modalState; });
    var formData = useSelector(function (state) { return state.adminQuery.formData; });
    var name = formData.name, type = formData.type, moduleId = formData.moduleId, queryURL = formData.queryURL, answerTheSurveyMoreThanOnce = formData.answerTheSurveyMoreThanOnce;
    var _a = __read(useState([]), 2), moduleOptions = _a[0], setModuleOptions = _a[1];
    var _b = __read(useState(null), 2), anchorEl = _b[0], setAnchorEl = _b[1];
    var _c = __read(useState([]), 2), inputErrors = _c[0], setInputErrors = _c[1];
    var _d = __read(useState(false), 2), expanded = _d[0], setExpanded = _d[1];
    var navigate = useNavigate();
    var handlePopoverOpen = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handlePopoverClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    var requiredTranslate = Translate({ term: 'common.required' });
    var nameTranslate = Translate({ term: 'profile.name' });
    var typeTranslate = Translate({ term: 'messages.type' });
    var moduleTranslate = Translate({ term: 'therapy.module' });
    var selectModuleTranslate = Translate({ term: 'therapy.select_module' });
    var uploadNewInquiry = Translate({
        term: 'admin.inquiry.upload_new_inquiry_to_server',
    });
    var updateInquiry = Translate({
        term: 'admin.inquiry.update_inquiry_to_server',
    });
    var selectInquiryType = Translate({
        term: 'admin.inquiry.select_inquiry_type',
    });
    var linkToInquiry = Translate({
        term: 'admin.inquiry.link_to_inquiry',
    });
    var writeMaterialUrl = Translate({
        term: 'admin.inquiry.write_material_url',
    });
    var generatedInquiry = Translate({
        term: 'admin.inquiry.generated_inquiry',
    });
    var uploadInquiry = Translate({
        term: 'admin.inquiry.upload_inquiry',
    });
    var clear = Translate({
        term: 'actions.clear',
    });
    var answerTheSurveyMoreThanOnceTranslate = Translate({
        term: 'admin.inquiry.answer_the_survey_more_than_once',
    });
    var nameRequiredTranslate = Translate({
        term: 'material_library.upload_wargning_messages.name_required',
    });
    var typeRequiredTranslate = Translate({
        term: 'material_library.upload_wargning_messages.type_required',
    });
    var moduleRequiredTranslate = Translate({
        term: 'material_library.upload_wargning_messages.module_required',
    });
    var linkToQueryRequiredTranslate = Translate({
        term: 'admin.inquiry.link_to_query_required',
    });
    var successTranslate = Translate({
        term: 'common.save_success',
    });
    var failedTranslate = Translate({
        term: 'common.save_failed',
    });
    var surveyTemplateTranslate = Translate({
        term: 'admin.inquiry.open_survey_template',
    });
    var modifyTranslate = Translate({ term: 'actions.modify' });
    var deleteTranslate = Translate({ term: 'actions.delete' });
    var iconOpened = {
        transition: 'transform 0.3s ease-out',
        transform: 'rotate(180deg)',
    };
    var iconClosed = {
        transition: 'transform 0.3s ease-out',
        transform: 'rotate(0deg)',
    };
    var getModules = function () { return __awaiter(void 0, void 0, void 0, function () {
        var modulesData, modulesOptions, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getModulesAdmin(user.session)];
                case 1:
                    modulesData = _a.sent();
                    if (modulesData) {
                        modulesOptions = modulesData.map(function (module) {
                            return {
                                value: module._id,
                                optionText: module.name.fi || module.name.se,
                            };
                        });
                        setModuleOptions(sortBy(modulesOptions, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onSubmitSuccess = function () {
        toast(successTranslate, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 2000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
        dispatch(clearFormState());
    };
    var onSubmitFail = function () {
        toast(failedTranslate, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 2000,
            toastId: 'warningId',
            className: 'toast-message-warning',
        });
    };
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errors, questionnaireData, updatedQuestionnaireData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    setInputErrors([]);
                    errors = [];
                    if (!user.session)
                        return [2 /*return*/];
                    if (!name) {
                        errors.push(nameRequiredTranslate);
                    }
                    if (!type) {
                        errors.push(typeRequiredTranslate);
                    }
                    if (!moduleId) {
                        errors.push(moduleRequiredTranslate);
                    }
                    if (!queryURL) {
                        errors.push(linkToQueryRequiredTranslate);
                    }
                    if (errors.length > 0) {
                        setInputErrors(errors);
                        return [2 /*return*/];
                    }
                    if (!(modalState === 'listing')) return [3 /*break*/, 2];
                    return [4 /*yield*/, createAdminQuestionnaire(formData, user.session)];
                case 1:
                    questionnaireData = _a.sent();
                    if (questionnaireData) {
                        onSubmitSuccess();
                    }
                    else {
                        onSubmitFail();
                    }
                    return [3 /*break*/, 4];
                case 2:
                    if (!(modalState === 'editing')) return [3 /*break*/, 4];
                    return [4 /*yield*/, updateAdminQuestionnaire(formData, user.session)];
                case 3:
                    updatedQuestionnaireData = _a.sent();
                    if (updatedQuestionnaireData) {
                        onSubmitSuccess();
                        navigate('/query/');
                    }
                    else {
                        onSubmitFail();
                    }
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_1 = _a.sent();
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getModules();
        // Reset possible api errors if page is reloaded. This is because store is persisted.
        dispatch(setApiError([]));
    }, []);
    useEffect(function () {
        if (window.location.pathname === '/query' ||
            window.location.pathname === '/query/') {
            dispatch(setModalState('listing'));
        }
    }, []);
    return (_jsxs("div", __assign({ className: "mt-6" }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx("h2", __assign({ className: "text-2xl font-semibold" }, { children: modalState === 'listing' ? uploadNewInquiry : updateInquiry })), _jsxs("form", __assign({ onSubmit: function (e) {
                    e.preventDefault();
                    onSubmit();
                } }, { children: [_jsxs("div", __assign({ className: "flex flex-col space-y-4 mb-11" }, { children: [_jsxs("p", __assign({ className: "flex justify-end text-gray-600 mt-4 -mb-2", style: { width: '650px' } }, { children: ["*", requiredTranslate] })), _jsx(TextInput, { label: nameTranslate, onChange: function (name) { return dispatch(setFormField({ name: name })); }, value: name, required: true }), _jsxs("div", __assign({ className: "flex flex-row justify-between", style: { width: '650px' } }, { children: [_jsxs("label", __assign({ className: "w-1/3" }, { children: [typeTranslate, "*"] })), _jsx(Select, { selectProps: {
                                            id: 'type-select',
                                            labelId: 'type-select-label',
                                            label: selectInquiryType,
                                            value: type,
                                            onChange: function (_a) {
                                                var target = _a.target;
                                                return dispatch(setFormField({ type: target.value }));
                                            },
                                        }, width: '330px', emptyOptionValue: '', emptyOptionText: selectInquiryType, datas: QUESTIONNAIRE_LIST })] })), _jsxs("div", __assign({ className: "flex flex-row justify-between", style: { width: '650px' } }, { children: [_jsxs("label", __assign({ className: "w-1/3" }, { children: [moduleTranslate, "*"] })), _jsx(Select, { selectProps: {
                                            id: 'module-select',
                                            labelId: 'module-select-label',
                                            label: selectModuleTranslate,
                                            value: moduleId,
                                            onChange: function (_a) {
                                                var target = _a.target;
                                                return dispatch(setFormField({ moduleId: target.value }));
                                            },
                                        }, width: '330px', emptyOptionValue: 0, emptyOptionText: selectModuleTranslate, datas: moduleOptions !== null && moduleOptions !== void 0 ? moduleOptions : [] })] })), _jsx(FormGroup, __assign({ "aria-label": answerTheSurveyMoreThanOnceTranslate, className: "flex justify-center text-gray-600 mt-4 -mb-2" }, { children: _jsx(FormControlLabel, { value: answerTheSurveyMoreThanOnce, checked: answerTheSurveyMoreThanOnce, control: _jsx(Switch, {}), label: answerTheSurveyMoreThanOnceTranslate, labelPlacement: "end", onChange: function (_event, checked) {
                                        return dispatch(setFormField({
                                            answerTheSurveyMoreThanOnce: checked,
                                        }));
                                    } }) })), _jsxs("div", __assign({ className: expanded ? 'expanded-open' : 'expanded-closed' }, { children: [_jsxs("div", __assign({ className: "bg-white pt-3 pb-1 px-3 md:pt-5 md:pb-3 rounded-md shadow-md relative flex flex-col gap-2" }, { children: [surveyTemplateTranslate, _jsx("div", __assign({ className: "absolute -top-1 right-0 p-3 cursor-pointer", onClick: function () { return setExpanded(!expanded); } }, { children: _jsx(ExpandMoreIcon, { style: expanded ? iconOpened : iconClosed }) }))] })), expanded && _jsx(SurveyCreatorWidget, {})] }))] })), modalState === 'listing' ? (_jsxs("div", __assign({ className: "flex justify-end space-x-6", style: { width: '650px' } }, { children: [_jsx("button", __assign({ type: "submit" }, { children: uploadInquiry })), _jsx("button", __assign({ type: "button", onClick: function () {
                                    dispatch(clearFormState());
                                    setInputErrors([]);
                                }, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: clear }))] }))) : (_jsxs("div", __assign({ className: "flex justify-end gap-4" }, { children: [_jsx("button", __assign({ type: "submit" }, { children: modifyTranslate })), _jsx("button", __assign({ onClick: function () { return dispatch(setModalState('deleting')); }, type: "button", className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: deleteTranslate }))] }))), inputErrors &&
                        inputErrors.length > 0 &&
                        inputErrors.map(function (errorMessage, i) { return (_jsx("div", __assign({ className: "text-warning-600 px-4 py-2 bg-white border border-warning-600 rounded-md mt-3 mb-3" }, { children: errorMessage }), i)); }), apiErrors &&
                        apiErrors.length > 0 &&
                        apiErrors.map(function (errorMessage, i) { return (_jsx("div", __assign({ className: "text-warning-600 px-4 py-2 bg-white border border-warning-600 rounded-md mt-3 mb-3" }, { children: errorMessage }), i)); })] }))] })));
};
export default UploadQuery;
