var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Header from '../../components/therapist/Header';
import LogoLink from '../../components/common/LogoLink';
import { Translate } from '../../components/translation/Translate';
var Links = function () {
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: Translate({ term: 'nav.links' }) }), _jsx("main", __assign({ className: "px-8 py-4" }, { children: _jsxs("div", __assign({ className: "bg-white p-4 rounded-md shadow-md grid  md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4" }, { children: [_jsx(LogoLink, { title: "\u00C4\u00E4nnekoulun kauppa", url: "/", logo: "/assets/images/ak-logo.png" }), _jsx(LogoLink, { title: "\u00C4\u00E4nnekoulun kauppa", url: "/", logo: "/assets/images/ak-logo.png" }), _jsx(LogoLink, { title: "\u00C4\u00E4nnekoulun kauppa", url: "/", logo: "/assets/images/ak-logo.png" }), _jsx(LogoLink, { title: "\u00C4\u00E4nnekoulun kauppa", url: "/", logo: "/assets/images/ak-logo.png" }), _jsx(LogoLink, { title: "\u00C4\u00E4nnekoulun kauppa", url: "/", logo: "/assets/images/ak-logo.png" }), _jsx(LogoLink, { title: "\u00C4\u00E4nnekoulun kauppa", url: "/", logo: "/assets/images/ak-logo.png" })] })) }))] }));
};
export default Links;
