var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { Translate } from '../../translation/Translate';
import ChatMessage from './chatMessage';
var ChatMessageList = function (_a) {
    var chatMessageList = _a.chatMessageList, userId = _a.userId, softDeleteHelpChatMessageFunction = _a.softDeleteHelpChatMessageFunction;
    var scrollContainerRef = useRef(null);
    useEffect(function () {
        var _a, _b;
        var bottomTop = (_a = scrollContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight;
        (_b = scrollContainerRef.current) === null || _b === void 0 ? void 0 : _b.scrollTo({ top: bottomTop });
    }, [chatMessageList]);
    return (_jsx("div", __assign({ className: "relative bg-lightBlue rounded-md border border-lightBlue" }, { children: _jsx("div", __assign({ style: { height: '50vh' }, ref: scrollContainerRef, className: "overflow-y-scroll overflow-x-hidden py-2 md:py-4 px-1 sm:px-3 md:px-8" }, { children: chatMessageList.length > 0 ? (chatMessageList.map(function (chatMessage, key) {
                return (_jsx(ChatMessage, { chatMessage: chatMessage, userId: userId, softDeleteHelpChatMessageFunction: softDeleteHelpChatMessageFunction }, key));
            })) : (_jsx("div", __assign({ className: "text-center p-6" }, { children: Translate({ term: 'messages.no_messages' }) }))) })) })));
};
export default ChatMessageList;
