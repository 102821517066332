var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from '../../translation/Translate';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
var PracticeBooks = function () {
    return (_jsxs("div", __assign({ className: "bg-white lg:w-2/3 border border-cyan py-3 px-2 sm:p-6 rounded-md relative shadow-lg" }, { children: [_jsx("h3", __assign({ className: "ml-2 mb-2 font-semibold text-lg text-grey-800 sm:mb-5" }, { children: Translate({ term: 'customer_service.excercise_books' }) })), _jsxs("div", __assign({ className: "flex flex-col space-y-3 ml-2 mb-4 sm:mb-6 sm:flex-row sm:justify-between sm:space-y-0" }, { children: [_jsxs("div", __assign({ className: "flex flex-row space-x-2 sm:space-x-0 sm:flex-col w-20 min-w-[80px] sm:w-40 sm:min-w-[160px] h-[auto]" }, { children: [_jsx("img", { className: "", src: "https://www.aannekoulu.fi/wp-content/uploads/2022/03/Screenshot-2022-03-07-at-12.59.12.png" }), _jsx("p", { children: "Sanavaraston teht\u00E4v\u00E4kirja" })] })), _jsxs("div", __assign({ className: "flex flex-row sm:flex-col space-x-2 sm:space-x-0 sm:items-center sm:text-cente w-20 min-w-[80px] sm:w-40 sm:min-w-[160px] h-[auto]" }, { children: [_jsx("img", { src: "https://www.aannekoulu.fi/wp-content/uploads/2022/02/arran-parinaa-harjoituskirja.jpg" }), _jsx("p", { children: "\u00C4rr\u00E4n p\u00E4rin\u00E4\u00E4 -harjoituskirja" })] })), _jsxs("div", __assign({ className: "flex flex-row sm:flex-col space-x-2 sm:space-x-0 sm:items-end sm:text-right w-20 min-w-[80px] sm:w-40 sm:min-w-[160px] h-[auto]" }, { children: [_jsx("img", { src: "https://www.aannekoulu.fi/wp-content/uploads/2022/06/s_kansi.jpg" }), _jsx("p", { children: "\u00C4ss\u00E4n s\u00E4hin\u00E4\u00E4 -harjoituskirja" })] }))] })), _jsxs("p", __assign({ className: "absolute bottom-1 right-2 inline ml-6" }, { children: [Translate({ term: 'customer_service.read_more' }), _jsxs("a", __assign({ className: "text-blue", href: "https://0f7b87-93.myshopify.com/", target: "_blank", rel: "noreferrer" }, { children: [' ', "aannekoulu.fi", ' ', _jsx(ArrowCircleRightOutlinedIcon, { style: { backgroundColor: '#FFFFFF', fill: '#133964' } })] }))] }))] })));
};
export default PracticeBooks;
