var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Header from '../../components/therapist/Header';
import { Translate } from '../../components/translation/Translate';
var Plan = function () {
    // const session = useSelector((state: AppState) => state.user.session);
    // const userinfo = useSelector((state: AppState) => state.user.userinfo);
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: Translate({ term: 'nav.plan_assignments' }) }), _jsxs("main", { children: [_jsx("nav", __assign({ className: "tabs" }, { children: "???" })), "Listaus ???"] })] }));
};
export default Plan;
