var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
var SelectDataArrayComponent = function (_a) {
    var labelTranslate = _a.labelTranslate, selectTextTranslate = _a.selectTextTranslate, dataArray = _a.dataArray, setDataArray = _a.setDataArray, selectDataArr = _a.selectDataArr, removeDataFunction = _a.removeDataFunction;
    return (_jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-4/12 mt-2" }, { children: [labelTranslate, "*"] })), _jsxs("div", __assign({ className: "flex flex-col w-8/12" }, { children: [_jsxs("select", __assign({ className: "select", style: { width: '66.666666%' }, value: selectTextTranslate, onChange: function (e) {
                            var data = JSON.parse(e.target.value);
                            if (!dataArray.find(function (z) { return z.value === data.value; })) {
                                setDataArray(__spreadArray(__spreadArray([], __read(dataArray), false), [data], false));
                            }
                        } }, { children: [_jsx("option", __assign({ value: "" }, { children: selectTextTranslate })), selectDataArr.map(function (zone, index) { return (_jsx("option", __assign({ value: JSON.stringify(zone) }, { children: zone.optionText }), index)); })] })), _jsx("div", __assign({ className: "mt-2" }, { children: dataArray.map(function (zone, index) { return (_jsxs("div", __assign({ className: "select-item" }, { children: [_jsx("span", __assign({ className: "text" }, { children: zone.optionText })), _jsx("span", __assign({ onClick: function () {
                                        removeDataFunction(index);
                                    }, className: "close" }, { children: "\u00D7" }))] }), index)); }) }))] }))] })));
};
export default SelectDataArrayComponent;
