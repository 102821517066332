var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useMatch, useResolvedPath, } from 'react-router-dom';
import Avatar from '../../common/Avatar';
import { Translate } from '../../translation/Translate';
import { getWindowDimensions } from '../../../functions/screen';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import CallSplitOutlinedIcon from '@mui/icons-material/CallSplitOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { logout } from '../../../functions/logout';
import MenuHeader from './MenuHeader';
import useUser from '../../../hooks/useUser';
var IconLink = function (_a) {
    var iconsOnly = _a.iconsOnly, icon = _a.icon, title = _a.title, path = _a.path, active = _a.active, style = _a.style, target = _a.target;
    var linkStyle = [
        'flex px-2 gap-2 py-2 rounded-md hover:bg-white hover:bg-opacity-50',
    ];
    if (iconsOnly) {
        linkStyle.push('mr-2');
    }
    if (active)
        linkStyle.push('bg-white bg-opacity-50');
    var titleString = '';
    if (typeof title === 'string') {
        titleString = title;
    }
    if (style) {
        linkStyle.push(style);
    }
    if (target) {
        return (_jsxs("a", __assign({ href: path, target: target, className: linkStyle.join(' ') }, { children: [_jsx("div", __assign({ className: "h-6 w-6 text-xs overflow-hidden flex items-center text-gray-500", title: titleString }, { children: icon })), iconsOnly ? '' : _jsx("div", __assign({ className: "text-gray-700" }, { children: title }))] })));
    }
    return (_jsxs(NavLink, __assign({ to: path, className: linkStyle.join(' '), style: { justifyContent: iconsOnly ? 'center' : 'flex-start' } }, { children: [_jsx("div", __assign({ className: "h-6 w-6 text-xs overflow-hidden flex items-center text-gray-500", title: titleString }, { children: icon })), iconsOnly ? '' : _jsx("div", __assign({ className: "text-gray-700" }, { children: title }))] })));
};
var MainSubNav = function (_a) {
    var item = _a.item;
    if (item.sub && item.sub.length > 0) {
        return (_jsx("ul", __assign({ className: "ml-10" }, { children: item.sub.map(function (subItem, subKey) {
                var sResolved = useResolvedPath(subItem.path);
                var subMatch = useMatch({ path: sResolved.pathname });
                var classNames = [];
                if (subMatch) {
                    classNames.push('flex px-2 gap-2 py-1 rounded-md bg-white bg-opacity-50');
                }
                else {
                    classNames.push('flex px-2 gap-2 py-1 rounded-md hover:bg-white hover:bg-opacity-50');
                }
                return (_jsx("li", __assign({ className: "p-0 my-2" }, { children: _jsx(NavLink, __assign({ to: subItem.path, className: classNames.join(' ') }, { children: subItem.title })) }), subKey));
            }) })));
    }
    return _jsx(React.Fragment, {});
};
var MainNav = function (_a) {
    var iconsOnly = _a.iconsOnly;
    var user = useUser();
    var menuItems = [
        {
            title: Translate({ term: 'nav.home' }),
            icon: _jsx(HomeOutlinedIcon, {}),
            path: '/',
        },
        {
            title: Translate({ term: 'nav.periods' }),
            icon: _jsx(MenuBookOutlinedIcon, {}),
            path: '/periods',
        },
        {
            title: Translate({ term: 'nav.evaluations' }),
            icon: _jsx(CallSplitOutlinedIcon, {}),
            path: '/evaluations',
        },
        {
            title: Translate({ term: 'nav.questioinnaires' }),
            path: '/query',
            icon: _jsx(DynamicFeedOutlinedIcon, {}),
        },
        {
            title: Translate({ term: 'nav.material_library' }),
            path: '/materials',
            icon: _jsx(DynamicFeedOutlinedIcon, {}),
        },
        {
            title: Translate({ term: 'nav.messages' }),
            path: '/messages',
            icon: _jsx(ChatBubbleOutlineOutlinedIcon, {}),
        },
        // {
        // 	title: Translate({ term: 'nav.links' }),
        // 	path: '/links',
        // 	icon: <InsertLinkOutlinedIcon />,
        // },
        {
            title: Translate({ term: 'nav.share_license' }),
            icon: _jsx(AddCircleOutlineOutlinedIcon, {}),
            path: '/share-license',
        },
    ];
    return (_jsx("nav", __assign({ className: "main-nav flex-1 justify-start px-6 ".concat(iconsOnly ? 'w-24' : 'w-72') }, { children: _jsx("ul", __assign({ className: "flex flex-col" }, { children: menuItems.map(function (item, key) {
                var _a, _b;
                var match = useMatch(item.path + '/*');
                if (item.path === '/') {
                    var resolved = useResolvedPath(item.path);
                    match = useMatch({ path: resolved.pathname }); //, end: true
                }
                var submenu = '';
                if (match !== null) {
                    //iconsOnly !== true
                    submenu = _jsx(MainSubNav, { item: item });
                }
                if (!item.hide) {
                    if (item.path === '/evaluations') {
                        if ((_b = (_a = user.userinfo) === null || _a === void 0 ? void 0 : _a.therapistInfo) === null || _b === void 0 ? void 0 : _b.evaluationTherapist) {
                            return (_jsxs("li", __assign({ className: "p-0 my-2" }, { children: [_jsx(IconLink, { iconsOnly: iconsOnly, path: item.path, title: item.title, icon: item.icon, active: match ? true : false }), submenu] }), key));
                        }
                        else {
                            return (_jsxs("li", __assign({ className: "p-0 my-2" }, { children: [_jsx(IconLink, { iconsOnly: iconsOnly, path: item.path, title: item.title, icon: item.icon, active: match ? true : false }), submenu] }), key));
                        }
                    }
                    else {
                        return (_jsxs("li", __assign({ className: "p-0 my-2" }, { children: [_jsx(IconLink, { iconsOnly: iconsOnly, path: item.path, title: item.title, icon: item.icon, active: match ? true : false }), submenu] }), key));
                    }
                }
            }) })) })));
};
var QuickLinks = function (_a) {
    var iconsOnly = _a.iconsOnly;
    var naviItems = [
        {
            title: Translate({ term: 'nav.quick_help' }),
            icon: _jsx(SupportOutlinedIcon, {}),
            path: '/help-chat',
        },
        {
            title: 'Diarium',
            icon: _jsx(BorderColorOutlinedIcon, {}),
            path: 'https://oma.diarium.fi/2480/users/login/',
            target: '_blank',
        },
    ];
    return (_jsx("nav", __assign({ className: "actions my-10 ".concat(iconsOnly ? 'mb-2' : 'list-none') }, { children: _jsx("ul", __assign({ className: "flex flex-col ".concat(iconsOnly ? 'items-center' : '') }, { children: naviItems.map(function (item, key) { return (_jsx("li", __assign({ className: "p-0 my-2 ".concat(iconsOnly ? 'text-center' : ''), style: { justifyContent: iconsOnly ? 'center' : 'flex-start' } }, { children: _jsx("div", __assign({ className: iconsOnly ? 'mb-2' : 'mb-0' }, { children: _jsx(IconLink, { iconsOnly: iconsOnly, path: item.path, title: item.title, icon: item.icon, style: "bg-white p-2 rounded-md", target: item.target }) })) }), key)); }) })) })));
};
var Profile = function (_a) {
    var _b, _c, _d, _e;
    var iconsOnly = _a.iconsOnly;
    var user = useUser();
    return (_jsxs("div", __assign({ className: "flex flex-col items-".concat(iconsOnly ? 'center' : 'flex-start') }, { children: [_jsx(Link, __assign({ to: "/profile" }, { children: _jsx(Avatar, { image: (_c = (_b = user.userinfo) === null || _b === void 0 ? void 0 : _b.profileImageUrl) !== null && _c !== void 0 ? _c : (_jsx(PersonOutlineOutlinedIcon, { style: { width: '100%', height: '100%' } })), firstName: ((_d = user.userinfo) === null || _d === void 0 ? void 0 : _d.firstName) ? user.userinfo.firstName : '', lastName: ((_e = user.userinfo) === null || _e === void 0 ? void 0 : _e.lastName) ? user.userinfo.lastName : '', name: iconsOnly === true ? false : true }) })), iconsOnly === true ? (_jsx("div", __assign({ className: "pt-4" }, { children: _jsx("button", __assign({ onClick: function () { return logout(user.session); } }, { children: _jsx(LogoutIcon, {}) })) }))) : (_jsx("button", __assign({ className: "mt-6", onClick: function () { return logout(user.session); } }, { children: Translate({ term: 'nav.log_out' }) })))] })));
};
var MainMenu = function () {
    var minLimit = 800;
    var maxLimit = 1000;
    var origWd = getWindowDimensions();
    var _a = __read(useState(origWd.width >= 600 ? false : true), 2), iconsOnly = _a[0], setIconsOnly = _a[1];
    useEffect(function () {
        function handleResize() {
            var wd = getWindowDimensions();
            if (wd.width >= maxLimit) {
                // && iconsOnly === true
                setIconsOnly(false);
            }
            else if (wd.width < minLimit && iconsOnly === false) {
                setIconsOnly(true);
            }
        }
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    var asideClass = ['menu flex-none w-24']; //border-r border-gray-300
    var menuClass = [
        'fixed flex flex-col h-screen bg-cyan py-6 w-24 overflow-x-hidden',
    ];
    if (iconsOnly) {
        asideClass.push('w-24');
        menuClass.push('w-24');
    }
    else {
        asideClass.push('w-72');
        menuClass.push('w-72');
    }
    return (_jsx("aside", __assign({ className: asideClass.join(' ') }, { children: _jsxs("div", __assign({ className: menuClass.join(' ') }, { children: [_jsx(MenuHeader, { iconsOnly: iconsOnly }), _jsx(MainNav, { iconsOnly: iconsOnly }), _jsxs("div", __assign({ className: "justify-end px-6" }, { children: [_jsx(QuickLinks, { iconsOnly: iconsOnly }), _jsx("div", { className: "border-b-2 -mt-2 mb-6 w-[95%] border-white" }), _jsx(Profile, { iconsOnly: iconsOnly })] }))] })) })));
};
export default MainMenu;
