var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../reducers/settingsSlice';
import locales from '../translation/ILocales';
var LanguageSelector = function () {
    var dispatch = useDispatch();
    var userLang = useSelector(function (state) { return state.user.language; });
    var userinfoLang = useSelector(function (state) { var _a; return (_a = state.settings) === null || _a === void 0 ? void 0 : _a.language; });
    if (!userLang && userinfoLang) {
        dispatch(setLanguage(userinfoLang));
    }
    function changeLanguage(lang) {
        if (lang !== userinfoLang)
            dispatch(setLanguage(lang));
    }
    return (_jsx("div", __assign({ className: "flex-none flex gap-2" }, { children: locales.map(function (l, key) {
            var classes = ['w-14'];
            if (l.id !== userinfoLang)
                classes.push('border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300');
            return (_jsx("button", __assign({ onClick: function () { return changeLanguage(l.id); }, className: classes.join(' ') }, { children: l.name_short }), key));
        }) })));
};
export default LanguageSelector;
