var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';
import { getUserinfo } from '../../actions/userActions';
import Header from '../../components/therapist/Header';
import HomeStats from '../../components/therapist/home/HomeStats';
import ButtonWithCount from '../../components/common/ButtonWithCount';
import { useTranslate } from '../../components/translation/Translate';
import { setUser } from '../../reducers/userSlice';
import { setLanguage } from '../../reducers/settingsSlice';
import { setPosts } from '../../reducers/postSlice';
import { getTherapistPosts } from '../../actions/postActions';
import PostsInfo from '../../components/therapist/home/PostsInfo';
import { getFilteredPeriods, getTherapistPeriods, } from '../../actions/periodActions';
import { setFilteredPeriods, setPeriodCountByFilter, setPeriods } from '../../reducers/periodSlice';
import HelpChatSelectorContent from '../../components/therapist/helpChat/ChatMessageEditor/helpChatSelectorContent';
import { getUnreadHelpChatMessagesAmount } from '../../actions/HelpChatMessageActions';
import useUser from '../../hooks/useUser';
import TherapistFilteredPatientPeriodsTable from '../../components/common/TherapistFilteredPatientPeriodsTable';
import useAppSelector from '../../hooks/useAppSelector';
import { PERIOD_FILTERS, PERIOD_VISIBILITY } from '../../components/common/enums';
import { getFilteredTherapistsPatientPeriodsCount } from '../../actions/therapistActions';
var TherapistHome = function () {
    var _a, _b;
    var t = useTranslate();
    var user = useUser();
    var dispatch = useDispatch();
    var _c = __read(useState(0), 2), amountOfHelpChatMessages = _c[0], setAmountOfHelpChatMessages = _c[1];
    var _d = __read(useState(['1']), 2), activeTableSelections = _d[0], setActiveableSelections = _d[1];
    var userinfo = useAppSelector(function (state) { return state.user.userinfo; });
    var userinfoLang = useAppSelector(function (state) { var _a; return (_a = state.settings) === null || _a === void 0 ? void 0 : _a.language; });
    var posts = useAppSelector(function (state) { return state.posts.posts; });
    var periodCountByFilter = useAppSelector(function (state) { return state.periods.periodCountByFilter; });
    var newPeriodsCount = periodCountByFilter[PERIOD_FILTERS.NEW];
    var finishedPeriodsCount = periodCountByFilter[PERIOD_FILTERS.FINISHED];
    var soonToEndPeriodsCount = periodCountByFilter[PERIOD_FILTERS.SOON_TO_END];
    var passivePeriodsCount = periodCountByFilter[PERIOD_FILTERS.PASSIVE];
    var unreadMessagesPeriodsCount = periodCountByFilter[PERIOD_FILTERS.UNREAD_MESSAGES];
    var loadPosts = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var postsResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTherapistPosts(user.session)];
                case 1:
                    postsResponse = _a.sent();
                    if (postsResponse) {
                        dispatch(setPosts(postsResponse));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch, (_a = user.session) === null || _a === void 0 ? void 0 : _a.accessToken]);
    var fetchUserInfo = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var userData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!userinfo) return [3 /*break*/, 2];
                    return [4 /*yield*/, getUserinfo(user.session)];
                case 1:
                    userData = _a.sent();
                    if (userData) {
                        if (!userinfoLang && userData.language) {
                            dispatch(setLanguage(userData.language));
                        }
                        dispatch(setUser(userData));
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [dispatch, (_b = user.session) === null || _b === void 0 ? void 0 : _b.accessToken, userinfo, userinfoLang]);
    var fetchPeriodCounts = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var getCountFor;
        return __generator(this, function (_a) {
            getCountFor = function (filter, hideFrom) { return __awaiter(void 0, void 0, void 0, function () {
                var response, count;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, getFilteredTherapistsPatientPeriodsCount(user.session, {
                                filter: filter,
                                hideFrom: hideFrom
                            })];
                        case 1:
                            response = _b.sent();
                            count = (_a = response === null || response === void 0 ? void 0 : response.periods) !== null && _a !== void 0 ? _a : 0;
                            dispatch(setPeriodCountByFilter({ filter: filter, count: count }));
                            return [2 /*return*/];
                    }
                });
            }); };
            getCountFor(PERIOD_FILTERS.NEW);
            getCountFor(PERIOD_FILTERS.FINISHED, [PERIOD_VISIBILITY.FINISHED]);
            getCountFor(PERIOD_FILTERS.SOON_TO_END, [PERIOD_VISIBILITY.SOON_TO_END]);
            getCountFor(PERIOD_FILTERS.PASSIVE, [PERIOD_VISIBILITY.PASSIVE]);
            getCountFor(PERIOD_FILTERS.UNREAD_MESSAGES);
            return [2 /*return*/];
        });
    }); }, []);
    var fetchPeriods = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var filters, filters_1, filters_1_1, filter, periods, e_1_1;
        var e_1, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    filters = [
                        'unreadMessages',
                        'soonToEndPeriods',
                        'passivePeriods',
                        'finishedPeriods',
                    ];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, 7, 8]);
                    filters_1 = __values(filters), filters_1_1 = filters_1.next();
                    _b.label = 2;
                case 2:
                    if (!!filters_1_1.done) return [3 /*break*/, 5];
                    filter = filters_1_1.value;
                    return [4 /*yield*/, getFilteredPeriods(user.session, {
                            filter: filter,
                            page: 1,
                            limit: 5,
                        })];
                case 3:
                    periods = _b.sent();
                    if (periods) {
                        dispatch(setFilteredPeriods({
                            filter: filter,
                            periods: periods !== null && periods !== void 0 ? periods : [],
                        }));
                    }
                    _b.label = 4;
                case 4:
                    filters_1_1 = filters_1.next();
                    return [3 /*break*/, 2];
                case 5: return [3 /*break*/, 8];
                case 6:
                    e_1_1 = _b.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 8];
                case 7:
                    try {
                        if (filters_1_1 && !filters_1_1.done && (_a = filters_1.return)) _a.call(filters_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); }, []);
    var getOwnPeriods = function () {
        getTherapistPeriods(user.session).then(function (response) {
            if (response) {
                dispatch(setPeriods(response));
            }
        });
    };
    var getAmountOfUnreadHelpChatMessages = function () { return __awaiter(void 0, void 0, void 0, function () {
        var amountOfUnreadHelpChatMessages;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getUnreadHelpChatMessagesAmount(user.session)];
                case 1:
                    amountOfUnreadHelpChatMessages = _a.sent();
                    if (amountOfUnreadHelpChatMessages) {
                        setAmountOfHelpChatMessages(amountOfUnreadHelpChatMessages.amountOfUnreadChatMessages);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var tableSettings = function (key) {
        setActiveableSelections([key]);
    };
    useEffect(function () {
        fetchUserInfo();
        fetchPeriodCounts();
        getOwnPeriods();
        loadPosts();
        fetchPeriods();
        getAmountOfUnreadHelpChatMessages();
    }, []);
    var selectors = [
        {
            title: t('home.new_messages'),
            amount: unreadMessagesPeriodsCount,
            content: (_jsx(TherapistFilteredPatientPeriodsTable, { docsPerPage: 5, filter: PERIOD_FILTERS.UNREAD_MESSAGES, columns: ['name', 'unread_received', 'period_count'] })),
            name: t('home.new_messages'),
            tab: 'unreadMessages',
        },
        {
            title: t('home.periods_new'),
            amount: newPeriodsCount,
            content: (_jsx(TherapistFilteredPatientPeriodsTable, { docsPerPage: 5, filter: 'newPeriods', columns: ['name', 'start_questionnaire_done', 'period_count'] })),
            name: t('home.periods_new'),
            tab: 'newPeriods',
        },
        {
            title: t('period.end_report'),
            amount: soonToEndPeriodsCount,
            content: (_jsx(TherapistFilteredPatientPeriodsTable, { docsPerPage: 5, filter: 'soonToEndPeriods', columns: ['name', 'content', 'phase', "period_ends", "hide_soon_to_end"] })),
            name: t('period.end_report'),
            tab: 'soonToEndPeriods',
        },
        {
            title: t('home.periods_passive'),
            amount: passivePeriodsCount,
            content: (_jsx(TherapistFilteredPatientPeriodsTable, { docsPerPage: 5, filter: 'passivePeriods', columns: ['name', 'content', 'last_message', 'phase', "hide_passive"] })),
            name: t('home.periods_passive'),
            tab: 'passivePeriods',
        },
        {
            title: t('home.periods_ended'),
            amount: finishedPeriodsCount,
            content: (_jsx(TherapistFilteredPatientPeriodsTable, { docsPerPage: 5, filter: 'finishedPeriods', columns: ['name', 'content', 'phase', "period_ends", "hide_finished"] })),
            tab: 'finishedPeriods',
        },
        {
            title: t('help_chat.help_chat_messages'),
            amount: amountOfHelpChatMessages,
            content: _jsx(HelpChatSelectorContent, {}),
            tab: 'finishedPeriods',
        },
    ];
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: t('home.welcome') }), _jsxs("main", __assign({ className: "px-8 py-4 flex flex-col gap-6" }, { children: [_jsxs("div", __assign({ className: "mb-4" }, { children: [_jsx("span", __assign({ className: "text-xl" }, { children: t('home.today') })), ' ', _jsx(Moment, __assign({ className: "text-xl", format: t('common.date_format') }, { children: new Date() }))] })), _jsx("div", __assign({ className: "xl:col-span-2 grid md:grid-cols-1 gap-3 py-4 px-6" }, { children: _jsx(PostsInfo, { posts: posts }) })), _jsx("div", __assign({ className: "bg-white px-8 pt-4 pb-8 rounded-md shadow-md" }, { children: _jsxs("div", __assign({ className: "grid grid-cols-1 xl:grid-cols-4 gap-8 mt-4" }, { children: [_jsxs("div", __assign({ className: "" }, { children: [_jsx("div", __assign({ className: "text-lg" }, { children: t('home.you_have') })), _jsx("div", __assign({ className: "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-1 mt-4" }, { children: selectors.map(function (item, key) {
                                                var num = (key + 1).toString();
                                                return (_jsx(ButtonWithCount, { id: num, amount: item.amount, title: item.title, active: activeTableSelections.includes(num), action: tableSettings }, key));
                                            }) }))] })), _jsx("div", __assign({ className: "col-span-3" }, { children: activeTableSelections.map(function (act, key) {
                                        var num = parseInt(act) - 1;
                                        if (selectors[num]) {
                                            return (_jsx("div", { children: _jsx("div", { children: selectors[num].content }) }, key));
                                        }
                                    }) }))] })) })), _jsx("div", __assign({ className: "xl:col-span-2 grid md:grid-cols-1 gap-3 py-4 px-6 bg-white rounded-md shadow-md" }, { children: _jsx(HomeStats, {}) }))] }))] }));
};
export default TherapistHome;
