import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    licenses: [],
};
var LicenseSlice = createSlice({
    name: 'licenses',
    initialState: initialState,
    reducers: {
        setLicenses: function (state, action) {
            if (action.payload === null) {
                state.licenses = [];
            }
            else {
                state.licenses = action.payload;
            }
        },
    },
});
export var setLicenses = LicenseSlice.actions.setLicenses;
export default LicenseSlice;
