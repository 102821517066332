module.exports = {
	theme: {
		fontFamily: {
			display: ['Comfortaa', 'Helvetica', 'Arial', 'sans-serif'],
			body: ['Comfortaa', 'Helvetica', 'Arial', 'sans-serif'],
			sans: ['Comfortaa', 'Helvetica', 'Arial', 'sans-serif'],
		},
		colors: {
			white: '#FFF',
			black: '#000',
			blue: '#133964', //'#143a64',
			orange: '#FFBB6A',
			green: '#B5EAB4',
			cyan: '#C6DFE0',
			lightBlue: '#E9F3F4',
			gray: {
				50: '#F9FAFB', // background
				100: '#F2F4F7',
				200: '#EAECF0',
				300: '#e0e0e0',
				400: '#98A2B3',
				500: '#667085', // light text
				600: '#757575',
				700: '#344054',
				800: '#4B4B4B', //dark text
				900: '#212121',
			},
			warning: {
				200: '#FFB35F',
				400: '#EB8839',
				600: '#DC6803',
				800: '#93370D',
			},
			success: {
				600: '#039855',
				700: '#027A48',
				800: '#05603A',
			}
		},
		extend: {
			maxHeight: {
				94: '355px',
				'80vh': '80vh',
			},
		},
	},
	variants: {},
	plugins: [],
};
