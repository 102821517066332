var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { Translate } from '../../../components/translation/Translate';
import PeriodMessages from '../../../components/common/period/messages/PeriodMessages';
import Assignments from '../../../components/common/assignments/Assignments';
import AccordionPanel from '../../../components/common/AccordionPanel';
import { getWindowDimensions } from '../../../functions/screen';
var PeriodHistory = function () {
    var _a, _b, _c;
    var _d = __read(React.useState(null), 2), selectedAssignment = _d[0], setSelectedAssignment = _d[1];
    var _e = __read(useState(null), 2), reply = _e[0], setReply = _e[1];
    var periods = useSelector(function (state) { return state.periods.periodHistory; });
    var messageTranslate = Translate({ term: 'home.message' });
    var selectedPeriod = periods && (periods === null || periods === void 0 ? void 0 : periods.length) > 0 ? periods[0] : null;
    var _f = __read(useState(getWindowDimensions().width >= 1024 ? false : true), 2), show = _f[0], setShow = _f[1];
    var lastEndedPeriodTranslate = Translate({
        term: 'period.last_ended_period',
    });
    var assignmentHistoryTranslate = Translate({
        term: 'period.assignment_history',
    });
    var surveyTranslate = Translate({ term: 'period.feedback_surveys' });
    var messageHistoryTranslate = Translate({
        term: 'messages.message_history',
    });
    var assignmentsHistoryTranslate = Translate({
        term: 'period.assignments_history',
    });
    useEffect(function () {
        function handleResize() {
            var wd = getWindowDimensions();
            if (wd.width >= 1024)
                setShow(false);
            if (wd.width < 1024)
                setShow(true);
        }
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getWindowDimensions()]);
    if (selectedPeriod) {
        var assignments = (_a = selectedPeriod.assignments) === null || _a === void 0 ? void 0 : _a.map(function (assignment) {
            return assignment;
        });
        return (_jsxs("main", __assign({ className: "px-3 md:px-8 py-4" }, { children: [_jsx("h1", __assign({ className: "text-blue text-center mb-4" }, { children: lastEndedPeriodTranslate })), _jsx("div", __assign({ className: "max-w-3xl lg:max-w-7xl mx-auto" }, { children: show ? (_jsx("div", __assign({ className: "lg:hidden" }, { children: _jsxs("div", __assign({ className: "col-span-3" }, { children: [_jsxs("div", __assign({ className: "grid gap-2 md:gap-3 mb-4" }, { children: [_jsx("h3", { children: assignmentsHistoryTranslate }), _jsx(AccordionPanel, __assign({ summary: _jsx("div", { children: assignmentHistoryTranslate }) }, { children: _jsx(Assignments, { assignments: assignments ? assignments : [], isDone: true }) })), _jsx(AccordionPanel, __assign({ summary: _jsx("div", { children: surveyTranslate }) }, { children: _jsx(Assignments, { assignments: assignments ? assignments : [] }) }))] })), _jsx("div", __assign({ className: "col-span-3" }, { children: _jsxs("div", __assign({ className: "grid gap-2" }, { children: [_jsx("h3", { children: messageHistoryTranslate }), _jsx(PeriodMessages, { messages: selectedPeriod.messages ? selectedPeriod.messages : [], client: true, periodId: selectedPeriod._id ? selectedPeriod._id : '1', replyToMessage: function (message) {
                                                    setReply(message);
                                                }, sessionsDone: selectedPeriod.sessions
                                                    ? String(selectedPeriod.sessions)
                                                    : '', sessions: ((_b = selectedPeriod.module) === null || _b === void 0 ? void 0 : _b.sessions)
                                                    ? String(selectedPeriod.module.sessions)
                                                    : '', periodValidUntil: selectedPeriod.validUntil }), reply === null ? (_jsx(_Fragment, {})) : (_jsxs("div", __assign({ className: "flex flex-row mt-3 space-x-2 bg-white py-1 px-3 md:px-8 rounded-md border border-gray-200 relative" }, { children: [_jsx("div", __assign({ className: "p-1 flex items-center" }, { children: _jsx(ChatBubbleOutlineOutlinedIcon, {}) })), _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("p", { children: [messageTranslate, ":"] }), _jsx("p", __assign({ className: "truncate .." }, { children: reply }))] }))] })))] })) }))] })) }))) : (_jsx("div", __assign({ className: "hidden lg:flex lg:flex-col" }, { children: _jsxs("div", __assign({ className: "flex flex-row gap-6 justify-between mt-4 md:mt-6" }, { children: [_jsxs("div", __assign({ className: "space-y-3 w-1/2" }, { children: [_jsx("h3", __assign({ className: "text-xl text-left font-semibold text-grey-800 mb-0 md:mb-3" }, { children: messageHistoryTranslate })), _jsx(PeriodMessages, { messages: selectedPeriod.messages ? selectedPeriod.messages : [], client: true, periodId: selectedPeriod._id ? selectedPeriod._id : '1', replyToMessage: function (message) {
                                                setReply(message);
                                            }, sessionsDone: selectedPeriod.sessions
                                                ? String(selectedPeriod.sessions)
                                                : '', sessions: ((_c = selectedPeriod.module) === null || _c === void 0 ? void 0 : _c.sessions)
                                                ? String(selectedPeriod.module.sessions)
                                                : '', periodValidUntil: selectedPeriod.validUntil }), reply === null ? (_jsx(_Fragment, {})) : (_jsxs("div", __assign({ className: "flex flex-row mt-3 space-x-2 bg-white py-1 px-3 md:px-8 rounded-md border border-gray-200 relative" }, { children: [_jsx("div", __assign({ className: "p-1 flex items-center" }, { children: _jsx(ChatBubbleOutlineOutlinedIcon, {}) })), _jsxs("div", __assign({ className: "flex flex-col truncate .." }, { children: [_jsxs("p", { children: [messageTranslate, ":"] }), _jsx("p", __assign({ className: "truncate .." }, { children: reply }))] }))] })))] })), _jsxs("div", __assign({ className: "w-1/2" }, { children: [_jsx("h3", __assign({ className: "text-xl text-right font-semibold text-grey-800 mb-0 md:mb-3" }, { children: assignmentsHistoryTranslate })), _jsxs("div", __assign({ className: "grid gap-2 md:gap-3" }, { children: [_jsx(AccordionPanel, __assign({ summary: _jsx("div", { children: assignmentHistoryTranslate }) }, { children: _jsx(Assignments, { assignments: assignments ? assignments : [], isDone: true }) })), _jsx(AccordionPanel, __assign({ summary: _jsx("div", { children: surveyTranslate }) }, { children: _jsx(Assignments, { assignments: assignments ? assignments : [] }) }))] }))] }))] })) }))) }))] })));
    }
    else {
        return (_jsx(React.Fragment, { children: _jsx("div", { children: "loading..." }) }));
    }
};
export default PeriodHistory;
