var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable indent */
import React from 'react';
import ReactPlayer from 'react-player';
import PeriodMaterialIcon from './period/PeriodMaterialIcon';
import PeriodMaterialMarkdown from './period/PeriodMaterialMarkdown';
export var MaterialSummary = function (_a) {
    var material = _a.material, periodId = _a.periodId;
    var materialIcon = (_jsx(PeriodMaterialIcon, { type: material ? material.type : '' }));
    var description = undefined;
    if (material) {
        var mDescription = undefined;
        if (material.description && material.description !== '') {
            mDescription = material.description;
        }
        else if (material.therapistInstructions &&
            material.therapistInstructions !== '') {
            mDescription = material.therapistInstructions;
        }
        else if (material.patientInstructions &&
            material.patientInstructions !== '') {
            mDescription = material.patientInstructions;
        }
        //mDescription.replace(/(\r\n|\n|\r)/gm, " ")
        if (mDescription && material.html) {
            description = _jsx("div", { dangerouslySetInnerHTML: { __html: mDescription } });
        }
        else if (mDescription && !material.html) {
            description = (_jsx(PeriodMaterialMarkdown, { text: mDescription, textOnly: true }));
        }
    }
    var url = periodId
        ? "/materials/".concat(material === null || material === void 0 ? void 0 : material._id, "?periodId=").concat(periodId)
        : "/materials/".concat(material === null || material === void 0 ? void 0 : material._id);
    return (_jsx(React.Fragment, { children: material && (_jsx("div", __assign({ className: "bg-white py-4 px-8 rounded-md shadow-md cursor-pointer" }, { children: _jsxs("a", __assign({ href: url }, { children: [_jsxs("h3", __assign({ className: "text-blue" }, { children: [materialIcon, " ", material.name] })), _jsxs("div", __assign({ className: "flex gap-4 my-2 h-20 overflow-hidden" }, { children: [material.type === 'video' && (_jsx("div", __assign({ className: "flex-none w-32 h-20 bg-gray-300" }, { children: _jsx(ReactPlayer, { url: material.url, width: "128px", height: "80px", light: true }) }))), _jsx("div", { children: description && (_jsx("div", __assign({ className: "text-sm lines-3" }, { children: description }))) })] }))] })) }))) }));
};
