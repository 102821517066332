var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
var TableDiv = function (_a) {
    var sort = _a.sort, reverse = _a.reverse, handleSetSort = _a.handleSetSort, materials = _a.materials, setSelectedMaterial = _a.setSelectedMaterial, headCells = _a.headCells;
    return (_jsxs("table", __assign({ className: "table-fixed border-collapse rounded-md shadow-md w-full max-w-6xl mt-8" }, { children: [_jsx("thead", { children: _jsx("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2", style: { height: '60px' } }, { children: headCells.map(function (headCell, key) {
                        if (headCell.id !== 'modules' &&
                            headCell.id !== 'module' &&
                            headCell.id !== 'category') {
                            var sortIcon = sort === headCell.id && reverse ? (_jsx(ArrowUpwardIcon, { className: sort === headCell.id
                                    ? 'text-blue ml-1 opacity-100'
                                    : 'text-blue ml-1 opacity-25', fontSize: "small" })) : (_jsx(ArrowDownwardIcon, { className: sort === headCell.id
                                    ? 'text-blue ml-1 opacity-100'
                                    : 'text-blue ml-1 opacity-25', fontSize: "small" }));
                            if (headCell.id === 'name') {
                                return (_jsxs("th", __assign({ onClick: function () { return handleSetSort(headCell.id); }, className: "w-1/3 xl:w-2/5 border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: [headCell.label, " ", sortIcon] }), key));
                            }
                            else if (headCell.id === 'updatedAt' ||
                                headCell.id === 'modified.time') {
                                return (_jsxs("th", __assign({ onClick: function () { return handleSetSort(headCell.id); }, className: "w-44 border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: [headCell.label, " ", sortIcon] }), key));
                            }
                            else if (headCell.id === 'type') {
                                return (_jsxs("th", __assign({ onClick: function () { return handleSetSort(headCell.id); }, className: "border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: [headCell.label, " ", sortIcon] }), key));
                            }
                            return (_jsxs("th", __assign({ onClick: function () { return handleSetSort(headCell.id); }, className: "border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: [headCell.label, " ", sortIcon] }), key));
                        }
                        return (_jsx("th", __assign({ className: "border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: headCell.label }), key));
                    }) })) }), _jsx("tbody", { children: materials.map(function (material, index) {
                    return (_jsx("tr", __assign({ style: { height: '60px' }, className: "text-sm relative hover:bg-gray-100 cursor-pointer", onClick: function () {
                            setSelectedMaterial(material);
                        } }, { children: headCells.map(function (headCell, key) {
                            var content = headCell.content
                                ? headCell.content(material)
                                : '';
                            return (_jsx("td", __assign({ className: "px-4 py-4 text-left border-b border-gray-300 cursor-pointer" }, { children: content }), key));
                        }) }), index));
                }) })] })));
};
export default TableDiv;
