import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSession } from '../reducers/userSlice';
import CreateAccount from '../components/client/CreateAccount';
var Home = function () {
    var session = useSelector(function (state) { return state.user.session; });
    var dispatch = useDispatch();
    var navigate = useNavigate();
    if (session !== null) {
        dispatch(setSession(null));
        navigate('/', { replace: true });
    }
    return _jsx(CreateAccount, {});
};
export default Home;
