var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Translate } from '../../../translation/Translate';
import Moment from 'react-moment';
import { moduleTotalSessions } from '../../../../config/periodSessions';
var PeriodInfo = function (_a) {
    var _b;
    var period = _a.period, index = _a.index, started = _a.started, validUntil = _a.validUntil, moduleName = _a.moduleName, category = _a.category, sessionsDone = _a.sessionsDone, sessions = _a.sessions, zone = _a.zone;
    var periodTranslate = Translate({ term: 'nav.period' });
    var periodTimeTranslate = Translate({ term: 'periods.period_time' });
    var zoneTranslate = Translate({ term: 'zone.zone' });
    var moduleId = (_b = period.module) === null || _b === void 0 ? void 0 : _b._id;
    var currentModuleMaxSessions = moduleTotalSessions[moduleId];
    return (_jsxs("div", __assign({ className: "flex flex-row justify-start gap-8 my-5" }, { children: [_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("p", { children: [periodTranslate, " ", index + 1] }), _jsxs("div", __assign({ className: "mt-5 relative mt-2 text-gray-600" }, { children: [_jsxs("span", { children: [periodTimeTranslate, ": "] }), _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: started })), _jsx("span", __assign({ className: "mx-1" }, { children: "-" })), _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: validUntil }))] }))] })), _jsxs("div", __assign({ className: "flex flex-col text-gray-600" }, { children: [_jsx("p", { children: moduleName }), _jsx("p", { children: category }), _jsxs("p", { children: [Translate({ term: 'home.phase' }), ": ", sessionsDone, "/", currentModuleMaxSessions] }), _jsxs("div", { children: [zoneTranslate, ": ", zone] })] }))] })));
};
export default PeriodInfo;
