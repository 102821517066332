var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from '../therapist/Home';
import TherapistLayout from '../../components/layouts/TherapistLayout';
import Periods from '../therapist/Periods';
import Period from '../therapist/Period';
import StartQuestioinnares from '../therapist/StartQuestioinnaires';
import EndReports from '../therapist/EndReports';
import PeriodHistory from '../therapist/PeriodHistory';
import Evaluations from '../therapist/Evaluations';
import Evaluation from '../therapist/Evaluation';
import Materials from '../therapist/Materials';
import Plan from '../therapist/Plan';
import Messages from '../therapist/Messages';
import Links from '../therapist/Links';
import ShareLicence from '../common/ShareLicense';
import Profile from '../therapist/Profile';
import Logout from '../Logout';
import Query from '../therapist/Query';
import Material from '../therapist/Material';
import HelpChat from '../therapist/HelpChat';
import PatientPeriods from '../therapist/PatientPeriods';
export var TherapistRoutes = function () {
    return (_jsx(Suspense, __assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(Routes, { children: _jsxs(Route, __assign({ path: "/", element: _jsx(TherapistLayout, {}) }, { children: [_jsx(Route, { index: true, element: _jsx(Home, {}) }), _jsx(Route, { path: "/periods", element: _jsx(Periods, {}) }), _jsx(Route, { path: "/periods/:id", element: _jsx(Period, {}) }), _jsx(Route, { path: "/patient/:id/periods", element: _jsx(PatientPeriods, {}) }), _jsx(Route, { path: "/periods/start-questioinnaires", element: _jsx(StartQuestioinnares, {}) }), _jsx(Route, { path: "/periods/end-reports", element: _jsx(EndReports, {}) }), _jsx(Route, { path: "/periods/history", element: _jsx(PeriodHistory, {}) }), _jsx(Route, { path: "/evaluations", element: _jsx(Evaluations, {}) }), _jsx(Route, { path: "/evaluations/:id", element: _jsx(Evaluation, {}) }), _jsx(Route, { path: "/materials", element: _jsx(Materials, {}) }), _jsx(Route, { path: "/materials/:id", element: _jsx(Material, {}) }), _jsx(Route, { path: "/materials/plan", element: _jsx(Plan, {}) }), _jsx(Route, { path: "/messages", element: _jsx(Messages, {}) }), _jsx(Route, { path: "/links", element: _jsx(Links, {}) }), _jsx(Route, { path: "/share-license", element: _jsx(ShareLicence, {}) }), _jsx(Route, { path: "/query", element: _jsx(Query, {}) }), _jsx(Route, { path: "/help-chat", element: _jsx(HelpChat, {}) }), _jsx(Route, { path: "/profile", element: _jsx(Profile, {}) }), _jsx(Route, { path: "/logout", element: _jsx(Logout, {}) }), _jsx(Route, { path: "*", element: _jsx(NoMatch, {}) })] })) }) })));
};
function NoMatch() {
    return (_jsxs("div", { children: [_jsx("h2", { children: "Nothing to see here!" }), _jsx("p", { children: _jsx(Link, __assign({ to: "/" }, { children: "Go to the home page" })) })] }));
}
