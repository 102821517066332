var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import CustomerServiceTitle from '../../components/client/CustomerService/CustomerServiceTitle';
import ContactInfo from '../../components/client/CustomerService/ContactInfo';
import PracticeBooks from '../../components/client/CustomerService/PracticeBooks';
import Links from '../../components/client/CustomerService/Links';
var CustomerService = function () {
    return (_jsx(React.Fragment, { children: _jsx("main", __assign({ className: "px-3 sm:px-8 sm:py-4 sm:mt-0" }, { children: _jsx("div", __assign({ className: "max-w-4xl mx-auto" }, { children: _jsxs("div", { children: [_jsx(CustomerServiceTitle, {}), _jsxs("div", __assign({ className: "grid gap-6 mt-6" }, { children: [_jsx(ContactInfo, {}), _jsxs("div", __assign({ className: "flex flex-col gap-6 mb-4 lg:flex-row lg:justify-between" }, { children: [_jsx(PracticeBooks, {}), _jsx(Links, {})] }))] }))] }) })) })) }));
};
export default CustomerService;
