/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createEntityAdapter, } from '@reduxjs/toolkit';
var adminQueryAdapter = createEntityAdapter();
var initialState = adminQueryAdapter.getInitialState({
    errors: [],
});
var slice = createSlice({
    name: 'apiError',
    initialState: initialState,
    reducers: {
        setApiError: function (state, action) {
            state.errors = action.payload;
        },
    },
});
export var setApiError = slice.actions.setApiError;
export default slice;
