var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { PERIOD_MATERIALS, THERAPY_MODULE_TYPES } from '../../common/enums';
export default function AfterPeriodInfo(_a) {
    var moduleId = _a.moduleId;
    var heading = "";
    var content = _jsx(_Fragment, {});
    function createMaterialLink(materialId) {
        return "/materials/".concat(materialId);
    }
    switch (moduleId) {
        case THERAPY_MODULE_TYPES.ÄÄNNEVIRHEET:
            heading = 'Jatkoharjoitteluohjeet äännevirheisiin';
            content = (_jsxs(_Fragment, { children: [_jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.K_ÄÄNNE) }, { children: "K-\u00E4\u00E4nne" })), _jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.R_ÄÄNNE) }, { children: "R-\u00E4\u00E4nne" })), _jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.L_ÄÄNNE) }, { children: "L-\u00E4\u00E4nne" })), _jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.S_ÄÄNNE) }, { children: "S-\u00E4\u00E4nne" }))] }));
            break;
        case THERAPY_MODULE_TYPES.SANAVARASTO:
            heading = 'Jatkoharjoitteluohjeet sanavarastoon';
            content = (_jsx(Link, __assign({ className: "underline", to: createMaterialLink(PERIOD_MATERIALS.SANAVARASTO) }, { children: "Sanavarastoon" })));
            break;
    }
    return (_jsxs("div", __assign({ className: "flex flex-col gap-3 pb-3" }, { children: [_jsxs("h4", __assign({ className: "font-bold" }, { children: [heading, ":"] })), content] })));
}
