var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Pagination } from '../../common/Pagination';
import { MaterialSummary } from '../../common/MaterialSummary';
import { Translate } from '../../translation/Translate';
var MaterialList = function (_a) {
    var materials = _a.materials, activeTotalCount = _a.activeTotalCount, activePage = _a.activePage, activeMaxPage = _a.activeMaxPage, switchPage = _a.switchPage, periodId = _a.periodId;
    return (_jsx("div", __assign({ className: "py-4 px-8" }, { children: _jsxs("div", __assign({ className: "bg-lightBlue pt-4 p-8 rounded-md shadow-md mt-6" }, { children: [_jsxs("p", { children: [activeTotalCount, ' ', Translate({ term: 'material_library.search_results' })] }), _jsx("div", __assign({ className: "grid gap-6 lg:grid-cols-2 2xl:grid-cols-3 mt-4" }, { children: materials &&
                        materials.list &&
                        materials.list.map(function (material, key) {
                            return (_jsx(MaterialSummary, { material: material, periodId: periodId }, key));
                        }) })), _jsx(Pagination, { activePage: activePage, maxPage: activeMaxPage, switchPage: switchPage })] })) })));
};
export default MaterialList;
