var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress } from '@mui/material';
import { theme } from '../../../../tailwind.config';
export default function ProgressBar(_a) {
    var percentage = _a.percentage, message = _a.message;
    var roundedPercentage = Math.round(percentage);
    return (_jsxs("div", __assign({ className: "flex flex-col gap-3 p-4 items-center" }, { children: [message && _jsx("span", { children: message }), _jsxs("div", __assign({ className: "relative w-max h-max flex flex-row jusfify-center items-center" }, { children: [_jsx(CircularProgress, { sx: { color: theme.colors.gray[300] }, className: "absolute", variant: "determinate", value: 100, size: 60 }), _jsx(CircularProgress, { variant: "determinate", value: roundedPercentage, size: 60 }), _jsx("div", __assign({ className: "h-full w-full absolute top-0 left-0 flex flex-row justify-center items-center" }, { children: _jsxs("span", __assign({ style: { transform: 'translateY(5%)' }, className: "text-sm" }, { children: [roundedPercentage, "%"] })) }))] }))] })));
}
