var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Moment from 'react-moment';
import { Translate } from '../../translation/Translate';
var VideoMaterial = function (_a) {
    var material = _a.material;
    return (_jsxs("div", { children: [_jsxs("h2", __assign({ className: "text-blue" }, { children: [_jsx(OndemandVideoIcon, {}), " ", material.name] })), _jsx("div", { children: material.url !== undefined && (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("div", __assign({ className: "mt-2" }, { children: _jsxs("p", __assign({ className: "text-sm text-gray-500" }, { children: [Translate({ term: 'common.last_modified' }), ' ', _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: material.modified || material.created }))] })) })), _jsx("div", __assign({ className: "max-w-3xl" }, { children: _jsx("div", __assign({ style: { padding: "59.25% 0 0 0", position: "relative" } }, { children: _jsx("iframe", { className: "absolute top-0 left-0 w-full h-full", src: material.url, allow: "autoplay; fullscreen; picture-in-picture", allowFullScreen: true }) })) }))] }))) })] }));
};
export default VideoMaterial;
