var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Avatar from '../common/Avatar';
import { Translate } from '../../components/translation/Translate';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getSortParam } from './utils/SortTablesByTherapyType';
import { countUnreadMsgForPeriod } from './utils/CountTablesFunctions';
import { Chip } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { moduleTotalSessions } from '../../config/periodSessions';
var PeriodsTable = function (_a) {
    var periods = _a.periods, columns = _a.columns, setSortParams = _a.setSortParams;
    var userinfo = useSelector(function (state) { return state.user.userinfo; });
    var _b = __read(React.useState(null), 2), sort = _b[0], setSort = _b[1];
    var _c = __read(React.useState(false), 2), reverse = _c[0], setReverse = _c[1];
    var dateTimeTranslate = Translate({ term: 'common.date_time_format' });
    var nameTranslate = Translate({ term: 'home.client' });
    var contentTranslate = Translate({ term: 'home.content' });
    var phaseTranslate = Translate({ term: 'home.phase' });
    var lastMessageTranslate = Translate({ term: 'home.last_message' });
    var noMessageTranslate = Translate({ term: 'messages.no_messages' });
    var sentTimeTranslate = Translate({ term: 'home.sent_time' });
    var startQuestinnaireDoneTranslate = Translate({
        term: 'period.start_questionnaire_done',
    });
    var navigate = useNavigate();
    function handleSetSort(id) {
        if (sort && id === sort) {
            setReverse(!reverse);
        }
        else {
            setSort(id);
            setReverse(!reverse);
        }
        if (setSortParams) {
            setSortParams({
                sort: getSortParam(id),
                order: reverse ? 'asc' : 'desc',
            });
        }
    }
    function getModuleName(period) {
        if (period.module.name) {
            return period.module.name.fi || period.module.name.se;
        }
        else {
            return 'Ei moduulin nimeä';
        }
    }
    var headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: nameTranslate,
            content: function (period) {
                var _a, _b, _c, _d, _e, _f;
                return (_jsxs("div", __assign({ className: "flex flex-row" }, { children: [_jsx(Avatar, { firstName: (_b = (_a = period.patient) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : '', lastName: (_d = (_c = period.patient) === null || _c === void 0 ? void 0 : _c.lastName) !== null && _d !== void 0 ? _d : '', image: (_f = (_e = period.patient) === null || _e === void 0 ? void 0 : _e.profileImageUrl) !== null && _f !== void 0 ? _f : (_jsx(PersonOutlineOutlinedIcon, { style: { width: '100%', height: '100%' } })) }), countUnreadMsgForPeriod(period, userinfo) > 0 && (_jsx(Chip, { style: {
                                cursor: 'pointer',
                                marginLeft: '10px',
                                marginTop: '7px',
                            }, color: 'primary', label: countUnreadMsgForPeriod(period, userinfo), size: "small" }))] })));
            },
        },
        {
            id: 'content',
            numeric: false,
            disablePadding: false,
            label: contentTranslate,
            content: function (period) { return getModuleName(period); },
        },
        {
            id: 'phase',
            numeric: true,
            disablePadding: false,
            label: phaseTranslate,
            content: function (period) {
                var _a, _b;
                var moduleId = (_a = period.module) === null || _a === void 0 ? void 0 : _a._id;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                var currentModuleMaxSessions = moduleTotalSessions[moduleId]; // || 12;
                return (_jsx("p", { children: "".concat((_b = period.sessions) !== null && _b !== void 0 ? _b : '-', " / ").concat(currentModuleMaxSessions) }));
            },
            align: 'right',
        },
        {
            id: 'last_message',
            numeric: true,
            disablePadding: false,
            label: lastMessageTranslate,
            content: function (period) {
                if (period.messages && period.messages.length > 0) {
                    var messages = __spreadArray([], __read(period.messages), false).reverse();
                    return (_jsx(Moment, __assign({ format: dateTimeTranslate }, { children: messages.length > 0 ? messages[0].created : '' })));
                }
                return noMessageTranslate;
            },
            align: 'right',
        },
        {
            id: 'unread_received',
            numeric: true,
            disablePadding: false,
            label: sentTimeTranslate,
            content: function (period) {
                var _a;
                if (period.messages && ((_a = period.messages) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    var messages = __spreadArray([], __read(period.messages), false).reverse();
                    return (_jsx(Moment, __assign({ format: dateTimeTranslate }, { children: messages.length > 0 ? messages[0].created : '' })));
                }
                return _jsx("div", { className: "truncate max-w-xs" });
            },
        },
        {
            id: 'start_questionnaire_done',
            numeric: true,
            disablePadding: false,
            label: startQuestinnaireDoneTranslate,
            content: function (period) {
                var _a, _b;
                return ((_a = period === null || period === void 0 ? void 0 : period.startQuestionnaire) === null || _a === void 0 ? void 0 : _a.filledOut) ? (_jsx(Moment, __assign({ format: dateTimeTranslate }, { children: (_b = period === null || period === void 0 ? void 0 : period.startQuestionnaire) === null || _b === void 0 ? void 0 : _b.filledOut }))) : ('');
            },
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: '',
        },
    ];
    var selectPeriod = function (event, id) {
        navigate('/periods/' + id); //, { replace: true }
    };
    if (periods) {
        return (_jsxs("table", __assign({ className: "border-collapse bg-white rounded-md shadow-md w-full" }, { children: [_jsx("thead", { children: _jsx("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2" }, { children: headCells.map(function (headCell, key) {
                            if (headCell.id !== 'action' &&
                                (!columns || columns.includes(headCell.id))) {
                                var classes = [
                                    'px-4 py-4 text-left border-b border-gray-400 cursor-pointer',
                                ];
                                var aClasses = ['text-blue ml-1'];
                                if (sort && sort === headCell.id) {
                                    classes.push('');
                                    aClasses.push('opacity-100');
                                }
                                else {
                                    aClasses.push('opacity-25');
                                }
                                var sortIcon = sort && sort === headCell.id && reverse === true ? (_jsx(ArrowUpwardIcon, { className: aClasses.join(' '), fontSize: "small" })) : (_jsx(ArrowDownwardIcon, { className: aClasses.join(' '), fontSize: "small" }));
                                return (_jsxs("th", __assign({ className: classes.join(' '), onClick: function () { return handleSetSort(headCell.id); } }, { children: [headCell.label, sortIcon] }), key));
                            }
                        }) })) }), _jsx("tbody", { children: periods.map(function (period, key) {
                        return (_jsx("tr", __assign({ className: "text-sm relative hover:bg-gray-100 cursor-pointer", onClick: function (event) { return selectPeriod(event, period._id); } }, { children: headCells.map(function (headCell, cellKey) {
                                if (headCell.id !== 'action' &&
                                    (!columns || columns.includes(headCell.id))) {
                                    var tProps = {};
                                    var content = headCell.content
                                        ? headCell.content(period)
                                        : '';
                                    if (headCell.align)
                                        tProps = __assign(__assign({}, tProps), { align: headCell.align });
                                    return (_jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300 cursor-pointer" }, { children: content }), cellKey));
                                }
                                return null;
                            }) }), key));
                    }) })] })));
    }
    else {
        return null;
    }
};
export default PeriodsTable;
