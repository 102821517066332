import userSlice from './userSlice';
import periodSlice from './periodSlice';
import settingsSlice from './settingsSlice';
import periodMaterialsSlice from './periodMaterialsSlice';
import evaluationSlice from './evaluationSlice';
import licenseSlice from './licenseSlice';
import PostSlice from './postSlice';
import TherapistSlice from './therapistSlice';
import AdminQuerySlice from './adminQuerySlice';
import AdminLicenseSlice from './adminLicenseSlice';
import ApiErrorSlice from './apiErrorSlice';
import AdminPeriodsSlice from './adminPeriodsSlice';
import AdminMaterialsSlice from './adminMaterialsSlice';
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import PatientSlice from './patientSlice';
var appReducer = persistCombineReducers({
    key: 'root',
    storage: storage,
}, {
    user: userSlice.reducer,
    periods: periodSlice.reducer,
    patients: PatientSlice.reducer,
    settings: settingsSlice.reducer,
    materials: periodMaterialsSlice.reducer,
    evaluations: evaluationSlice.reducer,
    licenses: licenseSlice.reducer,
    posts: PostSlice.reducer,
    therapist: TherapistSlice.reducer,
    adminQuery: AdminQuerySlice.reducer,
    adminLicense: AdminLicenseSlice.reducer,
    adminPeriods: AdminPeriodsSlice.reducer,
    adminMaterials: AdminMaterialsSlice.reducer,
    apiError: ApiErrorSlice.reducer,
});
var rootReducer = function (state, action) {
    return appReducer(state, action);
};
export default rootReducer;
