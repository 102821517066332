var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Survey } from 'survey-react-ui';
import { SurveyPDF } from 'survey-pdf';
import { StylesManager, Model } from 'survey-core';
import './survey.css';
import BasicModal from './BasicModal';
function saveSurveyToPdf(filename, answers, questions) {
    var options = {
        fontSize: 14,
        margins: {
            left: 10,
            right: 10,
            top: 10,
            bot: 10,
        },
        format: [210, 297],
    };
    var pdf = new SurveyPDF(questions, options);
    pdf.data = answers;
    pdf.mode = 'display';
    pdf.save(filename);
}
var JsSurveyModal = function (_a) {
    var questionnairesName = _a.questionnairesName, answers = _a.answers, questions = _a.questions, openQuestionnaire = _a.openQuestionnaire, setOpenQuestionnaire = _a.setOpenQuestionnaire;
    questionnairesName = questionnairesName.replace(/\s/g, '');
    questionnairesName = questionnairesName.toLowerCase();
    StylesManager.applyTheme('modern');
    var survey = new Model(questions);
    survey.data = answers || '';
    survey.mode = 'display';
    survey.onUpdateChoiceItemCss.add(function (sender, options) {
        if (options.item.value < 0 &&
            options.css.includes('sv-checkbox--checked')) {
            options.css += ' negative-value';
        }
        if (options.item.value > 0 &&
            options.css.includes('sv-checkbox--checked')) {
            options.css += ' positive-value';
        }
    });
    return (_jsx(BasicModal, __assign({ open: openQuestionnaire, handleClose: function () { return setOpenQuestionnaire(false); } }, { children: _jsxs("div", { children: [_jsx("div", __assign({ className: "flex" }, { children: _jsx("div", __assign({ className: "flex-none" }, { children: _jsxs("button", __assign({ className: "mr-10", onClick: function () {
                                return saveSurveyToPdf(questionnairesName, answers, questions);
                            } }, { children: [questionnairesName, ".pdf"] })) })) })), _jsx("div", __assign({ className: "max-h-96 overflow-y-scroll survey-container" }, { children: _jsx(Survey, { model: survey }) }))] }) })));
};
export default JsSurveyModal;
