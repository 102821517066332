var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from '../../translation/Translate';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { HOME_URL } from '../../../config/constants';
var Links = function () {
    return (_jsxs("div", __assign({ className: "bg-white border border-cyan py-3 px-2 sm:p-5 rounded-md shadow-lg" }, { children: [_jsx("h3", __assign({ className: "font-semibold text-lg text-grey-800" }, { children: Translate({ term: 'customer_service.links' }) })), _jsxs("div", __assign({ className: "ml-2 lg:ml-0 mt-2" }, { children: [_jsxs("p", { children: [Translate({ term: 'customer_service.blog' }), ' ', _jsx("a", __assign({ href: "".concat(HOME_URL, "/blogi/"), target: "_blank", rel: "noreferrer" }, { children: _jsx(ArrowCircleRightOutlinedIcon, { style: { backgroundColor: '#FFFFFF', fill: '#133964' } }) }))] }), _jsxs("p", { children: [Translate({ term: 'customer_service.facebook_site' }), ' ', _jsx("a", __assign({ href: "https://www.facebook.com/aannekoulu/", target: "_blank", rel: "noreferrer" }, { children: _jsx(ArrowCircleRightOutlinedIcon, { style: { backgroundColor: '#FFFFFF', fill: '#133964' } }) }))] })] }))] })));
};
export default Links;
