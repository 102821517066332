var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import { Modal } from '@mui/material';
import { Translate } from '../../../translation/Translate';
import { useEffect, useState } from 'react';
import PeriodViewWith2Guardians from './PeriodViewWith2Guardians';
import AddNewGuardianView from './AddNewGuardianView';
import GuardianPeriodView from './GuardianPeriodView';
import ActionButtons from './ActionButtons';
import PatientInfo from './PatientInfo';
import { addAnotherGuardianToPeriod, getPeriodsFromPatientForAdmin, } from '../../../../actions/periodActions';
import './PeriodModalStyle.css';
import PeriodInfo from './PeriodInfo';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../common/toastStyle.css';
import useToast from '../../../../hooks/useToast';
var PeriodModal = function (_a) {
    var selectedPeriod = _a.selectedPeriod, setSelectedPeriod = _a.setSelectedPeriod, patient = _a.patient, guardians = _a.guardians, session = _a.session;
    var _b = __read(useState(false), 2), expanded = _b[0], setExpanded = _b[1];
    var _c = __read(useState(''), 2), firstName = _c[0], setFirstName = _c[1];
    var _d = __read(useState(''), 2), lastName = _d[0], setLastName = _d[1];
    var _e = __read(useState(''), 2), phone = _e[0], setPhone = _e[1];
    var _f = __read(useState(''), 2), email = _f[0], setEmail = _f[1];
    var _g = __read(useState(''), 2), emailVerify = _g[0], setEmailVerify = _g[1];
    var _h = __read(useState([]), 2), endedPeriods = _h[0], setEndedPeriods = _h[1];
    var patientId = selectedPeriod.patient ? selectedPeriod.patient._id : '';
    var isActivePeriod = selectedPeriod.endReport && selectedPeriod.endReport.date ? false : true;
    var activePeriodTranslate = Translate({ term: 'period.active_period' });
    var endedPeriodsTranslate = Translate({ term: 'period.ended_periods' });
    var newGuardianAdded = Translate({ term: 'account.new_guardian_added' });
    var _j = __read(useState(''), 2), infoText = _j[0], setInfoText = _j[1];
    var fillFormTranslate = Translate({ term: 'common.fill_form' });
    useEffect(function () {
        if (patientId !== '' && !isActivePeriod) {
            getPatientPeriods();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var getPatientPeriods = function () { return __awaiter(void 0, void 0, void 0, function () {
        var endedPeriodsData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getPeriodsFromPatientForAdmin(patientId, session)];
                case 1:
                    endedPeriodsData = _a.sent();
                    if (endedPeriodsData) {
                        setEndedPeriods(endedPeriodsData);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var saveNewGuardian = function () {
        if (firstName === '' ||
            lastName === '' ||
            phone === '' ||
            email === '' ||
            email !== emailVerify) {
            setInfoText(fillFormTranslate);
        }
        else {
            var filterData = {
                periodId: selectedPeriod._id,
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                email: email,
            };
            addAnotherGuardianToPeriod(filterData, session).then(function (data) {
                if (data) {
                    // TODO: get correct infos and translate
                    if (data === 'Sähköposti on jo käytössä') {
                        setInfoText('Sähköposti on jo käytössä');
                    }
                    else {
                        showToastMessage(newGuardianAdded);
                    }
                }
            }).catch(function (err) {
                //console.error(err);
            });
        }
    };
    var cancelNewGuardian = function () {
        setExpanded(false);
        setInfoText('');
        setEmailVerify('');
        setFirstName('');
        setLastName('');
        setPhone('');
        setEmail('');
    };
    var showToastMessage = useToast({
        onClose: function () {
            cancelNewGuardian();
            setSelectedPeriod(null);
        },
    }).showToastMessage;
    return (_jsx(Modal, __assign({ open: selectedPeriod !== null, onClose: function () {
            cancelNewGuardian();
            setSelectedPeriod(null);
        }, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", disableAutoFocus: true }, { children: _jsxs("div", __assign({ className: "absolute table z-10 px-5 pb-5 pt-2 bg-white border rounded-md top-1/2 left-1/2", style: expanded || (guardians && guardians.length >= 2)
                ? {
                    width: '1000px',
                    height: 'auto',
                    transform: 'translate(-50%, -50%',
                }
                : {
                    width: '600px',
                    height: 'auto',
                    transform: 'translate(-50%, -50%',
                } }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx(PatientInfo, { patient: patient, expanded: expanded || (guardians && guardians.length === 2) ? true : false, setSelectedPeriod: setSelectedPeriod }), _jsxs("div", __assign({ className: "mt-2" }, { children: [guardians && guardians.length >= 2 ? (_jsx(PeriodViewWith2Guardians, { guardian1: guardians[0], guardian2: guardians[1] })) : null, guardians && guardians.length === 1 && expanded ? (_jsx(AddNewGuardianView, { guardians: guardians, firstName: firstName, lastName: lastName, phone: phone, email: email, emailVerify: emailVerify, setFirstName: setFirstName, setLastName: setLastName, setPhone: setPhone, setEmail: setEmail, setEmailVerify: setEmailVerify })) : null, guardians && guardians.length === 1 && !expanded ? (_jsx(GuardianPeriodView, { guardians: guardians })) : null, _jsx("p", __assign({ className: "flex justify-end text-warning-600 mr-4 mt-2" }, { children: infoText })), guardians && guardians.length < 2 && isActivePeriod ? (_jsx(ActionButtons, { expanded: expanded, setExpanded: setExpanded, saveNewGuardian: saveNewGuardian, cancelNewGuardian: cancelNewGuardian })) : null] })), isActivePeriod ? (_jsxs("div", { children: [_jsx("div", __assign({ className: "periodline" }, { children: activePeriodTranslate })), _jsx(PeriodInfo, { index: 0, started: selectedPeriod.started ? selectedPeriod.started : '-', validUntil: selectedPeriod.validUntil ? selectedPeriod.validUntil : '-', moduleName: selectedPeriod.module && selectedPeriod.module.name
                                        ? selectedPeriod.module.name.fi ||
                                            selectedPeriod.module.name.se
                                        : '-', category: selectedPeriod.module &&
                                        selectedPeriod.module.categories &&
                                        selectedPeriod.module.categories.length > 0
                                        ? selectedPeriod.module.categories[0].fi ||
                                            selectedPeriod.module.categories[0].se
                                        : '-', sessionsDone: selectedPeriod.sessions ? selectedPeriod.sessions : '-', sessions: selectedPeriod.module && selectedPeriod.module.sessions
                                        ? selectedPeriod.module.sessions
                                        : '-', zone: selectedPeriod.zone ? selectedPeriod.zone.name : '-', period: selectedPeriod })] })) : (_jsxs("div", { children: [_jsx("div", __assign({ className: "periodline" }, { children: endedPeriodsTranslate })), endedPeriods.map(function (period, index) {
                                    // TODO: is there a limit how many ended periods we want to show here ? now its last 3 ended periods
                                    return index < 3 ? (_jsx(PeriodInfo, { index: index, started: period.started ? period.started : '-', validUntil: period.validUntil ? period.validUntil : '-', moduleName: period.module && period.module.name
                                            ? period.module.name.fi || period.module.name.se
                                            : '-', category: period.module &&
                                            period.module.categories &&
                                            period.module.categories.length > 0
                                            ? period.module.categories[0].fi ||
                                                period.module.categories[0].se
                                            : '-', sessionsDone: period.sessions ? period.sessions : '-', sessions: period.module && period.module.sessions
                                            ? period.module.sessions
                                            : '-', zone: period.zone ? period.zone.name : '-', period: period }, index)) : null;
                                })] }))] }))] })) })));
};
export default PeriodModal;
