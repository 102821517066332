var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PrimaryButton from '../../../common/buttons/primaryButton';
import SecondaryButton from '../../../common/buttons/secondaryButton';
import { Translate } from '../../../translation/Translate';
import AddAttachmentButton from './addAttachmentButton';
import MessageEditorAttachments from './messageEditorAttachments';
import CircularProgress from '@mui/material/CircularProgress';
var ChatMessageEditor = function (_a) {
    var sendChatMessage = _a.sendChatMessage, text = _a.text, setText = _a.setText, attachmentList = _a.attachmentList, setAttachmentList = _a.setAttachmentList, clearMessageEditor = _a.clearMessageEditor, loading = _a.loading;
    var writeMessageTranslation = Translate({ term: 'messages.write_message' });
    var cancelTranslation = Translate({ term: 'actions.cancel' });
    var sendTranslation = Translate({ term: 'actions.send' });
    return (_jsxs("div", __assign({ className: "flex flex-col bg-white py-4 px-8 rounded-md border border-gray-200" }, { children: [loading && (_jsx("div", __assign({ className: "pt-3 flex justify-center items-center gap-2" }, { children: _jsx(CircularProgress, {}) }))), _jsxs("div", { children: [_jsx("label", { children: writeMessageTranslation }), _jsx("textarea", { value: text, onChange: function (event) { return setText(event.target.value); }, className: "w-full border border-gray-300 rounded-md p-2 text-base" })] }), attachmentList.length > 0 && (_jsx(MessageEditorAttachments, { attachmentList: attachmentList, setAttachmentList: setAttachmentList })), _jsxs("div", __assign({ className: "flex flex-row justify-between my-1 md:my-3" }, { children: [_jsx(AddAttachmentButton, { attachmentList: attachmentList, setAttachmentList: setAttachmentList }), _jsxs("div", __assign({ className: "flex flex-row gap-4" }, { children: [_jsx(SecondaryButton, { text: cancelTranslation, action: clearMessageEditor }), _jsx(PrimaryButton, { text: sendTranslation, action: function () { return sendChatMessage(text, attachmentList); } })] }))] }))] })));
};
export default ChatMessageEditor;
