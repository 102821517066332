var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import BasicModal from '../../common/modals/BasicModal';
import ConfirmationModal from '../../common/modals/ConfirmationModal';
import SelectLanguage from '../../common/SelectLanguage';
import { Translate } from '../../translation/Translate';
var DefaultMessagesModal = function (_a) {
    var deactivateMessage = _a.deactivateMessage, activeMessage = _a.activeMessage, editorInputs = _a.editorInputs, selectedLanguage = _a.selectedLanguage, setSelectedLanguage = _a.setSelectedLanguage, deleteMessage = _a.deleteMessage, saveMessage = _a.saveMessage, openConfirmationModal = _a.openConfirmationModal, setOpenConfirmationModal = _a.setOpenConfirmationModal;
    var profileNameTranslate = Translate({ term: 'profile.name' });
    var messageTemplateTranslate = Translate({ term: 'messages.template' });
    var deleteTranslate = Translate({ term: 'actions.delete' });
    var saveTranslate = Translate({ term: 'actions.save' });
    var deleteDefaultMessageTranslate = Translate({
        term: 'actions.delete_defaultMessage',
    });
    useEffect(function () {
        if (activeMessage !== undefined) {
            setSelectedLanguage((activeMessage === null || activeMessage === void 0 ? void 0 : activeMessage.language) || 'fi');
        }
    }, [activeMessage, setSelectedLanguage]);
    return (_jsxs("div", { children: [_jsxs(BasicModal, __assign({ handleClose: function () { return deactivateMessage(); }, open: activeMessage !== undefined }, { children: [_jsxs("div", __assign({ className: "block text-sm py-2" }, { children: [_jsx("label", { children: profileNameTranslate }), _jsx("input", { type: "text", ref: editorInputs.name, defaultValue: activeMessage === null || activeMessage === void 0 ? void 0 : activeMessage.name, className: "text-base mt-1 w-full block" })] })), _jsxs("div", __assign({ className: "block text-sm py-2" }, { children: [_jsx("label", { children: messageTemplateTranslate }), _jsx("textarea", { rows: 8, ref: editorInputs.message, defaultValue: activeMessage === null || activeMessage === void 0 ? void 0 : activeMessage.message, className: "text-base mt-1 block bg-white border border-gray-300 rounded-md px-2 py-1 w-full" })] })), _jsx(SelectLanguage, { selectedLanguage: selectedLanguage, setSelectedLanguage: setSelectedLanguage, style: 'block text-sm py-2' }), activeMessage && !activeMessage.generalDefaultMessage && (_jsxs("div", __assign({ className: "grid grid-cols-2 mt-4" }, { children: [_jsx("div", { children: _jsx("button", __assign({ hidden: !(activeMessage && activeMessage.name), onClick: function () { return setOpenConfirmationModal(true); }, className: "bg-lightBlue text-blue" }, { children: deleteTranslate })) }), _jsx("div", __assign({ className: "text-right" }, { children: _jsx("button", __assign({ onClick: saveMessage }, { children: saveTranslate })) }))] })))] })), _jsx(ConfirmationModal, { headerText: deleteDefaultMessageTranslate, opentConfirmationModal: openConfirmationModal, setOpenConfirmationModal: setOpenConfirmationModal, actionFunction: deleteMessage })] }));
};
export default DefaultMessagesModal;
