var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MarkChatReadOutlined } from '@mui/icons-material';
var PeriodDivider = function (_a) {
    var type = _a.type, text = _a.text, action = _a.action;
    var messageClass = 'px-4 py-1 bg-white border rounded-md flex-none';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var messageContent = text;
    if (action)
        messageClass += ' cursor-pointer';
    if (type === 'completedSession') {
        messageClass += ' border-success-700';
    }
    else if (type === 'completeSession') {
        messageClass += ' border-warning-600';
        messageContent = (_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx("div", __assign({ className: "text-warning-600 text-xl self-center" }, { children: "+" })), _jsx("div", __assign({ className: "self-center" }, { children: text }))] })));
    }
    else if (type === 'message') {
        messageClass += ' border-warning-600';
        messageContent = (_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx("div", __assign({ className: "text-warning-600 text-xl self-center" }, { children: _jsx(MarkChatReadOutlined, {}) })), _jsx("div", __assign({ className: "self-center" }, { children: text }))] })));
    }
    else {
        messageClass += ' border-gray-700';
    }
    return (_jsxs("div", __assign({ className: "my-6 text-center flex items-center gap-3" }, { children: [_jsx("div", { className: "bg-gray-400 h-px flex-auto" }), _jsx("div", __assign({ className: messageClass, onClick: function () { return (action ? action() : null); } }, { children: messageContent })), _jsx("div", { className: "bg-gray-400 h-px flex-auto" })] })));
};
export default PeriodDivider;
