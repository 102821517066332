var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Moment from 'react-moment';
import { deleteInvitation } from '../../../actions/invitationActions';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '../../common/Avatar';
import { Translate } from '../../translation/Translate';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getEvaluationInvitations } from '../../../actions/invitationActions';
import { setEvaluationInvitations } from '../../../reducers/evaluationSlice';
import BasicModal from '../../common/modals/BasicModal';
import useUser from '../../../hooks/useUser';
var EvaluationInvitationsTable = function (_a) {
    var filters = _a.filters, columns = _a.columns;
    var dispatch = useDispatch();
    var evaluations = useSelector(function (state) { return state.evaluations.evaluationInvitations; });
    var user = useUser();
    var _b = __read(React.useState(null), 2), sort = _b[0], setSort = _b[1];
    var _c = __read(React.useState(false), 2), reverse = _c[0], setReverse = _c[1];
    var _d = __read(React.useState(false), 2), opentConfirmationModal = _d[0], setOpenConfirmationModal = _d[1];
    var _e = __read(React.useState(), 2), evaluationId = _e[0], setEvaluationId = _e[1];
    function handleSetSort(id) {
        if (sort && id === sort) {
            setReverse(!reverse);
        }
        else {
            setSort(id);
            setReverse(false);
        }
    }
    function loadEvaluationInvitations() {
        getEvaluationInvitations(user.session)
            .then(function (response) {
            if (response) {
                dispatch(setEvaluationInvitations(response));
            }
        })
            .catch(function (error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            //console.error(errorCode, errorMessage);
        });
    }
    var headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'home.client' }),
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'profile.email' }),
        },
        {
            id: 'phone',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'profile.phone' }),
        },
        {
            id: 'zone',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'zone.zone_name' }),
        },
        {
            id: 'validUntil',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'licenses.valid_until' }),
        },
        {
            id: 'removeEvaluationInvitation',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'evaluations.remove_reservation' }),
        },
    ];
    function askDeleteReservation(id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setEvaluationId(id);
                setOpenConfirmationModal(!opentConfirmationModal);
                return [2 /*return*/];
            });
        });
    }
    function deleteReservation(id) {
        return __awaiter(this, void 0, void 0, function () {
            var deleted;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setOpenConfirmationModal(false);
                        return [4 /*yield*/, deleteInvitation(id, user.session)];
                    case 1:
                        deleted = _a.sent();
                        if (deleted) {
                            loadEvaluationInvitations();
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    if (evaluations) {
        return (_jsxs("div", { children: [_jsxs("table", __assign({ className: "border-collapse bg-white shadow-md w-full" }, { children: [_jsx("thead", { children: _jsx("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2" }, { children: headCells.map(function (headCell, key) {
                                    if (headCell.id !== 'action' &&
                                        (!columns || columns.includes(headCell.id))) {
                                        var classes = [
                                            'px-4 py-4 text-left border-b border-gray-400 cursor-pointer',
                                        ];
                                        var aClasses = ['text-blue ml-1'];
                                        if (sort && sort === headCell.id) {
                                            classes.push('');
                                            aClasses.push('opacity-100');
                                        }
                                        else {
                                            aClasses.push('opacity-25');
                                        }
                                        var sortIcon = sort && sort === headCell.id && reverse === true ? (_jsx(ArrowUpwardIcon, { className: aClasses.join(' '), fontSize: "small" })) : (_jsx(ArrowDownwardIcon, { className: aClasses.join(' '), fontSize: "small" }));
                                        return (_jsxs("th", __assign({ className: classes.join(' '), onClick: function () { return handleSetSort(headCell.id); } }, { children: [headCell.label, sortIcon] }), key));
                                    }
                                }) })) }), _jsx("tbody", { children: evaluations.map(function (evaluation, key) {
                                var _a, _b, _c;
                                return (_jsxs("tr", __assign({ className: "text-sm hover:bg-gray-100" }, { children: [evaluation.patient.firstName ? (_jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: _jsx(Avatar, { firstName: (_a = evaluation.patient) === null || _a === void 0 ? void 0 : _a.firstName, lastName: (_b = evaluation.patient) === null || _b === void 0 ? void 0 : _b.lastName }) }), key + '1')) : (_jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: "Ei asiakkuutta" }), key + '1')), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: evaluation.email }), key + '2'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: evaluation.phone }), key + '3'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: (_c = evaluation.zone) === null || _c === void 0 ? void 0 : _c.name }), key + '4'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: evaluation.validUntil })) }), key + '5'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: _jsx("button", __assign({ onClick: function () { return askDeleteReservation(evaluation._id); } }, { children: Translate({ term: 'actions.delete' }) })) }), key + '6')] }), key));
                            }) })] })), _jsx(BasicModal, __assign({ open: opentConfirmationModal, handleClose: function () { return setOpenConfirmationModal(false); } }, { children: _jsx("div", __assign({ className: "flex justify-center" }, { children: _jsxs("div", __assign({ className: "flex-none" }, { children: [_jsx("h2", { children: Translate({ term: 'licenses.remove_license_confirmation' }) }), _jsxs("div", __assign({ className: "justify-items-center justify-center items-center whitespace-nowrap flex space-x-10 pt-4" }, { children: [_jsx("button", __assign({ onClick: function () { return deleteReservation(evaluationId); }, className: "bg-white hover:bg-blue duration-300 hover:text-white text-black border-2 border-blue rounded-md" }, { children: Translate({ term: 'common.yes' }) })), _jsx("button", __assign({ onClick: function () { return setOpenConfirmationModal(false); }, className: "bg-white hover:bg-blue duration-300 hover:text-white text-black border-2 border-blue rounded-md" }, { children: Translate({ term: 'common.no' }) }))] }))] })) })) }))] }));
    }
    else {
        return _jsx("div", { children: "loading" });
    }
};
export default EvaluationInvitationsTable;
