export var moduleTotalSessions = {
    '61f0ffc86fcb1a2901ede7f1': 10,
    '61f0fb706fcb1a2901ede7e6': 10,
    '61f1007f6fcb1a2901ede7f7': 10,
    '61f01cbc6fcb1a2901ede7e1': 12,
    '61f0fff06fcb1a2901ede7f3': 12,
    '61f1002e6fcb1a2901ede7f5': 12,
    '622202c82b8a5f60e7894027': 12,
    '61f0ff8f6fcb1a2901ede7ef': 12,
    '61f0fbb76fcb1a2901ede7e8': 12,
    '61f0ff3b6fcb1a2901ede7ed': 12,
    '6221fb122b8a5f60e7893d12': 12,
    '6221fb252b8a5f60e7893d1d': 12,
    '6221fdb42b8a5f60e7893e1d': 12,
    '622201bc2b8a5f60e7893fb9': 12, //'Etäterapia',
};
// export const moduleTotalSessions: PeriodSessions = {
// 	[TherapyModuleID.KielellinenTietoisuus]: 10,
// 	[TherapyModuleID.Sanavarasto]: 10,
// 	[TherapyModuleID.VarhainenKielenkehitys]: 10,
// 	[TherapyModuleID.Äännevirheet]: 12,
// 	[TherapyModuleID.ValikoivaSyöjä]: 12,
// 	[TherapyModuleID.Monikielisyys]: 12,
// 	[TherapyModuleID.PalvelutarpeenArvio]: 12,
// 	[TherapyModuleID.MVP]: 12,
// 	[TherapyModuleID.PuheenYmmärtäminen]: 12,
// 	[TherapyModuleID.Kerronta]: 12,
// 	[TherapyModuleID.ÄännekoulunÄrräkurssi]: 12,
// 	[TherapyModuleID.ÄännekoulunÄlläkurssi]: 12,
// 	[TherapyModuleID.ÄännekoulunRKlinikanÄrräkurssi]: 12,
// 	[TherapyModuleID.TerapeutinOmatAsiakkaat]: 12,
// };
