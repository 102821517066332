var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { sortBy } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getModulesAdmin } from '../../../actions/moduleActions';
import { getTherapyTypesAdmin } from '../../../actions/therapyTypeActions';
import { getZones } from '../../../actions/zoneActions';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from '../../translation/Translate';
import './periodsStyle.css';
import { clearStates, setSearchParamsField, setSearchTags, } from '../../../reducers/adminPeriodsSlice';
import { Select } from '../../therapist/utils/Select';
import DateInput from '../../common/DateInput';
import format from 'date-fns/format';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { getAdminTherapists } from '../../../actions/adminActions';
var SearchPeriods = function (_a) {
    var session = _a.session, searchAllPeriods = _a.searchAllPeriods, isLoading = _a.isLoading;
    var _b = __read(useState('lookAllPeriods'), 2), periodFilterValue = _b[0], setPeriodFilterValue = _b[1];
    var lastNameTranslate = Translate({ term: 'profile.last_name' });
    var birthYearTranslate = Translate({ term: 'profile.birth_year' });
    var emailTranslate = Translate({ term: 'profile.email' });
    var licenseKeyTranslate = Translate({ term: 'licenses.license_key' });
    var therapyTypeTranslate = Translate({ term: 'therapy.therapy_type' });
    var moduleTranslate = Translate({ term: 'therapy.module' });
    var zoneTranslate = Translate({ term: 'zone.zone' });
    var startDateTranslate = Translate({ term: 'period.period_time' });
    var endDateTranslate = Translate({ term: 'period.ended' });
    var allPeriodsTranslate = Translate({
        term: 'period.all_periods',
    });
    var activePeriodsTranslate = Translate({
        term: 'period.only_active_periods',
    });
    var allEndedPeriodsTranslate = Translate({
        term: 'period.only_all_ended_periods',
    });
    var onlyReasentlyEndedPeriodsTranslate = Translate({
        term: 'period.only_reasently_ended_periods',
    });
    var therapistTranslate = Translate({ term: 'home.therapist' });
    var selectTherapistTranslate = Translate({
        term: 'admin.license.select_therapist',
    });
    var selectTherapyTypeTranslate = Translate({
        term: 'therapy.select_therapy_type',
    });
    var selectModuleTranslate = Translate({ term: 'therapy.select_module' });
    var selectZoneTranslate = Translate({ term: 'therapy.select_zone' });
    var dispatch = useDispatch();
    var mountedRef = useRef(true);
    var searchParams = useSelector(function (state) { return state.adminPeriods.searchParams; });
    var lastName = searchParams.lastName, birthyear = searchParams.birthyear, email = searchParams.email, therapyType = searchParams.therapyType, module = searchParams.module, zone = searchParams.zone, startDate = searchParams.startDate, endDate = searchParams.endDate, therapist = searchParams.therapist, licenseKey = searchParams.licenseKey;
    var _c = __read(useState([]), 2), modules = _c[0], setModules = _c[1];
    var _d = __read(useState([]), 2), zones = _d[0], setZones = _d[1];
    var _e = __read(useState([]), 2), therapyTypes = _e[0], setTherapyTypes = _e[1];
    var _f = __read(useState([]), 2), therapistOptions = _f[0], setTherapistOptions = _f[1];
    var getZoneNames = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var zonesData, zonesArray, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getZones(session)];
                case 1:
                    zonesData = _a.sent();
                    if (zonesData) {
                        zonesArray = zonesData.map(function (item) {
                            return {
                                value: item._id,
                                optionText: item.name,
                            };
                        });
                        setZones(sortBy(zonesArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    console.error(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [session === null || session === void 0 ? void 0 : session.accessToken]);
    var getModules = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var modulesData, modulesArray, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getModulesAdmin(session)];
                case 1:
                    modulesData = _a.sent();
                    if (modulesData) {
                        modulesArray = modulesData.map(function (item) {
                            return {
                                value: item._id,
                                optionText: item.name.fi || item.name.se,
                            };
                        });
                        setModules(sortBy(modulesArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [session === null || session === void 0 ? void 0 : session.accessToken]);
    var getTherapyTypes = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var therapyTypeData, therapyTypeArray, err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getTherapyTypesAdmin(session)];
                case 1:
                    therapyTypeData = _a.sent();
                    if (therapyTypeData) {
                        therapyTypeArray = therapyTypeData.map(function (item) {
                            return {
                                value: item._id,
                                optionText: item.name.fi || item.name.se,
                            };
                        });
                        setTherapyTypes(sortBy(therapyTypeArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_3 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [session === null || session === void 0 ? void 0 : session.accessToken]);
    var getTherapistOptions = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var therapistsData, therapistsArray, err_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!session)
                        return [2 /*return*/];
                    return [4 /*yield*/, getAdminTherapists(session)];
                case 1:
                    therapistsData = _a.sent();
                    if (therapistsData) {
                        therapistsArray = therapistsData.map(function (user) {
                            return {
                                value: user.userId,
                                optionText: (user.firstName || '') + ' ' + (user.lastName || ''),
                            };
                        });
                        setTherapistOptions(sortBy(therapistsArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_4 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [session === null || session === void 0 ? void 0 : session.accessToken]);
    useEffect(function () {
        getTherapyTypes();
        getModules();
        getZoneNames();
        getTherapistOptions();
        return function () {
            mountedRef.current = false;
        };
    }, [getModules, getTherapistOptions, getTherapyTypes, getZoneNames]);
    var onSelectChange = function (field, target, searchTagValue, searchTagLabel) {
        var _a;
        dispatch(setSearchParamsField((_a = {}, _a[field] = target.value, _a)));
        dispatch(setSearchTags({
            value: searchTagValue,
            label: searchTagLabel,
        }));
    };
    var handlePeriodFilterValueChange = function (event) {
        var value = event.target.value;
        setPeriodFilterValue(value);
        dispatch(setSearchParamsField({ periodFilterState: value }));
        if (value !== 'lookAllPeriods') {
            dispatch(setSearchParamsField({ startDate: null }));
            dispatch(setSearchTags({
                value: '',
                label: '',
            }));
            dispatch(setSearchParamsField({ endDate: null }));
            dispatch(setSearchTags({
                value: '',
                label: '',
            }));
        }
    };
    return (_jsxs("div", __assign({ style: { width: '580px' }, className: "space-y-3" }, { children: [_jsxs("form", __assign({ className: "flex flex-col mt-5 space-y-3" }, { children: [_jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", __assign({ className: "mt-2" }, { children: lastNameTranslate })), _jsx("input", { type: "text", onChange: function (e) {
                                    dispatch(setSearchParamsField({ lastName: e.target.value }));
                                    dispatch(setSearchTags({
                                        value: e.target.value,
                                        label: lastNameTranslate,
                                    }));
                                }, value: lastName, className: "w-96 h-11" })] })), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", __assign({ className: "mt-2" }, { children: birthYearTranslate })), _jsx("input", { type: "number", onChange: function (e) {
                                    dispatch(setSearchParamsField({
                                        birthyear: parseInt(e.target.value, 10),
                                    }));
                                    dispatch(setSearchTags({
                                        value: parseInt(e.target.value, 10),
                                        label: birthYearTranslate,
                                    }));
                                }, value: birthyear, className: "w-60 h-11 px-3 border border-gray-300 rounded-md" })] })), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", __assign({ className: "mt-2" }, { children: emailTranslate })), _jsx("input", { type: "text", onChange: function (e) {
                                    dispatch(setSearchParamsField({ email: e.target.value }));
                                    dispatch(setSearchTags({
                                        value: e.target.value,
                                        label: emailTranslate,
                                    }));
                                }, value: email, className: "w-96 h-11" })] })), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", __assign({ className: "mt-2" }, { children: licenseKeyTranslate })), _jsx("input", { type: "text", onChange: function (e) {
                                    dispatch(setSearchParamsField({ licenseKey: e.target.value }));
                                    dispatch(setSearchTags({
                                        value: e.target.value,
                                        label: licenseKeyTranslate,
                                    }));
                                }, value: licenseKey, className: "w-96 h-11" })] })), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", __assign({ className: "mt-2" }, { children: therapistTranslate })), _jsx(Select, { selectProps: {
                                    id: 'therapist-select',
                                    labelId: 'therapist-select-label',
                                    label: selectTherapistTranslate,
                                    value: therapist,
                                    onChange: function (_a) {
                                        var _b, _c;
                                        var target = _a.target;
                                        return onSelectChange('therapist', target, (_c = (_b = therapistOptions === null || therapistOptions === void 0 ? void 0 : therapistOptions.find(function (type) { return target.value === type.value; })) === null || _b === void 0 ? void 0 : _b.optionText) !== null && _c !== void 0 ? _c : '', therapistTranslate);
                                    },
                                }, datas: therapistOptions !== null && therapistOptions !== void 0 ? therapistOptions : [] })] })), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", __assign({ className: "mt-2" }, { children: therapyTypeTranslate })), _jsx(Select, { selectProps: {
                                    id: 'therapy-type-select',
                                    labelId: 'therapy-type-select-label',
                                    label: selectTherapyTypeTranslate,
                                    value: therapyType,
                                    onChange: function (_a) {
                                        var _b, _c;
                                        var target = _a.target;
                                        return onSelectChange('therapyType', target, (_c = (_b = therapyTypes === null || therapyTypes === void 0 ? void 0 : therapyTypes.find(function (type) { return target.value === type.value; })) === null || _b === void 0 ? void 0 : _b.optionText) !== null && _c !== void 0 ? _c : '', therapyTypeTranslate);
                                    },
                                }, datas: therapyTypes !== null && therapyTypes !== void 0 ? therapyTypes : [] })] })), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", __assign({ className: "mt-2" }, { children: moduleTranslate })), _jsx(Select, { selectProps: {
                                    id: 'module-select',
                                    labelId: 'module-select-label',
                                    label: selectModuleTranslate,
                                    value: module,
                                    onChange: function (_a) {
                                        var _b, _c;
                                        var target = _a.target;
                                        return onSelectChange('module', target, (_c = (_b = modules === null || modules === void 0 ? void 0 : modules.find(function (type) { return target.value === type.value; })) === null || _b === void 0 ? void 0 : _b.optionText) !== null && _c !== void 0 ? _c : '', moduleTranslate);
                                    },
                                }, datas: modules !== null && modules !== void 0 ? modules : [] })] })), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", __assign({ className: "mt-2" }, { children: zoneTranslate })), _jsx(Select, { selectProps: {
                                    id: 'zone-select',
                                    labelId: 'zone-select-label',
                                    label: selectZoneTranslate,
                                    value: zone,
                                    onChange: function (_a) {
                                        var _b, _c;
                                        var target = _a.target;
                                        return onSelectChange('zone', target, (_c = (_b = zones === null || zones === void 0 ? void 0 : zones.find(function (type) { return target.value === type.value; })) === null || _b === void 0 ? void 0 : _b.optionText) !== null && _c !== void 0 ? _c : '', zoneTranslate);
                                    },
                                }, datas: zones !== null && zones !== void 0 ? zones : [] })] })), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", __assign({ className: "mt-2" }, { children: startDateTranslate })), _jsx(DateInput, { label: Translate({ term: 'period.started' }), value: startDate, onChange: function (startDate) {
                                    dispatch(setSearchParamsField({ startDate: startDate }));
                                    dispatch(setSearchTags({
                                        value: format(startDate, 'dd.MM.yyyy'),
                                        label: startDateTranslate,
                                    }));
                                } }), _jsx("label", __assign({ className: "mt-2" }, { children: "-" })), _jsx(DateInput, { label: endDateTranslate, value: endDate, onChange: function (endDate) {
                                    dispatch(setSearchParamsField({ endDate: endDate }));
                                    dispatch(setSearchTags({
                                        value: format(endDate, 'dd.MM.yyyy'),
                                        label: endDateTranslate,
                                    }));
                                } })] })), _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex justify-end gap-2" }, { children: [_jsx("label", { children: allPeriodsTranslate }), _jsx("input", { type: "radio", name: "periodFilter", value: "lookAllPeriods", checked: periodFilterValue === 'lookAllPeriods', onChange: handlePeriodFilterValueChange })] })), _jsxs("div", __assign({ className: "flex justify-end gap-2" }, { children: [_jsx("label", { children: activePeriodsTranslate }), _jsx("input", { type: "radio", name: "periodFilter", value: "lookActivePeriods", checked: periodFilterValue === 'lookActivePeriods', onChange: handlePeriodFilterValueChange })] })), _jsxs("div", __assign({ className: "flex justify-end gap-2" }, { children: [_jsx("label", { children: allEndedPeriodsTranslate }), _jsx("input", { type: "radio", name: "periodFilter", value: "lookAllEndedPeriods", checked: periodFilterValue === 'lookAllEndedPeriods', onChange: handlePeriodFilterValueChange })] })), _jsxs("div", __assign({ className: "flex justify-end gap-2" }, { children: [_jsx("label", { children: onlyReasentlyEndedPeriodsTranslate }), _jsx("input", { type: "radio", name: "periodFilter", value: "lookReasentlyEndedPeriods", checked: periodFilterValue === 'lookReasentlyEndedPeriods', onChange: handlePeriodFilterValueChange })] }))] }))] })), _jsxs("div", __assign({ className: "flex justify-end space-x-3 pt-4" }, { children: [_jsx("button", __assign({ onClick: searchAllPeriods, disabled: isLoading }, { children: Translate({ term: 'periods.search' }) })), _jsx("button", __assign({ disabled: isLoading, onClick: function () { return dispatch(clearStates()); }, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: Translate({ term: 'actions.clear' }) }))] })), isLoading && (_jsx(Box, __assign({ sx: { width: '100%', maxWidth: '72rem' } }, { children: _jsx(LinearProgress, {}) })))] })));
};
export default SearchPeriods;
