var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import MainNav from './MainNav';
var Navigation = function (_a) {
    var iconsOnly = _a.iconsOnly;
    return (_jsx("nav", __assign({ className: "flex gap-4 place-content-center lg:-mx-6 lg:ml-0 lg:mr-0 invisible lg:visible" }, { children: _jsx(MainNav, { iconsOnly: iconsOnly, classname: 'flex gap-4 m-2 place-content-center' }) })));
};
export default Navigation;
