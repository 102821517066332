var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Delete from '@mui/icons-material/Delete';
import { Translate } from '../../translation/Translate';
import ConfirmationModalWithFunctionParams from '../../common/modals/ConfirmationModalWithFunctionParams';
var ChatMessageTextAndDeleteMessage = function (_a) {
    var isOwnMessage = _a.isOwnMessage, chatMessage = _a.chatMessage, softDeleteHelpChatMessageFunction = _a.softDeleteHelpChatMessageFunction;
    var _b = __read(useState(false), 2), openConfirmationModal = _b[0], setOpenConfirmationModal = _b[1];
    var deleteDefaultMessageTranslate = Translate({
        term: 'actions.delete_defaultMessage',
    });
    return (_jsxs("div", { children: [isOwnMessage && (_jsx("div", __assign({ onClick: function () { return setOpenConfirmationModal(true); }, className: "flex justify-end" }, { children: _jsx(Delete, { style: { cursor: 'pointer' } }) }))), _jsx("p", __assign({ style: {
                    maxWidth: '100%',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    hyphens: 'auto',
                } }, { children: chatMessage.messageText })), _jsx(ConfirmationModalWithFunctionParams, { headerText: deleteDefaultMessageTranslate, opentConfirmationModal: openConfirmationModal, setOpenConfirmationModal: setOpenConfirmationModal, idValue: chatMessage._id, actionFunction: softDeleteHelpChatMessageFunction })] }));
};
export default ChatMessageTextAndDeleteMessage;
