var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Translate } from '../../translation/Translate';
export function countAllPeriods(periods) {
    if (periods.periods)
        return periods.periods.length;
    return 0;
}
export function countUnreadMsg(periods, userinfo) {
    var _a;
    var msgCount = 0;
    if (periods.periods) {
        (_a = periods.periods) === null || _a === void 0 ? void 0 : _a.forEach(function (period) {
            period.messages.map(function (message) {
                if (message.createdBy !== (userinfo === null || userinfo === void 0 ? void 0 : userinfo.userId) &&
                    message.readBy.findIndex(function (readby) {
                        return readby.user === (userinfo === null || userinfo === void 0 ? void 0 : userinfo.userId);
                    }) === -1)
                    msgCount++;
            });
        });
    }
    return msgCount;
}
export function countUnreadMsgForPeriod(period, userinfo) {
    var msgCount = 0;
    if (period && period.messages && period.messages.length > 0) {
        period.messages.map(function (message) {
            if (message.createdBy !== (userinfo === null || userinfo === void 0 ? void 0 : userinfo.userId) &&
                message.readBy.findIndex(function (readby) {
                    return readby.user === (userinfo === null || userinfo === void 0 ? void 0 : userinfo.userId);
                }) === -1)
                msgCount++;
        });
    }
    return msgCount;
}
export function countEndedPeriods(periods) {
    var count = 0;
    if (periods.periods) {
        periods.periods.forEach(function (period) {
            var today = moment(new Date()).format();
            var periodEnded = moment(new Date(period.validUntil)).format();
            if (today > periodEnded && !isEmpty(period.endReport))
                count++;
        });
    }
    return count;
}
export function countEndReportPeriods(periods) {
    var count = 0;
    if (periods.periods) {
        periods.periods.forEach(function (period) {
            var today = moment(new Date()).format();
            var periodEnded = moment(new Date(period.validUntil))
                .subtract(3, 'weeks')
                .format();
            if (today > periodEnded && isEmpty(period.endReport))
                count++;
        });
    }
    return count;
}
export function countPassivePeriods(periods) {
    var count = 0;
    if (periods.periods) {
        periods.periods.forEach(function (period) {
            var today = moment(new Date()).format();
            var passiveLastMsg = period.messages && period.messages.length > 0
                ? moment(new Date(__spreadArray([], __read(period.messages), false).reverse()[0].created))
                    .add(3, 'weeks')
                    .format()
                : '';
            if (isEmpty(period.endReport) && today > passiveLastMsg)
                count++;
        });
    }
    return count;
}
export var countTherapyTypePeriods = function (periods, therapyType, userLang) {
    var count = 0;
    if (therapyType === Translate({ term: 'therapy.types.all' })) {
        count = periods ? periods.length : 0;
    }
    else if (therapyType === Translate({ term: 'therapy.types.hybrid' })) {
        periods === null || periods === void 0 ? void 0 : periods.forEach(function (period) {
            if (period.therapyType &&
                period.therapyType.name &&
                (period.therapyType.name['fi'] === 'Hybriditerapia' ||
                    period.therapyType.name['se'] === 'Hybridterapi'))
                count++;
        });
    }
    else if (therapyType === Translate({ term: 'therapy.types.onsite' })) {
        periods === null || periods === void 0 ? void 0 : periods.forEach(function (period) {
            if (period.therapyType &&
                period.therapyType.name &&
                (period.therapyType.name['fi'] === 'Lähiterapia' ||
                    period.therapyType.name['se'] === 'Närterapi'))
                count++;
        });
    }
    else if (therapyType === Translate({ term: 'therapy.types.online' })) {
        periods === null || periods === void 0 ? void 0 : periods.forEach(function (period) {
            if (period.therapyType &&
                period.therapyType.name &&
                (period.therapyType.name['fi'] === 'Nettiterapia' ||
                    period.therapyType.name['se'] === 'Nätterapi'))
                count++;
        });
    }
    else if (therapyType === Translate({ term: 'therapy.types.remote' })) {
        periods === null || periods === void 0 ? void 0 : periods.forEach(function (period) {
            if (period.therapyType &&
                period.therapyType.name &&
                (period.therapyType.name['fi'] === 'Etäterapia' ||
                    period.therapyType.name['se'] === 'Distansterapi'))
                count++;
        });
    }
    return count;
};
