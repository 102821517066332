var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { getModulesAdmin } from '../../../../actions/moduleActions';
import { updateSelectedUser } from '../../../../actions/userActions';
import { getZones } from '../../../../actions/zoneActions';
import { Translate } from '../../../translation/Translate';
import SelectDataArrayComponent from '../../users/SelectDataArrayComponent';
import useUser from '../../../../hooks/useUser';
var UserUpdate = function (_a) {
    var showToastMessage = _a.showToastMessage, searchAllUsers = _a.searchAllUsers, setSelectedUser = _a.setSelectedUser, active = _a.active, therapyModels = _a.therapyModels, isOverflowTherapist = _a.isOverflowTherapist, id = _a.id, status = _a.status, moduleArrTemp = _a.moduleArrTemp, zoneArrTemp = _a.zoneArrTemp, evaluationZonesArrTemp = _a.evaluationZonesArrTemp;
    var user = useUser();
    var selectZoneTranslate = Translate({ term: 'common.choose_zone' });
    var selectModuleTranslate = Translate({ term: 'therapy.select_module' });
    var customerUpdatedSuccessfullyTranslate = Translate({
        term: 'profile.customer_updated_successfully',
    });
    var _b = __read(useState([]), 2), modules = _b[0], setModules = _b[1];
    var _c = __read(useState([]), 2), zones = _c[0], setZones = _c[1];
    var selectEvaluationZoneTranslate = Translate({
        term: 'common.choose_evaluation_zone',
    });
    var chooseZoneTranslate = Translate({
        term: 'common.choose_zone',
    });
    var _d = __read(useState(moduleArrTemp), 2), moduleArr = _d[0], setModuleArr = _d[1];
    var _e = __read(useState(Array.isArray(zoneArrTemp) ? zoneArrTemp : [zoneArrTemp]), 2), zoneArr = _e[0], setZoneArr = _e[1];
    var _f = __read(useState(evaluationZonesArrTemp), 2), evaluationZonesArr = _f[0], setEvaluationZonesArr = _f[1];
    var _g = __read(useState(therapyModels), 2), newTherapyModels = _g[0], setNewTherapyModels = _g[1];
    var _h = __read(useState(isOverflowTherapist), 2), isOverflow = _h[0], setIsOverflowTherapist = _h[1];
    useEffect(function () {
        getModules();
        getZoneNames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var getModules = function () { return __awaiter(void 0, void 0, void 0, function () {
        var modulesData, modulesArray, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getModulesAdmin(user.session)];
                case 1:
                    modulesData = _a.sent();
                    if (modulesData) {
                        modulesArray = modulesData.map(function (item) {
                            return {
                                value: item._id,
                                optionText: item.name.fi || item.name.se,
                            };
                        });
                        setModules(sortBy(modulesArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getZoneNames = function () { return __awaiter(void 0, void 0, void 0, function () {
        var zonesData, zonesArray, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getZones(user.session)];
                case 1:
                    zonesData = _a.sent();
                    if (zonesData) {
                        zonesArray = zonesData.map(function (item) {
                            return {
                                value: item._id,
                                optionText: item.name,
                            };
                        });
                        setZones(sortBy(zonesArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var modifySelectedUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var updateUserInfoRequest;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(zoneArr.length > 0)) return [3 /*break*/, 2];
                    updateUserInfoRequest = {
                        id: id,
                        status: status,
                        zone: zoneArr,
                        evaluationZones: evaluationZonesArr,
                        modules: moduleArr || [],
                        therapyModels: newTherapyModels,
                        isOverflowTherapist: isOverflow,
                    };
                    return [4 /*yield*/, updateSelectedUser(updateUserInfoRequest, user.session)
                            .then(function (data) {
                            if (data) {
                                showToastMessage(customerUpdatedSuccessfullyTranslate);
                                searchAllUsers();
                                setSelectedUser(null);
                            }
                        })
                            .catch(function (err) {
                            // console.error(err);
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    showToastMessage(chooseZoneTranslate);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    function removeZone(index) {
        setZoneArr(zoneArr.filter(function (el, i) { return i !== index; }));
    }
    function removeModule(index) {
        setModuleArr(moduleArr.filter(function (el, i) { return i !== index; }));
    }
    function removeEvaluationZone(index) {
        setEvaluationZonesArr(evaluationZonesArr.filter(function (el, i) { return i !== index; }));
    }
    var changeTherapyModel = function (index) {
        if (active) {
            setNewTherapyModels(__assign(__assign({}, newTherapyModels), { online: index === 0 ? !newTherapyModels.online : newTherapyModels.online, onsite: index === 1 ? !newTherapyModels.onsite : newTherapyModels.onsite, evaluations: index === 2
                    ? !newTherapyModels.evaluations
                    : newTherapyModels.evaluations, hybrid: index === 3 ? !newTherapyModels.hybrid : newTherapyModels.hybrid, remote: index === 4 ? !newTherapyModels.remote : newTherapyModels.remote }));
        }
    };
    var onlineTranslate = Translate({
        term: 'therapy.types.online',
    });
    var onsiteTranslate = Translate({
        term: 'therapy.types.onsite',
    });
    var evaluationsTranslate = Translate({
        term: 'therapy.types.evaluations',
    });
    var hybridTranslate = Translate({
        term: 'therapy.types.hybrid',
    });
    var remoteTranslate = Translate({
        term: 'therapy.types.remote',
    });
    var therapyModelsArray = [
        {
            id: 0,
            label: onlineTranslate,
        },
        {
            id: 1,
            label: onsiteTranslate,
        },
        {
            id: 2,
            label: evaluationsTranslate,
        },
        {
            id: 3,
            label: hybridTranslate,
        },
        {
            id: 4,
            label: remoteTranslate,
        },
    ];
    return (_jsxs("div", { children: [_jsx("div", { className: "border-b my-7" }), _jsx(SelectDataArrayComponent, { labelTranslate: Translate({ term: 'zone.area' }), selectTextTranslate: selectZoneTranslate, dataArray: zoneArr, setDataArray: setZoneArr, selectDataArr: zones, removeDataFunction: removeZone }), status === 'therapist' && (_jsxs("div", { children: [_jsx(SelectDataArrayComponent, { labelTranslate: Translate({ term: 'zone.evaluation_zones' }), selectTextTranslate: selectEvaluationZoneTranslate, dataArray: evaluationZonesArr, setDataArray: setEvaluationZonesArr, selectDataArr: zones, removeDataFunction: removeEvaluationZone }), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsx("label", __assign({ className: "w-4/12 mt-2" }, { children: Translate({ term: 'admin.therapist.is_overflow_therapist' }) })), _jsx("input", { type: "checkbox", onChange: function () { return setIsOverflowTherapist(!isOverflow); }, checked: isOverflow }), _jsx("span", { className: "checkmark" })] })), _jsx(SelectDataArrayComponent, { labelTranslate: Translate({ term: 'nav.modules' }), selectTextTranslate: selectModuleTranslate, dataArray: moduleArr, setDataArray: setModuleArr, selectDataArr: modules, removeDataFunction: removeModule })] })), _jsx("div", __assign({ className: "flex justify-end mt-6" }, { children: _jsx("button", __assign({ className: active ? '' : 'cursor-default', onClick: function () {
                        !active ? null : modifySelectedUser();
                    } }, { children: Translate({ term: 'actions.save' }) })) }))] }));
};
export default UserUpdate;
