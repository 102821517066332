var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useContext } from 'react';
import { Link, NavLink, useMatch } from 'react-router-dom';
import Avatar from '../common/Avatar';
import { LanguageContext, Translate } from '../translation/Translate';
import { getWindowDimensions } from '../../functions/screen';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import akLogo from '../../assets/images/ak-logo.png';
import asLogo from '../../assets/images/as-logo.png';
import { logout } from '../../functions/logout';
import useUser from '../../hooks/useUser';
var MenuHeader = function (_a) {
    var iconsOnly = _a.iconsOnly;
    var language = useContext(LanguageContext);
    return (_jsx("div", __assign({ className: "mb-4 flex flex-col gap-3 px-6" }, { children: _jsxs("div", __assign({ className: "flex items-center gap-2 flex-none" }, { children: [_jsx("div", __assign({ className: "w-8 h-8 bg-white border border-blue border-opacity-25 p-1 shadow-md rounded-md flex justify-items-center items-center" }, { children: _jsx("img", { alt: Translate({ term: 'site.name_short' }), src: language === 'se' ? asLogo : akLogo }) })), iconsOnly ? ('') : (_jsx("div", __assign({ className: "text-lg text-blue font-semibold" }, { children: Translate({ term: 'site.name' }) })))] })) })));
};
var IconLink = function (_a) {
    var iconsOnly = _a.iconsOnly, icon = _a.icon, title = _a.title, path = _a.path, active = _a.active, style = _a.style, target = _a.target;
    var linkStyle = [
        'flex px-2 gap-2 py-2 rounded-md hover:bg-white hover:bg-opacity-50',
    ];
    if (iconsOnly) {
        linkStyle.push('mr-2');
    }
    if (active)
        linkStyle.push('bg-white bg-opacity-50');
    var titleString = '';
    if (typeof title === 'string') {
        titleString = title;
    }
    if (style) {
        linkStyle.push(style);
    }
    if (target) {
        return (_jsxs("a", __assign({ href: path, target: target, className: linkStyle.join(' ') }, { children: [_jsx("div", __assign({ className: "h-6 w-6 text-xs overflow-hidden flex items-center text-gray-500", title: titleString }, { children: icon })), iconsOnly ? '' : _jsx("div", __assign({ className: "text-gray-700" }, { children: title }))] })));
    }
    return (_jsxs(NavLink, __assign({ to: path, className: linkStyle.join(' ') }, { children: [_jsx("div", __assign({ className: "h-6 w-6 text-xs overflow-hidden flex items-center text-gray-500", title: titleString }, { children: icon })), iconsOnly ? '' : _jsx("div", __assign({ className: "text-gray-700" }, { children: title }))] })));
};
var Profile = function (_a) {
    var _b, _c;
    var iconsOnly = _a.iconsOnly;
    var user = useUser();
    return (_jsxs("div", { children: [_jsx(Link, __assign({ to: "/" }, { children: _jsx(Avatar, { image: _jsx(PersonOutlineOutlinedIcon, { style: { width: '100%', height: '100%' } }), firstName: ((_b = user.userinfo) === null || _b === void 0 ? void 0 : _b.firstName) ? user.userinfo.firstName : '', lastName: ((_c = user.userinfo) === null || _c === void 0 ? void 0 : _c.lastName) ? user.userinfo.lastName : '', name: iconsOnly === true ? false : true }) })), iconsOnly === true ? (_jsx("div", __assign({ className: "pt-4" }, { children: _jsx("button", __assign({ onClick: function () { return logout(user.session); } }, { children: _jsx(LogoutIcon, {}) })) }))) : (_jsx("div", __assign({ className: "pt-4" }, { children: _jsx("button", __assign({ onClick: function () { return logout(user.session); } }, { children: Translate({ term: 'nav.log_out' }) })) })))] }));
};
var MainMenu = function () {
    var minLimit = 800;
    var maxLimit = 1000;
    var origWd = getWindowDimensions();
    var _a = __read(useState(origWd.width >= 600 ? false : true), 2), iconsOnly = _a[0], setIconsOnly = _a[1];
    useEffect(function () {
        function handleResize() {
            var wd = getWindowDimensions();
            if (wd.width >= maxLimit) {
                // && iconsOnly === true
                setIconsOnly(false);
            }
            else if (wd.width < minLimit && iconsOnly === false) {
                setIconsOnly(true);
            }
        }
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    var asideClass = ['menu flex-none w-24']; //border-r border-gray-300
    var menuClass = ['fixed flex flex-col h-screen bg-cyan py-6 w-24'];
    if (iconsOnly) {
        asideClass.push('w-24');
        menuClass.push('w-24');
    }
    else {
        asideClass.push('w-72');
        menuClass.push('w-72');
    }
    var home = useMatch('/');
    var invitation = useMatch('/share-license');
    var instructions = useMatch('/instructions');
    return (_jsx("aside", __assign({ className: asideClass.join(' ') }, { children: _jsxs("div", __assign({ className: menuClass.join(' ') }, { children: [_jsx(MenuHeader, { iconsOnly: iconsOnly }), _jsxs("ul", { children: [_jsx("li", __assign({ className: "p-0 my-2" }, { children: _jsx(IconLink, { iconsOnly: iconsOnly, path: "/", title: Translate({ term: 'nav.home' }), icon: _jsx(HomeOutlinedIcon, {}), active: home ? true : false }) }), "1"), _jsx("li", __assign({ className: "p-0 my-2" }, { children: _jsx(IconLink, { iconsOnly: iconsOnly, path: "/share-license", title: Translate({ term: 'nav.share_license' }), icon: _jsx(AddCircleOutlineOutlinedIcon, {}), active: invitation ? true : false }) }), "2"), _jsx("li", __assign({ className: "p-0 my-2" }, { children: _jsx(IconLink, { iconsOnly: iconsOnly, path: "/instructions", title: Translate({ term: 'nav.instructions' }), icon: _jsx(InfoOutlinedIcon, {}), active: instructions ? true : false }) }), "3")] }), _jsx("div", __assign({ className: "justify-end px-6 pt-4" }, { children: _jsx(Profile, { iconsOnly: iconsOnly }) }))] })) })));
};
export default MainMenu;
