var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { confirmAccount } from '../../../actions/sessionActions';
import { setSession } from '../../../reducers/userSlice';
import { Translate } from '../../translation/Translate';
import { useNavigate } from 'react-router-dom';
var ConfirmExistingUserAccount = function (_a) {
    var ssn = _a.ssn;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _b = __read(useState(''), 2), username = _b[0], setUsername = _b[1];
    var _c = __read(useState(''), 2), password = _c[0], setPassword = _c[1];
    var _d = __read(useState(undefined), 2), errorText = _d[0], setErrorText = _d[1];
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var invitationId = urlParams.get('id');
    var loginFailedText = Translate({ term: 'login.login_failed' });
    var usernameMissingText = Translate({ term: 'login.username_missing' });
    var passwordMissingText = Translate({ term: 'login.password_missing' });
    function confirmUserAccount() {
        return __awaiter(this, void 0, void 0, function () {
            var sessionData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(username && password && invitationId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, confirmAccount(username, password, invitationId, ssn)];
                    case 1:
                        sessionData = _a.sent();
                        if (sessionData) {
                            dispatch(setSession(sessionData));
                            navigate('/', { replace: true });
                        }
                        else {
                            setErrorText(loginFailedText);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        if (!username) {
                            setErrorText(usernameMissingText);
                        }
                        else {
                            setErrorText(passwordMissingText);
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs(React.Fragment, { children: [_jsx("h2", __assign({ className: "text-xl" }, { children: Translate({ term: 'login.confirm_username' }) })), errorText && (_jsx("div", __assign({ className: "text-warning-600 px-4 py-2 bg-white border border-warning-600 rounded-md" }, { children: errorText }))), _jsxs("div", __assign({ className: "grid gap-4" }, { children: [_jsx("div", { children: _jsxs("label", __assign({ className: "grid gap-y-2 gap-x-4 md:grid-cols-5 text-left" }, { children: [_jsx("span", __assign({ className: "col-span-2" }, { children: Translate({ term: 'login.enter_username' }) })), _jsx("input", { className: "col-span-3", type: "text", value: username, onChange: function (e) { return setUsername(e.target.value); }, placeholder: Translate({ term: 'login.username' }) })] })) }), _jsx("div", { children: _jsxs("label", __assign({ className: "grid gap-y-2 gap-x-4 md:grid-cols-5 text-left" }, { children: [_jsx("span", __assign({ className: "col-span-2" }, { children: Translate({ term: 'login.enter_password' }) })), _jsx("input", { className: "col-span-3", type: "password", value: password, onChange: function (e) { return setPassword(e.target.value); }, placeholder: Translate({ term: 'login.password' }) })] })) })] })), _jsx("div", __assign({ className: "text-center" }, { children: _jsx("button", __assign({ className: "", onClick: function () { return confirmUserAccount(); } }, { children: Translate({ term: 'login.confirm_username' }) })) }))] }));
};
export default ConfirmExistingUserAccount;
