var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Moment from 'react-moment';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Translate } from '../../translation/Translate';
var UsedLicensesTable = function (_a) {
    var filters = _a.filters, columns = _a.columns, licenses = _a.licenses;
    var _b = __read(React.useState(null), 2), sort = _b[0], setSort = _b[1];
    var _c = __read(React.useState(false), 2), reverse = _c[0], setReverse = _c[1];
    function handleSetSort(id) {
        if (sort && id === sort) {
            setReverse(!reverse);
        }
        else {
            setSort(id);
            setReverse(false);
        }
    }
    var headCells = [
        {
            id: 'license',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'licenses.license_key' }),
        },
        {
            id: 'licenseType',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'licenses.license_type' }),
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'licenses.reserver_email' }),
        },
        {
            id: 'validUntil',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'licenses.used' }),
        },
    ];
    if (licenses) {
        return (_jsxs("table", __assign({ className: "border-collapse bg-white shadow-md w-full" }, { children: [_jsx("thead", { children: _jsx("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2" }, { children: headCells.map(function (headCell, key) {
                            if (headCell.id !== 'action' &&
                                (!columns || columns.includes(headCell.id))) {
                                var classes = [
                                    'px-4 py-4 text-left border-b border-gray-400 cursor-pointer',
                                ];
                                var aClasses = ['text-blue ml-1'];
                                if (sort && sort === headCell.id) {
                                    classes.push('');
                                    aClasses.push('opacity-100');
                                }
                                else {
                                    aClasses.push('opacity-25');
                                }
                                var sortIcon = sort && sort === headCell.id && reverse === true ? (_jsx(ArrowUpwardIcon, { className: aClasses.join(' '), fontSize: "small" })) : (_jsx(ArrowDownwardIcon, { className: aClasses.join(' '), fontSize: "small" }));
                                return (_jsxs("th", __assign({ className: classes.join(' '), onClick: function () { return handleSetSort(headCell.id); } }, { children: [headCell.label, sortIcon] }), key));
                            }
                        }) })) }), _jsx("tbody", { children: licenses.map(function (license, key) {
                        var _a;
                        return (_jsxs("tr", __assign({ className: "text-sm hover:bg-gray-100" }, { children: [_jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: license.key }), key + '1'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: license.modules.length > 0 &&
                                        license.modules.map(function (module) {
                                            return (_jsx("p", { children: Translate({
                                                    term: "therapy.modules.m".concat(module._id),
                                                }) }, module._id));
                                        }) }), key + '2'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: (_a = license.invitation) === null || _a === void 0 ? void 0 : _a.email }), key + '3'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: license.used })) }), key + '4')] }), key));
                    }) })] })));
    }
    else {
        return _jsx("div", { children: "loading" });
    }
};
export default UsedLicensesTable;
