var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from '../translation/Translate';
var PageSelect = function (_a) {
    var page = _a.page, setPage = _a.setPage, lastPage = _a.lastPage;
    var pageTranslate = Translate({ term: 'common.page' });
    var nextTranslate = Translate({ term: 'actions.next' });
    var previousTranslate = Translate({ term: 'actions.previous' });
    return (_jsxs("div", __assign({ className: "flex flex-row justify-between w-full max-w-6xl px-4" }, { children: [page > 1 ? (_jsx("button", __assign({ onClick: function () { return (page >= 2 ? setPage(page - 1) : null); }, className: "mt-3 -ml-4 border-2 border-gray-200 bg-white text-black hover:bg-gray-200 duration-300" }, { children: previousTranslate }))) : (_jsx("button", __assign({ className: "cursor-default mt-3 -ml-4 border-2 border-gray-200 bg-white text-gray-500" }, { children: previousTranslate }))), _jsxs("div", __assign({ className: "mt-6 flex flex-row" }, { children: [pageTranslate, ' ', _jsxs("p", __assign({ className: "ml-1 font-bold" }, { children: [page, "/", lastPage] }))] })), page < lastPage ? (_jsx("button", __assign({ onClick: function () { return (page < lastPage ? setPage(page + 1) : null); }, className: "mt-3 -mr-4 border-2 border-gray-200 bg-white text-black hover:bg-gray-200 duration-300" }, { children: nextTranslate }))) : (_jsx("button", __assign({ className: "cursor-default mt-3 -mr-4 border-2 border-gray-200 bg-white text-gray-500" }, { children: nextTranslate })))] })));
};
export default PageSelect;
