var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VIDEO_URL } from "../../../../../config/constants";
import { Translate } from "../../../../translation/Translate";
var Vocabulary = function () {
    var title1 = Translate({ term: "therapy.modules.vocabulary.title1" });
    var title2 = Translate({ term: "therapy.modules.vocabulary.title2" });
    var text1 = Translate({ term: "therapy.modules.vocabulary.text1" });
    var text2 = Translate({ term: "therapy.modules.vocabulary.text2" });
    var text3 = Translate({ term: "therapy.modules.vocabulary.text3" });
    return (_jsx("div", __assign({ className: "pt-4" }, { children: _jsxs("div", { children: [_jsx("h3", { children: title1 }), _jsx("h4", { children: title2 }), _jsxs("ol", __assign({ className: "pt-4 ml-4" }, { children: [_jsxs("li", { children: ["1. ", text1] }), _jsxs("li", { children: ["2. ", text2] })] })), _jsx("ul", __assign({ className: "pt-4 ml-4", style: { listStyle: 'inherit' } }, { children: _jsx("li", { children: text3 }) })), _jsx("div", __assign({ className: "pt-4" }, { children: _jsx("video", __assign({ controls: true, width: "300", height: "150" }, { children: _jsx("source", { src: "".concat(VIDEO_URL, "/sanavarastonnettiterapianalkuvideo.mp4"), type: "video/mp4" }) })) }))] }) })));
};
export default Vocabulary;
