var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ZoneDetails from '../../components/admin/licenses/ZoneDetails';
import TabButton from '../../components/admin/materials/TabButton';
import Header from '../../components/licenseController/Header';
import { Translate } from '../../components/translation/Translate';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { clearFormState } from '../../reducers/adminLicenseSlice';
import ZoneLicenses from '../../components/admin/licenses/ZoneLicenses';
var Zone = function () {
    var dispatch = useDispatch();
    var _a = __read(useState('zoneDetails'), 2), selectedTab = _a[0], setSelectedTab = _a[1];
    var tabs = [
        {
            id: 'zoneDetails',
            title: Translate({ term: 'admin.license.zone_details' }),
            content: _jsx(ZoneDetails, {}),
        },
        {
            id: 'zoneLicenses',
            title: Translate({ term: 'admin.license.zone_licenses' }),
            content: _jsx(ZoneLicenses, {}),
        },
    ];
    var index = tabs.findIndex(function (tab) { return tab.id === selectedTab; });
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: Translate({ term: 'licenses.control_licences' }) }), _jsxs("main", __assign({ className: "px-9 py-4" }, { children: [_jsx("div", __assign({ className: "flex justify-start gap-4 mt-6" }, { children: _jsx("button", __assign({ onClick: function () {
                                dispatch(clearFormState());
                                history.back();
                            }, className: "border-2 border-white bg-blue text-white" }, { children: _jsxs("p", __assign({ className: "-ml-2" }, { children: [_jsx(ArrowBackIcon, { fontSize: "small" }), Translate({ term: 'actions.back' })] })) })) })), _jsx("div", __assign({ className: "mt-3 flex" }, { children: tabs.map(function (tab, index) { return (_jsx(TabButton, { id: tab.id, title: tab.title, selectedTab: selectedTab, setSelectedTab: setSelectedTab }, index)); }) })), tabs[index].content] }))] }));
};
export default Zone;
