var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var AvatarCircle = function (_a) {
    var firstName = _a.firstName, lastName = _a.lastName, size = _a.size, image = _a.image;
    var circleClasses = 'rounded-full bg-lightBlue relative flex-none m-1';
    var textClasses = 'font-semibold uppercase tracking-wide text-gray-600 absolute left-1/2 top-1/2';
    var imageClasses = 'rounded-full object-cover w-full h-full';
    if (size && size === 'xl') {
        circleClasses += ' w-24 h-24 border-2 border-white shadow-md';
        textClasses += ' text-2xl';
    }
    else if (size && size === 'lg') {
        circleClasses += ' w-16 h-16 border-2 border-white shadow-md';
        textClasses += ' text-2xl';
    }
    else if (size && size === 'sm') {
        circleClasses += ' w-6 h-6';
        textClasses += ' text-sm';
    }
    else {
        circleClasses += ' w-10 h-10';
        textClasses += ' text-base';
    }
    var img = '';
    if (image) {
        if (typeof image === 'string') {
            img = (_jsx("img", { className: imageClasses, src: image, alt: "".concat(firstName, " ").concat(lastName) }));
        }
        else {
            img = (_jsx("div", __assign({ className: textClasses, style: { transform: 'translate(-50%, -50%)' } }, { children: image })));
        }
    }
    else {
        img = (_jsx("div", __assign({ className: textClasses, style: { transform: 'translate(-50%, -50%)' } }, { children: "".concat(firstName ? firstName[0] : '').concat(lastName ? lastName[0] : '') })));
    }
    return _jsx("div", __assign({ className: circleClasses }, { children: img }));
};
var Avatar = function (_a) {
    var firstName = _a.firstName, lastName = _a.lastName, size = _a.size, name = _a.name, image = _a.image;
    if (name === false) {
        return (_jsx(AvatarCircle, { firstName: firstName, lastName: lastName, image: image, size: size }));
    }
    return (_jsxs("div", __assign({ className: "flex gap-3" }, { children: [_jsx(AvatarCircle, { firstName: firstName, lastName: lastName, image: image, size: size }), _jsxs("div", __assign({ className: "flex-auto self-center truncate ..." }, { children: [firstName, " ", lastName] }))] })));
};
export default Avatar;
