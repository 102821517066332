var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Translate } from '../../translation/Translate';
import Moment from 'react-moment';
import React, { useState } from 'react';
import SinglePostModal from '../Posts/SinglePostModal';
var PostsInfo = function (_a) {
    var posts = _a.posts;
    var _b = __read(useState(false), 2), show = _b[0], setShow = _b[1];
    var dateFormatTranslate = Translate({ term: 'common.date_format' });
    var _c = __read(React.useState(null), 2), activePost = _c[0], setActivePost = _c[1];
    if (posts) {
        return (_jsxs("div", { children: [_jsx("div", __assign({ className: "-mb-3 hidden sm:block" }, { children: _jsx(Swiper, __assign({ spaceBetween: 50, slidesPerView: 1, style: {
                            paddingLeft: '50px',
                            paddingRight: '50px',
                            paddingBottom: '10px',
                        }, centeredSlides: true, navigation: true, onSlideChange: function () { return (show ? setShow(false) : null); }, pagination: {
                            dynamicBullets: true,
                            clickable: true,
                            type: 'bullets',
                        }, modules: [Autoplay, Pagination, Navigation], className: "" }, { children: posts.map(function (post, index) {
                            return (_jsx(SwiperSlide, __assign({ className: "mb-10" }, { children: _jsxs("div", __assign({ className: "text-center", onClick: function () { return setActivePost(post); } }, { children: [_jsxs("h3", __assign({ className: "mb-2 font-semibold text-xl text-grey-600" }, { children: [_jsx(Moment, __assign({ format: dateFormatTranslate }, { children: post.created })), ' ', post.subject, ":"] })), _jsx("p", __assign({ className: "truncate .." }, { children: post.message }))] })) }), index));
                        }) })) })), _jsx(SinglePostModal, { activePost: activePost, setActivePost: setActivePost })] }));
    }
    else {
        return _jsx(_Fragment, {});
    }
};
export default PostsInfo;
