var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { logout } from '../functions/logout';
import useUser from '../hooks/useUser';
var Logout = function () {
    var user = useUser();
    React.useEffect(function () {
        logout(user.session);
    }, []);
    return (_jsx("div", __assign({ className: "" }, { children: _jsx("div", { children: "Logging out..." }) })));
};
export default Logout;
