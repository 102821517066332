var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apiPost, apiGet, apiPut, apiDelete, apiPostJson } from './apiActions';
import axios from 'axios';
export function findPeriodPatientName(session, periodId) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(periodId && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet("/api/period/".concat(periodId, "/patient-name"), session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function createExtensionPeriod(invitation, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(invitation && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPostJson('/api/period/extension-period', invitation, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 201) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/, false];
            }
        });
    });
}
export function sendAssignmentVideos(file, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(file && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPost('/api/periods/assignment-video', file, session, true)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function sendStartVideos(attachments, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(attachments && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPostJson('/api/periods/start-videos', attachments, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateTherapistNote(_a, session) {
    var patientId = _a.patientId, note = _a.note;
    return __awaiter(this, void 0, void 0, function () {
        var response, error_5;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/patient/update-therapist-note', { patientId: patientId, note: note }, session)];
                case 2:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
                case 3:
                    error_5 = _b.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updatePeriodSessions(_a, session) {
    var periodId = _a.periodId, sessionsDone = _a.sessionsDone;
    return __awaiter(this, void 0, void 0, function () {
        var response, error_6;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(periodId && session)) return [3 /*break*/, 4];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/period/update-sessions', { periodId: periodId, sessionsDone: sessionsDone }, session)];
                case 2:
                    response = _b.sent();
                    // TODO: Validate data
                    return [2 /*return*/, response.data];
                case 3:
                    error_6 = _b.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateStartQuestionnaireInPeriod(updateStartQuestionnaireRequest, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/periods/startquestionnaire', updateStartQuestionnaireRequest, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_7 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateQuestionnaireInPeriod(updateQuestionnaireRequest, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/periods/questionnaire', updateQuestionnaireRequest, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
                case 3:
                    error_8 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/, false];
            }
        });
    });
}
// FIXME: Go through this api call
export function getActivePeriods(session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/periods', session)];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 3:
                    error_9 = _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function hidePeriodFrom(session, periodId, hideFrom) {
    return __awaiter(this, void 0, void 0, function () {
        var error_10;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) {
                        return [2 /*return*/, false];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPost("/api/periods/hide-period", { periodId: periodId, hideFrom: hideFrom }, session)];
                case 2:
                    _a.sent();
                    return [2 /*return*/, true];
                case 3:
                    error_10 = _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, false];
            }
        });
    });
}
export function getAllPeriods(session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_11;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) {
                        return [2 /*return*/, null];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/periods/all', session)];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 3:
                    error_11 = _a.sent();
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getPeriodHistory(session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_12;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/periods/history', session)];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 3:
                    error_12 = _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getPeriodsAdmin(filters, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_13;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPostJson('/api/periods-admin', filters, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_13 = _a.sent();
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getTherapistPeriods(session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_14;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/periods/therapist-periods', session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_14 = _a.sent();
                    //console.error('getTherapistPeriods error: ', error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
/**
 * Returns filtered periods by passed PeriodFilter value. The endpoint takes only one param by call. Example: { filter: 'finishedPeriods' } -> returns all periods that are finished.
 * @param session UserSession
 * @param params parameter to filter the returned array with. If nothing is passed then all periods are returned.
 * @returns Period[], Example: { filter: 'finishedPeriods' } -> returns all periods that are finished.
 */
export function getFilteredPeriods(session, params) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_15;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/therapistperiodsfilter', session, params)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_15 = _a.sent();
                    if (axios.isAxiosError(error_15)) {
                        //console.error('getFilteredPeriods error message: ', error.message);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('getFilteredPeriods unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getAdminPeriod(periodId, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_16;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(periodId && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/adminperiod/' + periodId, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_16 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getTherapistPeriod(periodId, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_17;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(periodId && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/therapistperiods/' + periodId, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_17 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getPeriodsFromPatientForAdmin(patientId, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_18;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(patientId && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/patient-periods/' + patientId, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_18 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getPeriodAttachment(periodId, attachment, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_19;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(periodId && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/period/' + periodId + '/' + attachment, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_19 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
//FIXME: backend needs stream pipelines
export function getPeriodAttachmentUrl(periodId, attachment, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_20;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(periodId && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/fileurl/' + periodId + '/' + attachment, session)];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response];
                case 3:
                    error_20 = _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
// TODO: check if this really works correctly. should it be in use with async/await
export function setValidUntil(periodId, date, session) {
    return __awaiter(this, void 0, void 0, function () {
        var keyVal, response, error_21;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    keyVal = {
                        date: date,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPost('/api/setenddate/' + periodId, keyVal, session)];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response];
                case 3:
                    error_21 = _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function setPeriodAssignment(periodId, assignmentId, key, value, session) {
    return __awaiter(this, void 0, void 0, function () {
        var keyVal, response, error_22;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    keyVal = { id: assignmentId };
                    if (typeof value !== 'string') {
                        keyVal[key] = JSON.stringify(value);
                    }
                    else
                        keyVal[key] = value;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPost('/api/setassignment/' + periodId, keyVal, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_22 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function sendPeriodMessage(payload, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_23;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPostJson('/api/period/message/', payload, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_23 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getAndSetReadByMessagesActivePeriods(periodId, name, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_24;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/periods/set-read-by/', {
                            periodId: periodId,
                            name: name,
                        }, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_24 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function addAnotherGuardianToPeriod(filterData, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_25;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/periods/add-new-guardian', filterData, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_25 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
/**
 * This is not in use at the moment since removing periods is a problematic feature.
 */
export function deletePeriod(periodId, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_26;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiDelete('/api/periods/' + periodId, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_26 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function startPeriod(startPeriodRequest, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_27;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/periods/start-period', startPeriodRequest, session)];
                case 2:
                    response = _a.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_27 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function startExtensionPeriod(periodId, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!session) {
                        return [2 /*return*/, false];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut("/api/periods/start-extension-period", { periodId: periodId }, session)];
                case 2:
                    response = _b.sent();
                    if (response && response.status && response.status === 204) {
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    _a = _b.sent();
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function addQuestionnaire(startPeriodaddQuestionnaireRequest, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_28;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/periods/add-questionnaire', startPeriodaddQuestionnaireRequest, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_28 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function deletePeriodMessage(periodId, message, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_29;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(periodId && session && message)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/periods/message/delete', { periodId: periodId, message: message }, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        // TODO: Validate data
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_29 = _a.sent();
                    //console.error(error);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/, false];
            }
        });
    });
}
