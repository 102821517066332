var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Translate } from '../../../translation/Translate';
var ActionButtons = function (_a) {
    var expanded = _a.expanded, setExpanded = _a.setExpanded, saveNewGuardian = _a.saveNewGuardian, cancelNewGuardian = _a.cancelNewGuardian;
    var saveTranslate = Translate({ term: 'actions.save' });
    var cancelTranslate = Translate({ term: 'actions.cancel' });
    var newGuardianTranslate = Translate({ term: 'period.add_new_guardian' });
    return expanded ? (_jsxs("div", __assign({ className: "flex flex-row gap-4 mt-3 justify-end mr-4" }, { children: [_jsx("button", __assign({ onClick: saveNewGuardian }, { children: saveTranslate })), _jsx("button", __assign({ className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300", onClick: cancelNewGuardian }, { children: cancelTranslate }))] }))) : (_jsxs("div", __assign({ className: "flex flex-row" }, { children: [_jsx("div", { className: "w-1/3" }), _jsxs("div", __assign({ className: "flex flex-row mt-4 gap-2 cursor-pointer", onClick: function () { return setExpanded(true); } }, { children: [_jsx(AddCircleOutlineIcon, {}), _jsx("p", { children: newGuardianTranslate })] }))] })));
};
export default ActionButtons;
