import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { DefaultRoutes } from './routes/DefaultRoutes';
import { TherapistRoutes } from './routes/TherapistRoutes';
import { ClientRoutes } from './routes/ClientRoutes';
import { AdminRoutes } from './routes/AdminRoutes';
import { LicenseControllerRoutes } from './routes/LicenseControllerRoutes';
export var CreateRoutes = function () {
    var session = useSelector(function (state) { return state.user.session; });
    if (!!session && session.userType) {
        if (session.userType === 'therapist') {
            return _jsx(TherapistRoutes, {});
        }
        else if (session.userType === 'licenseController') {
            return _jsx(LicenseControllerRoutes, {});
        }
        else if (session.userType === 'customer') {
            return _jsx(ClientRoutes, {});
        }
        else if (session.userType === 'admin') {
            return _jsx(AdminRoutes, {});
        }
        else {
            return _jsx(DefaultRoutes, {});
        }
    }
    return _jsx(DefaultRoutes, {});
};
