var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from '../../components/translation/Translate';
export var Pagination = function (_a) {
    var activePage = _a.activePage, maxPage = _a.maxPage, switchPage = _a.switchPage;
    return (_jsxs("div", __assign({ className: "flex flex-row justify-between w-full max-w-6xl px-4" }, { children: [activePage > 1 ? (_jsx("button", __assign({ onClick: function () { return switchPage(activePage - 1); }, className: "mt-3 -ml-4 border-2 border-gray-200 bg-white text-black hover:bg-gray-200 duration-300" }, { children: Translate({ term: 'actions.previous' }) }))) : (_jsx("button", __assign({ className: "cursor-default mt-3 -ml-4 border-2 border-gray-200 bg-white text-gray-500" }, { children: Translate({ term: 'actions.previous' }) }))), _jsxs("div", __assign({ className: "mt-6 flex flex-row" }, { children: [Translate({ term: 'common.page' }), ' ', _jsxs("p", __assign({ className: "ml-1 font-bold" }, { children: [activePage, " / ", maxPage] }))] })), activePage < maxPage ? (_jsx("button", __assign({ onClick: function () { return switchPage(activePage + 1); }, className: "mt-3 -mr-4 border-2 border-gray-200 bg-white text-black hover:bg-gray-200 duration-300" }, { children: Translate({ term: 'actions.next' }) }))) : (_jsx("button", __assign({ className: "cursor-default mt-3 -mr-4 border-2 border-gray-200 bg-white text-gray-500" }, { children: Translate({ term: 'actions.next' }) })))] })));
};
