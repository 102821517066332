import { jsx as _jsx } from "react/jsx-runtime";
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react';
import { useSelector, useDispatch } from 'react-redux';
import { setFormField } from '../../../reducers/adminQuerySlice';
var creatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
};
export function SurveyCreatorWidget() {
    var dispatch = useDispatch();
    var formData = useSelector(function (state) { return state.adminQuery.formData; });
    var queryURL = formData.queryURL;
    var creator = new SurveyCreator(creatorOptions);
    var queryType = typeof queryURL;
    if (queryType === 'string')
        creator.text = queryURL;
    if (queryType === 'object')
        creator.text = JSON.stringify(queryURL);
    creator.saveSurveyFunc = function () {
        dispatch(setFormField({ queryURL: creator.text }));
    };
    return _jsx(SurveyCreatorComponent, { creator: creator });
}
