import { jsx as _jsx } from "react/jsx-runtime";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './CKEditorStyle.css';
var config = {
    toolbar: {
        items: [
            'heading',
            '|',
            'bold',
            'italic',
            '|',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo',
        ],
    },
};
var CkEditorComponent = function (_a) {
    var setTextMaterial = _a.setTextMaterial, setMaterial = _a.setMaterial, material = _a.material;
    return (_jsx(CKEditor, { config: config, editor: ClassicEditor, data: (material === null || material === void 0 ? void 0 : material.description) || '', onChange: function (event, editor) {
            var data = editor.getData();
            if (setTextMaterial)
                setTextMaterial(data);
            if (material && setMaterial) {
                setMaterial(data);
            }
        } }));
};
export default CkEditorComponent;
