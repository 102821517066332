var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Translate } from '../../../translation/Translate';
var AllDoneMessage = function () {
    return (_jsx(React.Fragment, { children: _jsx("main", __assign({ className: "px-3 py-4 md:px-8 max-w-screen-xl m-auto" }, { children: _jsx("div", __assign({ className: "bg-white border border-cyan px-3 py-4 rounded-md shadow-lg" }, { children: _jsx("div", __assign({ className: "py-4 bg-white justify-items-center items-center" }, { children: _jsx("div", __assign({ className: "grid place-items-center" }, { children: _jsxs("div", __assign({ className: "justify-items-center items-center" }, { children: [_jsx("h3", __assign({ className: "mb-2 font-semibold text-xl text-grey-600 text-center" }, { children: Translate({
                                        term: 'start_questionnaire.all_done_message.message',
                                    }) })), _jsx("p", __assign({ className: "text-center" }, { children: Translate({
                                        term: 'start_questionnaire.all_done_message.message2',
                                    }) }))] })) })) })) })) })) }));
};
export default AllDoneMessage;
