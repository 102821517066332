var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserinfo, updateTherapistAvailability, } from '../../../actions/userActions';
import AccordionPanel from '../../common/AccordionPanel';
import { Translate } from '../../translation/Translate';
import { setUser } from '../../../reducers/userSlice';
import { getTherapistOwnAvailabilities } from '../../../actions/therapistActions';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import useUser from '../../../hooks/useUser';
var OwnAvailability = function () {
    var dispatch = useDispatch();
    var user = useUser();
    var clientsText = Translate({ term: 'profile.clients' });
    var _a = __read(useState([]), 2), availabilities = _a[0], setAvailabilities = _a[1];
    var saveSuccessTranslate = Translate({
        term: 'common.save_success',
    });
    var saveFailedTranslate = Translate({
        term: 'common.save_failed',
    });
    var getTherapistAvailabilityFunction = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTherapistOwnAvailabilities(user.session)];
                case 1:
                    data = _a.sent();
                    if (data) {
                        setAvailabilities(data);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        getTherapistAvailabilityFunction();
    }, []);
    var showToastMessage = function (value, message) {
        if (value === 'success') {
            toast(message, {
                position: 'top-center',
                hideProgressBar: true,
                transition: Zoom,
                closeButton: false,
                autoClose: 2000,
                toastId: 'successId',
                className: 'toast-message-success',
            });
        }
        else if (value === 'warning') {
            toast(message, {
                position: 'top-center',
                hideProgressBar: true,
                transition: Zoom,
                closeButton: false,
                autoClose: 2000,
                toastId: 'warningId',
                className: 'toast-message-warning',
            });
        }
    };
    function updateAvailability() {
        return __awaiter(this, void 0, void 0, function () {
            var response, userData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, updateTherapistAvailability(availabilities, user.session)];
                    case 1:
                        response = _a.sent();
                        if (!response) return [3 /*break*/, 3];
                        return [4 /*yield*/, getUserinfo(user.session)];
                    case 2:
                        userData = _a.sent();
                        if (userData) {
                            showToastMessage('success', saveSuccessTranslate);
                            dispatch(setUser(userData));
                        }
                        else {
                            showToastMessage('warning', saveFailedTranslate);
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    function updateAvailabilityData(amount, id) {
        var amountNumber = Number(amount);
        if (amountNumber >= 0) {
            var updatedAvailability = availabilities.map(function (availability) {
                if (availability.moduleId === id) {
                    return { moduleId: id, amount: Number(amount) };
                }
                return availability;
            });
            setAvailabilities(updatedAvailability);
        }
    }
    return (_jsxs("div", { children: [_jsx(ToastContainer, { limit: 1 }), _jsx(AccordionPanel, __assign({ summary: _jsx("h3", { children: Translate({ term: 'profile.personal_availability' }) }), summaryClosed: _jsx(_Fragment, {}), actions: [
                    _jsx("button", __assign({ onClick: updateAvailability }, { children: Translate({ term: 'actions.save' }) }), "availabilitySummaryButton"),
                ] }, { children: _jsx("div", __assign({ className: "flex flex-col gap-2 my-4" }, { children: _jsx("div", { children: _jsx("table", { children: _jsx("tbody", { children: availabilities.map(function (availability) {
                                    return (_jsxs("tr", { children: [_jsx("td", __assign({ className: "pr-6" }, { children: Translate({
                                                    term: "therapy.modules.m".concat(availability.moduleId),
                                                }) })), _jsxs("td", __assign({ className: "text-right" }, { children: [_jsx("input", { type: "number", min: "0", onChange: function (e) {
                                                            return updateAvailabilityData(e.target.value, availability.moduleId);
                                                        }, defaultValue: availability.amount, className: "w-12" }), ' ', clientsText] }))] }, availability.moduleId));
                                }) }) }) }) })) }))] }));
};
export default OwnAvailability;
