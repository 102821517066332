var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
var MessageAttachment = function (_a) {
    var index = _a.index, attachmentName = _a.attachmentName, removeAttachment = _a.removeAttachment;
    return (_jsxs("div", __assign({ className: "rounded-md bg-gray-200 text-gray-700 pl-4 py-1 pr-10 relative" }, { children: [attachmentName, _jsx("div", __assign({ className: "absolute top-0 right-0 pt-0.5 pr-2" }, { children: _jsx("div", __assign({ className: "text-warning-600 cursor-pointer", onClick: function () { return removeAttachment(index); } }, { children: _jsx(HighlightOffIcon, {}) })) }))] })));
};
export default MessageAttachment;
