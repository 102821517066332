var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { createPostForTherapists, getPostsForAdmin, } from '../../actions/postActions';
import Header from '../../components/licenseController/Header';
import { Translate } from '../../components/translation/Translate';
import PostModal from '../../components/admin/modals/posts/PostModal';
import CreatePost from '../../components/admin/posts/CreatePost';
import TablePosts from '../../components/admin/posts/TablePosts';
import { useDispatch, useSelector } from 'react-redux';
import { getUserinfo } from '../../actions/userActions';
import { setLanguage } from '../../reducers/settingsSlice';
import { setUser } from '../../reducers/userSlice';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { Pagination } from '../../components/common/Pagination';
import useUser from '../../hooks/useUser';
var Posts = function () {
    var _a;
    var dispatch = useDispatch();
    var user = useUser();
    var userinfo = useSelector(function (state) { return state.user.userinfo; });
    var userinfoLang = useSelector(function (state) { var _a; return (_a = state.settings) === null || _a === void 0 ? void 0 : _a.language; });
    var postForTherapistTranslate = Translate({
        term: 'messages.post_for_therapists',
    });
    var _b = __read(useState({
        subject: '',
        message: '',
        posts: [],
        reverse: true,
        sort: 'created',
        selectedPost: null,
        lastPage: 1,
        open: false,
    }), 2), postData = _b[0], setPostData = _b[1];
    var _c = __read(useState(1), 2), page = _c[0], setPage = _c[1];
    useEffect(function () {
        if (!userinfo)
            fetchUserInfo();
        getPosts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, postData.sort, postData.reverse]);
    var fetchUserInfo = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var userData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!userinfo) return [3 /*break*/, 2];
                    return [4 /*yield*/, getUserinfo(user.session)];
                case 1:
                    userData = _a.sent();
                    if (userData) {
                        if (!userinfoLang && userData.language)
                            dispatch(setLanguage(userData.language));
                        dispatch(setUser(userData));
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [dispatch, (_a = user.session) === null || _a === void 0 ? void 0 : _a.accessToken, userinfo, userinfoLang]);
    function handleSetSort(id) {
        if (postData.sort && id === postData.sort) {
            setPostData(__assign(__assign({}, postData), { reverse: !postData.reverse }));
        }
        else {
            setPostData(__assign(__assign({}, postData), { sort: id, reverse: !postData.reverse }));
        }
    }
    var getPosts = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getPostsForAdmin(page, postData.sort, postData.reverse ? -1 : 1, user.session)
                        .then(function (data) {
                        if (data) {
                            setPostData(__assign(__assign({}, postData), { posts: data.posts, lastPage: data.totalPages, subject: '', message: '', selectedPost: null }));
                        }
                    })
                        .catch(function (err) {
                        //console.error(err);
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var sendMessage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newPost;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(postData.subject !== '' && postData.message !== '')) return [3 /*break*/, 2];
                    newPost = {
                        subject: postData.subject,
                        message: postData.message,
                    };
                    return [4 /*yield*/, createPostForTherapists(newPost, user.session)
                            .then(function (data) {
                            if (data) {
                                getPosts();
                                page !== 1 ? setPage(1) : null;
                                showToastMessage(postForTherapistTranslate);
                            }
                        })
                            .catch(function (err) {
                            //console.error(err);
                        })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var showToastMessage = function (info) {
        toast(info, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 2000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
    };
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: Translate({ term: 'home.info' }) }), _jsx(ToastContainer, { limit: 1 }), _jsx(PostModal, { showToastMessage: showToastMessage, postData: postData, setPostData: setPostData, getPosts: getPosts, session: user.session }), _jsx("main", { children: _jsxs("div", __assign({ className: "px-9 py-4" }, { children: [_jsx("p", __assign({ className: "text-xl font-normal" }, { children: Translate({ term: 'actions.create_new_therapist_post' }) })), _jsx(CreatePost, { postData: postData, setPostData: setPostData, sendMessage: sendMessage }), _jsx(TablePosts, { postData: postData, setPostData: setPostData, handleSetSort: handleSetSort }), _jsx(Pagination, { activePage: page, maxPage: postData.lastPage, switchPage: setPage })] })) })] }));
};
export default Posts;
