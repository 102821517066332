var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { Translate } from '../../translation/Translate';
var MainNav = function () {
    var menuItems = [
        {
            title: Translate({ term: 'home.info' }),
            path: '/',
        },
        {
            title: Translate({ term: 'material_library.materials' }),
            path: '/materials',
        },
        {
            title: Translate({ term: 'nav.questioinnaires' }),
            path: '/query',
        },
        {
            title: Translate({ term: 'licenses.licenses' }),
            path: '/license',
        },
        {
            title: Translate({ term: 'nav.periods' }),
            path: '/periods',
        },
        {
            title: Translate({ term: 'nav.users' }),
            path: '/users',
        },
    ];
    var IsLinkActive = function (path) {
        var resolved = useResolvedPath(path);
        return useMatch({
            path: path === '/' ? resolved.pathname : path + '/',
        });
    };
    return (_jsx("ul", { children: menuItems.map(function (item, key) {
            var match = IsLinkActive(item.path);
            return (_jsx("li", __assign({ className: "p-0 my-2" }, { children: _jsx(NavLink, __assign({ to: item.path, className: match
                        ? 'flex w-11/12 px-2 ml-3 gap-2 py-2 rounded-md bg-white border-2 border-cyan'
                        : 'flex w-11/12 px-2 ml-3 gap-2 py-2 rounded-md bg-cyan border-2 border-cyan hover:bg-white duration-300' }, { children: item.title })) }), key));
        }) }));
};
export default MainNav;
