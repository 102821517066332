var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SportsEsportsSharpIcon from '@mui/icons-material/SportsEsportsSharp';
import Moment from 'react-moment';
import { GAME_URL } from '../../../config/constants';
import { Translate } from '../../translation/Translate';
var GameMaterial = function (_a) {
    var material = _a.material;
    var link;
    if (material.settings &&
        material.settings.assignmentId &&
        material.settings.assignmentId[0] &&
        material.settings.gameMotor) {
        link = (_jsx("div", __assign({ className: "mt-4" }, { children: _jsx("a", __assign({ href: GAME_URL + '/game.php?peli=m' +
                    material.settings.gameMotor +
                    '&laksynId=' +
                    material.settings.assignmentId[0], target: "_blank", className: "btn", rel: "noreferrer" }, { children: Translate({ term: 'material_library.open_game_to_new_window' }) })) })));
    }
    else if (material.idNumber && material.idNumber[0]) {
        link = (_jsx("div", __assign({ className: "mt-4" }, { children: _jsx("a", __assign({ href: GAME_URL + '/game.php?peli=' + material.idNumber[0], target: "_blank", className: "btn", rel: "noreferrer" }, { children: Translate({ term: 'material_library.open_game_to_new_window' }) })) })));
    }
    return (_jsxs("div", { children: [_jsxs("h2", __assign({ className: "text-blue" }, { children: [_jsx(SportsEsportsSharpIcon, {}), " ", material.name] })), _jsx("div", { children: _jsxs("div", __assign({ className: "lg:col-span-2" }, { children: [_jsx("div", __assign({ className: "mt-2" }, { children: _jsxs("p", __assign({ className: "text-sm text-gray-500" }, { children: [Translate({ term: 'common.last_modified' }), ' ', _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: material.modified ? material.modified : material.created }))] })) })), link] })) })] }));
};
export default GameMaterial;
