var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, } from '@mui/material';
export var Select = function (_a) {
    var selectProps = _a.selectProps, datas = _a.datas, emptyOptionValue = _a.emptyOptionValue, emptyOptionText = _a.emptyOptionText, labelColor = _a.labelColor, width = _a.width;
    var labelId = selectProps.labelId, label = selectProps.label;
    return (_jsxs(FormControl, __assign({ size: "small", sx: width ? { width: width, backgroundColor: 'white' } : { width: '250px' } }, { children: [_jsx(InputLabel, __assign({ style: labelColor ? { color: labelColor } : undefined, id: labelId }, { children: label })), _jsxs(MuiSelect, __assign({}, selectProps, { children: [emptyOptionValue ? (_jsx(MenuItem, __assign({ value: emptyOptionValue }, { children: emptyOptionText }))) : null, datas === null || datas === void 0 ? void 0 : datas.map(function (data, index) { return (_jsx(MenuItem, __assign({ value: typeof data === 'string' || typeof data === 'number'
                            ? data
                            : data.value }, { children: typeof data === 'string' || typeof data === 'number'
                            ? data
                            : data.optionText
                                ? data.optionText
                                : data.value }), index)); })] }))] })));
};
