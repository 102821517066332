var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MainNav from './MainNav';
import { Translate } from '../../translation/Translate';
import MenuHeader from './MenuHeader';
import { logout } from '../../../functions/logout';
import LanguageSelector from '../../common/LanguageSelector';
import useUser from '../../../hooks/useUser';
var MobileNavigation = function (_a) {
    var iconsOnly = _a.iconsOnly, showSideBarFunction = _a.showSideBarFunction;
    var user = useUser();
    return (_jsxs("nav", __assign({ className: "sidebar mb-8 mt-4 md:-ml-1" }, { children: [_jsx(MenuHeader, { iconsOnly: iconsOnly }), _jsx(MainNav, { iconsOnly: iconsOnly, classname: '', showSideBarFunction: showSideBarFunction }), _jsx("div", __assign({ className: "absolute bottom-0 left-0 flex p-3 md:p-5 gap-2 rounded-md hover:bg-grey-50 hover:bg-opacity-50", onClick: showSideBarFunction }, { children: _jsx(LanguageSelector, {}) })), _jsx("div", __assign({ className: "absolute bottom-0 right-0 flex p-3 md:p-5 gap-2 rounded-md hover:bg-grey-50 hover:bg-opacity-50", onClick: showSideBarFunction }, { children: _jsx("button", __assign({ onClick: function () { return logout(user.session); }, className: "bg-white hover:bg-blue duration-300 hover:text-white text-black border-2 border-blue rounded-md" }, { children: Translate({ term: 'nav.log_out' }) })) }))] })));
};
export default MobileNavigation;
