var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import logo from '../../../assets/images/ak-logo.png';
import { Translate } from '../../translation/Translate';
var MenuHeader = function (_a) {
    var iconsOnly = _a.iconsOnly;
    var siteSecondaryName = Translate({ term: 'site.secondary_name' });
    return (_jsx("div", __assign({ className: "lg:mb-4 absolute top-5 right-0 lg:relative lg:top-0 flex flex-col gap-3 px-6 -ml-3 lg:ml-0" }, { children: _jsxs("div", __assign({ className: "flex items-center gap-2 flex-none" }, { children: [_jsx("div", __assign({ className: "w-8 h-8 bg-white border border-blue border-opacity-25 p-1 shadow-md rounded-md overflow-hidden flex justify-items-center items-center" }, { children: _jsx("img", { alt: Translate({ term: 'site.name_short' }), src: logo }) })), iconsOnly ? ('') : (_jsx("div", __assign({ className: "text-lg text-blue font-semibold" }, { children: siteSecondaryName })))] })) })));
};
export default MenuHeader;
