var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../translation/Translate';
import { defaultLanguange } from '../../../config/languageSettings';
import Avatar from '../../common/Avatar';
import { Translate } from '../../translation/Translate';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
var ProsessedEvaluationsTable = function (_a) {
    var filters = _a.filters, columns = _a.columns, evaluations = _a.evaluations;
    var userLang = useContext(LanguageContext);
    var _b = __read(React.useState(null), 2), sort = _b[0], setSort = _b[1];
    var _c = __read(React.useState(false), 2), reverse = _c[0], setReverse = _c[1];
    var navigate = useNavigate();
    function handleSetSort(id) {
        if (sort && id === sort) {
            setReverse(!reverse);
        }
        else {
            setSort(id);
            setReverse(false);
        }
    }
    function getModuleName(period) {
        if (period.module) {
            if (period.module.name[userLang]) {
                return period.module.name[userLang];
            }
            else {
                return period.module.name[defaultLanguange];
            }
        }
        else {
            return '<Oikea otsikko tähän>';
        }
    }
    var headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'home.client' }),
            content: function (period) {
                var _a, _b, _c, _d;
                return (_jsx(Avatar, { firstName: (_b = (_a = period.patient) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : '', lastName: (_d = (_c = period.patient) === null || _c === void 0 ? void 0 : _c.lastName) !== null && _d !== void 0 ? _d : '' }));
            },
        },
        {
            id: 'ageGroupType',
            numeric: true,
            disablePadding: false,
            label: Translate({ term: 'evaluations.age_group' }),
            content: function (period) { return '2/12'; },
            align: 'right',
        },
        {
            id: 'zone',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'zone.zone_name' }),
            content: function (period) { return getModuleName(period); },
        },
        {
            id: 'evaluated',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'evaluations.evaluate' }),
            content: function (period) { return getModuleName(period); },
        },
    ];
    var selectEvaluation = function (event, id) {
        navigate('/evaluations/' + id);
    };
    if (evaluations) {
        return (_jsxs("table", __assign({ className: "border-collapse bg-white shadow-md w-full" }, { children: [_jsx("thead", { children: _jsx("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2" }, { children: headCells.map(function (headCell, key) {
                            if (headCell.id !== 'action' &&
                                (!columns || columns.includes(headCell.id))) {
                                var classes = [
                                    'px-4 py-4 text-left border-b border-gray-400 cursor-pointer',
                                ];
                                var aClasses = ['text-blue ml-1'];
                                if (sort && sort === headCell.id) {
                                    classes.push('');
                                    aClasses.push('opacity-100');
                                }
                                else {
                                    aClasses.push('opacity-25');
                                }
                                var sortIcon = sort && sort === headCell.id && reverse === true ? (_jsx(ArrowUpwardIcon, { className: aClasses.join(' '), fontSize: "small" })) : (_jsx(ArrowDownwardIcon, { className: aClasses.join(' '), fontSize: "small" }));
                                return (_jsxs("th", __assign({ className: classes.join(' '), onClick: function () { return handleSetSort(headCell.id); } }, { children: [headCell.label, sortIcon] }), key));
                            }
                        }) })) }), _jsx("tbody", { children: evaluations.map(function (evaluation, key) {
                        var _a, _b;
                        return (_jsxs("tr", __assign({ className: "text-sm hover:bg-gray-100", onClick: function (event) { return selectEvaluation(event, evaluation._id); } }, { children: [_jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: _jsx(Avatar, { firstName: (_a = evaluation.patient) === null || _a === void 0 ? void 0 : _a.firstName, lastName: (_b = evaluation.patient) === null || _b === void 0 ? void 0 : _b.lastName }) }), key + '1'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: Translate({
                                        term: "client.ageGroupType.".concat(evaluation === null || evaluation === void 0 ? void 0 : evaluation.ageGroupType),
                                    }) }), key + '2'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: evaluation.zone.name }), key + '3'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: evaluation.ready })) }), key + '4')] }), key));
                    }) })] })));
    }
    else {
        return _jsx("div", { children: "loading" });
    }
};
export default ProsessedEvaluationsTable;
