var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    evaluationInvitations: [],
    evaluations: []
};
var EvaluationSlice = createSlice({
    name: "evaluations",
    initialState: initialState,
    reducers: {
        setEvaluationInvitations: function (state, action) {
            if (action.payload === null) {
                state.evaluationInvitations = [];
            }
            else {
                state.evaluationInvitations = action.payload;
            }
        },
        setEvaluation: function (state, action) {
            if (action.payload === null) {
                state.evaluations = [];
            }
            else {
                state.evaluations = action.payload;
            }
        },
    }
});
export var setEvaluationInvitations = (_a = EvaluationSlice.actions, _a.setEvaluationInvitations), setEvaluation = _a.setEvaluation;
export default EvaluationSlice;
