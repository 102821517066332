var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import EditQuery from '../../components/admin/query/EditQuery';
import AdminLayout from '../../components/layouts/AdminLayout';
import Licenses from '../admin/Licenses';
import Materials from '../admin/Materials';
import Periods from '../admin/Periods';
import Posts from '../admin/Posts';
import Query from '../admin/Query';
import Users from '../admin/Users';
import Zone from '../admin/Zone';
export var AdminRoutes = function () {
    return (_jsx(Suspense, __assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(Routes, { children: _jsxs(Route, __assign({ path: "/", element: _jsx(AdminLayout, {}) }, { children: [_jsx(Route, { index: true, element: _jsx(Posts, {}) }), _jsx(Route, { path: "/materials", element: _jsx(Materials, {}) }), _jsx(Route, { path: "/query", element: _jsx(Query, {}) }), _jsx(Route, { path: "/query/:id", element: _jsx(EditQuery, {}) }), _jsx(Route, { path: "/license", element: _jsx(Licenses, {}) }), _jsx(Route, { path: "/license/zone/:id", element: _jsx(Zone, {}) }), _jsx(Route, { path: "/periods", element: _jsx(Periods, {}) }), _jsx(Route, { path: "/users", element: _jsx(Users, {}) }), _jsx(Route, { path: "*", element: _jsx(NoMatch, {}) })] })) }) })));
};
function NoMatch() {
    return (_jsxs("div", { children: [_jsx("h2", { children: "Nothing to see here!" }), _jsx("p", { children: _jsx(Link, __assign({ to: "/" }, { children: "Go to the home page" })) })] }));
}
