var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createEntityAdapter, } from '@reduxjs/toolkit';
import { paginatedInitialState } from './periodSlice';
var adminPeriodsAdapter = createEntityAdapter();
var initialState = adminPeriodsAdapter.getInitialState({
    periodFormData: {
        firstName: '',
        lastName: '',
        email: '',
        emailVerify: '',
        phone: '',
        municipality: '',
        notes: '',
        name: '',
    },
    searchParams: {
        lastName: '',
        birthyear: 0,
        email: '',
        therapyType: '',
        module: '',
        zone: '',
        startDate: null,
        endDate: null,
        periodFilterState: 'lookAllPeriods',
        page: 1,
        order: 1,
        sort: 'created',
        therapist: '',
    },
    searchTags: [],
    selectedPeriods: [],
    periodState: 'listing',
    modalState: 'listing',
    isLoading: false,
    paginatedPeriods: paginatedInitialState,
});
var slice = createSlice({
    name: 'adminPeriods',
    initialState: initialState,
    reducers: {
        setPeriodFormDataField: function (state, action) {
            state.periodFormData = __assign(__assign({}, state.periodFormData), action.payload);
        },
        setSearchParamsField: function (state, action) {
            state.searchParams = __assign(__assign({}, state.searchParams), action.payload);
        },
        setSearchTags: function (state, action) {
            var tagFound = state.searchTags.findIndex(function (tag) { return tag.label === action.payload.label; });
            if (tagFound > -1 && action.payload.value) {
                state.searchTags[tagFound] = action.payload;
            }
            else if (tagFound > -1 && !action.payload.value) {
                state.searchTags = state.searchTags.filter(function (tag) { return tag.label !== action.payload.label; });
            }
            else {
                state.searchTags = __spreadArray(__spreadArray([], __read(state.searchTags), false), [action.payload], false);
            }
        },
        setSelectedPeriods: function (state, action) {
            var periodFound = state.selectedPeriods.find(function (periodId) { return periodId === action.payload; });
            if (periodFound) {
                state.selectedPeriods = state.selectedPeriods.filter(function (periodId) { return periodId !== action.payload; });
            }
            else {
                state.selectedPeriods = __spreadArray(__spreadArray([], __read(state.selectedPeriods), false), [action.payload], false);
            }
        },
        setSelectAllPeriods: function (state, action) {
            state.selectedPeriods = action.payload;
        },
        setModalState: function (state, action) {
            if (action.payload === 'listing') {
                state.periodFormData = initialState.periodFormData;
                state.modalState = initialState.modalState;
            }
            else {
                state.modalState = action.payload;
            }
        },
        setPeriods: function (state, action) {
            state.paginatedPeriods = action.payload;
        },
        setPeriodState: function (state, action) {
            if (action.payload === 'listing') {
                state.periodState = action.payload;
                state.selectedPeriods = initialState.selectedPeriods;
            }
            else {
                state.periodState = action.payload;
            }
        },
        clearStates: function (state) {
            state.searchParams = initialState.searchParams;
            state.modalState = initialState.modalState;
            state.periodFormData = initialState.periodFormData;
            state.searchTags = initialState.searchTags;
            state.paginatedPeriods = initialState.paginatedPeriods;
        },
        clearPaginatedPeriods: function (state) {
            state.paginatedPeriods = initialState.paginatedPeriods;
        },
    },
});
export var setSearchParamsField = (_a = slice.actions, _a.setSearchParamsField), clearStates = _a.clearStates, setSearchTags = _a.setSearchTags, setPeriods = _a.setPeriods, setSelectedPeriods = _a.setSelectedPeriods, setSelectAllPeriods = _a.setSelectAllPeriods, setPeriodState = _a.setPeriodState, clearPaginatedPeriods = _a.clearPaginatedPeriods, setModalState = _a.setModalState;
export default slice;
