var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createEntityAdapter, } from '@reduxjs/toolkit';
var adminMaterialAdapter = createEntityAdapter();
var initialState = adminMaterialAdapter.getInitialState({
    formData: {
        _id: null,
        modules: [],
        name: '',
        language: "fi",
        type: 'text',
        description: '',
        therapistInstructions: '',
        patientInstructions: '',
    },
    modalState: 'listing',
});
var slice = createSlice({
    name: 'adminMaterial',
    initialState: initialState,
    reducers: {
        setFormField: function (state, action) {
            state.formData = __assign(__assign({}, state.formData), action.payload);
        },
        setModalState: function (state, action) {
            if (action.payload === 'listing') {
                state.formData = initialState.formData;
                state.modalState = initialState.modalState;
            }
            else {
                state.modalState = action.payload;
            }
        },
        clearFormState: function (state) {
            state.formData = initialState.formData;
            state.modalState = initialState.modalState;
        },
    },
});
export var setFormField = (_a = slice.actions, _a.setFormField), setModalState = _a.setModalState, clearFormState = _a.clearFormState;
export default slice;
