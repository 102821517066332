export function validateSsn(value) {
    if (value && /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([0-9][0-9][+\-A])\d{3}[\dA-Z]$/.test(value)) {
        return true;
    }
    return false;
}
export function validateEmail(value) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return true;
    }
    return false;
}
