var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { getModulesAdmin } from '../../../actions/moduleActions';
import { createTherapist } from '../../../actions/userActions';
import { getZones } from '../../../actions/zoneActions';
import { Translate } from '../../translation/Translate';
import './selectItemStyle.css';
import SelectDataArrayComponent from './SelectDataArrayComponent';
import useUser from '../../../hooks/useUser';
var Therapists = function () {
    var user = useUser();
    var selectZoneTranslate = Translate({ term: 'common.choose_zone' });
    var selectEvaluationZoneTranslate = Translate({
        term: 'common.choose_evaluation_zone',
    });
    var selectModuleTranslate = Translate({ term: 'therapy.select_module' });
    var creatingFailedTranslate = Translate({ term: 'common.creating_failed' });
    var newTherapistCreatedTranslate = Translate({
        term: 'common.new_therapist_was_created',
    });
    var firstnameMissingTranslate = Translate({
        term: 'common.firstname_missing',
    });
    var lastnameMissingTranslate = Translate({
        term: 'common.lastname_missing',
    });
    var emailMissingTranslate = Translate({
        term: 'common.email_missing',
    });
    var phoneMissingTranslate = Translate({
        term: 'common.phone_missing',
    });
    var emailsDontMatchTranslate = Translate({
        term: 'common.emails_dont_match',
    });
    var chooseZoneTranslate = Translate({
        term: 'common.choose_zone',
    });
    var chooseModuleTranslate = Translate({
        term: 'therapy.select_module',
    });
    var _a = __read(useState(''), 2), emailVerify = _a[0], setEmailVerify = _a[1];
    var _b = __read(useState(''), 2), infoText = _b[0], setInfoText = _b[1];
    var _c = __read(useState([]), 2), modules = _c[0], setModules = _c[1];
    var _d = __read(useState([]), 2), zones = _d[0], setZones = _d[1];
    var _e = __read(useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        zones: [],
        modules: [],
        evaluationZones: [],
        therapyModels: {
            online: false,
            onsite: false,
            remote: false,
            hybrid: false,
            evaluations: false,
        },
        isOverflowTherapist: false,
    }), 2), newTherapist = _e[0], setNewTherapist = _e[1];
    var _f = __read(useState([]), 2), moduleArr = _f[0], setModuleArr = _f[1];
    var _g = __read(useState([]), 2), zoneArr = _g[0], setZoneArr = _g[1];
    var _h = __read(useState([]), 2), evaluationZonesArr = _h[0], setEvaluationZonesArr = _h[1];
    useEffect(function () {
        getModules();
        getZoneNames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var getModules = function () { return __awaiter(void 0, void 0, void 0, function () {
        var modulesData, modulesArray, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getModulesAdmin(user.session)];
                case 1:
                    modulesData = _a.sent();
                    if (modulesData) {
                        modulesArray = modulesData.map(function (item) {
                            return {
                                value: item._id,
                                optionText: item.name.fi || item.name.se,
                            };
                        });
                        setModules(sortBy(modulesArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getZoneNames = function () { return __awaiter(void 0, void 0, void 0, function () {
        var zonesData, zonesArray, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getZones(user.session)];
                case 1:
                    zonesData = _a.sent();
                    if (zonesData) {
                        zonesArray = zonesData.map(function (item) {
                            return {
                                value: item._id,
                                optionText: item.name,
                            };
                        });
                        setZones(sortBy(zonesArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var formIsValid = function () {
        var errorMessages = [];
        newTherapist.firstName === ''
            ? errorMessages.push(firstnameMissingTranslate)
            : null;
        newTherapist.lastName === ''
            ? errorMessages.push(lastnameMissingTranslate)
            : null;
        newTherapist.email === ''
            ? errorMessages.push(emailMissingTranslate)
            : null;
        newTherapist.phone === ''
            ? errorMessages.push(phoneMissingTranslate)
            : null;
        newTherapist.email !== emailVerify
            ? errorMessages.push(emailsDontMatchTranslate)
            : null;
        zoneArr.length === 0 ? errorMessages.push(chooseZoneTranslate) : null;
        moduleArr.length === 0 ? errorMessages.push(chooseModuleTranslate) : null;
        return errorMessages;
    };
    var createNewTherapist = function () {
        var errorMessages = formIsValid();
        if (errorMessages.length === 0) {
            var newTherapistRequest = {
                firstName: newTherapist.firstName,
                lastName: newTherapist.lastName,
                email: newTherapist.email,
                phone: newTherapist.phone,
                zones: zoneArr,
                modules: moduleArr,
                therapyModels: newTherapist.therapyModels,
                isOverflowTherapist: newTherapist.isOverflowTherapist,
                evaluationZones: evaluationZonesArr,
            };
            createTherapist(newTherapistRequest, user.session)
                .then(function (data) {
                if (data) {
                    clearFields();
                    showToastMessage();
                }
                else {
                    setInfoText(creatingFailedTranslate);
                }
            })
                .catch(function (err) {
                //console.error(err);
            });
        }
        else {
            setInfoText(errorMessages.join(', '));
        }
    };
    var showToastMessage = function () {
        toast(newTherapistCreatedTranslate, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 2000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
    };
    var clearFields = function () {
        setNewTherapist({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            zones: [],
            modules: [],
            evaluationZones: [],
            therapyModels: {
                online: false,
                onsite: false,
                remote: false,
                hybrid: false,
                evaluations: false,
            },
            isOverflowTherapist: false,
        });
        setModuleArr([]);
        setZoneArr([]);
        setEvaluationZonesArr([]);
        setEmailVerify('');
        setInfoText('');
    };
    function removeZone(index) {
        setZoneArr(zoneArr.filter(function (el, i) { return i !== index; }));
    }
    function removeEvaluationZone(index) {
        setEvaluationZonesArr(evaluationZonesArr.filter(function (el, i) { return i !== index; }));
    }
    function removeModule(index) {
        setModuleArr(moduleArr.filter(function (el, i) { return i !== index; }));
    }
    var changeTherapyModel = function (index) {
        setNewTherapist(__assign(__assign({}, newTherapist), { therapyModels: {
                online: index === 0
                    ? !newTherapist.therapyModels.online
                    : newTherapist.therapyModels.online,
                onsite: index === 1
                    ? !newTherapist.therapyModels.onsite
                    : newTherapist.therapyModels.onsite,
                remote: index === 2
                    ? !newTherapist.therapyModels.remote
                    : newTherapist.therapyModels.remote,
                hybrid: index === 3
                    ? !newTherapist.therapyModels.hybrid
                    : newTherapist.therapyModels.hybrid,
                evaluations: index === 4
                    ? !newTherapist.therapyModels.evaluations
                    : newTherapist.therapyModels.evaluations,
            } }));
    };
    return (_jsxs("div", __assign({ className: "mt-6", style: { width: '700px' } }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx("h2", __assign({ className: "text-2xl font-semibold" }, { children: Translate({ term: 'common.create_new_therapist' }) })), _jsxs("p", __assign({ className: "flex justify-end text-gray-600 mt-4 -mb-2" }, { children: ["*", Translate({ term: 'common.required' })] })), _jsxs("div", __assign({ className: "flex justify-between mt-4" }, { children: [_jsxs("label", __assign({ className: "w-4/12 mt-2" }, { children: [Translate({ term: 'profile.user_names' }), "*"] })), _jsxs("div", __assign({ className: "flex justify-between w-8/12 gap-4" }, { children: [_jsx("input", { type: "text", onChange: function (e) {
                                    return setNewTherapist(__assign(__assign({}, newTherapist), { firstName: e.target.value }));
                                }, value: newTherapist.firstName, className: "h-10 w-4/12" }), _jsx("input", { type: "text", onChange: function (e) {
                                    return setNewTherapist(__assign(__assign({}, newTherapist), { lastName: e.target.value }));
                                }, value: newTherapist.lastName, className: "h-10 w-7/12" })] }))] })), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-4/12 mt-2" }, { children: [Translate({ term: 'profile.email_address' }), "*"] })), _jsx("input", { type: "text", onChange: function (e) {
                            return setNewTherapist(__assign(__assign({}, newTherapist), { email: e.target.value }));
                        }, value: newTherapist.email, className: "h-10 w-6/12" })] })), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-4/12 mt-2" }, { children: [Translate({ term: 'profile.email_again' }), "*"] })), _jsx("input", { type: "text", onChange: function (e) { return setEmailVerify(e.target.value); }, value: emailVerify, className: "h-10 w-6/12" })] })), _jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-4/12 mt-2" }, { children: [Translate({ term: 'profile.phone' }), "*"] })), _jsx("input", { type: "text", onChange: function (e) {
                            return setNewTherapist(__assign(__assign({}, newTherapist), { phone: e.target.value }));
                        }, value: newTherapist.phone, className: "h-10 w-4/12" })] })), _jsx(SelectDataArrayComponent, { labelTranslate: Translate({ term: 'zone.area' }), selectTextTranslate: selectZoneTranslate, dataArray: zoneArr, setDataArray: setZoneArr, selectDataArr: zones, removeDataFunction: removeZone }), _jsx(SelectDataArrayComponent, { labelTranslate: Translate({ term: 'zone.evaluation_zones' }), selectTextTranslate: selectEvaluationZoneTranslate, dataArray: evaluationZonesArr, setDataArray: setEvaluationZonesArr, selectDataArr: zones, removeDataFunction: removeEvaluationZone }), _jsx(SelectDataArrayComponent, { labelTranslate: Translate({ term: 'nav.modules' }), selectTextTranslate: selectModuleTranslate, dataArray: moduleArr, setDataArray: setModuleArr, selectDataArr: modules, removeDataFunction: removeModule }), _jsx("p", __assign({ style: { color: 'red' }, className: "text-right mt-4 text-red-600" }, { children: infoText })), _jsxs("div", __assign({ className: "flex justify-end gap-4 mt-6" }, { children: [_jsx("button", __assign({ onClick: createNewTherapist }, { children: Translate({ term: 'actions.create_user' }) })), _jsx("button", __assign({ onClick: clearFields, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: Translate({ term: 'actions.clear' }) }))] }))] })));
};
export default Therapists;
