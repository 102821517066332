var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MessageAttachment from './MessageAttachment';
import { Translate } from '../../../translation/Translate';
var MessageEditorAttachments = function (_a) {
    var attachmentList = _a.attachmentList, setAttachmentList = _a.setAttachmentList;
    var messageAttachmentsTranslate = Translate({
        term: 'messages.attachments',
    });
    var removeAttachment = function (index) {
        var updatedAttachmentList = attachmentList.filter(function (attachment, key) { return key !== index; });
        setAttachmentList(updatedAttachmentList);
    };
    return (_jsxs("div", __assign({ className: "border border-gray-300 rounded-md p-2 grid gap-2" }, { children: [_jsx("div", __assign({ className: "text-xs" }, { children: messageAttachmentsTranslate })), _jsx("div", __assign({ className: "flex gap-3" }, { children: attachmentList.map(function (attachment, key) { return (_jsx(MessageAttachment, { index: key, attachmentName: attachment.name, removeAttachment: removeAttachment }, key)); }) }))] })));
};
export default MessageEditorAttachments;
