var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import Header from '../../components/therapist/Header';
import { Translate } from '../../components/translation/Translate';
import ChatMessageList from '../../components/therapist/helpChat/chatMessageList';
import ChatMessageEditor from '../../components/therapist/helpChat/ChatMessageEditor/chatMessageEditor';
import { createHelpChatMessage, getHelpChatMessageList, softDeleteHelpChatMessage, } from '../../actions/HelpChatMessageActions';
import { getSignedUploadUrl, sendFileWithSignedUrl, } from '../../actions/fileActions';
import useUser from '../../hooks/useUser';
import useToast from '../../hooks/useToast';
var HelpChat = function () {
    var _a;
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var user = useUser();
    var userId = (_a = user.userinfo) === null || _a === void 0 ? void 0 : _a.userId;
    var _c = __read(useState([]), 2), chatMessageList = _c[0], setChatMessageList = _c[1];
    var helpChatTitleTranslate = Translate({ term: 'nav.quick_help' });
    var _d = __read(useState(''), 2), text = _d[0], setText = _d[1];
    var _e = __read(useState([]), 2), attachmentList = _e[0], setAttachmentList = _e[1];
    var _f = useToast(), showToastMessage = _f.showToastMessage, toastContainer = _f.toastContainer;
    var invalidFileFormatErrorTranslate = Translate({
        term: 'common.wrong_file_format',
    });
    useEffect(function () {
        getHelpChatMessageListFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var getHelpChatMessageListFunction = function () { return __awaiter(void 0, void 0, void 0, function () {
        var chatMessageListData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getHelpChatMessageList(user.session)];
                case 1:
                    chatMessageListData = _a.sent();
                    if (chatMessageListData) {
                        setChatMessageList(chatMessageListData);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var softDeleteHelpChatMessageFunction = function (chatMessageId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, softDeleteHelpChatMessage(chatMessageId, user.session)];
                case 1:
                    response = _a.sent();
                    if (response) {
                        setMessageSoftDeleted(chatMessageId);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var setMessageSoftDeleted = function (chatMessageId) {
        var updateChatMessageList = chatMessageList.map(function (chatMessage) {
            if (chatMessage._id === chatMessageId) {
                chatMessage.deleted = new Date();
                return chatMessage;
            }
            else {
                return chatMessage;
            }
        });
        setChatMessageList(updateChatMessageList);
    };
    var sendHelpChatMessage = function (messageText, attachmentList) { return __awaiter(void 0, void 0, void 0, function () {
        var attachmentUrls, attachmentList_1, attachmentList_1_1, data, attachment, signedUrlData, fileSent, messageAttachment, e_1_1, newHelpChatMessage;
        var e_1, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    attachmentUrls = [];
                    if (!(attachmentList.length > 0)) return [3 /*break*/, 9];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 7, 8, 9]);
                    attachmentList_1 = __values(attachmentList), attachmentList_1_1 = attachmentList_1.next();
                    _b.label = 2;
                case 2:
                    if (!!attachmentList_1_1.done) return [3 /*break*/, 6];
                    data = attachmentList_1_1.value;
                    attachment = data.file;
                    return [4 /*yield*/, createAndReturnSignedUploadUrl(attachment, 'helpChat')];
                case 3:
                    signedUrlData = _b.sent();
                    if (!signedUrlData) return [3 /*break*/, 5];
                    if (signedUrlData instanceof Error) {
                        showToastMessage &&
                            showToastMessage(invalidFileFormatErrorTranslate, 'warning');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, sendFileWithSignedUrl(attachment, signedUrlData.url)];
                case 4:
                    fileSent = _b.sent();
                    if (fileSent) {
                        messageAttachment = {
                            name: attachment.name,
                            size: attachment.size,
                            mimetype: attachment.type,
                            filePath: signedUrlData.filePath,
                        };
                        attachmentUrls.push(messageAttachment);
                    }
                    _b.label = 5;
                case 5:
                    attachmentList_1_1 = attachmentList_1.next();
                    return [3 /*break*/, 2];
                case 6: return [3 /*break*/, 9];
                case 7:
                    e_1_1 = _b.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 9];
                case 8:
                    try {
                        if (attachmentList_1_1 && !attachmentList_1_1.done && (_a = attachmentList_1.return)) _a.call(attachmentList_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                    return [7 /*endfinally*/];
                case 9:
                    if (!(messageText !== '' || attachmentUrls.length > 0)) return [3 /*break*/, 11];
                    return [4 /*yield*/, createAndReturnHelpChatMessage(messageText, attachmentUrls)];
                case 10:
                    newHelpChatMessage = _b.sent();
                    if (newHelpChatMessage) {
                        setChatMessageList(__spreadArray(__spreadArray([], __read(chatMessageList), false), [newHelpChatMessage], false));
                        clearMessageEditor();
                    }
                    _b.label = 11;
                case 11:
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var createAndReturnSignedUploadUrl = function (attachment, pathType) { return __awaiter(void 0, void 0, void 0, function () {
        var payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = {
                        mimetype: attachment.type,
                        path: attachment.name,
                        pathType: pathType,
                    };
                    return [4 /*yield*/, getSignedUploadUrl(payload, user.session)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var createAndReturnHelpChatMessage = function (messageText, attachmentList) { return __awaiter(void 0, void 0, void 0, function () {
        var chatMessageRequest, newChatMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    chatMessageRequest = {
                        messageText: messageText,
                        attachmentList: attachmentList,
                    };
                    return [4 /*yield*/, createHelpChatMessage(chatMessageRequest, user.session)];
                case 1:
                    newChatMessage = _a.sent();
                    return [2 /*return*/, newChatMessage];
            }
        });
    }); };
    var clearMessageEditor = function () {
        setText('');
        setAttachmentList([]);
    };
    return (_jsxs(React.Fragment, { children: [toastContainer, _jsx(Header, { title: helpChatTitleTranslate }), _jsxs("main", __assign({ className: "px-8 py-4 flex flex-col gap-6" }, { children: [_jsx(ChatMessageList, { chatMessageList: chatMessageList, userId: userId !== null && userId !== void 0 ? userId : '', softDeleteHelpChatMessageFunction: softDeleteHelpChatMessageFunction }), _jsx(ChatMessageEditor, { text: text, setText: setText, attachmentList: attachmentList, setAttachmentList: setAttachmentList, sendChatMessage: sendHelpChatMessage, clearMessageEditor: clearMessageEditor, loading: loading })] }))] }));
};
export default HelpChat;
