var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Translate } from '../../../components/translation/Translate';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import TherapyPeriod from '../../../components/client/homePage/TherapyPeriod';
import ProfileInfo from '../../../components/client/Profile/ProfileInfo';
import ChangePassword from '../../../components/common/profile/ChangePassword';
import EditProfile from '../../../components/client/Profile/EditProfile';
import LanguageSelector from '../../../components/common/LanguageSelector';
var ClientPriod = function () {
    var _a = __read(useState(false), 2), editPage = _a[0], setEditPage = _a[1];
    return (_jsx(React.Fragment, { children: _jsxs("main", __assign({ className: "px-3 md:px-8" }, { children: [_jsx(EditProfile, { editPage: editPage, editProfile: function () { return setEditPage(!editPage); }, setEditPage: setEditPage }), _jsxs("div", { children: [_jsx("div", __assign({ className: "flex justify-center block sm:hidden" }, { children: _jsx("h1", __assign({ className: "text-blue" }, { children: Translate({ term: 'nav.profile' }) })) })), _jsxs("div", __assign({ className: "flex flex-col justify-center mx-auto mb-4 space-y-3 max-w-5xl" }, { children: [_jsxs("div", __assign({ className: "hidden sm:flex sm:justify-between sm:block" }, { children: [_jsx("h1", __assign({ className: "text-blue" }, { children: Translate({ term: 'nav.profile' }) })), _jsx(LanguageSelector, {})] })), _jsxs("div", __assign({ className: "flex flex-col space-y-3 md:flex-row md:justify-between md:space-y-0" }, { children: [_jsxs("div", __assign({ className: "flex flex-col p-4 md:p-6 bg-white border-2 border-cyan rounded-lg shadow-lg w-full md:w-7/12" }, { children: [_jsx(ProfileInfo, {}), _jsx("div", __assign({ className: "flex justify-end" }, { children: _jsxs("button", __assign({ onClick: function () { return setEditPage(!editPage); } }, { children: [Translate({ term: 'profile.edit_info' }), ' ', _jsx(BorderColorOutlinedIcon, { style: { marginBottom: '8px' } })] })) }))] })), _jsx("div", __assign({ className: "w-full md:w-2/5" }, { children: _jsx(TherapyPeriod, { classname: 'bg-white border-2 border-cyan p-4 md:p-6 rounded-md shadow-lg' }) }))] })), _jsx("div", __assign({ className: "w-full md:w-7/12" }, { children: _jsx(ChangePassword, {}) }))] }))] })] })) }));
};
export default ClientPriod;
