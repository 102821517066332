var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SearchIcon from '@mui/icons-material/Search';
import { Translate } from '../../translation/Translate';
var SearchDiv = function (_a) {
    var materialName = _a.materialName, setMaterialName = _a.setMaterialName;
    var getMaterialsTranslate = Translate({
        term: 'material_library.get_materials',
    });
    var searchByNameTranslate = Translate({
        term: 'material_library.search_by_name',
    });
    return (_jsxs("div", __assign({ className: "flex justify-between max-w-6xl" }, { children: [_jsx("h2", __assign({ className: "text-2xl font-semibold" }, { children: getMaterialsTranslate })), _jsx("div", __assign({ className: "flex flex-row gap-6" }, { children: _jsxs("div", __assign({ style: {
                        border: '1px solid #e0e0e0',
                        backgroundColor: 'white',
                        paddingLeft: '.5em',
                        borderRadius: '6px',
                    } }, { children: [_jsx(SearchIcon, {}), _jsx("input", { type: "text", onChange: function (e) { return setMaterialName(e.target.value); }, value: materialName, className: "h-12 w-56", style: { border: 'none', outline: 'none' }, placeholder: searchByNameTranslate })] })) }))] })));
};
export default SearchDiv;
