var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
var AccordionPanel = function (_a) {
    var summary = _a.summary, summaryClosed = _a.summaryClosed, icon = _a.icon, iconOpen = _a.iconOpen, open = _a.open, cancel = _a.cancel, actions = _a.actions, children = _a.children;
    var _b = __read(React.useState(open === true ? true : false), 2), expanded = _b[0], setExpanded = _b[1];
    var iconOpened = {
        transition: 'transform 0.3s ease-out',
        transform: 'rotate(180deg)',
    };
    var iconClosed = {
        transition: 'transform 0.3s ease-out',
        transform: 'rotate(0deg)',
    };
    return (_jsx("div", { children: _jsx("div", __assign({ className: expanded ? 'expanded-open' : 'expanded-closed' }, { children: _jsxs("div", __assign({ className: expanded
                    ? 'bg-white pt-3 pb-1 px-3 md:pt-5 md:pb-3 rounded-md shadow-md relative flex flex-col gap-2'
                    : 'bg-white pt-3 pb-1 px-3 md:pt-5 md:pb-3 rounded-md shadow-md relative flex flex-col gap-2' }, { children: [_jsxs("div", __assign({ className: "cursor-pointer", onClick: function () { return setExpanded(!expanded); } }, { children: [_jsx("div", __assign({ className: "absolute -top-1 right-0 p-3" }, { children: icon ? (expanded && iconOpen ? (iconOpen) : (icon)) : (_jsx(ExpandMoreIcon, { style: expanded ? iconOpened : iconClosed })) })), _jsx("div", { children: summary })] })), _jsx("div", __assign({ className: "show-block" }, { children: summaryClosed })), _jsxs("div", __assign({ className: "hide-block" }, { children: [children, (cancel || (actions && actions.length > 0)) && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsx("div", __assign({ className: "flex-auto" }, { children: cancel && (_jsx("button", __assign({ onClick: function () { return setExpanded(!expanded); }, className: "bg-lightBlue text-blue" }, { children: cancel }))) })), _jsx("div", __assign({ className: "flex-none" }, { children: actions === null || actions === void 0 ? void 0 : actions.map(function (action, key) { return (_jsx(React.Fragment, { children: action }, key)); }) }))] })))] }))] })) })) }));
};
export default AccordionPanel;
