var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from "../../../../translation/Translate";
var LinguisticAwareness = function () {
    var title1 = Translate({ term: "therapy.modules.linguistic_awareness.title1" });
    var title2 = Translate({ term: "therapy.modules.linguistic_awareness.title2" });
    var text1 = Translate({ term: "therapy.modules.linguistic_awareness.text1" });
    var text2 = Translate({ term: "therapy.modules.linguistic_awareness.text2" });
    var text3 = Translate({ term: "therapy.modules.linguistic_awareness.text3" });
    var text4 = Translate({ term: "therapy.modules.linguistic_awareness.text4" });
    var text5 = Translate({ term: "therapy.modules.linguistic_awareness.text5" });
    var text6 = Translate({ term: "therapy.modules.linguistic_awareness.text6" });
    return (_jsxs("div", __assign({ className: "pt-4" }, { children: [_jsx("h3", { children: title1 }), _jsx("h4", { children: title2 }), _jsxs("ul", __assign({ className: "pt-4 ml-4", style: { listStyle: 'inherit' } }, { children: [_jsx("li", { children: text1 }), _jsx("li", { children: text2 }), _jsx("li", { children: text3 }), _jsx("li", { children: text4 }), _jsx("li", { children: text5 }), _jsx("li", { children: text6 })] }))] })));
};
export default LinguisticAwareness;
