var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {};
var PeriodMaterialSlice = createSlice({
    name: 'periodsMaterial',
    initialState: initialState,
    reducers: {
        setPeriodMaterialsList: function (state, action) {
            state.list = action.payload;
        },
        setModules: function (state, action) {
            state.modules = action.payload;
        },
        setUpdateTime: function (state, action) {
            state.listUpdated = action.payload;
        },
        resetMaterials: function (state) {
            //state = initialState;
            //state = {...initialState};
            state.list = null;
            state.listUpdated = null;
            state.modules = null;
            state.delete;
        },
    },
});
export var setPeriodMaterialsList = (_a = PeriodMaterialSlice.actions, _a.setPeriodMaterialsList), setModules = _a.setModules, setUpdateTime = _a.setUpdateTime, resetMaterials = _a.resetMaterials;
export default PeriodMaterialSlice;
