var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { addDays } from 'date-fns/esm';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { setPeriodAssignment } from '../../../actions/periodActions';
import PeriodsContext from '../../common/period/PeriodsContext';
import { Translate } from '../../translation/Translate';
import { orderBy } from 'lodash';
import useUser from '../../../hooks/useUser';
var ShareAssignment = function (_a) {
    var material = _a.material, periodIdValue = _a.periodIdValue, patientName = _a.patientName;
    var user = useUser();
    var _b = __read(useState(periodIdValue ? periodIdValue : null), 2), periodID = _b[0], setPeriodID = _b[1];
    var periods = useContext(PeriodsContext);
    var clientTranslate = Translate({ term: 'home.client' });
    var assignmentAddedTranslate = Translate({
        term: 'period.assignment_has_been_added',
    });
    var assignmentAlreadyAddedTranslate = Translate({
        term: 'period.assignment_is_already_added',
    });
    var createOptionsArray = function (originalArray) {
        var e_1, _a;
        var _b, _c;
        var options = [];
        var counter = {}; // keeps track of duplicate labels
        var reversedOriginalArray = originalArray.slice(); // create a copy of the original array
        reversedOriginalArray.reverse(); // reverse the array to apply correct order for count
        try {
            for (var reversedOriginalArray_1 = __values(reversedOriginalArray), reversedOriginalArray_1_1 = reversedOriginalArray_1.next(); !reversedOriginalArray_1_1.done; reversedOriginalArray_1_1 = reversedOriginalArray_1.next()) {
                var period = reversedOriginalArray_1_1.value;
                if (period.patient) {
                    var label = "".concat(period.patient.firstName, " ").concat((_b = period.patient.lastName) !== null && _b !== void 0 ? _b : '');
                    var count = (_c = counter[label]) !== null && _c !== void 0 ? _c : 0; // get current count or default to 0
                    counter[label] = count + 1; // increment count for this label
                    var value = period._id;
                    options.push({
                        value: value,
                        label: count === 0 ? label : "".concat(label, " (").concat(count + 1, ")"),
                    });
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (reversedOriginalArray_1_1 && !reversedOriginalArray_1_1.done && (_a = reversedOriginalArray_1.return)) _a.call(reversedOriginalArray_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return orderBy(options, function (option) { return option.label; });
    };
    var userPeriods = createOptionsArray((periods === null || periods === void 0 ? void 0 : periods.periods) || []);
    var showToastMessage = function (warning) {
        if (warning) {
            toast(assignmentAddedTranslate, {
                position: 'top-center',
                hideProgressBar: true,
                transition: Zoom,
                closeButton: false,
                autoClose: 2000,
                toastId: 'successId',
                className: 'toast-message-success',
            });
        }
        else {
            toast(assignmentAlreadyAddedTranslate, {
                position: 'top-center',
                hideProgressBar: true,
                transition: Zoom,
                closeButton: false,
                autoClose: 2000,
                toastId: 'warningId',
                className: 'toast-message-warning',
            });
        }
    };
    function shareAssignment() {
        var deadline = addDays(new Date(), 7);
        if ((material === null || material === void 0 ? void 0 : material._id) && periodID) {
            var newAssignment = {
                type: material.type,
                deadline: deadline,
                name: material.name,
                description: material.description,
                therapistInstructions: material.therapistInstructions,
                patientInstructions: material.patientInstructions,
                url: material.url,
                idNumber: material.idNumber || [],
                textMaterial: material.textMaterial || undefined,
                html: material.html || undefined,
            };
            setPeriodAssignment(periodID, material._id, 'new', newAssignment, user.session).then(function (data) {
                if (data)
                    showToastMessage(true);
                else
                    showToastMessage(false);
            });
        }
    }
    var usersOptions = {
        options: userPeriods,
        getOptionLabel: function (option) { return option.label; },
        isOptionEqualToValue: function (option, value) {
            return option.value === value.value;
        },
    };
    return (_jsxs("div", { children: [_jsx(ToastContainer, { limit: 1 }), periodIdValue ? (_jsxs("div", __assign({ className: "flex flex-col justify-start bg-gray-100 border border-gray-200 p-6 rounded-md mt-4" }, { children: [_jsxs("h3", __assign({ className: "text-blue" }, { children: [clientTranslate, ": ", patientName] })), _jsx("div", __assign({ className: "text-left mt-4" }, { children: _jsx("button", __assign({ onClick: function () { return shareAssignment(); }, disabled: periodID === null, className: periodID === null ? 'opacity-25 cursor-not-allowed' : '' }, { children: Translate({ term: 'material_library.share_assignment' }) })) }))] }))) : (_jsxs("div", __assign({ className: "flex flex-col justify-start bg-gray-100 border border-gray-200 p-6 rounded-md mt-4" }, { children: [_jsx("h3", __assign({ className: "text-blue" }, { children: Translate({ term: 'material_library.share_assignment' }) })), _jsx("div", __assign({ className: "text-sm text-gray-400 mt-4" }, { children: Translate({ term: 'material_library.select_customer' }) })), _jsx("div", { children: _jsx(Autocomplete, __assign({}, usersOptions, { className: "bg-white border border-gray-300 rounded-md p-2", id: "disable-close-on-select", onChange: function (event, newValue) {
                                if (newValue && newValue.value)
                                    setPeriodID(newValue.value);
                                else
                                    setPeriodID(null);
                            }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { variant: "standard", className: "border-0" }))); } })) }), _jsx("div", __assign({ className: "text-right mt-4" }, { children: _jsx("button", __assign({ onClick: function () { return shareAssignment(); }, disabled: periodID === null ? true : false, className: periodID === null ? 'opacity-25 cursor-not-allowed' : '' }, { children: Translate({ term: 'material_library.share_assignment' }) })) }))] })))] }));
};
export default ShareAssignment;
