var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Translate } from '../../translation/Translate';
import Moment from 'react-moment';
import Avatar from '../../common/Avatar';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
var ProfileInfo = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var userinfo = useSelector(function (state) { return state.user.userinfo; });
    return (_jsxs("div", { children: [_jsx("div", __assign({ className: "mb-4 text-lg font-semibold" }, { children: _jsx(Avatar, { firstName: (_c = (_b = (_a = userinfo === null || userinfo === void 0 ? void 0 : userinfo.customerInfo) === null || _a === void 0 ? void 0 : _a.dependant) === null || _b === void 0 ? void 0 : _b.firstName) !== null && _c !== void 0 ? _c : '', lastName: (_f = (_e = (_d = userinfo === null || userinfo === void 0 ? void 0 : userinfo.customerInfo) === null || _d === void 0 ? void 0 : _d.dependant) === null || _e === void 0 ? void 0 : _e.lastName) !== null && _f !== void 0 ? _f : '', size: "lg", image: (userinfo === null || userinfo === void 0 ? void 0 : userinfo.profileImageUrl) !== '' ? (userinfo === null || userinfo === void 0 ? void 0 : userinfo.profileImageUrl) : (_jsx(PersonOutlineOutlinedIcon, { style: { width: '100%', height: '100%' } })) }) })), _jsxs("div", __assign({ className: "flex flex-row justify-between space-x-1" }, { children: [_jsx("p", { children: Translate({ term: 'profile.birthday' }) }), _jsx(Moment, __assign({ format: "DD.MM.YYYY" }, { children: (_h = (_g = userinfo === null || userinfo === void 0 ? void 0 : userinfo.customerInfo) === null || _g === void 0 ? void 0 : _g.dependant) === null || _h === void 0 ? void 0 : _h.dateOfBirth }))] })), _jsx("p", __assign({ className: "my-4 font-semibold text-lg text-grey-800" }, { children: Translate({ term: 'profile.guardian_info' }) })), _jsxs("div", __assign({ className: "flex flex-row justify-between leading-7 space-x-1" }, { children: [_jsx("p", { children: Translate({ term: 'login.username' }) }), _jsx("p", __assign({ style: {
                            maxWidth: '50%',
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                            hyphens: 'auto',
                        } }, { children: userinfo === null || userinfo === void 0 ? void 0 : userinfo.username }))] })), _jsxs("div", __assign({ className: "flex flex-row justify-between leading-7 space-x-1" }, { children: [_jsx("p", { children: Translate({ term: 'profile.first_name' }) }), _jsx("p", __assign({ style: {
                            maxWidth: '50%',
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                            hyphens: 'auto',
                        } }, { children: userinfo === null || userinfo === void 0 ? void 0 : userinfo.firstName }))] })), _jsxs("div", __assign({ className: "flex flex-row justify-between leading-7 space-x-1" }, { children: [_jsx("p", { children: Translate({ term: 'profile.last_name' }) }), _jsx("p", __assign({ style: {
                            maxWidth: '50%',
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                            hyphens: 'auto',
                        } }, { children: userinfo === null || userinfo === void 0 ? void 0 : userinfo.lastName }))] })), _jsxs("div", __assign({ className: "flex flex-row justify-between leading-7 space-x-1" }, { children: [_jsx("p", { children: Translate({ term: 'profile.phone' }) }), _jsx("p", __assign({ style: {
                            maxWidth: '50%',
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                            hyphens: 'auto',
                        } }, { children: userinfo === null || userinfo === void 0 ? void 0 : userinfo.phone }))] })), _jsxs("div", __assign({ className: "mb-6 flex flex-row justify-between leading-7 space-x-1" }, { children: [_jsx("p", { children: Translate({ term: 'profile.email' }) }), _jsx("p", __assign({ style: {
                            maxWidth: '50%',
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                            hyphens: 'auto',
                        } }, { children: userinfo === null || userinfo === void 0 ? void 0 : userinfo.email }))] }))] }));
};
export default ProfileInfo;
