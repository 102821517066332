var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { PERIOD_FILTERS, PERIOD_VISIBILITY } from './enums';
import { Pagination } from './Pagination';
import useUser from '../../hooks/useUser';
import { getFilteredTherapistsPatients } from '../../actions/therapistActions';
import PatientPeriodTable from '../therapist/PatientPeriodTable';
import useAppDispatch from '../../hooks/useAppDispatch';
import { resetPatientPeriods, setAllPatientPeriods, } from '../../reducers/patientSlice';
var defaultState = {
    page: 1,
    perPage: 5,
    totalDocs: 0,
    totalPages: 1,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    nextPage: 0,
    pagingCounter: 0,
};
export default function TherapistFilteredPatientPeriodsTable(_a) {
    var showEndedPeriods = _a.showEndedPeriods, _b = _a.docsPerPage, docsPerPage = _b === void 0 ? 5 : _b, filter = _a.filter, columns = _a.columns, phase = _a.phase, therapyType = _a.therapyType;
    var user = useUser();
    var _c = __read(useState(1), 2), page = _c[0], setPage = _c[1];
    var _d = __read(useState(filter === PERIOD_FILTERS.UNREAD_MESSAGES ? {
        sort: "periods.latestMessageDate",
        order: "asc"
    } : {
        sort: ['firstName', 'lastName'],
        order: 'asc',
    }), 2), sortParams = _d[0], setSortParams = _d[1];
    var _e = __read(useState(__assign({}, defaultState)), 2), patients = _e[0], setPatients = _e[1];
    var dispatch = useAppDispatch();
    function getPeriodVisiblityFor(filter) {
        switch (filter) {
            case PERIOD_FILTERS.SOON_TO_END:
                return [PERIOD_VISIBILITY.SOON_TO_END];
            case PERIOD_FILTERS.FINISHED:
                return [PERIOD_VISIBILITY.FINISHED];
            case PERIOD_FILTERS.PASSIVE:
                return [PERIOD_VISIBILITY.PASSIVE];
            default:
                return [];
        }
    }
    function fetchFilteredTherapistPatients() {
        return __awaiter(this, void 0, void 0, function () {
            var patients_1, patientPeriods, _a, _b, patient, error_1;
            var e_1, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getFilteredTherapistsPatients(user.session, __assign(__assign(__assign(__assign({ filter: filter, page: page, limit: docsPerPage }, sortParams), { therapyType: therapyType }), (phase !== 'null' && { phase: phase })), { hideFrom: getPeriodVisiblityFor(filter), showEndedPeriods: showEndedPeriods }))];
                    case 1:
                        patients_1 = _d.sent();
                        if (!patients_1) {
                            setPatients(__assign({}, defaultState));
                            dispatch(resetPatientPeriods());
                            return [2 /*return*/];
                        }
                        setPatients(patients_1);
                        patientPeriods = {};
                        try {
                            for (_a = __values(patients_1.docs), _b = _a.next(); !_b.done; _b = _a.next()) {
                                patient = _b.value;
                                patientPeriods[patient.patientId] = patient;
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        dispatch(setAllPatientPeriods(patientPeriods));
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _d.sent();
                        console.error(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    // Fetch patients
    useEffect(function () {
        fetchFilteredTherapistPatients();
    }, [page, filter, sortParams, phase, therapyType, showEndedPeriods]);
    // Reset the page count when changing the view
    useEffect(function () {
        setPage(1);
    }, [filter]);
    return (_jsxs("div", { children: [_jsx(PatientPeriodTable, { refetch: fetchFilteredTherapistPatients, patients: patients, columns: columns, setSortParams: setSortParams }), _jsx(Pagination, { activePage: page, maxPage: patients.totalPages, switchPage: setPage })] }));
}
