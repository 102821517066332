var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
var SelectLicenseControllerZoneComponent = function (_a) {
    var labelTranslate = _a.labelTranslate, selectTextTranslate = _a.selectTextTranslate, newLicenceController = _a.newLicenceController, setNewLicenceController = _a.setNewLicenceController, selectDataArr = _a.selectDataArr;
    return (_jsxs("div", __assign({ className: "flex flex-row mt-4" }, { children: [_jsxs("label", __assign({ className: "w-4/12 mt-2" }, { children: [labelTranslate, "*"] })), _jsxs("select", __assign({ className: "select", style: { width: '66.666666%' }, value: JSON.stringify(newLicenceController.zone), onChange: function (e) {
                    if (e.target.value !== '') {
                        var zone = JSON.parse(e.target.value);
                        setNewLicenceController(__assign(__assign({}, newLicenceController), { zone: zone }));
                    }
                    else {
                        setNewLicenceController(__assign(__assign({}, newLicenceController), { zone: {
                                value: '0',
                                optionText: selectTextTranslate,
                            } }));
                    }
                } }, { children: [_jsx("option", __assign({ value: "" }, { children: selectTextTranslate })), selectDataArr.map(function (zone, index) { return (_jsx("option", __assign({ value: JSON.stringify(zone) }, { children: zone.optionText }), index)); })] }))] })));
};
export default SelectLicenseControllerZoneComponent;
