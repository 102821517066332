var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';
import { MATERIAL_URL } from '../../../config/constants';
import { Translate } from '../../translation/Translate';
var MdImage = function (props) {
    var parts = decodeURI(props.src).split('/');
    var url = props.src;
    if (url.substring(0, 4) !== 'http') {
        url = MATERIAL_URL + encodeURI(parts.join('/'));
    }
    switch (props.alt) {
        case 'embed/vimeo':
            return (_jsx(ReactPlayer, { url: url, width: "320px", height: "240px", controls: true, className: "my-2" }));
        case 'embed/youtube':
            return (_jsx(ReactPlayer, { url: url, width: "320px", height: "240px", controls: true, className: "my-2" }));
        // embed/googledocs
        case 'embed/wordwall':
            return null;
        // <div>
        // 	<a href={url} target="_blank" className="btn" rel="noreferrer">
        // 		Avaa peli uuteen ikkunaan
        // 	</a>
        // </div>
        //return <iframe src={props.src} style={{height: "240px",width:"320px"}}></iframe>;
        // embed/pdf
        case 'embed/pdf':
            return (_jsx("div", { children: _jsx("iframe", { src: url, height: "200", width: "300" }) }));
        case 'embed':
            return (_jsxs("div", __assign({ className: "py-2" }, { children: [_jsx("iframe", { src: url, height: "200", width: "300" }), _jsx("div", __assign({ className: "pt-2" }, { children: _jsx("a", __assign({ href: url, target: "_blank", className: "btn", rel: "noreferrer" }, { children: "Avaa peli uuteen ikkunaan" })) }))] })));
        default:
            return _jsx("img", { alt: props.alt, src: props.src });
    }
};
function createLinkFromUrl(str, name) {
    var href = str;
    var parts = decodeURI(str).split('/');
    if (href.substring(0, 4) !== 'http') {
        href = MATERIAL_URL + encodeURI(parts.join('/'));
    }
    var text = name;
    if (text) {
        if (typeof text === 'string')
            text = _jsx(PeriodMaterialMarkdown, { text: text });
    }
    else {
        if (parts.length > 0)
            text = parts[parts.length - 1];
        else
            text = '---' + Translate({ term: 'actions.download' });
    }
    return (_jsx("div", __assign({ className: "my-2" }, { children: _jsx("a", __assign({ className: "btn", href: href, target: "_blank", rel: "noreferrer" }, { children: text })) })));
}
var MdLink = function (props) {
    if (props.node.children && props.node.children.length === 0) {
        return (_jsxs("a", __assign({ className: "font-bold underline underline-offset-2 text-blue", href: props.href, target: "_blank", rel: "noreferrer" }, { children: ["-- ", Translate({ term: 'actions.download' })] })));
    }
    switch (props.children[0]) {
        case '':
            // eslint-disable-next-line no-case-declarations
            var text = undefined;
            props.children.map(function (child, index) {
                if (child !== '') {
                    return (_jsx("a", __assign({ className: "font-bold underline underline-offset-2 text-blue" //
                        , href: props.href, target: "_blank", rel: "noreferrer" }, { children: child }), index));
                }
            });
            text = Translate({ term: 'actions.download' });
            return (_jsx("a", __assign({ className: "font-bold underline underline-offset-2 text-blue" //
                , href: props.href, target: "_blank", rel: "noreferrer" }, { children: text })));
        case 'http-refresh-link':
            return createLinkFromUrl(props.href);
        case 'object':
            return createLinkFromUrl(props.href);
        default:
            return createLinkFromUrl(props.href, props.children);
    }
};
var PeriodMaterialMarkdown = function (_a) {
    var text = _a.text, textOnly = _a.textOnly;
    var renderers = {
        img: MdImage,
        a: MdLink,
    };
    if (textOnly) {
        return (_jsx(ReactMarkdown, __assign({ skipHtml: true, components: {
                h1: 'h1',
                h2: 'h2',
                h3: 'h3',
                h4: 'b',
                h5: 'b',
                h6: 'b',
                p: 'span',
                i: 'i',
                b: 'b',
                img: 'span',
                a: 'span',
            } }, { children: text })));
    }
    return _jsx(ReactMarkdown, __assign({ components: renderers }, { children: text }));
};
export default PeriodMaterialMarkdown;
