export function findIndexFromArray(array, key, value) {
    var index = undefined;
    if (array) {
        array.filter(function (a, indx) {
            if (value === a[key]) {
                index = indx;
                return a;
            }
        });
        return index;
    }
}
