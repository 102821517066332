var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '../../../components/translation/Translate';
import AccordionPanel from '../../../components/common/AccordionPanel';
import PeriodTitle from '../../../components/client/period/PeriodTitle';
import { getWindowDimensions } from '../../../functions/screen';
import Questionnaires from '../../../components/client/questionnaires/questionnaires';
import useAppSelector from '../../../hooks/useAppSelector';
import PatientPeriodAssignments from '../../../components/common/assignments/PatientPeriodsAssignments';
export default function PeriodAssignments() {
    var _a, _b, _c;
    var t = useTranslate();
    var navigate = useNavigate();
    var periods = useAppSelector(function (state) { return state.periods.periods; });
    var selectedPeriod = useAppSelector(function (state) { return state.periods.active; });
    var _d = __read(React.useState(null), 2), selectedAssignment = _d[0], setSelectedAssignment = _d[1];
    var assignmentCount = 0;
    function navigatePeriodMessages() {
        navigate("/period/".concat(selectedPeriod === null || selectedPeriod === void 0 ? void 0 : selectedPeriod._id));
    }
    useEffect(function () {
        function handleResize() {
            var wd = getWindowDimensions();
            if (wd.width >= 1024)
                navigatePeriodMessages();
        }
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var setAssignment = function (id, assignments) {
        var assignment = assignments.find(function (a) { return id === a.id; });
        assignment
            ? setSelectedAssignment(assignment)
            : setSelectedAssignment(null);
    };
    // If user happens to open some old link that points to /period/assignments
    // it wont break the app
    if (!(selectedPeriod && periods)) {
        navigatePeriodMessages();
        return _jsx(_Fragment, {});
    }
    var selectedPeriodIndex = periods.findIndex(function (period) { return period._id === selectedPeriod._id; });
    var assignments = (_a = selectedPeriod.assignments) === null || _a === void 0 ? void 0 : _a.map(function (assignment) {
        return assignment;
    });
    if (assignments) {
        for (var i = 0; i < assignments.length; i++) {
            if (!assignments[i].approved && assignments[i].deadline)
                assignmentCount++;
        }
    }
    var questionnaires = (_c = (_b = selectedPeriod.questionnaires) === null || _b === void 0 ? void 0 : _b.filter(function (questionnaire) {
        if (!questionnaire.answers || questionnaire.answerTheSurveyMoreThanOnce)
            return questionnaire;
    })) !== null && _c !== void 0 ? _c : [];
    return (_jsx("main", __assign({ className: "px-3 md:px-8 py-4" }, { children: _jsxs("div", __assign({ className: "max-w-3xl mx-auto" }, { children: [_jsx(PeriodTitle, __assign({ assignmentCount: assignmentCount, messageStyle: 'btn-sm rounded-2xl rounded-r-none border-t-8 border-l-8 border-b-8 border-white hover:border-cyan duration-300', assingmentStyle: 'btn-sm rounded-2xl rounded-l-none border-t-8 border-r-8 border-b-8 border-cyan' }, { children: _jsxs("b", { children: [t('common.period'), " ", selectedPeriodIndex + 1] }) })), _jsx("div", __assign({ className: "grid mt-4 md:mt-6" }, { children: _jsxs("div", __assign({ className: "col-span-2" }, { children: [_jsx("h3", __assign({ className: "text-lg font-semibold text-grey-800 mb-0 md:mb-3" }, { children: t('period.active_assignments') })), _jsxs("div", __assign({ className: "grid gap-2 md:gap-3" }, { children: [_jsx("div", __assign({ className: "bg-lightBlue px-4 py-2 rounded-md" }, { children: _jsx(PatientPeriodAssignments, { open: function (id) {
                                                return setAssignment(id, assignments ? assignments : []);
                                            }, assignments: assignments ? assignments : [], isDone: false }) })), _jsx(AccordionPanel, __assign({ summary: _jsx("div", { children: t('period.assignment_history') }) }, { children: _jsx(PatientPeriodAssignments, { assignments: assignments ? assignments : [], isDone: true }) })), _jsx(AccordionPanel, __assign({ open: questionnaires.length > 0, summary: _jsx("div", { children: t('period.feedback_surveys') }) }, { children: _jsx(Questionnaires, { questionnaires: questionnaires, periodId: selectedPeriod._id }) }))] }))] })) }))] })) })));
}
