var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../common/ConfirmModal';
import UploadQuery from './UploadQuery';
import { clearFormState, setModalState, } from '../../../reducers/adminQuerySlice';
import { deleteAdminQuestionnaire, } from '../../../actions/adminActions';
import { toast, Zoom } from 'react-toastify';
import { Translate } from '../../translation/Translate';
import 'react-toastify/dist/ReactToastify.css';
import '../../common/toastStyle.css';
import useUser from '../../../hooks/useUser';
var EditQuery = function () {
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var user = useUser();
    var modalState = useSelector(function (state) { return state.adminQuery.modalState; });
    var formData = useSelector(function (state) { return state.adminQuery.formData; });
    var successTranslate = Translate({
        term: 'common.delete_success',
    });
    var failedTranslate = Translate({
        term: 'common.delete_failed',
    });
    var deleteTitleTranslate = Translate({
        term: 'admin.inquiry.delete_questionnaire',
    });
    var deleteQuestionnaireConfirmationTranslate = Translate({
        term: 'admin.inquiry.delete_questionnaire_confirmation',
    });
    var onDeleteSuccess = function () {
        toast(successTranslate, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 2000,
            toastId: 'successId',
            className: 'toast-message-success',
        });
        dispatch(clearFormState());
        navigate('/query/');
    };
    var onDeleteFail = function () {
        toast(failedTranslate, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 2000,
            toastId: 'warningId',
            className: 'toast-message-warning',
        });
    };
    var onDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var deletedQuestionnaire, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!user.session)
                        return [2 /*return*/];
                    if (!formData._id)
                        return [2 /*return*/];
                    return [4 /*yield*/, deleteAdminQuestionnaire({ _id: formData._id }, user.session)];
                case 1:
                    deletedQuestionnaire = _a.sent();
                    if (deletedQuestionnaire) {
                        onDeleteSuccess();
                        clearFormState();
                    }
                    else {
                        onDeleteFail();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: "m-5" }, { children: [_jsx(UploadQuery, {}), _jsx(ConfirmModal, { open: modalState === 'deleting', onClose: function () { return dispatch(setModalState('editing')); }, message: [
                    deleteTitleTranslate,
                    deleteQuestionnaireConfirmationTranslate,
                ], deleteSelectedItem: onDelete })] })));
};
export default EditQuery;
