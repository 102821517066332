var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from '../../../translation/Translate';
import { StylesManager, Model } from 'survey-core';
import 'survey-core/modern.min.css';
import { Survey } from 'survey-react-ui';
import { updateStartQuestionnaireInPeriod } from '../../../../actions/periodActions';
import { setPeriods } from '../../../../reducers/periodSlice';
import PeriodContext from '../../../common/period/PeriodContext';
import StartQuestionnaireAttachment from './StartQuestionnaireAttachment';
import Info from './info';
import { logout } from '../../../../functions/logout';
import LanguageSelector from '../../../common/LanguageSelector';
import useUser from '../../../../hooks/useUser';
import { ToastContainer } from 'react-toastify';
var StartQuestionnaire = function () {
    var _a, _b, _c, _d;
    var dispatch = useDispatch();
    var periods = useSelector(function (state) { return state.periods.periods; });
    var user = useUser();
    var userinfoLang = useSelector(function (state) { var _a; return (_a = state.settings) === null || _a === void 0 ? void 0 : _a.language; });
    var stop = Translate({ term: 'actions.stop' });
    // FIXME: Hook error needs to be fixed.
    function sendStarterQuestionnaire(results) {
        return __awaiter(this, void 0, void 0, function () {
            var startQuestionnaire, updateStartQuestionnaireRequest, period, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(periods && periods[0].startQuestionnaire)) return [3 /*break*/, 2];
                        startQuestionnaire = __assign(__assign({}, periods[0].startQuestionnaire), { answers: results });
                        updateStartQuestionnaireRequest = {
                            periodId: periods[0]._id,
                            startQuestionnaire: startQuestionnaire,
                        };
                        return [4 /*yield*/, updateStartQuestionnaireInPeriod(updateStartQuestionnaireRequest, user.session)];
                    case 1:
                        period = _a.sent();
                        if (PeriodContext && period) {
                            dispatch(setPeriods(period));
                        }
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    var sendResults = useCallback(
    // JSurvey own params. Necessary to be typed
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (sender) {
        var results = sender.data;
        sendStarterQuestionnaire(results);
    }, [sendStarterQuestionnaire]);
    if (periods && periods.length > 0) {
        // Search the first period without answers and use its questions in survey
        var surveyQuestions = (_b = (_a = periods.find(function (period) { var _a; return !((_a = period.startQuestionnaire) === null || _a === void 0 ? void 0 : _a.filledOut); })) === null || _a === void 0 ? void 0 : _a.startQuestionnaire) === null || _b === void 0 ? void 0 : _b.questions;
        var survey = new Model(surveyQuestions);
        survey.locale = userinfoLang && userinfoLang === 'se' ? 'sv' : 'fi';
        StylesManager.applyTheme('modern');
        survey.onComplete.add(sendResults);
        return (_jsxs(React.Fragment, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx("main", __assign({ className: "px-3 py-4 md:px-8" }, { children: _jsxs("div", __assign({ className: "max-w-screen-xl m-auto" }, { children: [_jsx("div", __assign({ className: "flex" }, { children: _jsx(LanguageSelector, {}) })), !((_c = periods[0].startQuestionnaire) === null || _c === void 0 ? void 0 : _c.filledOut) && (_jsxs("div", __assign({ className: "w-full sm:w-3/4 lg:w-2/3 mx-auto" }, { children: [_jsx("div", __assign({ className: "pt-3 mb-4 w-full sm:w-3/4 lg:w-2/3 mx-auto" }, { children: _jsx(Info, {}) })), _jsx(Survey, { className: "bg-white", model: survey }), _jsx("div", __assign({ className: "pt-3 justify-items-center items-center" }, { children: _jsx("button", __assign({ onClick: function () { return logout(user.session); }, className: "bg-white hover:bg-blue duration-300 hover:text-white text-black border-2 border-blue rounded-md" }, { children: stop })) }))] }))), ((_d = periods[0].startQuestionnaire) === null || _d === void 0 ? void 0 : _d.filledOut) &&
                                (!periods[0].startVideo || periods[0].startVideo.length <= 0) && (_jsx("div", __assign({ className: "w-full sm:w-3/4 lg:w-2/3 mx-auto" }, { children: _jsx(StartQuestionnaireAttachment, {}) })))] })) }))] }));
    }
    else {
        return _jsx(_Fragment, {});
    }
};
export default StartQuestionnaire;
