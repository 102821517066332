var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from '../../translation/Translate';
import { clearFormState, setSearchParamsField, } from '../../../reducers/adminLicenseSlice';
function SearchBarLicenses(_a) {
    var onSearch = _a.onSearch;
    var dispatch = useDispatch();
    var searchParams = useSelector(function (state) { return state.adminLicense.searchParams; });
    var municipality = searchParams.municipality, name = searchParams.name, therapistName = searchParams.therapistName;
    return (_jsx("form", __assign({ className: "mb-4", onSubmit: function (e) {
            e.preventDefault();
            dispatch(setSearchParamsField({ page: 1 }));
            onSearch();
        } }, { children: _jsxs("div", __assign({ className: "flex flex-row gap-7 mt-6" }, { children: [_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("label", __assign({ className: "mb-2" }, { children: Translate({ term: 'admin.license.zone_name' }) })), _jsx("input", { type: "text", value: name !== null && name !== void 0 ? name : '', onChange: function (e) {
                                return dispatch(setSearchParamsField({ name: e.target.value }));
                            } })] })), _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("label", __assign({ className: "mb-2" }, { children: Translate({ term: 'home.therapist' }) })), _jsx("input", { type: "text", value: therapistName !== null && therapistName !== void 0 ? therapistName : '', onChange: function (e) {
                                return dispatch(setSearchParamsField({ therapistName: e.target.value }));
                            } })] })), _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("label", __assign({ className: "mb-2" }, { children: Translate({ term: 'zone.zone_name' }) })), _jsx("input", { type: "text", value: municipality !== null && municipality !== void 0 ? municipality : '', onChange: function (e) {
                                return dispatch(setSearchParamsField({ municipality: e.target.value }));
                            } })] })), _jsx("div", __assign({ className: "flex items-end ml-2" }, { children: _jsx("button", __assign({ type: "submit", className: "h-11" }, { children: Translate({ term: 'actions.search' }) })) })), _jsx("div", __assign({ className: "flex items-end" }, { children: _jsx("button", __assign({ type: "button", onClick: function () { return dispatch(clearFormState()); }, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: Translate({ term: 'actions.clear' }) })) }))] })) })));
}
export default SearchBarLicenses;
