var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createEntityAdapter, } from '@reduxjs/toolkit';
import { paginatedInitialState } from './periodSlice';
var adminQueryAdapter = createEntityAdapter();
var initialState = adminQueryAdapter.getInitialState({
    formData: {
        _id: null,
        name: '',
        type: '',
        moduleId: '',
        queryURL: '',
        answerTheSurveyMoreThanOnce: false,
    },
    searchParams: {
        name: '',
        moduleId: '',
        type: '',
        sort: 'modified.time',
        order: -1,
        page: 1,
    },
    questionnaires: paginatedInitialState,
    modalState: 'listing',
});
var slice = createSlice({
    name: 'adminQuery',
    initialState: initialState,
    reducers: {
        setFormField: function (state, action) {
            state.formData = __assign(__assign({}, state.formData), action.payload);
        },
        setSearchParamsField: function (state, action) {
            state.searchParams = __assign(__assign({}, state.searchParams), action.payload);
        },
        setModalState: function (state, action) {
            if (action.payload === 'listing') {
                state.formData = initialState.formData;
                state.modalState = initialState.modalState;
            }
            else {
                state.modalState = action.payload;
            }
        },
        setQuestionnaires: function (state, action) {
            state.questionnaires = action.payload;
        },
        clearFormState: function (state) {
            state.formData = initialState.formData;
            state.modalState = initialState.modalState;
            state.searchParams = initialState.searchParams;
        },
        clearSearchParamsState: function (state) {
            state.searchParams = initialState.searchParams;
        },
    },
});
export var setFormField = (_a = slice.actions, _a.setFormField), setModalState = _a.setModalState, clearFormState = _a.clearFormState, setSearchParamsField = _a.setSearchParamsField, clearSearchParamsState = _a.clearSearchParamsState, setQuestionnaires = _a.setQuestionnaires;
export default slice;
