var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Translate } from '../../translation/Translate';
import { getTherapistAvailabilities } from '../../../actions/therapistActions';
import TailwindConfig from '../../../../tailwind.config';
import OwnAvailability from '../profile/OwnAvailability';
import useUser from '../../../hooks/useUser';
function CircularProgressWithLabel(props) {
    var bg = __assign(__assign({}, props), { value: 100 });
    var main = __assign(__assign({}, props), { value: Math.round((props.val / props.mx) * 100) });
    if (props.val > props.mx) {
        main = __assign(__assign({}, props), { value: 100 });
    }
    return (_jsxs(Box, __assign({ sx: { position: 'relative', display: 'inline-flex' } }, { children: [_jsx(CircularProgress, __assign({ variant: "determinate", sx: { color: TailwindConfig.theme.colors.cyan } }, bg)), _jsx(CircularProgress, __assign({ variant: "determinate" }, main, { sx: { position: 'absolute' } })), _jsx(Box, __assign({ sx: {
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                } }, { children: _jsx("div", { children: "".concat(props.val, " / ").concat(props.mx) }) }))] })));
}
var HomeStats = function () {
    var _a = __read(useState(true), 2), ownAvailability = _a[0], setOwnAvailability = _a[1];
    var user = useUser();
    var userSession = useSelector(function (state) { return state.user.session; });
    var _b = __read(useState([]), 2), therapistActiveOnlinePeriodList = _b[0], setTherapistActiveOnlinePeriodList = _b[1];
    var _c = __read(useState([]), 2), allTherapistActiveOnlinePeriodList = _c[0], setAllTherapistActiveOnlinePeriodList = _c[1];
    var getTherapistAvailabilitiesFunction = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTherapistAvailabilities(userSession)];
                case 1:
                    data = _a.sent();
                    if (data) {
                        setTherapistActiveOnlinePeriodList(data.therapistActiveOnlinePeriodList);
                        setAllTherapistActiveOnlinePeriodList(data.allTherapistActiveOnlinePeriodList);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getTherapistAvailabilitiesFunction();
    }, [user]);
    return (_jsxs(React.Fragment, { children: [_jsx("div", __assign({ className: "xl:col-span-2 grid md:grid-cols-1 gap-3 py-4 px-6 mb-4" }, { children: _jsxs("div", __assign({ className: "flex gap-3" }, { children: [_jsx("h3", __assign({ className: "flex gap-3" }, { children: Translate({ term: 'home.own_free' }) })), _jsx("div", { children: _jsxs("label", __assign({ className: "switch" }, { children: [_jsx("input", { checked: !ownAvailability, type: "checkbox", onChange: function () {
                                            setOwnAvailability(!ownAvailability);
                                        } }), _jsx("span", { className: "slider round" })] })) }), _jsx("h3", __assign({ className: "flex gap-3" }, { children: Translate({ term: 'home.total_free' }) }))] })) })), _jsxs("div", { children: [ownAvailability ? (_jsx("div", __assign({ className: "xl:col-span-2 grid md:grid-cols-2 gap-3 py-4 px-6 mb-4 content-center" }, { children: therapistActiveOnlinePeriodList.length > 0 &&
                            therapistActiveOnlinePeriodList.map(function (ActiveOnlinePeriod) {
                                return (_jsxs("div", __assign({ className: "gap-3 w-76" }, { children: [_jsx("div", __assign({ className: "self-center" }, { children: _jsx("h3", { children: Translate({
                                                    term: "therapy.modules.m".concat(ActiveOnlinePeriod.moduleId),
                                                }) }) })), _jsxs("div", { children: [Translate({
                                                    term: 'home.free',
                                                }), ": ", ActiveOnlinePeriod.amount] }), _jsxs("div", { children: [Translate({
                                                    term: 'home.active',
                                                }), ": ", ActiveOnlinePeriod.used] })] }), ActiveOnlinePeriod.moduleId));
                            }) }))) : (_jsx("div", __assign({ className: "xl:col-span-2 grid md:grid-cols-2 gap-3 py-4 px-6 mb-4 content-center" }, { children: allTherapistActiveOnlinePeriodList.length > 0 &&
                            allTherapistActiveOnlinePeriodList.map(function (ActiveOnlinePeriod) {
                                return (_jsxs("div", __assign({ className: "gap-3 w-76" }, { children: [_jsx("div", __assign({ className: "flex-auto self-center" }, { children: _jsx("h3", { children: Translate({
                                                    term: "therapy.modules.m".concat(ActiveOnlinePeriod.moduleId),
                                                }) }) })), _jsxs("div", { children: [Translate({
                                                    term: 'home.free',
                                                }), ": ", ActiveOnlinePeriod.amount] }), _jsxs("div", { children: [Translate({
                                                    term: 'home.active',
                                                }), ": ", ActiveOnlinePeriod.used] })] }), ActiveOnlinePeriod.moduleId));
                            }) }))), _jsx(OwnAvailability, {})] })] }));
};
export default HomeStats;
