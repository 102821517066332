var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from '../../../translation/Translate';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import ImageOutlined from '@mui/icons-material/ImageOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Delete from '@mui/icons-material/Delete';
import { sendStartVideos } from '../../../../actions/periodActions';
import { setPeriods } from '../../../../reducers/periodSlice';
import { logout } from '../../../../functions/logout';
import PronounciationErrors from './intructions/PronounciationErrors';
import Vocabulary from './intructions/Vocabulary';
import LinguisticAwareness from './intructions/LinguisticAwareness';
import PickyEater from './intructions/PickyEater';
import EarlyLanguageDevelopment from './intructions/EarlyLanguageDevelopment';
import ConfirmationModal from '../../../common/modals/ConfirmationModal';
import { allowedMimeTypes, getSignedUploadUrl, sendFileWithSignedUrl, } from '../../../../actions/fileActions';
import { Zoom, toast } from 'react-toastify';
import useUser from '../../../../hooks/useUser';
import ProgressBar from '../../ProgressBar';
var StartQuestionnaireAttachment = function () {
    var dispatch = useDispatch();
    var periods = useSelector(function (state) { var _a; return (_a = state.periods) === null || _a === void 0 ? void 0 : _a.periods; });
    var user = useUser();
    var _a = __read(useState([]), 2), selectedStartMedia = _a[0], setSelectedStartMedia = _a[1];
    var _b = __read(React.useState(null), 2), selectedMedia = _b[0], setMedia = _b[1];
    var _c = __read(React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(React.useState(''), 2), status = _d[0], setStatus = _d[1];
    var _e = __read(React.useState(0), 2), percentage = _e[0], setPercentage = _e[1];
    var _f = __read(React.useState(false), 2), openConfirmationModal = _f[0], setOpenConfirmationModal = _f[1];
    var sendTranslate = Translate({ term: 'actions.send' });
    var stopTranslate = Translate({ term: 'actions.stop' });
    var deleteVideoTranslate = Translate({ term: 'actions.delete_video' });
    var mimeTypes = __spreadArray(__spreadArray([], __read(allowedMimeTypes.image), false), __read(allowedMimeTypes.video), false).toString();
    var title1 = Translate({
        term: 'therapy.modules.basic_introductions.title1',
    });
    var text1 = Translate({
        term: 'therapy.modules.basic_introductions.text1',
    });
    var text2 = Translate({
        term: 'therapy.modules.basic_introductions.text2',
    });
    var text3 = Translate({
        term: 'therapy.modules.basic_introductions.text3',
    });
    var text4 = Translate({
        term: 'therapy.modules.basic_introductions.text4',
    });
    var text5 = Translate({
        term: 'therapy.modules.basic_introductions.text5',
    });
    var text6 = Translate({
        term: 'therapy.modules.basic_introductions.text6',
    });
    var text7 = Translate({
        term: 'therapy.modules.basic_introductions.text7',
    });
    var text8 = Translate({
        term: 'therapy.modules.basic_introductions.text8',
    });
    var downloadTranslate = Translate({
        term: 'actions.upload_material',
    });
    var fileSizeTooLargeTranslate = Translate({
        term: 'common.file_size_too_large',
    });
    var attachmentMissingTranslate = Translate({
        term: 'common.attachment_missing',
    });
    var sendFailedContactCustomerServiceTranslate = Translate({
        term: 'common.send_failed_contact_customer_service',
    });
    var invalidFileFormatErrorTranslate = Translate({
        term: 'common.wrong_file_format',
    });
    var onFileChange = function (file) {
        if (file && file.length > 0) {
            // 1 000 000 000 === 1gt
            if (file[0].size > 4000000000) {
                showToastMessage(fileSizeTooLargeTranslate, 'warning');
                return;
            }
            var files = selectedStartMedia.concat(file[0]);
            setSelectedStartMedia(files);
        }
    };
    var showToastMessage = function (text, type) {
        if (type === void 0) { type = 'success'; }
        toast(text, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: type === 'success' ? false : true,
            autoClose: type === 'success' ? 2000 : false,
            toastId: type === 'warning' ? 'warningId' : 'successId',
            className: type === 'warning' ? 'toast-message-warning' : 'toast-message-success',
        });
    };
    var saveFiles = function (selectedMedia) { return __awaiter(void 0, void 0, void 0, function () {
        var attachmentUrls, selectedMedia_1, selectedMedia_1_1, media, payload, signedUrlData, fileSent, messageAttachment, e_1_1, periodPayload, updatedPeriods;
        var e_1, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!periods) return [3 /*break*/, 11];
                    setLoading(true);
                    setStatus('Valmistellaan latausta');
                    setPercentage(0);
                    if (selectedStartMedia.length === 0) {
                        showToastMessage(attachmentMissingTranslate, 'warning');
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    attachmentUrls = [];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 7, 8, 9]);
                    selectedMedia_1 = __values(selectedMedia), selectedMedia_1_1 = selectedMedia_1.next();
                    _b.label = 2;
                case 2:
                    if (!!selectedMedia_1_1.done) return [3 /*break*/, 6];
                    media = selectedMedia_1_1.value;
                    payload = {
                        mimetype: media.type,
                        path: media.name,
                        pathType: 'startPeriod',
                        periodId: periods[0]._id,
                    };
                    return [4 /*yield*/, getSignedUploadUrl(payload, user.session)];
                case 3:
                    signedUrlData = _b.sent();
                    if (!signedUrlData) return [3 /*break*/, 5];
                    // HTTP 400 Bad request
                    if (signedUrlData instanceof Error) {
                        showToastMessage(invalidFileFormatErrorTranslate, 'warning');
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    // Send file to bucket
                    setStatus("Ladataan liitett\u00E4 ".concat(media.name));
                    return [4 /*yield*/, sendFileWithSignedUrl(media, signedUrlData.url, function (event) {
                            setPercentage((event.loaded / event.total) * 100);
                        })];
                case 4:
                    fileSent = _b.sent();
                    if (fileSent) {
                        messageAttachment = {
                            name: media.name,
                            size: media.size,
                            mimetype: media.type,
                            file: signedUrlData.filePath,
                        };
                        attachmentUrls.push(messageAttachment);
                    }
                    _b.label = 5;
                case 5:
                    selectedMedia_1_1 = selectedMedia_1.next();
                    return [3 /*break*/, 2];
                case 6: return [3 /*break*/, 9];
                case 7:
                    e_1_1 = _b.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 9];
                case 8:
                    try {
                        if (selectedMedia_1_1 && !selectedMedia_1_1.done && (_a = selectedMedia_1.return)) _a.call(selectedMedia_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                    return [7 /*endfinally*/];
                case 9:
                    periodPayload = {
                        periodId: periods[0]._id,
                        attachmentUrls: attachmentUrls,
                    };
                    return [4 /*yield*/, sendStartVideos(periodPayload, user.session)];
                case 10:
                    updatedPeriods = _b.sent();
                    if (updatedPeriods) {
                        dispatch(setPeriods(updatedPeriods));
                    }
                    else {
                        showToastMessage(sendFailedContactCustomerServiceTranslate, 'warning');
                    }
                    setLoading(false);
                    _b.label = 11;
                case 11: return [2 /*return*/];
            }
        });
    }); };
    var deleteMedia = function () {
        var updatedMedia = selectedStartMedia.filter(function (media) { return media.name !== selectedMedia; });
        setSelectedStartMedia(updatedMedia);
        setMedia(null);
        setOpenConfirmationModal(false);
    };
    if (periods) {
        return (_jsxs("div", __assign({ className: "flex flex-col my-auto" }, { children: [_jsxs("div", __assign({ className: "my-4 bg-lightBlue border border-cyan px-3 py-4 rounded-md shadow-lg md:py-5" }, { children: [_jsx("h3", __assign({ className: "mb-2 font-semibold text-xl text-grey-600" }, { children: title1 })), _jsxs("div", { children: [_jsx("p", { children: text1 }), _jsxs("p", __assign({ className: "pt-4" }, { children: [text2, _jsx("a", __assign({ href: "https://www.aannekoulu.fi/videointi-ohjeet/", target: "_blank", rel: "noreferrer" }, { children: "https://www.aannekoulu.fi/videointi-ohjeet/" }))] })), _jsx("p", __assign({ className: "pt-4" }, { children: text3 }))] }), (function () {
                            switch (periods[0].module._id) {
                                case '61f01cbc6fcb1a2901ede7e1': // 61f01cbc6fcb1a2901ede7e1: 'Äännevirheet', Pronunciation errors
                                    return _jsx(PronounciationErrors, {});
                                case '61f0fb706fcb1a2901ede7e6': // 61f0fb706fcb1a2901ede7e6: 'Sanavarasto', Vocabulary
                                    return _jsx(Vocabulary, {});
                                case '61f0ffc86fcb1a2901ede7f1': // 61f0ffc86fcb1a2901ede7f1: 'Kielellinen tietoisuus', Linguistic awareness
                                    return _jsx(LinguisticAwareness, {});
                                case '61f0fff06fcb1a2901ede7f3': // 61f0fff06fcb1a2901ede7f3: 'Valikoiva syöjä', Picky eater
                                    return _jsx(PickyEater, {});
                                case '61f1007f6fcb1a2901ede7f7': // 61f1007f6fcb1a2901ede7f7: 'Varhainen kielenkehitys', Early language development
                                    return _jsx(EarlyLanguageDevelopment, {});
                                default:
                                    return null;
                            }
                        })(), _jsx("p", __assign({ className: "pt-4" }, { children: text4 })), _jsx("p", { children: text5 }), _jsxs("ul", __assign({ className: "pt-4" }, { children: [_jsxs("li", { children: ["\u2022 ", text6] }), _jsxs("li", { children: ["\u2022", ' ', _jsx("a", __assign({ style: { textDecoration: 'underline' }, href: "https://www.aannekoulu.fi/videointi-ohjeet/", target: "_blank", rel: "noreferrer" }, { children: text7 }))] })] })), _jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "selectFile", className: "block text-sm pt-4" }, { children: text8 })), _jsxs("div", __assign({ className: "mt-2 flex flex-row bg-blue text-white border cursor-pointer rounded-md shadow-md w-60 h-12" }, { children: [_jsxs("label", __assign({ className: "m-auto", htmlFor: "selectFile" }, { children: [downloadTranslate, _jsx(InsertDriveFileOutlinedIcon, {})] })), _jsx("input", { id: "selectFile", style: { display: 'none' }, accept: mimeTypes, type: "file", onChange: function (e) { return onFileChange(e.target.files); } })] }))] })] })), selectedStartMedia.length > 0 ? (_jsx("div", __assign({ className: "mb-4 flex-auto" }, { children: _jsx("div", __assign({ className: "flex flex-col w-full" }, { children: _jsx("ul", { children: selectedStartMedia.map(function (selectedFile) {
                                return (_jsxs("li", __assign({ className: "my-1 flex justify-between items-center cursor-pointer bg-white border rounded-md shadow-md w-full h-12 pl-2 pr-2", onClick: function () {
                                        setMedia(selectedFile.name),
                                            setOpenConfirmationModal(true);
                                    } }, { children: [_jsxs("div", __assign({ className: "flex flex-row gap-1" }, { children: [_jsxs("div", __assign({ className: "flex flex-row gap-0 items-center justify-center" }, { children: [selectedFile.type.includes('video') && (_jsx(VideocamOutlinedIcon, {})), selectedFile.type.includes('image') && (_jsx(ImageOutlined, {}))] })), selectedFile.name] })), _jsx("div", { children: _jsx(Delete, {}) })] }), selectedFile.name));
                            }) }) })) }))) : (_jsx("div", {})), _jsx("div", __assign({ className: "pt-3 flex justify-center items-center gap-2" }, { children: loading && _jsx(ProgressBar, { percentage: percentage, message: status }) })), _jsxs("div", __assign({ className: "pt-3 flex justify-center items-center gap-2" }, { children: [_jsx("button", __assign({ disabled: loading, onClick: function () { return saveFiles(selectedStartMedia); }, className: "h-11" }, { children: sendTranslate })), _jsx("button", __assign({ onClick: function () { return logout(user.session); }, className: "bg-white hover:bg-blue duration-300 hover:text-white text-black border-2 border-blue rounded-md" }, { children: stopTranslate }))] })), _jsx(ConfirmationModal, { headerText: deleteVideoTranslate, opentConfirmationModal: openConfirmationModal, setOpenConfirmationModal: setOpenConfirmationModal, actionFunction: deleteMedia })] })));
    }
    else
        return _jsx("div", {});
};
export default StartQuestionnaireAttachment;
