var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSession } from '../reducers/userSlice';
import ValidateExtensionInvitation from '../components/client/CreateUserForExtensionPeriod/ValidateExtensionInvitation';
import aannekouluLogo from '../assets/images/aannekoulu-logo.png';
import { useState } from 'react';
import { validateInvitation } from '../actions/invitationActions';
import { setLanguage } from '../reducers/settingsSlice';
import AddUserInfoData from '../components/client/CreateUserForExtensionPeriod/AddUserInfoData';
import AddUserData from '../components/client/CreateUserForExtensionPeriod/AddUserData';
import Phase4 from '../components/client/CreateAccount/Phase4';
import { ageFromDateOfBirth, dateOfBirthFromSsn, } from '../functions/conversions';
import { createCustomerAndStartExtensionPeriod } from '../actions/userActions';
var CreateUserForExtensionPeriod = function () {
    var session = useSelector(function (state) { return state.user.session; });
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var invitationId = urlParams.get('id');
    var _a = __read(useState('validateExtensionInvitation'), 2), state = _a[0], setState = _a[1];
    var _b = __read(useState(''), 2), ssn = _b[0], setSsn = _b[1];
    var _c = __read(useState(null), 2), patientId = _c[0], setPatientId = _c[1];
    var _d = __read(useState(null), 2), patientAge = _d[0], setPatientAge = _d[1];
    var _e = __read(useState('kala'), 2), userInfoFirstName = _e[0], setUserInfoFirstName = _e[1];
    var _f = __read(useState(''), 2), userInfoLastName = _f[0], setUserInfoLastName = _f[1];
    var _g = __read(useState(''), 2), userInfoPhoneNumber = _g[0], setUserInfoPhoneNumber = _g[1];
    var _h = __read(useState(''), 2), userInfoEmail = _h[0], setUserInfoEmail = _h[1];
    var _j = __read(useState(''), 2), userName = _j[0], setUserName = _j[1];
    var _k = __read(useState(''), 2), password = _k[0], setPassword = _k[1];
    var _l = __read(useState(''), 2), passwordAgain = _l[0], setPasswordAgain = _l[1];
    var _m = __read(useState(false), 2), approveUsage = _m[0], setApproveUsage = _m[1];
    var _o = __read(useState(false), 2), approveTerms = _o[0], setApproveTerms = _o[1];
    var _p = __read(useState(false), 2), showNotification = _p[0], setShowNotification = _p[1];
    if (session !== null) {
        dispatch(setSession(null));
        navigate('/', { replace: true });
    }
    var validateExtensionInvitation = function () { return __awaiter(void 0, void 0, void 0, function () {
        var patient, dateOfBirth;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, validateInvitation(ssn, invitationId)];
                case 1:
                    patient = _a.sent();
                    if (patient) {
                        dateOfBirth = dateOfBirthFromSsn(ssn);
                        setPatientAge(ageFromDateOfBirth(dateOfBirth));
                        dispatch(setLanguage(patient.language));
                        setPatientId(patient._id);
                        setState('addUserInfoData');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var saveUserInfo = function () {
        setState('addUserData');
    };
    var createNewUserAndUserInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newUser, newUserInfo, createNewCustomerRequest, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newUser = {
                        username: userName,
                        password: password,
                    };
                    newUserInfo = {};
                    if (patientAge && patientAge < 16) {
                        newUserInfo = {
                            firstName: userInfoFirstName,
                            lastName: userInfoLastName,
                            email: userInfoEmail,
                            phone: userInfoPhoneNumber,
                            customerInfo: {
                                dependants: patientId,
                                agreementApproved: new Date(),
                            },
                        };
                    }
                    else {
                        newUserInfo = {
                            firstName: userInfoFirstName,
                            lastName: userInfoLastName,
                            email: userInfoEmail,
                            phone: userInfoPhoneNumber,
                            customerInfo: {
                                patient: patientId,
                                agreementApproved: new Date(),
                            },
                        };
                    }
                    createNewCustomerRequest = {
                        user: newUser,
                        userInfo: newUserInfo,
                        invitationId: invitationId,
                        password: ssn,
                    };
                    return [4 /*yield*/, createCustomerAndStartExtensionPeriod(createNewCustomerRequest)];
                case 1:
                    response = _a.sent();
                    if (response) {
                        setState('finishedMessageView');
                    }
                    else {
                        setShowNotification(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", __assign({ className: "flex justify-items-end items-center my-12" }, { children: _jsxs("div", __assign({ className: "max-w-screen-sm w-11/12 grid gap-3 m-auto" }, { children: [_jsx("h1", __assign({ className: "text-center text-xl m-auto" }, { children: _jsx("img", { alt: "\u00C4\u00E4nnekoulu", src: aannekouluLogo, className: "sm:max-w-lg" }) })), _jsxs("div", __assign({ className: "bg-cyan px-8 md:px-16 py-8 rounded-md shadow-md text-center grid gap-8" }, { children: [state === 'validateExtensionInvitation' && (_jsx(ValidateExtensionInvitation, { ssn: ssn, setSsn: setSsn, validateExtensionInvitation: validateExtensionInvitation })), state === 'addUserInfoData' && (_jsx(AddUserInfoData, { setUserInfoFirstName: setUserInfoFirstName, setUserInfoLastName: setUserInfoLastName, setUserInfoPhoneNumber: setUserInfoPhoneNumber, setUserInfoEmail: setUserInfoEmail, saveUserInfo: saveUserInfo })), state === 'addUserData' && (_jsx(AddUserData, { userInfoFirstName: userInfoFirstName, userInfoLastName: userInfoLastName, userName: userName, setUserName: setUserName, password: password, setPassword: setPassword, passwordAgain: passwordAgain, setPasswordAgain: setPasswordAgain, approveUsage: approveUsage, setApproveUsage: setApproveUsage, approveTerms: approveTerms, setApproveTerms: setApproveTerms, createNewUserAndUserInfo: createNewUserAndUserInfo, showNotification: showNotification })), state === 'finishedMessageView' && _jsx(Phase4, {})] }))] })) })));
};
export default CreateUserForExtensionPeriod;
