var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { Translate } from '../../translation/Translate';
var EvaluationQuestionaireSelection = function (_a) {
    var activeQuestionaire = _a.activeQuestionaire, setActiveQuestionaire = _a.setActiveQuestionaire, next = _a.next;
    var moduleSelector = useRef(null);
    var ageGroupsTypes = [
        'ageGroupType1',
        'ageGroupType2',
        'ageGroupType3',
        'ageGroupType4',
        'ageGroupType5',
        'ageGroupType6',
    ];
    var handleQuestionaireSelection = function (value) {
        setActiveQuestionaire(value);
    };
    function submitQuestionaire() {
        if (next)
            next();
    }
    return (_jsxs(React.Fragment, { children: [_jsx("h2", __assign({ className: "text-xl" }, { children: Translate({ term: 'evaluation.questionnaire_selection.text1' }) })), _jsxs("div", __assign({ className: "grid gap-4" }, { children: [_jsxs("label", { children: [Translate({ term: 'evaluation.questionnaire_selection.text2' }), _jsxs("select", __assign({ className: "block w-full", onChange: function (e) { return handleQuestionaireSelection(e.target.value); }, ref: moduleSelector }, { children: [_jsxs("option", __assign({ value: "" }, { children: [' ', Translate({ term: 'evaluation.questionnaire_selection.text3' })] })), ageGroupsTypes === null || ageGroupsTypes === void 0 ? void 0 : ageGroupsTypes.map(function (ageGroup, key) {
                                        return (_jsx("option", __assign({ value: ageGroup }, { children: Translate({ term: "client.ageGroupType.".concat(ageGroup) }) }), key));
                                    })] }))] }), _jsx("button", __assign({ disabled: activeQuestionaire === '', className: activeQuestionaire === '' ? 'opacity-25 cursor-not-allowed' : '', onClick: function () { return submitQuestionaire(); } }, { children: Translate({ term: 'evaluation.questionnaire_selection.text4' }) }))] }))] }));
};
export default EvaluationQuestionaireSelection;
