var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useDispatch } from 'react-redux';
import { saveDraft, deleteDraft, setPeriod, } from '../../../reducers/periodSlice';
import { sendPeriodMessage } from '../../../actions/periodActions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Translate } from '../../translation/Translate';
import { useState } from 'react';
import { getSignedUploadUrl, sendFileWithSignedUrl, } from '../../../actions/fileActions';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import useUser from '../../../hooks/useUser';
import ProgressBar from '../ProgressBar';
export var MessageAttachment = function (_a) {
    var title = _a.title, id = _a.id, remove = _a.remove;
    return (_jsxs("div", __assign({ className: "rounded-md bg-gray-200 text-gray-700 pl-4 py-1 pr-10 relative" }, { children: [title, _jsx("div", __assign({ className: "absolute top-0 right-0 pt-0.5 pr-2" }, { children: _jsx("div", __assign({ className: "text-warning-600 cursor-pointer", onClick: function () { return (remove ? remove(id) : {}); } }, { children: _jsx(HighlightOffIcon, {}) })) }))] })));
};
var PatientPeriodsMessageEditor = function (_a) {
    var periodId = _a.periodId, assignments = _a.assignments, getPeriods = _a.getPeriods, reply = _a.reply, setReply = _a.setReply;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var _b = __read(useState(null), 2), draft = _b[0], setMyDraft = _b[1];
    var dispatch = useDispatch();
    var user = useUser();
    var messageAttachmentsTranslate = Translate({
        term: 'messages.attachments',
    });
    var failedTranslate = Translate({ term: 'common.send_failed' });
    var fileSizeTooLargeTranslate = Translate({
        term: 'common.file_size_too_large',
    });
    var attachment = useRef(null);
    var textarea = useRef(null);
    var _c = __read(React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(React.useState(0), 2), percentage = _d[0], setPercentage = _d[1];
    var _e = __read(React.useState(''), 2), status = _e[0], setStatus = _e[1];
    var invalidFileFormatErrorTranslate = Translate({
        term: 'common.wrong_file_format',
    });
    // TODO: make generic function for this.
    var showToastMessage = function (text, type) {
        if (type === void 0) { type = 'success'; }
        toast(text, {
            position: 'top-center',
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            autoClose: 2000,
            toastId: type === 'warning' ? 'warningId' : 'successId',
            className: type === 'warning' ? 'toast-message-warning' : 'toast-message-success',
        });
    };
    var fileSelector = function () {
        if (attachment && attachment.current)
            attachment.current.click();
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var handleFileSelection = function (e) {
        var files = e.target.files;
        if (files && files.length) {
            // 1 000 000 000 === 1gt
            if (files[0].size > 4000000000) {
                showToastMessage(fileSizeTooLargeTranslate, 'warning');
                return;
            }
            var file = {
                name: files[0].name,
                file: files[0],
            };
            var atts = draft ? __spreadArray(__spreadArray([], __read(draft.attachments), false), [file], false) : [file];
            setDraft('attachments', atts);
            e.target.value = null;
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function setDraft(key, value) {
        if (periodId) {
            var newDraft = draft
                ? __assign({}, draft) : {
                periodId: periodId,
                message: '',
                attachments: [],
            };
            if (key === 'message') {
                if (value && typeof value === 'string')
                    newDraft.message = value;
                else if (value === '' && newDraft.message.length === 1)
                    newDraft.message = '';
            }
            else if (key === 'attachments' && value && typeof value !== 'string') {
                newDraft.attachments = value;
            }
            dispatch(saveDraft(newDraft));
            setMyDraft(newDraft);
        }
    }
    function removeAttachment(key) {
        if (draft && draft.attachments && draft.attachments[key]) {
            var atts = __spreadArray([], __read(draft.attachments), false).splice(key, 0);
            setDraft('attachments', atts);
        }
    }
    function sendMessage(message) {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function () {
            var attachmentUrls, _g, _h, attachment_1, payload, signedUrlData, fileSent, messageAttachment, e_1_1, messagePayload, updatedPeriod;
            var e_1, _j;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        if (!draft) return [3 /*break*/, 13];
                        if (draft.message === '' && draft.attachments.length === 0)
                            return [2 /*return*/];
                        if (!periodId) return [3 /*break*/, 12];
                        setLoading(true);
                        setStatus('Valmistellaan latausta');
                        setPercentage(0);
                        if (!(draft === null || draft === void 0 ? void 0 : draft.attachments)) return [3 /*break*/, 12];
                        attachmentUrls = [];
                        _k.label = 1;
                    case 1:
                        _k.trys.push([1, 8, 9, 10]);
                        _g = __values(draft.attachments), _h = _g.next();
                        _k.label = 2;
                    case 2:
                        if (!!_h.done) return [3 /*break*/, 7];
                        attachment_1 = _h.value;
                        payload = {
                            mimetype: (_a = attachment_1.file) === null || _a === void 0 ? void 0 : _a.type,
                            path: (_b = attachment_1.file) === null || _b === void 0 ? void 0 : _b.name,
                            pathType: 'period',
                            periodId: periodId,
                        };
                        return [4 /*yield*/, getSignedUploadUrl(payload, user.session)];
                    case 3:
                        signedUrlData = _k.sent();
                        if (!signedUrlData) return [3 /*break*/, 5];
                        // HTTP 400 Bad Request
                        if (signedUrlData instanceof Error) {
                            showToastMessage(invalidFileFormatErrorTranslate, 'warning');
                            setLoading(false);
                            return [2 /*return*/];
                        }
                        // Send file to bucket
                        setStatus("Ladataan liitett\u00E4 ".concat((_c = attachment_1.file) === null || _c === void 0 ? void 0 : _c.name));
                        return [4 /*yield*/, sendFileWithSignedUrl(attachment_1.file, signedUrlData.url, function (event) {
                                setPercentage((event.loaded / event.total) * 100);
                            })];
                    case 4:
                        fileSent = _k.sent();
                        if (fileSent) {
                            messageAttachment = {
                                name: (_d = attachment_1.file) === null || _d === void 0 ? void 0 : _d.name,
                                size: (_e = attachment_1.file) === null || _e === void 0 ? void 0 : _e.size,
                                mimetype: (_f = attachment_1.file) === null || _f === void 0 ? void 0 : _f.type,
                                file: signedUrlData.filePath,
                            };
                            attachmentUrls.push(messageAttachment);
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        showToastMessage(failedTranslate, 'warning');
                        _k.label = 6;
                    case 6:
                        _h = _g.next();
                        return [3 /*break*/, 2];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        e_1_1 = _k.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 10];
                    case 9:
                        try {
                            if (_h && !_h.done && (_j = _g.return)) _j.call(_g);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 10:
                        // Save video url with other params.
                        setStatus('Lähetetään viesti');
                        messagePayload = {
                            periodId: periodId,
                            message: message,
                            attachmentUrls: attachmentUrls,
                        };
                        return [4 /*yield*/, sendPeriodMessage(messagePayload, user.session)];
                    case 11:
                        updatedPeriod = _k.sent();
                        clearDraft();
                        if (updatedPeriod) {
                            dispatch(setPeriod(updatedPeriod));
                        }
                        else {
                            showToastMessage(failedTranslate, 'warning');
                        }
                        _k.label = 12;
                    case 12:
                        setLoading(false);
                        _k.label = 13;
                    case 13: return [2 /*return*/];
                }
            });
        });
    }
    function clearDraft() {
        if (setReply !== undefined)
            setReply(null);
        setMyDraft(null);
        if (textarea && textarea.current)
            textarea.current.value = '';
        if (periodId)
            dispatch(deleteDraft(periodId));
    }
    return (_jsxs("div", __assign({ className: "bg-white py-1 px-3 md:px-8 rounded-md border border-gray-200 relative" }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsxs("label", __assign({ className: "" }, { children: [reply === null ? (_jsx("p", __assign({ className: "mt-4 text-base font-medium text-grey-500" }, { children: Translate({ term: 'messages.write_message' }) }))) : (_jsx("p", __assign({ className: "mt-4 text-base font-medium text-grey-500" }, { children: "Vastaa viestiin" }))), _jsx("textarea", { defaultValue: draft && draft.message ? draft.message : '', onChange: function () { var _a; return setDraft('message', (_a = textarea.current) === null || _a === void 0 ? void 0 : _a.value); }, ref: textarea, className: "w-full max-h-16 sm:max-h-20 md:max-h-24 lg:max-h-28 border border-gray-300 rounded-md p-2 sm:p-2 text-base", rows: 4 })] })), draft && draft.attachments && draft.attachments.length > 0 && (_jsxs("div", __assign({ className: "border border-gray-300 rounded-md p-2 grid gap-2" }, { children: [_jsx("div", __assign({ className: "text-xs" }, { children: messageAttachmentsTranslate })), _jsx("div", __assign({ className: "flex gap-3" }, { children: 
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        draft.attachments.map(function (a, key) { return (_jsx(MessageAttachment, { id: key, title: a.name, remove: removeAttachment }, key)); }) }))] }))), loading && _jsx(ProgressBar, { percentage: percentage, message: status }), _jsxs("div", __assign({ className: "flex flex-row justify-between my-1 md:my-3" }, { children: [_jsxs("div", __assign({ className: "space-x-3 flex flex-row" }, { children: [_jsx("div", __assign({ className: "flex-initial bg-lightBlue p-1.5 rounded-full text-blue cursor-pointer", onClick: fileSelector }, { children: _jsx(AttachFileIcon, { style: { marginTop: '2px' }, className: "rotate-45" }) })), _jsx("input", { type: "file", name: "file[1]", className: "hidden", ref: attachment, onChange: handleFileSelection })] })), _jsxs("div", __assign({ className: "space-x-3 flex flex-row" }, { children: [_jsx("div", { children: _jsx("button", __assign({ onClick: function () { return clearDraft(); }, className: "bg-white border-2 border-blue hover:bg-blue hover:text-white duration-300 text-blue" }, { children: Translate({ term: 'actions.cancel' }) })) }), _jsx("div", __assign({ className: "text-right" }, { children: _jsx("button", __assign({ className: "h-11", onClick: function () {
                                        var _a;
                                        return sendMessage(((_a = textarea.current) === null || _a === void 0 ? void 0 : _a.value) ? textarea.current.value : '');
                                    } }, { children: Translate({ term: 'actions.send' }) })) }))] }))] }))] })));
};
export default PatientPeriodsMessageEditor;
