var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import BasicModal from '../../modals/BasicModal';
import { Translate } from '../../../translation/Translate';
import SecondaryButton from '../../buttons/secondaryButton';
import { sendPeriodMessageAttachmentsToHelpChat } from '../../../../actions/HelpChatMessageActions';
import useUser from '../../../../hooks/useUser';
var SendAttachmentToHelpChatModal = function (_a) {
    var opentConfirmationModal = _a.opentConfirmationModal, setOpenConfirmationModal = _a.setOpenConfirmationModal, periodId = _a.periodId, message = _a.message, showToastMessage = _a.showToastMessage;
    var user = useUser();
    var _b = __read(useState(''), 2), text = _b[0], setText = _b[1];
    var sendAttachmentToHelpChatTranslate = Translate({
        term: 'help_chat.send_help_chat_message',
    });
    var writeMessageTranslation = Translate({ term: 'messages.write_message' });
    var sendTranslate = Translate({ term: 'actions.send' });
    var cancelTranslate = Translate({ term: 'actions.cancel' });
    var successMessageTranslate = Translate({
        term: 'help_chat.success_message',
    });
    var warningMessageTranslate = Translate({
        term: 'material_library.upload_wargning_messages.something_went_wrong',
    });
    var sendAttachmentToHelpChat = function () { return __awaiter(void 0, void 0, void 0, function () {
        var messageAttachmentsToHelpChatRequest, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    messageAttachmentsToHelpChatRequest = {
                        periodId: periodId,
                        message: message,
                        text: text,
                    };
                    return [4 /*yield*/, sendPeriodMessageAttachmentsToHelpChat(messageAttachmentsToHelpChatRequest, user.session)];
                case 1:
                    response = _a.sent();
                    if (response) {
                        setOpenConfirmationModal(false);
                        showToastMessage(successMessageTranslate, 'success');
                    }
                    else {
                        showToastMessage(warningMessageTranslate, 'warning');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(BasicModal, __assign({ open: opentConfirmationModal, handleClose: function () { return setOpenConfirmationModal(false); } }, { children: _jsx("div", __assign({ className: "flex justify-center" }, { children: _jsxs("div", { children: [_jsx("h3", { children: sendAttachmentToHelpChatTranslate }), _jsxs("div", { children: [_jsxs("div", __assign({ className: "pt-4" }, { children: [_jsx("label", { children: writeMessageTranslation }), _jsx("textarea", { value: text, onChange: function (event) { return setText(event.target.value); }, className: "w-full border border-gray-300 rounded-md p-2 text-base" })] })), _jsxs("div", __assign({ className: "justify-items-center justify-center items-center whitespace-nowrap flex space-x-10 pt-4" }, { children: [_jsx(SecondaryButton, { text: sendTranslate, action: sendAttachmentToHelpChat }), _jsx(SecondaryButton, { text: cancelTranslate, action: function () { return setOpenConfirmationModal(false); } })] }))] })] }) })) })));
};
export default SendAttachmentToHelpChatModal;
