import { langFi } from '../../assets/languages/fi';
import { langSe } from '../../assets/languages/se';
var locales = [
    {
        id: 'fi',
        name: 'Suomi',
        name_short: 'FI',
        lang: langFi,
        translateCode: 'fi_FI',
    },
    {
        id: 'se',
        name: 'Svenska',
        name_short: 'SE',
        lang: langSe,
        translateCode: 'se_SE',
    },
];
export default locales;
