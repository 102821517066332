var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PeriodMaterialIcon from '../period/PeriodMaterialIcon';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { Translate } from '../../translation/Translate';
import ModalReturnAssignment from '../../client/period/ReturnAssignmentModal';
import { CircularProgress } from '@mui/material';
export var Assignment = function (_a) {
    var open = _a.open, approve = _a.approve, disapprove = _a.disapprove, remove = _a.remove, addToMessage = _a.addToMessage, removeDeadline = _a.removeDeadline, removeFromMessage = _a.removeFromMessage, id = _a.id, name = _a.name, description = _a.description, type = _a.type, patientInstructions = _a.patientInstructions, idNumber = _a.idNumber, showToastMessage = _a.showToastMessage;
    var _b = __read(React.useState(false), 2), openReturnModal = _b[0], setOpenReturnModal = _b[1];
    var _c = __read(React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    var actions = [];
    var actionIconClass = 'flex-initial px-1 text-center border cursor-pointer rounded-full';
    var ReturnTranslate = Translate({
        term: 'actions.answer',
    });
    var OpenTranslate = Translate({
        term: 'actions.open',
    });
    // remove assingment from done to active
    if (disapprove) {
        actions.push(_jsx("div", __assign({ onClick: function () { return disapprove(id); }, className: [
                actionIconClass,
                'w-6 h-6 border-warning-800 text-warning-800 bg-orange',
            ].join(' ') }, { children: _jsx(ArrowUpwardOutlinedIcon, { sx: { fontSize: 16, marginBottom: '3px' } }) })));
    }
    // removes assingment from period
    if (removeFromMessage) {
        actions.push(_jsx("div", __assign({ onClick: function () { return removeFromMessage(id); }, className: [
                actionIconClass,
                'w-6 h-6 border-warning-800 text-warning-800 bg-orange',
            ].join(' ') }, { children: _jsx(DeleteOutlineOutlinedIcon, { sx: { fontSize: 16, marginBottom: '4px', marginRight: '5px' } }) })));
    }
    // add assignment to active
    if (addToMessage) {
        actions.push(_jsx("div", __assign({ onClick: function () { return addToMessage(id); }, className: [
                actionIconClass,
                'w-6 h-6 border-success-800 text-success-800 bg-green',
            ].join(' ') }, { children: _jsx(ArrowDownwardOutlinedIcon, { sx: { fontSize: 16, marginBottom: '3px' } }) })));
    }
    // maybe this is not needed anymore
    if (remove) {
        actions.push(_jsx("div", __assign({ onClick: function () { return remove(id); }, className: [
                actionIconClass,
                'w-6 h-6 border-warning-800 text-warning-800 bg-orange',
            ].join(' ') }, { children: _jsx(DeleteOutlineOutlinedIcon, { sx: { fontSize: 16, marginBottom: '2px' } }) })));
    }
    // remove assignment to waiting list
    if (removeDeadline) {
        actions.push(_jsx("div", __assign({ onClick: function () { return removeDeadline(id); }, className: [
                actionIconClass,
                'w-6 h-6 border-warning-800 text-warning-800 bg-orange',
            ].join(' ') }, { children: _jsx(ArrowUpwardOutlinedIcon, { sx: { fontSize: 16, marginBottom: '3px' } }) })));
    }
    // mark assingment done
    if (approve) {
        actions.push(_jsx("div", __assign({ onClick: function () { return approve(id); }, className: [
                actionIconClass,
                'w-6 h-6 border-success-800 text-success-800 bg-green',
            ].join(' ') }, { children: _jsx(CheckIcon, { sx: { fontSize: 16, marginBottom: '2px' } }) })));
    }
    var actionButtons = undefined;
    var mainClass = ['relative flex gap-3 w-full bg-white pb-1 mt-2'];
    actionButtons = (_jsx("div", __assign({ className: "flex-none flex flex-col items-end gap-2 place-content-end" }, { children: actions.map(function (action, key) { return (_jsx(React.Fragment, { children: action }, key)); }) })));
    var desc = description;
    if (desc && desc.length < 10 && patientInstructions)
        desc += ' ' + patientInstructions;
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: mainClass.join(' ') }, { children: [type === 'video' && (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx(PeriodMaterialIcon, { type: type }), " ", name] }))), type === 'game' && (_jsx("div", { children: idNumber && (_jsxs("div", __assign({ className: "flex flex-col w-9/12 text-gray-500 hover:text-gray-800 cursor-pointer ml-3" }, { children: [_jsx(PeriodMaterialIcon, { type: type }), " ", name] }))) })), type === 'text' && (_jsxs("div", __assign({ className: "flex flex-col w-9/12 ml-3" }, { children: [_jsx(PeriodMaterialIcon, { type: type }), " ", name] }))), loading && _jsx(CircularProgress, {}), _jsx("div", __assign({ className: "flex-auto" }, { children: actionButtons })), _jsx(ModalReturnAssignment, { openModal: openReturnModal, setOpenModal: setOpenReturnModal, showToastMessage: showToastMessage, setLoading: setLoading })] })), _jsxs("div", __assign({ className: "flex flex-row gap-2 mt-2" }, { children: [_jsx("a", __assign({ href: "/materials/".concat(id) }, { children: _jsx("button", __assign({ className: "bg-white text-black border-2 border-blue rounded-md" }, { children: OpenTranslate })) })), open && (_jsx("button", __assign({ onClick: function () { return setOpenReturnModal(true); }, className: "border-2 border-blue" }, { children: ReturnTranslate })))] }))] }));
};
