var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Translate } from '../../translation/Translate';
import { Modal } from '@mui/material';
import { getActivePeriods, sendPeriodMessage, } from '../../../actions/periodActions';
import { useDispatch, useSelector } from 'react-redux';
import { setPeriods } from '../../../reducers/periodSlice';
import { getSignedUploadUrl, sendFileWithSignedUrl, } from '../../../actions/fileActions';
import useUser from '../../../hooks/useUser';
var ModalReturnAssignment = function (_a) {
    var openModal = _a.openModal, setOpenModal = _a.setOpenModal, showToastMessage = _a.showToastMessage, setLoading = _a.setLoading;
    var dispatch = useDispatch();
    var _b = __read(useState(false), 2), openMessage = _b[0], setOpenMessage = _b[1];
    var _c = __read(useState(false), 2), warning = _c[0], setWarning = _c[1];
    var _d = __read(useState(null), 2), selectedFile = _d[0], setSelectedFile = _d[1];
    var periods = useSelector(function (state) { return state.periods.periods; });
    var user = useUser();
    var _e = __read(useState(''), 2), comment = _e[0], setComment = _e[1];
    var sendTranslate = Translate({ term: 'actions.return' });
    var cancelTranslate = Translate({ term: 'actions.cancel' });
    var assignmentReturnedTranslate = Translate({
        term: 'period.assignment_returned',
    });
    var assginmentReturnErrorTranslate = Translate({
        term: 'period.assignment_return_error',
    });
    var invalidFileFormatErrorTranslate = Translate({
        term: 'common.wrong_file_format',
    });
    var onFileChange = function (files) {
        if (files)
            setSelectedFile(files[0]);
    };
    var saveFile = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, signedUrlData, fileSent, messageAttachment, newAssignments_1, assignmentTitle_1, messagePayload, updatedPeriod;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setOpenModal(false);
                    if (!(periods && periods.length > 0 && periods[0]._id)) return [3 /*break*/, 6];
                    setLoading(true);
                    payload = {
                        mimetype: file === null || file === void 0 ? void 0 : file.type,
                        path: file === null || file === void 0 ? void 0 : file.name,
                        pathType: 'period',
                        periodId: periods[0]._id,
                    };
                    return [4 /*yield*/, getSignedUploadUrl(payload, user.session)];
                case 1:
                    signedUrlData = _a.sent();
                    if (!signedUrlData) return [3 /*break*/, 5];
                    // HTTP 400 Bad Request
                    if (signedUrlData instanceof Error) {
                        showToastMessage && showToastMessage(invalidFileFormatErrorTranslate);
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, sendFileWithSignedUrl(file, signedUrlData.url)];
                case 2:
                    fileSent = _a.sent();
                    if (!fileSent) return [3 /*break*/, 4];
                    messageAttachment = {
                        name: file === null || file === void 0 ? void 0 : file.name,
                        size: file === null || file === void 0 ? void 0 : file.size,
                        mimetype: file === null || file === void 0 ? void 0 : file.type,
                        file: signedUrlData.filePath,
                    };
                    newAssignments_1 = [];
                    assignmentTitle_1 = '';
                    // Don't know why there is possibly multiple assigments
                    if (periods[0].assignments) {
                        periods[0].assignments.forEach(function (a) {
                            if (a.addedToMessage) {
                                newAssignments_1.push(a.id);
                                if (a.name) {
                                    assignmentTitle_1 = a.name;
                                }
                            }
                        });
                    }
                    messagePayload = {
                        periodId: periods[0]._id,
                        message: "".concat(assignmentTitle_1, " ").concat(comment),
                        assignments: newAssignments_1,
                        attachmentUrls: [messageAttachment],
                    };
                    return [4 /*yield*/, sendPeriodMessage(messagePayload, user.session)];
                case 3:
                    updatedPeriod = _a.sent();
                    setLoading(false);
                    if (showToastMessage)
                        showToastMessage(assignmentReturnedTranslate);
                    if (updatedPeriod)
                        getOwnActivePeriods();
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    if (showToastMessage) {
                        showToastMessage(assginmentReturnErrorTranslate);
                    }
                    _a.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); };
    function getOwnActivePeriods() {
        getActivePeriods(user.session).then(function (response) {
            if (response)
                dispatch(setPeriods(response));
        });
    }
    return (_jsx(Modal, __assign({ open: openModal, onClose: function () {
            setWarning(false);
            setOpenMessage(true);
        }, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", disableAutoFocus: true }, { children: _jsx("div", { children: _jsxs("div", __assign({ className: "absolute z-20 px-8 bg-white border h-auto rounded-md top-1/2 left-1/2", style: { width: '350px', transform: 'translate(-50%, -50%' } }, { children: [_jsxs("div", __assign({ className: "mt-4" }, { children: [selectedFile ? (_jsx("div", __assign({ onClick: function () { var _a; return (_a = document.getElementById('selectFile')) === null || _a === void 0 ? void 0 : _a.click(); }, className: "border cursor-pointer rounded-md shadow-md w-full h-auto" }, { children: _jsx("div", __assign({ className: "flex flex-row items-center space-x-2 ml-2" }, { children: _jsxs("div", { children: [_jsx("p", { children: "Tiedosto lis\u00E4tty:" }), _jsx("p", { children: selectedFile.name })] }) })) }))) : (_jsx("input", { id: "selectFile", type: "file", className: "mt-2 -ml-2", onChange: function (e) { return onFileChange(e.target.files); } })), _jsx("input", { id: "selectFile", type: "file", style: { display: 'none' }, onChange: function (e) { return onFileChange(e.target.files); } })] })), _jsx("p", __assign({ className: "mt-2 -ml-2" }, { children: "Kommentti:" })), _jsx("textarea", { onChange: function (e) { return setComment(e.target.value); }, className: "w-full h-8 border border-gray-300 rounded-md p-1 -ml-2 text-base", rows: 1 }), _jsxs("div", __assign({ className: "flex justify-end space-x-3 mt-2 mb-2" }, { children: [_jsx("button", __assign({ onClick: function () {
                                    setWarning(false);
                                    setOpenModal(false);
                                }, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: cancelTranslate })), !selectedFile ? (_jsx("button", __assign({ className: "h-11" }, { children: sendTranslate }))) : (_jsx("button", __assign({ onClick: function () {
                                    saveFile(selectedFile);
                                }, className: "h-11" }, { children: sendTranslate })))] }))] })) }) })));
};
export default ModalReturnAssignment;
