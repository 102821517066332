var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import LicenseControllerLayout from '../../components/layouts/LicenseControllerLayout';
import Home from '../licenseController/Home';
import Instructions from '../licenseController/Instructions';
import ShareLicense from '../common/ShareLicense';
export var LicenseControllerRoutes = function () {
    return (_jsx(Suspense, __assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(Routes, { children: _jsxs(Route, __assign({ path: "/", element: _jsx(LicenseControllerLayout, {}) }, { children: [_jsx(Route, { index: true, element: _jsx(Home, {}) }), _jsx(Route, { path: "/share-license", element: _jsx(ShareLicense, {}) }), _jsx(Route, { path: "/instructions", element: _jsx(Instructions, {}) }), _jsx(Route, { path: "*", element: _jsx(NoMatch, {}) })] })) }) })));
};
function NoMatch() {
    return (_jsxs("div", { children: [_jsx("h2", { children: "Nothing to see here!" }), _jsx("p", { children: _jsx(Link, __assign({ to: "/" }, { children: "Go to the home page" })) })] }));
}
