var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Moment from 'react-moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { Translate } from '../../translation/Translate';
import { Assignment } from './Assignment';
import { nanoid } from 'nanoid';
var Assignments = function (_a) {
    var size = _a.size, assignments = _a.assignments, isDone = _a.isDone, open = _a.open, openModal = _a.openModal, approve = _a.approve, disapprove = _a.disapprove, remove = _a.remove, addToMessage = _a.addToMessage, removeDeadline = _a.removeDeadline, removeFromMessage = _a.removeFromMessage, therapist = _a.therapist, onlyTitles = _a.onlyTitles, showToastMessage = _a.showToastMessage;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var assignmentGroups = [];
    var byTranslate = Translate({ term: 'assignment.by' });
    var returnTranslate = Translate({ term: 'actions.return' });
    var assignmentsDoneTranslate = Translate({
        term: 'period.all_assignments_done',
    });
    assignments.map(function (assignment) {
        var title = '';
        var date = assignment.created;
        if (assignment.approved) {
            date = assignment.approved;
            title = (_jsxs("div", __assign({ className: "text-right text-xs font-light" }, { children: [_jsx(CheckCircleIcon, { className: "-mt-1 text-success-600", sx: { fontSize: 18 } }), ' ', _jsx(Moment, __assign({ style: { color: '#667085' }, format: "DD.MM.YYYY" }, { children: date }))] })));
        }
        else if (!assignment.approved && assignment.deadline) {
            date = assignment.deadline;
            title = (_jsx("div", { className: "text-right text-xs font-light", style: { color: '#667085' } }));
        }
        if (isDone === false && !assignment.approved && assignment.deadline) {
            assignmentGroups.push({
                date: date,
                title: title,
                assignments: [assignment],
            });
        }
        else if (isDone === true && assignment.approved) {
            assignmentGroups.push({
                date: date,
                title: title,
                assignments: [assignment],
            });
        }
        else if (!assignment.deadline && therapist === 0) {
            assignmentGroups.push({
                date: date,
                title: title,
                assignments: [assignment],
            });
        }
    });
    if (assignmentGroups.length === 0 &&
        isDone === false &&
        addToMessage === undefined &&
        therapist !== 2) {
        return (_jsx("div", __assign({ className: "flex items-center justify-center my-2 p-6 bg-white border border-cyan rounded-md" }, { children: _jsxs("p", __assign({ className: "text-base font-medium text-grey-800" }, { children: [assignmentsDoneTranslate, ' ', _jsx(SentimentSatisfiedAltIcon, { style: { marginBottom: '2px' } })] })) })));
    }
    return (_jsx("div", __assign({ className: "flex flex-col my-2" }, { children: 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        assignmentGroups.map(function (group, key) { return (_jsxs("div", __assign({ className: "my-0.5 md:my-1.5 border bg-white rounded-md p-2 shadow-md" }, { children: [_jsx("div", __assign({ className: "text-right text-sm" }, { children: group.title })), onlyTitles ? (_jsx("div", __assign({ className: "" }, { children: group.assignments.map(function (assignment, key) {
                        return _jsx("div", { children: assignment.name }, key);
                    }) }))) : (_jsx("div", __assign({ className: "" }, { children: group.assignments.map(function (assignment, key) {
                        return (_jsx("div", { children: _jsx(Assignment, { id: assignment.id ? assignment.id : nanoid(), name: assignment.name, description: assignment.description, type: assignment.type, created: assignment.created, open: open, openModal: openModal, remove: remove, addToMessage: addToMessage, approve: approve, url: assignment.url, disapprove: disapprove, removeDeadline: removeDeadline, removeFromMessage: removeFromMessage, addedToMessage: assignment.addedToMessage, idNumber: assignment.idNumber, html: assignment.html, showToastMessage: showToastMessage }, key) }, key));
                    }) })))] }), key)); }) })));
};
export default Assignments;
