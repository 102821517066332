var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../reducers/userSlice';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { updateTherapistAbsenceMessage, getUserinfo, } from '../../../actions/userActions';
import TextField from '@mui/material/TextField';
import { Translate } from '../../translation/Translate';
import PickDefaultMessage from '../../common/PickDefaultMessage';
import { generalDefaultMessages } from '../Posts/GeneralDefaultMessages';
import useUser from '../../../hooks/useUser';
var AbsenceNotice = function (_a) {
    var cancel = _a.cancel, session = _a.session;
    var dispatch = useDispatch();
    var _b = __read(React.useState(null), 2), startDay = _b[0], setStartDay = _b[1];
    var _c = __read(React.useState(null), 2), endDay = _c[0], setEndDay = _c[1];
    var _d = __read(React.useState(false), 2), checkboxIsChecked = _d[0], setCheckboxIsChecked = _d[1];
    var textarea = useRef(null);
    var user = useUser();
    var defaultMessage = useRef(null);
    function useDefaultMessage() {
        if ((textarea === null || textarea === void 0 ? void 0 : textarea.current) &&
            (defaultMessage === null || defaultMessage === void 0 ? void 0 : defaultMessage.current) &&
            defaultMessage.current.value !== '') {
            var msg = JSON.parse(defaultMessage.current.value);
            textarea.current.value = msg.message;
            defaultMessage.current.value = '';
        }
    }
    var absenceDays = 0;
    if (startDay && endDay) {
        var start = moment(startDay);
        var end = moment(endDay);
        absenceDays = moment.duration(end.diff(start)).as('days') + 1;
        absenceDays = Math.round(absenceDays);
    }
    function handleCheckboxChange(event) {
        if (event.target.checked) {
            setCheckboxIsChecked(true);
        }
        else {
            setCheckboxIsChecked(false);
        }
    }
    function saveUserInfo(absenceMessageRequest) {
        return __awaiter(this, void 0, void 0, function () {
            var response, userData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, updateTherapistAbsenceMessage(absenceMessageRequest, session)];
                    case 1:
                        response = _a.sent();
                        if (!response) return [3 /*break*/, 3];
                        return [4 /*yield*/, getUserinfo(session)];
                    case 2:
                        userData = _a.sent();
                        if (userData) {
                            dispatch(setUser(userData));
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    var messageTemplates = generalDefaultMessages;
    function confirmAbsence() {
        var absenceMessage = '';
        if ((textarea === null || textarea === void 0 ? void 0 : textarea.current) && (defaultMessage === null || defaultMessage === void 0 ? void 0 : defaultMessage.current)) {
            absenceMessage = textarea.current.value;
        }
        if (startDay && endDay && absenceDays > 0 && absenceMessage !== '') {
            var start = moment(startDay).format();
            var end = moment(endDay).format();
            // Absence days is used to automatically lengthen custormers therapy period based on absenceDays value.
            if (!checkboxIsChecked) {
                absenceDays = 0;
            }
            var absenceMessageRequest = {
                start: start,
                end: end,
                absenceMessage: absenceMessage,
                autoreply: true,
                absenceDays: absenceDays,
            };
            saveUserInfo(absenceMessageRequest);
        }
        else {
            //console.error('fail success');
        }
    }
    return (_jsxs("div", __assign({ className: "grid md:grid-cols-3 md:gap-y-3" }, { children: [_jsx("div", __assign({ className: "col-start-1" }, { children: Translate({ term: 'profile.absence_time' }) })), _jsxs("div", __assign({ className: "md:col-span-2" }, { children: [_jsx(LocalizationProvider, __assign({ dateAdapter: AdapterMoment }, { children: _jsxs("div", __assign({ className: "flex gap-3" }, { children: [_jsx("div", { children: _jsxs("label", { children: [_jsx("div", __assign({ className: "block text-sm" }, { children: Translate({ term: 'profile.starting_day' }) })), _jsx(DatePicker, { mask: Translate({ term: 'common.date_mask' }), inputFormat: Translate({ term: 'common.date_format' }), value: startDay, onChange: function (newValue) {
                                                    setStartDay(newValue);
                                                }, renderInput: function (params) { return _jsx(TextField, __assign({}, params)); } })] }) }), _jsx("div", { children: "\u2013" }), _jsx("div", { children: _jsxs("label", { children: [_jsx("div", __assign({ className: "block text-sm" }, { children: Translate({ term: 'profile.ending_day' }) })), _jsx(DatePicker, { mask: Translate({ term: 'common.date_mask' }), inputFormat: Translate({ term: 'common.date_format' }), value: endDay, onChange: function (newValue) {
                                                    setEndDay(newValue);
                                                }, renderInput: function (params) { return _jsx(TextField, __assign({}, params)); } })] }) })] })) })), _jsxs("label", __assign({ className: "block py-1 flex gap-1 text-base mt-2" }, { children: [_jsx("input", { onChange: handleCheckboxChange, type: "checkbox", value: absenceDays, name: "days", className: "mt-1" }), _jsxs("div", { children: [Translate({ term: 'profile.extend_therapy_periods' }), ' ', _jsxs("span", __assign({ className: "whitespace-nowrap" }, { children: ["(", absenceDays, " ", Translate({ term: 'profile.days' }), ")"] }))] })] }))] })), _jsx(PickDefaultMessage, { useDefaultMessage: useDefaultMessage, defaultMessage: defaultMessage, user: user }), _jsx("div", __assign({ className: "col-start-1" }, { children: Translate({ term: 'profile.absence_message' }) })), _jsx("div", __assign({ className: "md:col-span-2" }, { children: _jsx("textarea", { ref: textarea, rows: 6, className: "text-base mt-1 block bg-white border border-gray-300 rounded-md px-2 py-1 w-full" }) })), _jsxs("div", __assign({ className: "col-span-3 flex gap-3 mt-3" }, { children: [_jsx("div", __assign({ className: "flex-auto" }, { children: cancel ? (_jsx("button", __assign({ className: "bg-lightBlue text-blue", onClick: cancel }, { children: Translate({ term: 'actions.cancel' }) }))) : ('') })), _jsx("div", { children: _jsx("button", __assign({ onClick: function () {
                                confirmAbsence();
                            } }, { children: Translate({ term: 'profile.confirm_absence' }) })) })] }))] })));
};
export default AbsenceNotice;
