var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import { Translate } from '../../translation/Translate';
import { setPosts } from '../../../reducers/postSlice';
import { getTherapistPosts, updatePostReadByWithUserId, } from '../../../actions/postActions';
import SinglePostModal from './SinglePostModal';
import useUser from '../../../hooks/useUser';
var MessagesTable = function (_a) {
    var columns = _a.columns;
    var dispatch = useDispatch();
    var user = useUser();
    var _b = __read(React.useState(null), 2), activePost = _b[0], setActivePost = _b[1];
    var posts = useSelector(function (state) { return state.posts.posts; });
    function loadPosts() {
        return __awaiter(this, void 0, void 0, function () {
            var postsResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTherapistPosts(user.session)];
                    case 1:
                        postsResponse = _a.sent();
                        if (postsResponse) {
                            dispatch(setPosts(postsResponse));
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        loadPosts();
    }, []);
    function setPostReadByUser(postId, readBy) {
        updatePostReadByWithUserId(postId, readBy, user.session).then(function (response) {
            if (response)
                loadPosts();
        });
    }
    var headCells = [
        {
            id: 'topic',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'messages.topic' }), //'Asiakas',
        },
        {
            id: 'messageSent',
            numeric: true,
            disablePadding: false,
            label: Translate({ term: 'messages.message_sent' }),
        },
        {
            id: 'content',
            numeric: false,
            disablePadding: false,
            label: Translate({ term: 'messages.message_content' }),
        },
    ];
    function checkIsMessageRead(post) {
        if (post.readBy) {
            var isRead = post.readBy.find(function (userId) { var _a; return userId === ((_a = user.userinfo) === null || _a === void 0 ? void 0 : _a.userId); });
            if (isRead) {
                return _jsx(_Fragment, {});
            }
        }
        return _jsx(Chip, { className: "ml-2", label: 1, size: "small", color: "primary" });
    }
    function sliceMessage(message) {
        if (message.length < 33) {
            return _jsx(_Fragment, { children: message });
        }
        var slicedMessage = message.slice(0, 30) + '..';
        return _jsx(_Fragment, { children: slicedMessage });
    }
    function activateMessage(post) {
        var _a, _b;
        var postId = post._id;
        if (post.readBy) {
            var isRead = post.readBy.find(function (userId) { var _a; return userId === ((_a = user.userinfo) === null || _a === void 0 ? void 0 : _a.userId); });
            if (!isRead) {
                if (post.readBy && ((_a = user === null || user === void 0 ? void 0 : user.userinfo) === null || _a === void 0 ? void 0 : _a.userId)) {
                    var userId = user.userinfo.userId;
                    var readBy = post.readBy;
                    var updatedReadBy = readBy.concat([userId]);
                    setPostReadByUser(postId, updatedReadBy);
                }
            }
        }
        else {
            if ((_b = user === null || user === void 0 ? void 0 : user.userinfo) === null || _b === void 0 ? void 0 : _b.userId) {
                var userId = user.userinfo.userId;
                var readBy = [userId];
                setPostReadByUser(postId, readBy);
            }
        }
        setActivePost(post);
    }
    return (_jsxs("div", { children: [_jsxs("table", __assign({ className: "border-collapse bg-white shadow-md w-full" }, { children: [_jsx("thead", { children: _jsx("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2" }, { children: headCells.map(function (headCell, key) {
                                if (headCell.id !== 'action' &&
                                    (!columns || columns.includes(headCell.id))) {
                                    var classes = [
                                        'px-4 py-4 text-left border-b border-gray-400',
                                    ];
                                    var aClasses = ['text-blue ml-1'];
                                    aClasses.push('opacity-25');
                                    return (_jsx("th", __assign({ className: classes.join(' ') }, { children: headCell.label }), key));
                                }
                            }) })) }), _jsx("tbody", { children: posts === null || posts === void 0 ? void 0 : posts.map(function (post, key) {
                            return (_jsxs("tr", __assign({ className: "text-sm hover:bg-gray-100 cursor-pointer", onClick: function () { return activateMessage(post); } }, { children: [_jsxs("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: [post.subject, checkIsMessageRead(post)] }), key + '1'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: _jsx(Moment, __assign({ format: Translate({ term: 'common.date_format' }) }, { children: post.created })) }), key + '2'), _jsx("td", __assign({ className: "px-4 py-2 text-left border-b border-gray-300" }, { children: sliceMessage(post.message) }), key + '3')] }), key));
                        }) })] })), _jsx(SinglePostModal, { activePost: activePost, setActivePost: setActivePost })] }));
};
export default MessagesTable;
