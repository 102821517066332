var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { StylesManager, Model } from 'survey-core';
import 'survey-core/modern.min.css';
import { Survey } from 'survey-react-ui';
import { getAllPeriods, updateQuestionnaireInPeriod, } from '../../../actions/periodActions';
import { setPeriods } from '../../../reducers/periodSlice';
import BasicModal from '../../common/modals/BasicModal';
import { Translate } from '../../translation/Translate';
import useUser from '../../../hooks/useUser';
var QuestionnaireModal = function (_a) {
    var openModal = _a.openModal, setOpenModal = _a.setOpenModal, questionnaire = _a.questionnaire, periodId = _a.periodId, showToastMessage = _a.showToastMessage;
    var dispatch = useDispatch();
    var user = useUser();
    var userinfoLang = useSelector(function (state) { var _a; return (_a = state.settings) === null || _a === void 0 ? void 0 : _a.language; });
    var querySharedTranslate = Translate({
        term: 'period.query_shared',
    });
    function sendQuestionnaire(results) {
        return __awaiter(this, void 0, void 0, function () {
            var questionnaireData, updateQuestionnaireRequest, response, data, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        questionnaireData = __assign(__assign({}, questionnaire), { answers: results });
                        updateQuestionnaireRequest = {
                            periodId: periodId,
                            questionnaire: questionnaireData,
                        };
                        return [4 /*yield*/, updateQuestionnaireInPeriod(updateQuestionnaireRequest, user.session)];
                    case 1:
                        response = _a.sent();
                        if (!response) return [3 /*break*/, 3];
                        return [4 /*yield*/, getAllPeriods(user.session)];
                    case 2:
                        data = _a.sent();
                        if (data) {
                            dispatch(setPeriods(data));
                        }
                        setOpenModal(false);
                        if (showToastMessage) {
                            showToastMessage(querySharedTranslate);
                        }
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    var sendResults = function (sender) {
        var results = sender.data;
        sendQuestionnaire(results);
    };
    var survey = new Model(questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.questions);
    survey.data = (questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.answers) || '';
    survey.locale = userinfoLang && userinfoLang === 'se' ? 'sv' : 'fi';
    StylesManager.applyTheme('modern');
    survey.onComplete.add(sendResults);
    return (_jsx(BasicModal, __assign({ open: openModal, handleClose: function () { return setOpenModal(false); } }, { children: _jsxs("div", { children: [_jsx("div", __assign({ className: "flex" }, { children: _jsx("div", { className: "flex-none" }) })), _jsx("div", __assign({ className: "max-h-96 overflow-y-scroll" }, { children: _jsx(Survey, { model: survey }) }))] }) })));
};
export default QuestionnaireModal;
