var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createEntityAdapter, } from '@reduxjs/toolkit';
import { paginatedInitialState } from './periodSlice';
var adminLicenseAdapter = createEntityAdapter();
var initialState = adminLicenseAdapter.getInitialState({
    zoneFormData: {
        firstName: '',
        lastName: '',
        email: '',
        emailVerify: '',
        phone: '',
        municipality: '',
        notes: '',
        name: '',
    },
    licenseFormData: {
        licenseCount: 0,
        modules: [],
        zone: '',
        licenseIsFor: 'zone',
        therapist: '',
        licenseValidUntil: 24,
    },
    searchParams: {
        page: 1,
        order: -1,
        sort: 'created',
    },
    selectedLicenses: [],
    validationErrors: [],
    modalState: 'listing',
    zoneState: 'creating',
    licenseTab: 'zone',
    zoneLicenseState: 'listing',
    isLoading: false,
    paginatedLicenses: paginatedInitialState,
});
var slice = createSlice({
    name: 'adminLicense',
    initialState: initialState,
    reducers: {
        setZoneFormDataField: function (state, action) {
            state.zoneFormData = __assign(__assign({}, state.zoneFormData), action.payload);
        },
        setLicenseFormDataField: function (state, action) {
            state.licenseFormData = __assign(__assign({}, state.licenseFormData), action.payload);
        },
        setSearchParamsField: function (state, action) {
            state.searchParams = __assign(__assign({}, state.searchParams), action.payload);
        },
        setModalState: function (state, action) {
            if (action.payload === 'listing') {
                state.zoneFormData = initialState.zoneFormData;
                state.modalState = initialState.modalState;
            }
            else {
                state.modalState = action.payload;
            }
        },
        setZoneState: function (state, action) {
            state.zoneState = action.payload;
        },
        setLicenseTab: function (state, action) {
            state.licenseTab = action.payload;
        },
        setValidationErrors: function (state, action) {
            state.validationErrors = action.payload;
        },
        clearFormState: function (state) {
            state.zoneFormData = initialState.zoneFormData;
            state.licenseFormData = initialState.licenseFormData;
            state.validationErrors = initialState.validationErrors;
            state.modalState = initialState.modalState;
            state.searchParams = initialState.searchParams;
        },
        clearSearchParamsState: function (state) {
            state.searchParams = initialState.searchParams;
        },
        setLicenses: function (state, action) {
            state.paginatedLicenses = action.payload;
        },
        clearLicenses: function (state) {
            state.paginatedLicenses = initialState.paginatedLicenses;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setSelectedLicenses: function (state, action) {
            var _a;
            var licenseFound = state.selectedLicenses.find(function (license) { return license._id === action.payload._id; });
            if (licenseFound) {
                state.selectedLicenses =
                    (_a = state.selectedLicenses.filter(function (license) { return license._id !== licenseFound._id; })) !== null && _a !== void 0 ? _a : [];
            }
            else {
                state.selectedLicenses = __spreadArray(__spreadArray([], __read(state.selectedLicenses), false), [action.payload], false);
            }
        },
        setSelectAllLicenses: function (state, action) {
            state.selectedLicenses = action.payload;
        },
        setSelectedLicensesFormField: function (state, action) {
            state.selectedLicenses = state.selectedLicenses.map(function (license) {
                if (license._id === action.payload._id)
                    return action.payload;
                return license;
            });
        },
        setZoneLicenseState: function (state, action) {
            state.zoneLicenseState = action.payload;
        },
    },
});
export var setZoneFormDataField = (_a = slice.actions, _a.setZoneFormDataField), setLicenseFormDataField = _a.setLicenseFormDataField, setModalState = _a.setModalState, clearFormState = _a.clearFormState, setSearchParamsField = _a.setSearchParamsField, clearSearchParamsState = _a.clearSearchParamsState, setValidationErrors = _a.setValidationErrors, setLicenses = _a.setLicenses, setZoneState = _a.setZoneState, setLicenseTab = _a.setLicenseTab, setIsLoading = _a.setIsLoading, clearLicenses = _a.clearLicenses, setSelectedLicenses = _a.setSelectedLicenses, setSelectAllLicenses = _a.setSelectAllLicenses, setZoneLicenseState = _a.setZoneLicenseState, setSelectedLicensesFormField = _a.setSelectedLicensesFormField;
export default slice;
