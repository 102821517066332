var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import logo from '../../../assets/images/ak-logo.png';
import { Translate } from '../../translation/Translate';
import { getWindowDimensions } from '../../../functions/screen';
import MenuHeader from './MenuHeader';
import MobileNavigation from './MobileNavigation';
import Navigation from './Navigation';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Dehaze } from '@mui/icons-material';
import { logout } from '../../../functions/logout';
import useUser from '../../../hooks/useUser';
var MainMenu = function (_a) {
    var setShow = _a.setShow, show = _a.show;
    var user = useUser();
    var altTranslate = Translate({ term: 'site.name_short' });
    var origWd = getWindowDimensions();
    var _b = __read(useState(origWd.width >= 1024 ? false : true), 2), iconsOnly = _b[0], setIconsOnly = _b[1];
    var _c = __read(useState(false), 2), showSideBar = _c[0], setShowSideBar = _c[1];
    var logoutText = Translate({ term: 'nav.log_out' });
    useEffect(function () {
        function handleResize() {
            var wd = getWindowDimensions();
            setShowSideBar(false);
            setShow(true);
            if (wd.width >= 1024)
                setIconsOnly(false);
            if (wd.width < 1024)
                setIconsOnly(true);
        }
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function showSideBarFunction() {
        setShow(!show);
        setShowSideBar(!showSideBar);
    }
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "flex-none lg:flex lg:justify-between gap-6 pt-3 pb-1 lg:border-b-2 lg:border-cyan" }, { children: [_jsx("div", __assign({ className: "flex-none mt-3" }, { children: iconsOnly ? _jsx(_Fragment, {}) : _jsx(MenuHeader, { iconsOnly: iconsOnly }) })), showSideBar === false ? (_jsx("div", __assign({ className: "" }, { children: iconsOnly ? (_jsxs("div", __assign({ className: "flex justify-between relative" }, { children: [_jsx("div", __assign({ className: "ml-4 -mt-2 md:ml-5 bg-blue p-2 w-10 rounded-md", onClick: showSideBarFunction }, { children: _jsx(Dehaze, { style: { color: 'white', cursor: 'pointer' } }) })), _jsx("div", __assign({ className: "absolute w-10 h-10 -top-2 right-3 sm:right-8 bg-white border border-blue border-opacity-25 p-1 shadow-md rounded-md overflow-hidden flex justify-items-center items-center" }, { children: _jsx("img", { alt: altTranslate, src: logo }) }))] }))) : (_jsx(Navigation, { iconsOnly: iconsOnly })) }))) : (_jsx(_Fragment, {})), _jsx("div", __assign({ className: "flex-none mr-3 lg:mr-6 lg:mt-1" }, { children: iconsOnly ? (_jsx(_Fragment, {})) : (_jsx("button", __assign({ onClick: function () { return logout(user.session); }, className: "bg-white hover:bg-blue duration-300 hover:text-white text-black border-2 border-blue rounded-md" }, { children: logoutText }))) }))] })), showSideBar ? (_jsx("div", __assign({ onClick: showSideBarFunction, className: "fixed overflow-y-hidden top-0 z-10 w-full pt-4 h-full m-h-screen tracking-widest divide-y bg-lightBlue" }, { children: _jsxs("div", __assign({ className: "mt-1 md:ml-1 mb-6" }, { children: [_jsx(ClearRoundedIcon, { style: {
                                cursor: 'pointer',
                                marginLeft: '24px',
                                marginBottom: '12px',
                                marginTop: '4px',
                            } }), _jsx(MobileNavigation, { iconsOnly: !showSideBar, showSideBarFunction: showSideBarFunction })] })) }))) : (_jsx("div", {}))] }));
};
export default MainMenu;
