var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from "../../../../translation/Translate";
var EarlyLanguageDevelopment = function () {
    var title1 = Translate({ term: "therapy.modules.early_language_development.title1" });
    var title2 = Translate({ term: "therapy.modules.early_language_development.title2" });
    var text1 = Translate({ term: "therapy.modules.early_language_development.text1" });
    var text2 = Translate({ term: "therapy.modules.early_language_development.text2" });
    var text3 = Translate({ term: "therapy.modules.early_language_development.text3" });
    var text4 = Translate({ term: "therapy.modules.early_language_development.text4" });
    var source = "https://player.vimeo.com/video/624232809?title=0&amp;byline=0&amp;portrait=0&amp;color=8dc7dc";
    return (_jsxs("div", __assign({ className: "pt-4" }, { children: [_jsx("h3", { children: title1 }), _jsx("h4", __assign({ className: "pt-4" }, { children: title2 })), _jsxs("ul", __assign({ className: "pt-4 ml-4", style: { listStyle: 'inherit' } }, { children: [_jsx("li", { children: text1 }), _jsx("li", { children: text2 }), _jsx("li", { children: text3 })] })), _jsxs("div", __assign({ className: "pt-4" }, { children: [_jsx("p", { children: text4 }), _jsx("video", __assign({ controls: true, width: "300", height: "150" }, { children: _jsx("source", { src: source, type: "video/mp4" }) }))] }))] })));
};
export default EarlyLanguageDevelopment;
