var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../components/licenseController/Header';
import { Translate } from '../../components/translation/Translate';
import { useSelector, useDispatch } from 'react-redux';
import { getZonesLicenses } from '../../actions/licenseActions';
import { setLicenses } from '../../reducers/licenseSlice';
/* MUI elements start */
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';
import FreeLicensesTable from '../../components/licenseController/licenseTables/FreeLicensesTable';
import ReservedLicensesTable from '../../components/licenseController/licenseTables/ReservedLicensesTable';
import UsedLicensesTable from '../../components/licenseController/licenseTables/UsedLicensesTable';
import { getUserinfo } from '../../actions/userActions';
import { setUser } from '../../reducers/userSlice';
import { setLanguage } from '../../reducers/settingsSlice';
import useUser from '../../hooks/useUser';
var LicenseControllerHome = function () {
    var _a;
    var dispatch = useDispatch();
    var user = useUser();
    var _b = __read(useState(0), 2), activeTab = _b[0], setActiveTab = _b[1];
    var userinfo = useSelector(function (state) { return state.user.userinfo; });
    var userinfoLang = useSelector(function (state) { var _a; return (_a = state.settings) === null || _a === void 0 ? void 0 : _a.language; });
    var licenses = useSelector(function (state) { return state.licenses.licenses; });
    var handleTabSelection = function (event, newValue) {
        setActiveTab(newValue);
    };
    useEffect(function () {
        if (!userinfo) {
            fetchUserInfo();
        }
    }, [userinfo]);
    var fetchUserInfo = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var userData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!userinfo) return [3 /*break*/, 2];
                    return [4 /*yield*/, getUserinfo(user.session)];
                case 1:
                    userData = _a.sent();
                    if (userData) {
                        if (!userinfoLang && userData.language) {
                            dispatch(setLanguage(userData.language));
                        }
                        dispatch(setUser(userData));
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [dispatch, (_a = user.session) === null || _a === void 0 ? void 0 : _a.accessToken, userinfo, userinfoLang]);
    useEffect(function () {
        getZonesLicenses(user.session).then(function (response) {
            if (response)
                dispatch(setLicenses(response));
        });
    }, [userinfo]);
    var freeLicenses = [];
    var reservedLicenses = [];
    var usedLicenses = [];
    if (licenses && (licenses === null || licenses === void 0 ? void 0 : licenses.length) > 0) {
        freeLicenses = licenses.filter(function (license) {
            return license.state === 'free' ||
                (!license.startDate &&
                    !license.invitation &&
                    license.state !== 'reserved' &&
                    license.state !== 'used');
        });
        reservedLicenses = licenses.filter(function (license) {
            return license.state === 'reserved' ||
                (!license.startDate &&
                    license.invitation &&
                    license.state !== 'free' &&
                    license.state !== 'used');
        });
        usedLicenses = licenses.filter(function (license) {
            return license.state === 'used' ||
                (license.startDate &&
                    license.state !== 'free' &&
                    license.state !== 'reserved');
        });
    }
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: Translate({ term: 'licenses.licenses' }) }), _jsxs("main", __assign({ className: "px-8 py-4" }, { children: [_jsxs(Tabs, __assign({ value: activeTab, onChange: handleTabSelection, "aria-label": "basic tabs example" }, { children: [_jsx(Tab, { label: _jsxs("span", { children: [Translate({ term: 'licenses.free_licenses' }), _jsx(Chip, { className: "ml-2", label: freeLicenses === null || freeLicenses === void 0 ? void 0 : freeLicenses.length, size: "small" })] }) }), _jsx(Tab, { label: _jsxs("span", { children: [Translate({ term: 'licenses.reserved_licenses' }), _jsx(Chip, { className: "ml-2", label: reservedLicenses === null || reservedLicenses === void 0 ? void 0 : reservedLicenses.length, size: "small" })] }) }), _jsx(Tab, { label: _jsxs("span", { children: [Translate({ term: 'licenses.used_licenses' }), _jsx(Chip, { className: "ml-2", label: usedLicenses === null || usedLicenses === void 0 ? void 0 : usedLicenses.length, size: "small" })] }) })] })), _jsx("div", { children: (function () {
                            if (activeTab === 0) {
                                return (_jsx("div", __assign({ className: "col-span-3" }, { children: _jsx(FreeLicensesTable, { licenses: freeLicenses }) })));
                            }
                            else if (activeTab === 1) {
                                return (_jsx("div", __assign({ className: "col-span-3" }, { children: _jsx(ReservedLicensesTable, { licenses: reservedLicenses }) })));
                            }
                            else {
                                return (_jsx("div", __assign({ className: "col-span-3" }, { children: _jsx(UsedLicensesTable, { licenses: usedLicenses }) })));
                            }
                        })() })] }))] }));
};
export default LicenseControllerHome;
