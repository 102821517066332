import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    posts: [],
};
var PostSlice = createSlice({
    name: 'posts',
    initialState: initialState,
    reducers: {
        setPosts: function (state, action) {
            if (action.payload === null) {
                state.posts = [];
            }
            else {
                state.posts = action.payload;
            }
        },
    },
});
export var setPosts = PostSlice.actions.setPosts;
export default PostSlice;
