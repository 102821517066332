var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from '../../../translation/Translate';
import CkEditorComponent from '../../../common/ckEditor/CkEditorComponent';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MSelect from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { sortBy } from 'lodash';
import { getModulesAdmin } from '../../../../actions/moduleActions';
import { setFormField } from '../../../../reducers/adminMaterialsSlice';
import useUser from '../../../../hooks/useUser';
var MaterialModalFields = function (_a) {
    var modifySelectedMaterial = _a.modifySelectedMaterial, setOpen = _a.setOpen;
    var dispatch = useDispatch();
    var material = useSelector(function (state) { return state.adminMaterials.formData; });
    var user = useUser();
    var materialNameTranslate = Translate({
        term: 'material_library.material_name',
    });
    var moduleTranslate = Translate({ term: 'therapy.module' });
    var modifyTranslate = Translate({ term: 'actions.modify' });
    var deleteTranslate = Translate({ term: 'actions.delete' });
    var language = material.language, name = material.name, modules = material.modules, description = material.description, type = material.type, therapistInstructions = material.therapistInstructions, patientInstructions = material.patientInstructions;
    var _b = __read(useState([]), 2), moduleOptions = _b[0], setModuleOptions = _b[1];
    var getModules = function () { return __awaiter(void 0, void 0, void 0, function () {
        var modulesData, modulesArray, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getModulesAdmin(user.session)];
                case 1:
                    modulesData = _a.sent();
                    if (modulesData) {
                        modulesArray = modulesData.map(function (item) {
                            return {
                                value: item._id,
                                optionText: item.name.fi || item.name.se,
                            };
                        });
                        setModuleOptions(sortBy(modulesArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getModules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx("div", __assign({ className: "flex flex-col w-7/12 space-y-4 ml-4" }, { children: _jsxs("div", __assign({ className: "overflow-y-scroll flex flex-col gap-2", style: { maxHeight: '75vh' } }, { children: [_jsxs("div", __assign({ className: "flex flex-col space-y-2" }, { children: [_jsxs("label", { children: [materialNameTranslate, "*"] }), _jsx("input", { type: "text", className: "", onChange: function (e) { return dispatch(setFormField({ name: e.target.value })); }, value: name })] })), _jsxs("div", __assign({ className: "flex flex-row justify-between" }, { children: [_jsx("label", __assign({ className: "w-1/3" }, { children: "Kieli*" })), _jsxs("div", __assign({ className: "flex flex-row gap-2" }, { children: [_jsx("input", { type: "radio", id: "fi", name: "language", value: "fi", checked: language === 'fi', onChange: function () { return dispatch(setFormField({ language: "fi" })); } }), _jsx("label", __assign({ htmlFor: "fi", className: "mr-4" }, { children: "Suomi" })), _jsx("input", { type: "radio", id: "se", name: "language", value: "se", checked: language === 'se', onChange: function () { return dispatch(setFormField({ language: "se" })); } }), _jsx("label", __assign({ htmlFor: "se" }, { children: "Ruotsi" }))] }))] })), _jsxs("div", __assign({ className: "flex flex-col space-y-2" }, { children: [_jsxs("label", __assign({ className: "w-1/3" }, { children: [moduleTranslate, "*"] })), _jsxs(FormControl, __assign({ sx: { width: 300 } }, { children: [_jsx(InputLabel, __assign({ id: "module-select-label" }, { children: Translate({ term: 'therapy.select_modules' }) })), _jsx(MSelect, __assign({ labelId: "module-select-label", id: "module-select", multiple: true, value: modules, onChange: function (_a) {
                                        var target = _a.target;
                                        return dispatch(setFormField({
                                            modules: target.value,
                                        }));
                                    }, input: _jsx(OutlinedInput, { id: "select-multiple-module", label: Translate({ term: 'therapy.select_modules' }) }), renderValue: function (selected) { return (_jsx(Box, __assign({ sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }, { children: selected.map(function (moduleId) {
                                            var _a, _b;
                                            return (_jsx(Chip, { label: (_b = (_a = moduleOptions === null || moduleOptions === void 0 ? void 0 : moduleOptions.find(function (e) { return e.value === moduleId; })) === null || _a === void 0 ? void 0 : _a.optionText) !== null && _b !== void 0 ? _b : '' }, moduleId));
                                        }) }))); } }, { children: moduleOptions === null || moduleOptions === void 0 ? void 0 : moduleOptions.map(function (module) { return (_jsx(MenuItem, __assign({ value: module.value }, { children: module.optionText }), module.value)); }) }))] }))] })), (material === null || material === void 0 ? void 0 : material.type) !== 'text' && (_jsxs("div", __assign({ className: "flex flex-col space-y-2" }, { children: [_jsx("label", { children: 'Sisältö' /*descriptionTranslate*/ }), _jsx("textarea", { onChange: function (e) {
                                return dispatch(setFormField({
                                    description: e.target.value,
                                }));
                            }, className: "flex h-24 border border-gray-300 rounded-md p-2 text-base", rows: 4, value: description })] }))), (material === null || material === void 0 ? void 0 : material.type) === 'text' && !(material === null || material === void 0 ? void 0 : material.html) && (_jsxs("div", __assign({ className: "flex flex-col space-y-2" }, { children: [_jsx("label", { children: 'Sisältö' /*descriptionTranslate*/ }), _jsx("textarea", { readOnly: true, className: "flex h-24 border border-gray-300 rounded-md p-2 text-base", rows: 4, value: description })] }))), (material === null || material === void 0 ? void 0 : material.type) === 'text' && (material === null || material === void 0 ? void 0 : material.html) && (_jsxs("div", __assign({ className: "flex flex-col space-y-2" }, { children: [_jsx("label", { children: 'Sisältö' /*updateMaterial*/ }), _jsx(CkEditorComponent, { setMaterial: function (description) {
                                return dispatch(setFormField({ description: description }));
                            }, material: material })] }))), _jsxs("div", __assign({ className: "flex flex-col space-y-2" }, { children: [_jsx("label", { children: 'Harjoitus' /*therapistInstructionsTranslate*/ }), _jsx("textarea", { onChange: function (e) {
                                return dispatch(setFormField({
                                    therapistInstructions: e.target.value,
                                }));
                            }, className: "flex h-24 border border-gray-300 rounded-md p-2 text-base", rows: 4, value: therapistInstructions })] })), _jsxs("div", __assign({ className: "flex flex-col space-y-2" }, { children: [_jsx("label", { children: 'Asiakkaan ohjeet' /*patientInstructionsTranslate*/ }), _jsx("textarea", { onChange: function (e) {
                                return dispatch(setFormField({
                                    patientInstructions: e.target.value,
                                }));
                            }, className: "flex h-24 border border-gray-300 rounded-md p-2 text-base", rows: 4, value: patientInstructions })] })), _jsxs("div", __assign({ className: "flex mt-2 justify-end gap-4" }, { children: [_jsx("button", __assign({ onClick: modifySelectedMaterial }, { children: modifyTranslate })), _jsx("button", __assign({ onClick: function () { return setOpen(true); }, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: deleteTranslate }))] }))] })) })));
};
export default MaterialModalFields;
