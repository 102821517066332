var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Translate } from '../../translation/Translate';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import moment from 'moment';
var TablePosts = function (_a) {
    var postData = _a.postData, setPostData = _a.setPostData, handleSetSort = _a.handleSetSort;
    var headCells = [
        {
            id: 'subject',
            label: Translate({ term: 'messages.topic' }),
            content: function (post) {
                var _a;
                return _jsx("div", { children: (_a = post === null || post === void 0 ? void 0 : post.subject) !== null && _a !== void 0 ? _a : '' });
            },
        },
        {
            id: 'created',
            label: Translate({ term: 'messages.post_created' }),
            content: function (post) {
                var _a;
                return _jsx("div", { children: moment((_a = post === null || post === void 0 ? void 0 : post.created) !== null && _a !== void 0 ? _a : '').format('DD.MM.YYYY') });
            },
        },
        {
            id: 'message',
            label: Translate({ term: 'messages.post_content' }),
            content: function (post) {
                var _a;
                return _jsx("div", { children: (_a = post === null || post === void 0 ? void 0 : post.message) !== null && _a !== void 0 ? _a : '' });
            },
        },
    ];
    return (_jsxs("table", __assign({ className: "table-fixed border-collapse rounded-md shadow-md w-full max-w-6xl mt-6" }, { children: [_jsx("thead", { children: _jsx("tr", __assign({ className: "bg-gray-100 text-xs px-4 py-2", style: { height: '60px' } }, { children: headCells.map(function (headCell, key) {
                        var sortIcon = postData.sort === headCell.id && postData.reverse ? (_jsx(ArrowUpwardIcon, { className: postData.sort === headCell.id
                                ? 'text-blue ml-1 opacity-100'
                                : 'text-blue ml-1 opacity-25', fontSize: "small" })) : (_jsx(ArrowDownwardIcon, { className: postData.sort === headCell.id
                                ? 'text-blue ml-1 opacity-100'
                                : 'text-blue ml-1 opacity-25', fontSize: "small" }));
                        return (_jsxs("th", __assign({ onClick: function () { return handleSetSort(headCell.id); }, className: "border-b px-4 py-3 text-left border-gray-400 cursor-pointer" }, { children: [headCell.label, " ", sortIcon] }), key));
                    }) })) }), _jsx("tbody", { children: postData.posts.map(function (post, index) {
                    return (_jsx("tr", __assign({ style: { height: '60px' }, className: "text-sm relative hover:bg-gray-100 cursor-pointer", onClick: function () { return setPostData(__assign(__assign({}, postData), { selectedPost: post })); } }, { children: headCells.map(function (headCell, key) {
                            var content = headCell.content ? headCell.content(post) : '';
                            return (_jsx("td", __assign({ className: "px-4 py-4 text-left border-b border-gray-300 cursor-pointer" }, { children: content }), key));
                        }) }), index));
                }) })] })));
};
export default TablePosts;
