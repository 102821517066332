var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext, useEffect } from 'react';
import MessageEditor from '../../common/period/messages/MessageEditor';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import PeriodMessages from '../../common/period/messages/PeriodMessages';
import AssignmentPanels from './AssignmentPanels';
import ConfirmPeriod from './ConfirmPeriod';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Header';
import { Translate, useTranslate } from '../../translation/Translate';
import PeriodContext from '../../common/period/PeriodContext';
import PeriodInfo from './PeriodInfo';
import PatientInfo from './PatientInfo';
import { Autocomplete, TextField } from '@mui/material';
import { searchPeriodMaterials } from '../../../actions/periodMaterialActions';
import { addDays } from 'date-fns';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import { setPeriodAssignment, addQuestionnaire, getTherapistPeriod, } from '../../../actions/periodActions';
import { setPeriod, setPeriodAssignments, setPeriodQuestionnaires, } from '../../../reducers/periodSlice';
import { searchQuestionnaires } from '../../../actions/questionnaireActions';
import useUser from '../../../hooks/useUser';
import { moduleTotalSessions } from '../../../config/periodSessions';
import { useParams } from 'react-router-dom';
import useAppSelector from '../../../hooks/useAppSelector';
import useAppDispatch from '../../../hooks/useAppDispatch';
export default function PatientPeriodContent(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var userInfo = _a.userInfo;
    var t = useTranslate();
    var dispatch = useAppDispatch();
    var selected = useContext(PeriodContext);
    var user = useUser();
    var patientId = useParams().id;
    var patientPeriods = useAppSelector(function (state) { return state.patients; }).patientPeriods[patientId];
    var latestPeriodIndex = ((_b = patientPeriods === null || patientPeriods === void 0 ? void 0 : patientPeriods.periods.length) !== null && _b !== void 0 ? _b : 1) - 1;
    var _l = __read(useState(0), 2), periodIndex = _l[0], setPeriodIndex = _l[1];
    var _m = __read(useState(null), 2), reply = _m[0], setReply = _m[1];
    var _o = __read(useState(null), 2), material = _o[0], setMaterial = _o[1];
    var _p = __read(useState([]), 2), materialList = _p[0], setMaterialList = _p[1];
    var _q = __read(useState(null), 2), questionnaire = _q[0], setQuestionnaire = _q[1];
    var _r = __read(useState([]), 2), questionnaireList = _r[0], setQuestionnaireList = _r[1];
    function isPeriodValid(period) {
        return period.validUntil && new Date(period.validUntil) >= new Date();
    }
    function selectPeriod(periodId, index) {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTherapistPeriod(periodId, user.session)];
                    case 1:
                        data = _a.sent();
                        if (!data) {
                            return [2 /*return*/];
                        }
                        setPeriodIndex(index);
                        dispatch(setPeriod(data));
                        return [2 /*return*/];
                }
            });
        });
    }
    function selectLatestPeriod() {
        return __awaiter(this, void 0, void 0, function () {
            var periodId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!patientPeriods) {
                            return [2 /*return*/];
                        }
                        periodId = patientPeriods.periods[latestPeriodIndex].periodId;
                        return [4 /*yield*/, selectPeriod(periodId, latestPeriodIndex)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        selectLatestPeriod();
    }, []);
    var assignmentAddedTranslate = Translate({ term: 'period.assignment_has_been_added' });
    var assignmentAlreadyAddedTranslate = Translate({ term: 'period.assignment_is_already_added' });
    var shareAssignmentTranslate = Translate({ term: 'material_library.share_assignment' });
    var searchMaterialTranslate = Translate({ term: 'material_library.search_by_name' });
    var searchQuestionnaireTranslate = Translate({ term: 'admin.inquiry.search_by_name' });
    var addQuestionnaireTranslate = Translate({ term: 'period.share_query' });
    var querySharedTranslate = Translate({ term: 'period.query_shared' });
    var queryShareFailedTranslate = Translate({ term: 'material_library.upload_wargning_messages.something_went_wrong' });
    var messageTranslate = Translate({ term: 'home.message' });
    var backTranslate = Translate({ term: 'actions.back' });
    var periodTranslate = Translate({ term: 'nav.period' });
    function searchMaterials(searchText) {
        return __awaiter(this, void 0, void 0, function () {
            var searchParams, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        searchParams = {
                            language: '',
                            type: '',
                            modules: '',
                            page: 1,
                            freeText: searchText,
                        };
                        if (!(user.session && searchText.length > 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, searchPeriodMaterials(user.session, searchParams)];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            setMaterialList(response.docs);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    ;
    function searchAllQuestionnaires(searchText) {
        return __awaiter(this, void 0, void 0, function () {
            var searchParams, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        searchParams = {
                            name: searchText,
                            page: 1,
                            limit: 10,
                        };
                        if (!(user.session && searchText.length > 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, searchQuestionnaires(searchParams, user.session)];
                    case 1:
                        response = (_a.sent());
                        if (response) {
                            setQuestionnaireList(response.docs);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    ;
    function showToastMessage(value, message) {
        if (value === 'success') {
            toast(message, {
                position: 'top-center',
                hideProgressBar: true,
                transition: Zoom,
                closeButton: false,
                autoClose: 2000,
                toastId: 'successId',
                className: 'toast-message-success',
            });
        }
        else if (value === 'warning') {
            toast(message, {
                position: 'top-center',
                hideProgressBar: true,
                transition: Zoom,
                closeButton: false,
                autoClose: 2000,
                toastId: 'warningId',
                className: 'toast-message-warning',
            });
        }
    }
    ;
    function shareAssignment() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var deadline, newAssignment, data;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        deadline = addDays(new Date(), 7);
                        if (!selected.period) {
                            return [2 /*return*/];
                        }
                        if (!((material === null || material === void 0 ? void 0 : material._id) && selected.period._id && selected.period.patient)) return [3 /*break*/, 2];
                        newAssignment = {
                            type: material.type,
                            deadline: deadline,
                            name: material.name,
                            description: material.description,
                            therapistInstructions: material.therapistInstructions,
                            patientInstructions: material.patientInstructions,
                            url: material.url,
                            idNumber: (_a = material.idNumber) !== null && _a !== void 0 ? _a : [],
                            textMaterial: (_b = material.textMaterial) !== null && _b !== void 0 ? _b : undefined,
                            html: (_c = material.html) !== null && _c !== void 0 ? _c : undefined,
                        };
                        return [4 /*yield*/, setPeriodAssignment(selected.period._id, material._id, 'new', newAssignment, user.session)];
                    case 1:
                        data = _d.sent();
                        if (data) {
                            showToastMessage('success', assignmentAddedTranslate);
                            dispatch(setPeriodAssignments(data.assignments));
                        }
                        else {
                            showToastMessage('warning', assignmentAlreadyAddedTranslate);
                        }
                        _d.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    function shareQuery() {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var addQuestionnaireRequest, newQuestionnaire, questionnairesList, updatedQuestionnairesList;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!selected.period) {
                            return [2 /*return*/];
                        }
                        if (!(questionnaire && selected.period._id)) return [3 /*break*/, 2];
                        addQuestionnaireRequest = {
                            periodId: selected.period._id,
                            questionnaireId: questionnaire._id,
                            name: questionnaire.name,
                            questions: questionnaire.questions,
                            answerTheSurveyMoreThanOnce: questionnaire.answerTheSurveyMoreThanOnce,
                        };
                        return [4 /*yield*/, addQuestionnaire(addQuestionnaireRequest, user.session)];
                    case 1:
                        newQuestionnaire = _b.sent();
                        if (newQuestionnaire) {
                            questionnairesList = (_a = selected.period.questionnaires) !== null && _a !== void 0 ? _a : [];
                            updatedQuestionnairesList = questionnairesList.concat(newQuestionnaire);
                            dispatch(setPeriodQuestionnaires(updatedQuestionnairesList));
                            showToastMessage('success', querySharedTranslate);
                        }
                        else {
                            showToastMessage('warning', queryShareFailedTranslate);
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    if (!(patientPeriods && selected.period)) {
        return _jsx("main", __assign({ className: "px-8 py-4 grid gap-6" }, { children: "Loading period data..." }));
    }
    return (_jsxs("main", __assign({ className: "px-8 py-4 grid gap-6" }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx(Header, { title: periodTranslate +
                    ' ' +
                    ((_c = selected.period.patient) === null || _c === void 0 ? void 0 : _c.firstName) +
                    ' ' +
                    ((_d = selected.period.patient) === null || _d === void 0 ? void 0 : _d.lastName) }), _jsx("button", __assign({ onClick: function () { return history.back(); }, className: "w-32 -my-2" }, { children: _jsxs("p", __assign({ className: "-ml-2" }, { children: [_jsx(ArrowBackIcon, {}), " ", backTranslate] })) })), !selected.period.started && _jsx(ConfirmPeriod, { period: selected.period }), selected.period.patient && (_jsx(PatientInfo, { period: selected.period, patient: selected.period.patient, guardians: selected.period.guardians, defaultAnswerDays: (_f = (_e = userInfo === null || userInfo === void 0 ? void 0 : userInfo.therapistInfo) === null || _e === void 0 ? void 0 : _e.defaultAnswerDays) !== null && _f !== void 0 ? _f : [] })), _jsx(PeriodInfo, { period: selected.period }), _jsxs("div", __assign({ className: "flex gap-4" }, { children: [_jsx(Autocomplete, { disablePortal: true, id: "combo-box-demo", options: materialList, getOptionLabel: function (option) { return option.name; }, sx: { width: 300 }, onChange: function (event, newValue) {
                            setMaterial(newValue);
                        }, renderInput: function (params) { return (_jsx(TextField, __assign({ onChange: function (event) {
                                searchMaterials(event.target.value);
                            } }, params, { label: searchMaterialTranslate }))); } }), _jsx("button", __assign({ className: "w-40", onClick: shareAssignment }, { children: shareAssignmentTranslate })), _jsx(Autocomplete, { disablePortal: true, id: "combo-box-demo", options: questionnaireList, getOptionLabel: function (option) { return option.name; }, sx: { width: 300 }, onChange: function (event, newValue) {
                            setQuestionnaire(newValue);
                        }, renderInput: function (params) { return (_jsx(TextField, __assign({ onChange: function (event) {
                                searchAllQuestionnaires(event.target.value);
                            } }, params, { label: searchQuestionnaireTranslate }))); } }), _jsx("button", __assign({ className: "w-40", onClick: shareQuery }, { children: addQuestionnaireTranslate }))] })), _jsxs("div", __assign({ className: "flex flex-col lg:flex-row-reverse gap-4" }, { children: [_jsx("div", __assign({ className: "lg:flex-initial lg:w-1/3" }, { children: _jsx(AssignmentPanels, {}) })), _jsxs("div", __assign({ className: "lg:flex-auto grid gap-1" }, { children: [_jsx("div", __assign({ className: "w-full flex flex-row gap-1 overflow-x-scroll" }, { children: patientPeriods.periods.length > 1 && patientPeriods.periods.map(function (period, index) { return (_jsxs("button", __assign({ className: "rounded-t-xl w-max min-w-max period-tab", onClick: function () { return selectPeriod(period.periodId, index); }, disabled: index === periodIndex }, { children: [t("common.period"), " ", index + 1] }), index)); }) })), _jsxs("div", __assign({ className: "space-y-3" }, { children: [_jsx(PeriodMessages, { messages: (_g = selected.period.messages) !== null && _g !== void 0 ? _g : [], periodId: selected.period._id, replyToMessage: function (message) {
                                            setReply(message);
                                        }, sessionsDone: selected.period.sessions ? String(selected.period.sessions) : '0', sessions: ((_h = selected.period.module) === null || _h === void 0 ? void 0 : _h.sessions)
                                            ? String(selected.period.module.sessions)
                                            : String(moduleTotalSessions[(_j = selected.period.module) === null || _j === void 0 ? void 0 : _j._id]), periodValidUntil: (_k = selected.period) === null || _k === void 0 ? void 0 : _k.validUntil }), reply !== null && (_jsxs("div", __assign({ className: "flex flex-row mt-3 space-x-2 bg-white py-1 px-3 md:px-8 rounded-md border border-gray-200 relative" }, { children: [_jsx("div", __assign({ className: "p-1 flex items-center" }, { children: _jsx(ChatBubbleOutlineOutlinedIcon, {}) })), _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("p", { children: [messageTranslate, ":"] }), _jsx("p", __assign({ className: "truncate .." }, { children: reply }))] }))] }))), selected.period && isPeriodValid(selected.period) && (_jsx(MessageEditor, { periodId: selected.period._id, draft: selected.draft, reply: reply, setReply: setReply }))] }))] }))] }))] })));
}
