var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { LanguageContext, Translate } from '../../translation/Translate';
import akLogo from '../../../assets/images/ak-logo.png';
import asLogo from '../../../assets/images/as-logo.png';
import { logout } from '../../../functions/logout';
import MainNav from './MainNav';
import useUser from '../../../hooks/useUser';
var MainMenu = function () {
    var user = useUser();
    var language = useContext(LanguageContext);
    return (_jsx("aside", __assign({ className: "menu flex-none w-60 lg:w-72" }, { children: _jsxs("div", __assign({ className: "fixed flex flex-col justify-between w-60 lg:w-72 h-screen border-t-8 border-r-8 border-cyan py-6 overflow-x-hidden" }, { children: [_jsxs("div", { children: [_jsx("div", __assign({ className: "mb-4 flex flex-col gap-3 px-3" }, { children: _jsxs("div", __assign({ className: "flex items-center gap-2 flex-none" }, { children: [_jsx("div", __assign({ className: "w-8 h-8 bg-white border border-blue border-opacity-25 p-1 shadow-md rounded-md overflow-hidden flex justify-items-center items-center" }, { children: _jsx("img", { alt: Translate({ term: 'site.name_short' }), src: language === 'se' ? asLogo : akLogo }) })), _jsx("div", __assign({ className: "hidden lg:flex text-lg text-blue font-semibold" }, { children: Translate({ term: 'site.name' }) }))] })) })), _jsx(MainNav, {})] }), _jsx("div", { children: _jsx("button", __assign({ className: "mt-4 ml-2 lg:ml-3 bg-cyan border-2 border-cyan text-black w-11/12 hover:bg-white duration-300", onClick: function () { return logout(user.session); } }, { children: Translate({ term: 'nav.log_out' }) })) })] })) })));
};
export default MainMenu;
