var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ChatMessageAttachment from './chatMessageAttachment';
var ChatMessageAttachmentList = function (_a) {
    var attachmentList = _a.attachmentList;
    return (_jsx("div", __assign({ className: "mt-2 pt-2 border-t" }, { children: _jsx("div", __assign({ className: "flex flex-col items-center justify-center gap-2" }, { children: attachmentList.map(function (attachment, key) {
                return _jsx(ChatMessageAttachment, { attachment: attachment }, key);
            }) })) })));
};
export default ChatMessageAttachmentList;
