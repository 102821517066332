var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
var TextInput = function (_a) {
    var onChange = _a.onChange, value = _a.value, label = _a.label, required = _a.required, placeholder = _a.placeholder, disabled = _a.disabled;
    return (_jsxs("div", __assign({ className: "flex flex-row justify-between", style: { width: '650px' } }, { children: [label && (_jsxs("label", __assign({ className: "w-1/3" }, { children: [label, required && '*'] }))), _jsx("input", { type: "text", onChange: function (e) { return onChange(e.target.value); }, value: value, disabled: disabled, placeholder: placeholder, className: "h-10 w-2/3" })] })));
};
export default TextInput;
