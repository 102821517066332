var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apiDelete, apiGet, apiPost, apiPut } from './apiActions';
import axios from 'axios';
import { setApiError } from '../reducers/apiErrorSlice';
import store from '../store';
var checkErrorResponseStatuses = function (error) {
    var _a, _b, _c, _d;
    if (error &&
        (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 400 ||
            ((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) === 403 ||
            ((_c = error.response) === null || _c === void 0 ? void 0 : _c.status) === 409 ||
            ((_d = error.response) === null || _d === void 0 ? void 0 : _d.status) === 422)) {
        store.dispatch(setApiError(error.response.data.error.messages));
    }
    return null;
};
export function createAdminQuestionnaire(questionnaire, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(questionnaire && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPost('/api/admin/questionnaire', questionnaire, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response.data];
                    }
                    else {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    if (axios.isAxiosError(error_1)) {
                        checkErrorResponseStatuses(error_1);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('createAdminQuestionnaire unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function searchQuestionnaires(questionnaire, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(questionnaire && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/admin/questionnaire', session, questionnaire)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    if (axios.isAxiosError(error_2)) {
                        //console.error('searchQuestionnaires axios error: ', error);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('searchQuestionnaires unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateAdminQuestionnaire(questionnaire, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(questionnaire && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/admin/questionnaire', questionnaire, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response.data];
                    }
                    else {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    if (axios.isAxiosError(error_3)) {
                        checkErrorResponseStatuses(error_3);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('createAdminQuestionnaire unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function deleteAdminQuestionnaire(questionnaire, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(questionnaire && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiDelete('/api/admin/questionnaire', session, questionnaire)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response.data];
                    }
                    else {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    if (axios.isAxiosError(error_4)) {
                        checkErrorResponseStatuses(error_4);
                        return [2 /*return*/, null];
                    }
                    else {
                        //error('deleteAdminQuestionnaire unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function createAdminZone(zone, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(zone && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPost('/api/admin/zone', zone, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response.data];
                    }
                    else {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_5 = _a.sent();
                    if (axios.isAxiosError(error_5)) {
                        checkErrorResponseStatuses(error_5);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('createAdminZone unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateAdminZone(zone, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(zone && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/admin/zone', zone, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response.data];
                    }
                    else {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_6 = _a.sent();
                    if (axios.isAxiosError(error_6)) {
                        checkErrorResponseStatuses(error_6);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('updateAdminZone unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function createAdminLicense(license, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(license && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPost('/api/admin/license', license, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response.data];
                    }
                    else {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_7 = _a.sent();
                    if (axios.isAxiosError(error_7)) {
                        checkErrorResponseStatuses(error_7);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('createAdminLicense unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getAdminTherapists(session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/admin/therapist', session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_8 = _a.sent();
                    if (axios.isAxiosError(error_8)) {
                        //console.error('getAdminTherapists axios error: ', error);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('getAdminTherapists unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getAdminLicenses(license, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/admin/license', session, license)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_9 = _a.sent();
                    if (axios.isAxiosError(error_9)) {
                        //console.error('getAdminTherapists axios error: ', error);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('getAdminTherapists unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getAdminZoneLicenses(searchParams, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_10;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiGet('/api/admin/zone/license', session, searchParams)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        return [2 /*return*/, response.data];
                    }
                    else {
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_10 = _a.sent();
                    if (axios.isAxiosError(error_10)) {
                        //console.error('getAdminZoneLicenses axios error: ', error);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('getAdminZoneLicenses unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateAdminZoneLicenses(licenses, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_11;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(licenses && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/admin/zone/license', licenses, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 204 &&
                        response.data) {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, true];
                    }
                    else {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_11 = _a.sent();
                    if (axios.isAxiosError(error_11)) {
                        checkErrorResponseStatuses(error_11);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('updateAdminZoneLicenses unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function deleteAdminZoneLicenses(licenses, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_12;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(licenses && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiDelete('/api/admin/zone/license', session, licenses)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 200 &&
                        response.data) {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, true];
                    }
                    else {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_12 = _a.sent();
                    if (axios.isAxiosError(error_12)) {
                        checkErrorResponseStatuses(error_12);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('deleteAdminZoneLicenses unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateAdminPeriodsTherapist(periodsData, session) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_13;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(periodsData && session)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apiPut('/api/admin/period/therapist', periodsData, session)];
                case 2:
                    response = _a.sent();
                    if (response &&
                        response.status &&
                        response.status === 204 &&
                        response.data) {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, true];
                    }
                    else {
                        store.dispatch(setApiError([]));
                        return [2 /*return*/, response];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_13 = _a.sent();
                    if (axios.isAxiosError(error_13)) {
                        checkErrorResponseStatuses(error_13);
                        return [2 /*return*/, null];
                    }
                    else {
                        //console.error('updateAdminPeriodsTherapist unexpected error: ', error);
                        return [2 /*return*/, null];
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
