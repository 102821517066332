var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c, _d;
import { createSlice } from '@reduxjs/toolkit';
import { PERIOD_FILTERS, THERAPY_TYPES } from '../components/common/enums';
import { findIndexFromArray } from '../functions/finders';
export var paginatedInitialState = {
    page: 1,
    perPage: 0,
    totalDocs: 0,
    totalPages: 0,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 5,
    nextPage: 2,
    pagingCounter: 0,
};
var initialState = {
    filters: {},
    drafts: [],
    periods: [],
    periodHistory: [],
    newCustomerPeriodsCount: 0,
    showEnded: false,
    finishedPeriods: paginatedInitialState,
    soonToEndPeriods: paginatedInitialState,
    passivePeriods: paginatedInitialState,
    unreadMessages: paginatedInitialState,
    newPeriods: paginatedInitialState,
    therapistPeriods: paginatedInitialState,
    therapyTypePeriods: (_a = {},
        _a[THERAPY_TYPES.ALL] = paginatedInitialState,
        _a[THERAPY_TYPES.ONLINE_THERAPY] = paginatedInitialState,
        _a[THERAPY_TYPES.THERAPISTS_OWN_PATIENTS] = paginatedInitialState,
        _a),
    periodCountByTherapyType: (_b = {},
        _b[THERAPY_TYPES.ALL] = 0,
        _b[THERAPY_TYPES.ONLINE_THERAPY] = 0,
        _b[THERAPY_TYPES.THERAPISTS_OWN_PATIENTS] = 0,
        _b),
    periodCountByFilter: (_c = {},
        _c[PERIOD_FILTERS.FINISHED] = 0,
        _c[PERIOD_FILTERS.SOON_TO_END] = 0,
        _c[PERIOD_FILTERS.PASSIVE] = 0,
        _c[PERIOD_FILTERS.UNREAD_MESSAGES] = 0,
        _c[PERIOD_FILTERS.NEW] = 0,
        _c[PERIOD_FILTERS.THERAPIST] = 0,
        _c)
};
var PeriodSlice = createSlice({
    name: 'periods',
    initialState: initialState,
    reducers: {
        setClientPeriodHistory: function (state, action) {
            var _a;
            state.periodHistory = (_a = action.payload) !== null && _a !== void 0 ? _a : [];
        },
        setPeriods: function (state, action) {
            var _a, _b;
            state.periods = (_a = action.payload) !== null && _a !== void 0 ? _a : [];
            // Filter periods where there is no messages from therapist. If the array is empty then the period is new and suitable for newCustomerPeriods.
            var periodsWithoutTherapistMessages = (_b = action.payload.filter(function (period) {
                var _a, _b;
                var filteredPeriodMessages = (_b = (_a = period.messages) === null || _a === void 0 ? void 0 : _a.filter(function (message) { return message.createdBy !== period.therapist; })) !== null && _b !== void 0 ? _b : [];
                if ((filteredPeriodMessages === null || filteredPeriodMessages === void 0 ? void 0 : filteredPeriodMessages.length) < 1) {
                    return period;
                }
            })) !== null && _b !== void 0 ? _b : [];
            state.newCustomerPeriodsCount = periodsWithoutTherapistMessages.length;
        },
        setShowEnded: function (state, action) {
            state.showEnded = action.payload;
        },
        setFilteredPeriods: function (state, action) {
            switch (action.payload.filter) {
                case 'finishedPeriods':
                    state.finishedPeriods = action.payload.periods;
                    break;
                case 'soonToEndPeriods':
                    state.soonToEndPeriods = action.payload.periods;
                    break;
                case 'passivePeriods':
                    state.passivePeriods = action.payload.periods;
                    break;
                case 'unreadMessages':
                    state.unreadMessages = action.payload.periods;
                    break;
                case 'newPeriods':
                    state.newPeriods = action.payload.periods;
                    break;
                case 'therapistPeriods':
                    state.therapistPeriods = action.payload.periods;
                    break;
                case 'therapyTypePeriods':
                    if (action.payload.therapyType !== undefined) {
                        state.therapyTypePeriods[action.payload.therapyType] =
                            action.payload.periods;
                    }
                    break;
                default:
                    state.periods = action.payload.periods.docs;
                    break;
            }
        },
        updatePeriod: function (state, action) {
            if (!state.periods) {
                return;
            }
            var index = state.periods.findIndex(function (period) { return period._id === action.payload.id; });
            if (index !== -1) {
                state.periods[index] = action.payload.period;
            }
        },
        setPeriod: function (state, action) {
            state.active = action.payload;
        },
        setPeriodMessages: function (state, action) {
            state.active.messages = action.payload;
        },
        setPeriodAssignments: function (state, action) {
            state.active.assignments = action.payload;
        },
        setPeriodQuestionnaires: function (state, action) {
            state.active.questionnaires = action.payload;
        },
        setPeriodList: function (state, action) {
            state.active = null;
            if (action.payload === null) {
                state.list = null;
            }
            else {
                state.list = action.payload;
            }
        },
        openPeriodAssignment: function (state, action) {
            if (action.payload === null) {
                state.active.openAssignment = null;
            }
            else {
                state.active.openAssignment = action.payload;
            }
        },
        setFilterModule: function (state, action) {
            if (action.payload === null) {
                state.filters.module = null;
            }
            else {
                state.filters.module = action.payload;
            }
        },
        setFilterSelection: function (state, action) {
            if (action.payload === null) {
                state.filters.selections = null;
            }
            else {
                state.filters.selections = action.payload;
            }
        },
        saveDraft: function (state, action) {
            if (action.payload.periodId) {
                var index = undefined;
                if (state.drafts)
                    index = findIndexFromArray(state.drafts, 'periodId', action.payload.periodId);
                if (index !== undefined) {
                    state.drafts[index] = action.payload;
                }
                else {
                    state.drafts.push(action.payload);
                }
            }
        },
        deleteDraft: function (state, action) {
            var index = undefined;
            if (state.drafts)
                index = findIndexFromArray(state.drafts, 'periodId', action.payload);
            if (index !== undefined) {
                state.drafts = __spreadArray([], __read(state.drafts), false).splice(index, 0);
            }
        },
        clearDrafts: function (state) {
            state.drafts = null;
        },
        setTherapyTypes: function (state, action) {
            state.therapyTypes = action.payload;
        },
        resetPeriods: function (state) {
            delete state.active;
            delete state.list;
            delete state.history;
            delete state.unreadMessages;
            delete state.therapyTypes;
            state.drafts = initialState.drafts;
            state.filters = initialState.filters;
        },
        setPeriodCountByTherapyType: function (state, action) {
            state.periodCountByTherapyType[action.payload.type] = action.payload.count;
        },
        setPeriodCountByFilter: function (state, action) {
            state.periodCountByFilter[action.payload.filter] = action.payload.count;
        }
    },
});
export var setClientPeriodHistory = (_d = PeriodSlice.actions, _d.setClientPeriodHistory), setPeriods = _d.setPeriods, updatePeriod = _d.updatePeriod, setShowEnded = _d.setShowEnded, setPeriod = _d.setPeriod, setPeriodList = _d.setPeriodList, setFilterModule = _d.setFilterModule, setFilterSelection = _d.setFilterSelection, setPeriodMessages = _d.setPeriodMessages, setPeriodAssignments = _d.setPeriodAssignments, setPeriodQuestionnaires = _d.setPeriodQuestionnaires, saveDraft = _d.saveDraft, deleteDraft = _d.deleteDraft, clearDrafts = _d.clearDrafts, resetPeriods = _d.resetPeriods, openPeriodAssignment = _d.openPeriodAssignment, setTherapyTypes = _d.setTherapyTypes, setFilteredPeriods = _d.setFilteredPeriods, setPeriodCountByTherapyType = _d.setPeriodCountByTherapyType, setPeriodCountByFilter = _d.setPeriodCountByFilter;
export default PeriodSlice;
