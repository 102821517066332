var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useEffect, useState } from 'react';
import { Translate } from '../../translation/Translate';
import { getTherapyTypes } from '../../../actions/therapyTypeActions';
import { createPost } from '../../../actions/postActions';
import PickDefaultMessage from '../../common/PickDefaultMessage';
import useUser from '../../../hooks/useUser';
var GroupMessage = function (_a) {
    var session = _a.session;
    var _b = __read(React.useState([]), 2), therapyTypes = _b[0], setTherapyTypes = _b[1];
    var user = useUser();
    var defaultMessage = useRef(null);
    var _c = __read(useState(false), 2), validTypes = _c[0], setValidTypes = _c[1];
    var _d = __read(useState(false), 2), validSubject = _d[0], setValidSubject = _d[1];
    var _e = __read(useState(false), 2), validMessage = _e[0], setValidMessage = _e[1];
    var _f = __read(useState(false), 2), messageIsSent = _f[0], setMessageIsSent = _f[1];
    var types = useRef(null);
    var subject = useRef(null);
    var message = useRef(null);
    function loadTherapyTypes() {
        getTherapyTypes(session).then(function (response) {
            if (response)
                setTherapyTypes(response);
        });
    }
    useEffect(function () {
        loadTherapyTypes();
    }, []);
    function useDefaultMessage() {
        if ((message === null || message === void 0 ? void 0 : message.current) &&
            (subject === null || subject === void 0 ? void 0 : subject.current) &&
            (defaultMessage === null || defaultMessage === void 0 ? void 0 : defaultMessage.current) &&
            defaultMessage.current.value !== '') {
            var msg = JSON.parse(defaultMessage.current.value);
            message.current.value = msg.message;
            subject.current.value = msg.subject;
            defaultMessage.current.value = '';
            handleChanges;
            setValidSubject(true);
            setValidMessage(true);
        }
    }
    function handleChanges() {
        setMessageIsSent(false);
        setValidTypes(false);
        setValidSubject(false);
        setValidMessage(false);
        if (types && types.current && types.current.value !== 'default')
            setValidTypes(true);
        if (subject && subject.current && subject.current.value.length > 0)
            setValidSubject(true);
        if (message && message.current && message.current.value.length > 0)
            setValidMessage(true);
    }
    function clearMessage() {
        if (types && types.current && types.current.value !== 'default')
            types.current.value = '';
        if (subject && subject.current && subject.current.value.length > 0)
            subject.current.value = '';
        if (message && message.current && message.current.value.length > 0)
            message.current.value = '';
        setValidTypes(false);
        setValidSubject(false);
        setValidMessage(false);
    }
    function sendMessage(e) {
        return __awaiter(this, void 0, void 0, function () {
            var therapyTypeIds, newPost, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        if (!(message &&
                            message.current &&
                            types &&
                            types.current &&
                            subject &&
                            subject.current &&
                            user.userinfo)) return [3 /*break*/, 2];
                        therapyTypeIds = [types.current.value];
                        if (types.current.value === 'allOwnCustomers') {
                            therapyTypeIds = therapyTypes.map(function (therapyType) { return therapyType._id; });
                        }
                        newPost = {
                            subject: subject.current.value,
                            message: message.current.value,
                            therapyTypes: therapyTypeIds,
                            userTypes: ['customer'],
                        };
                        return [4 /*yield*/, createPost(newPost, session)];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            clearMessage();
                            setMessageIsSent(true);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    var messageSentTranslate = Translate({ term: 'messages.message_sent' });
    return (_jsxs("form", __assign({ className: "grid md:grid-cols-3 md:gap-y-3" }, { children: [_jsx("label", __assign({ className: "col-span-3 flex gap-3 mt-3" }, { children: Translate({ term: 'messages.send_message_through_platform' }) })), _jsx("label", __assign({ className: "col-start-1" }, { children: Translate({ term: 'messages.client_group' }) })), _jsx("div", __assign({ className: "md:col-span-2" }, { children: _jsxs("select", __assign({ onChange: function () { return handleChanges(); }, ref: types }, { children: [_jsx("option", __assign({ value: 'default' }, { children: Translate({ term: 'messages.client_group' }) })), _jsx("option", __assign({ value: 'allOwnCustomers' }, { children: Translate({ term: 'messages.own_customers' }) }), 'all'), therapyTypes ? (therapyTypes.map(function (therapyType, key) {
                            if (therapyType.name.se)
                                return (_jsx("option", __assign({ value: therapyType._id }, { children: therapyType.name.se }), key));
                            if (therapyType.name.fi)
                                return (_jsx("option", __assign({ value: therapyType._id }, { children: therapyType.name.fi }), key));
                        })) : (_jsx(_Fragment, {}))] })) })), _jsx(PickDefaultMessage, { useDefaultMessage: useDefaultMessage, defaultMessage: defaultMessage, user: user }), _jsx("label", __assign({ className: "col-start-1" }, { children: Translate({ term: 'messages.messages_subject' }) })), _jsx("div", __assign({ className: "md:col-span-2" }, { children: _jsx("input", { onChange: function () { return handleChanges(); }, ref: subject, type: "text", className: "text-base mt-1 w-full block" }) })), _jsx("label", __assign({ className: "col-start-1" }, { children: Translate({ term: 'messages.message_content' }) })), _jsx("div", __assign({ className: "md:col-span-2" }, { children: _jsx("textarea", { onChange: function () { return handleChanges(); }, ref: message, rows: 6, className: "text-base mt-1 block bg-white border border-gray-300 rounded-md px-2 py-1 w-full" }) })), messageIsSent && (_jsx("div", __assign({ className: "text-success-600 px-4 mb-6 py-2 bg-white border border-success-600 rounded-md" }, { children: _jsx("p", { children: messageSentTranslate }) }))), _jsxs("div", __assign({ className: "col-span-3 flex gap-3 mt-3" }, { children: [_jsx("button", __assign({ onClick: sendMessage, disabled: !validTypes || !validSubject || !validMessage, className: !validTypes || !validSubject || !validMessage
                            ? 'opacity-25 cursor-not-allowed'
                            : '' }, { children: Translate({ term: 'actions.send' }) })), _jsx("button", __assign({ onClick: function (e) {
                            e.preventDefault(), clearMessage();
                        }, className: "bg-lightBlue text-blue" }, { children: Translate({ term: 'actions.clear' }) }))] }))] })));
};
export default GroupMessage;
