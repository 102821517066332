var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export default function PhaseWrapper(_a) {
    var header = _a.header, children = _a.children;
    return (_jsx("div", __assign({ className: "flex justify-items-end items-center my-12" }, { children: _jsxs("div", __assign({ className: "max-w-screen-sm w-11/12 grid gap-3 m-auto" }, { children: [header && typeof header === 'string' ? (_jsx("header", { children: _jsx("h2", { children: header }) })) : (header), _jsx("div", __assign({ className: "bg-cyan px-8 md:px-16 py-8 rounded-md shadow-md text-center grid gap-8" }, { children: children }))] })) })));
}
