var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PeriodMaterialIcon from '../period/PeriodMaterialIcon';
import PeriodMaterialMarkdown from '../period/PeriodMaterialMarkdown';
import ReactPlayer from 'react-player';
import { Translate } from '../../translation/Translate';
var AssignmentDetails = function (_a) {
    var id = _a.id, title = _a.title, description = _a.description, type = _a.type, created = _a.created, previewImage = _a.previewImage, deadline = _a.deadline, approved = _a.approved, removed = _a.removed, url = _a.url, patientInstructions = _a.patientInstructions;
    return (_jsxs(React.Fragment, { children: [_jsxs("h2", __assign({ className: "text-blue" }, { children: [_jsx(PeriodMaterialIcon, { type: type }), " ", title] })), _jsxs("div", __assign({ className: "grid gap-4 mt-4" }, { children: [type === 'video' && url && url !== '' ? (_jsx("div", __assign({ className: "" }, { children: _jsx(ReactPlayer, { url: url, width: "320px", height: "240px", controls: true }) }))) : (''), _jsx("div", __assign({ className: "period-material-content" }, { children: _jsx(PeriodMaterialMarkdown, { text: description }) })), patientInstructions ? (_jsxs("div", { children: [_jsx("h4", __assign({ className: "text-blue font-semibold" }, { children: Translate({ term: 'material_library.instructions' }) })), _jsx(PeriodMaterialMarkdown, { text: patientInstructions })] })) : ('')] }))] }));
};
export default AssignmentDetails;
