var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import { Translate } from '../../../translation/Translate';
var HelpChatSelectorContent = function () {
    return (_jsx("div", { children: _jsxs("a", __assign({ href: "/help-chat", className: "flex px-2 gap-2 py-2 rounded-md duration-300" }, { children: [_jsx("div", __assign({ className: "h-6 w-6 text-xs overflow-hidden flex items-center text-gray-500" }, { children: _jsx(SupportOutlinedIcon, {}) })), _jsx("div", __assign({ className: "text-gray-700" }, { children: Translate({ term: 'nav.quick_help' }) }))] })) }));
};
export default HelpChatSelectorContent;
