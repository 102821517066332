var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Translate } from "../../translation/Translate";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
var ContactInfo = function () {
    return (_jsxs("div", __assign({ className: "bg-white border border-cyan py-3 px-2 sm:p-6 rounded-md shadow-lg space-y-2" }, { children: [_jsxs("h3", __assign({ className: "font-semibold text-lg text-grey-800 mb-4" }, { children: [Translate({ term: "customer_service.contact_info" }), ":"] })), _jsxs("div", __assign({ className: "flex flex-row" }, { children: [_jsx(CallOutlinedIcon, {}), _jsxs("div", __assign({ className: "flex flex-col ml-5" }, { children: [_jsxs("p", { children: [_jsx("a", __assign({ className: "text-blue", href: "tel:0942727949" }, { children: "(09) 4272 7949" })), " (", Translate({ term: "customer_service.service_time" }), " 9-16)"] }), _jsxs("p", { children: [_jsx("a", __assign({ className: "text-blue", href: "tel:0942727949" }, { children: "(09) 4272 7949" })), " (", Translate({ term: "customer_service.tech_suport" }), ",", Translate({ term: "customer_service.service_time" }), " 9-17)"] })] }))] })), _jsxs("div", __assign({ className: "flex flex-row" }, { children: [_jsx(AlternateEmailOutlinedIcon, {}), _jsx("a", __assign({ className: "text-blue ml-5", href: "mailto:asiakaspalvelu@aannekoulu.fi" }, { children: "asiakaspalvelu@aannekoulu.fi" }))] })), _jsxs("div", __assign({ className: "flex flex-row" }, { children: [_jsx(TextsmsOutlinedIcon, {}), _jsxs("p", __assign({ className: "inline ml-5" }, { children: [Translate({ term: "customer_service.chat" }), " ", Translate({ term: "customer_service.address" }), _jsxs("a", __assign({ className: "text-blue", href: "https://aannekoulu.fi", target: "_blank", rel: "noreferrer" }, { children: [" aannekoulu.fi ", _jsx(ArrowCircleRightOutlinedIcon, { style: { backgroundColor: "#FFFFFF", fill: "#133964" } })] }))] }))] }))] })));
};
export default ContactInfo;
