var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import FinnishInstructions from '../../components/licenseController/instructions/FinnishInstructions';
import SwedishInstructions from '../../components/licenseController/instructions/SwedishInstructions';
import Header from '../../components/therapist/Header';
import { LanguageContext, Translate, } from '../../components/translation/Translate';
var Instructions = function () {
    var language = useContext(LanguageContext);
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { title: Translate({ term: 'nav.instructions' }) }), _jsx("main", __assign({ className: "px-8 py-4 flex flex-col gap-6" }, { children: _jsx("div", __assign({ className: "bg-white px-8 py-4 rounded-md shadow-md w-full max-w-3xl m-auto" }, { children: language === 'se' ? (_jsx(SwedishInstructions, {})) : (_jsx(FinnishInstructions, {})) })) }))] }));
};
export default Instructions;
