var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useTranslate } from '../../translation/Translate';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import CallSplitOutlinedIcon from '@mui/icons-material/CallSplitOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import IconLink from './IconLink';
import usePeriods from '../../../hooks/usePeriods';
export default function MainNav(_a) {
    var iconsOnly = _a.iconsOnly, classname = _a.classname, showSideBarFunction = _a.showSideBarFunction;
    var t = useTranslate();
    var location = useLocation();
    var latestPeriod = usePeriods().latestPeriod;
    function isActivePath(path) {
        return location.pathname.split("/")[1] === path.split("/")[1];
    }
    var menuItems = [
        {
            title: t('nav.home'),
            icon: _jsx(HomeOutlinedIcon, {}),
            path: '/',
        },
        {
            title: t('nav.period'),
            icon: _jsx(MenuBookOutlinedIcon, {}),
            path: (latestPeriod === null || latestPeriod === void 0 ? void 0 : latestPeriod._id) ? "/period/".concat(latestPeriod._id) : "/",
            sub: [
                {
                    title: t('nav.assignments'),
                    icon: 'icon',
                    path: '/period/assignments',
                },
            ],
        },
        {
            title: t('nav.customer_service'),
            icon: _jsx(CallSplitOutlinedIcon, {}),
            path: '/customer-service',
        },
        {
            title: t('nav.profile'),
            path: '/profile',
            icon: _jsx(DynamicFeedOutlinedIcon, {}),
        },
    ];
    return (_jsx("div", { children: _jsx("ul", __assign({ className: classname }, { children: menuItems.map(function (item) {
                if (!item.hide) {
                    return (_jsx("li", __assign({ className: "p-0 mb-1 mx-2 lg:mx-0 lg:ml-0 bg-blue lg:bg-lightBlue text-white rounded-md", onClick: showSideBarFunction }, { children: _jsx(IconLink, { iconsOnly: iconsOnly, path: item.path, title: item.title, icon: item.icon, active: isActivePath(item.path) }) }), item.path));
                }
            }) })) }));
}
;
