var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { getModulesAdmin } from '../../../actions/moduleActions';
import { Translate } from '../../translation/Translate';
import useUser from '../../../hooks/useUser';
// TODO: when changing language, module and category select goes empty
var SelectDiv = function (_a) {
    var module = _a.module, category = _a.category, setModule = _a.setModule, setCategory = _a.setCategory, setPage = _a.setPage, searchAllMaterials = _a.searchAllMaterials, clearSearch = _a.clearSearch;
    var user = useUser();
    var allTranslate = Translate({ term: 'material_library.all' });
    var selectModuleTranslate = Translate({ term: 'therapy.select_module' });
    var selectCategoryTranslate = Translate({
        term: 'material_library.choose_category',
    });
    var clearTranslate = Translate({ term: 'actions.clear' });
    var searchTranslate = Translate({ term: 'actions.search' });
    var RTranslate = Translate({ term: 'therapy.categories.R' });
    var STranslate = Translate({ term: 'therapy.categories.S' });
    var LTranslate = Translate({ term: 'therapy.categories.L' });
    var KTranslate = Translate({ term: 'therapy.categories.K' });
    var M_PTranslate = Translate({ term: 'therapy.categories.M_P' });
    var JTranslate = Translate({ term: 'therapy.categories.J' });
    var frontVowelsTranslate = Translate({
        term: 'therapy.categories.front_vowels',
    });
    var VTranslate = Translate({ term: 'therapy.categories.V' });
    var _b = __read(useState([]), 2), modules = _b[0], setModules = _b[1];
    var categories = [
        RTranslate,
        STranslate,
        LTranslate,
        KTranslate,
        M_PTranslate,
        JTranslate,
        frontVowelsTranslate,
        VTranslate,
    ];
    useEffect(function () {
        getModules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var getModules = function () { return __awaiter(void 0, void 0, void 0, function () {
        var modulesData, modulesArray, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getModulesAdmin(user.session)];
                case 1:
                    modulesData = _a.sent();
                    if (modulesData) {
                        modulesArray = modulesData.map(function (item) {
                            return {
                                value: item._id,
                                optionText: item.name.fi || item.name.se,
                            };
                        });
                        setModules(sortBy(modulesArray, function (option) { return option.optionText; }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: "flex flex-row gap-7 mt-6" }, { children: [_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("label", __assign({ className: "mb-2" }, { children: selectModuleTranslate })), _jsxs("select", __assign({ className: "select", style: { width: '330px' }, value: JSON.stringify(module), onChange: function (e) {
                            if (e.target.value !== '') {
                                var module_1 = JSON.parse(e.target.value);
                                setModule(module_1);
                            }
                            else {
                                setModule({
                                    value: '0',
                                    optionText: allTranslate,
                                });
                            }
                        } }, { children: [_jsx("option", __assign({ value: "" }, { children: allTranslate })), modules.map(function (module, index) { return (_jsx("option", __assign({ value: JSON.stringify(module) }, { children: module.optionText }), index)); })] }))] })), _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("label", __assign({ className: "mb-2" }, { children: selectCategoryTranslate })), _jsxs("select", __assign({ className: "select", style: { width: '270px' }, value: category, onChange: function (e) {
                            setCategory(e.target.value);
                        } }, { children: [_jsx("option", __assign({ value: allTranslate }, { children: allTranslate })), categories.map(function (category, index) { return (_jsx("option", __assign({ value: category }, { children: category }), index)); })] }))] })), _jsx("div", __assign({ className: "flex items-end ml-2" }, { children: _jsx("button", __assign({ onClick: function () {
                        setPage(1);
                        searchAllMaterials();
                    }, className: "h-11" }, { children: searchTranslate })) })), _jsx("div", __assign({ className: "flex items-end" }, { children: _jsx("button", __assign({ onClick: clearSearch, className: "border-2 border-blue bg-white text-black hover:bg-blue hover:text-white duration-300" }, { children: clearTranslate })) }))] })));
};
export default SelectDiv;
