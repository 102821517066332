var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { StylesManager, Model } from 'survey-core';
import 'survey-core/modern.min.css';
import { Survey } from 'survey-react-ui';
import { createNewEvaluation } from '../../../actions/evaluationActions';
import { Translate } from '../../translation/Translate';
var EvaluationQuestionaire = function (_a) {
    var questionnaire = _a.questionnaire, questions = _a.questions, activeQuestionaire = _a.activeQuestionaire, accountInfo = _a.accountInfo, next = _a.next;
    var _b = __read(useState('fi'), 2), selectedOption = _b[0], setSelectedOption = _b[1];
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var invitationId = urlParams.get('id');
    var survey = new Model(questions);
    survey.locale = selectedOption;
    StylesManager.applyTheme('modern');
    function createEvaluation(results) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var data, response, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        data = {
                            invitationId: invitationId,
                            ageGroupType: activeQuestionaire,
                            answers: results,
                            questions: questions,
                            questionnaireId: questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire._id,
                            questionnaireName: questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.name,
                            password: (_a = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.patient) === null || _a === void 0 ? void 0 : _a.ssn,
                        };
                        return [4 /*yield*/, createNewEvaluation(data)];
                    case 1:
                        response = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _b.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    var sendResults = useCallback(
    // JSurvey own params. Necessary to be typed
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (sender) {
        var results = sender.data;
        createEvaluation(results);
    }, [createEvaluation]);
    survey.onComplete.add(sendResults);
    return (_jsxs("div", { children: [_jsxs("select", __assign({ value: selectedOption, onChange: function (e) { return setSelectedOption(e.target.value); } }, { children: [_jsx("option", __assign({ value: "fi", selected: true }, { children: Translate({ term: 'languages.finnish' }) })), _jsx("option", __assign({ value: "sv" }, { children: Translate({ term: 'languages.swedish' }) }))] })), _jsx(Survey, { className: "bg-white", model: survey })] }));
};
export default EvaluationQuestionaire;
