var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import LanguageSelector from '../../common/LanguageSelector';
import { Translate } from '../../translation/Translate';
var CustomerServiceTitle = function () {
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "hidden sm:flex sm:justify-between sm:block" }, { children: [_jsx("h1", __assign({ className: "text-blue" }, { children: Translate({ term: 'nav.customer_service' }) })), _jsx(LanguageSelector, {})] })), _jsx("div", __assign({ className: "flex justify-center block sm:hidden" }, { children: _jsx("h1", __assign({ className: "text-blue" }, { children: Translate({ term: 'nav.customer_service' }) })) }))] }));
};
export default CustomerServiceTitle;
