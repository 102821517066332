var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable indent */
import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PeriodContext from '../../common/period/PeriodContext';
import { findIndexFromArray } from '../../../functions/finders';
import { setPeriodAssignments } from '../../../reducers/periodSlice';
import { setPeriodAssignment } from '../../../actions/periodActions';
import { Translate } from '../../translation/Translate';
import AccordionPanel from '../../common/AccordionPanel';
import Assignments from '../../common/assignments/Assignments';
import { useNavigate } from 'react-router-dom';
import Questions from './Questions';
import useUser from '../../../hooks/useUser';
var AssignmentPanels = function () {
    var _a;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var period = useContext(PeriodContext);
    var activePeriod = useSelector(function (state) { return state.periods.active; });
    var assignments = period && period.period && period.period.assignments
        ? period.period.assignments
        : undefined;
    var periodId = period && period.period ? period.period._id : undefined;
    var user = useUser();
    function findAssignment(id) {
        if (assignments)
            return findIndexFromArray(assignments, 'id', id);
    }
    var surveyTranslate = Translate({ term: 'admin.inquiry.questionnaires' });
    function assignmentChange(id, status, type) {
        var index = findAssignment(id);
        if (index !== undefined && assignments) {
            var selected = __assign({}, assignments[index]);
            switch (type) {
                case 'approved': {
                    if (status === true)
                        selected.approved = new Date();
                    else
                        delete selected.approved;
                    break;
                }
                case 'deadline': {
                    delete selected.deadline;
                    break;
                }
                case 'addedToMessage': {
                    if (status === true)
                        selected.addedToMessage = true;
                    else
                        delete selected.addedToMessage;
                    break;
                }
            }
            if (periodId) {
                setPeriodAssignment(periodId, id, type, status.toString(), user.session).then(function (data) {
                    if (data)
                        dispatch(setPeriodAssignments(data.assignments));
                });
            }
        }
    }
    if (period) {
        var panelData = [
            // {
            // 	title: Translate({ term: 'period.assignments_waiting' }),
            // 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
            // 	filter: (a: any) => !a.deadline,
            // 	addToMessage: true,
            // },
            {
                title: Translate({ term: 'period.active_assignments' }),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                // filter: (a: any) => !a.approved && a.deadline,
                filter: function (a) { return !a.approved; },
                approve: true,
                removeDeadline: false,
            },
            {
                title: Translate({ term: 'period.assignment_history' }),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                filter: function (a) { return a.approved; },
                disapprove: true,
            },
        ];
        return (_jsxs("div", __assign({ className: "grid gap-4 grid-cols-2 lg:grid-cols-1" }, { children: [_jsx("div", __assign({ className: "text-right" }, { children: _jsx("button", __assign({ onClick: function () { var _a; return navigate("/materials?periodId=".concat((_a = period.period) === null || _a === void 0 ? void 0 : _a._id)); } }, { children: Translate({ term: 'period.add_assignment' }) })) })), period &&
                    panelData.map(function (p, key) { return (_jsx(AccordionPanel, __assign({ summary: _jsxs("div", { children: [p.title, ' ', assignments ? assignments.filter(p.filter).length : ''] }) }, { children: _jsx(Assignments, { assignments: assignments ? assignments.filter(p.filter) : [], disapprove: p.disapprove
                                ? function (id) { return assignmentChange(id, false, 'approved'); }
                                : undefined, approve: p.approve
                                ? function (id) { return assignmentChange(id, true, 'approved'); }
                                : undefined, addToMessage: p.addToMessage
                                ? function (id) { return assignmentChange(id, true, 'addedToMessage'); }
                                : undefined, removeDeadline: p.removeDeadline
                                ? function (id) { return assignmentChange(id, false, 'deadline'); }
                                : undefined, removeFromMessage: !p.addedToMessage &&
                                assignments &&
                                assignments.filter(p.filter).find(function (a) { return !a.approved; })
                                ? function (id) { return assignmentChange(id, false, 'addedToMessage'); }
                                : undefined, openModal: true, isDone: assignments &&
                                assignments.filter(p.filter).find(function (a) { return a.approved; })
                                ? true
                                : false, therapist: key === 2 ? 2 : 0 }) }), key)); }), _jsx(AccordionPanel, __assign({ summary: _jsx("div", { children: surveyTranslate }) }, { children: _jsx(Questions, { startQuestionnaire: activePeriod === null || activePeriod === void 0 ? void 0 : activePeriod.startQuestionnaire, questionnaires: (_a = activePeriod === null || activePeriod === void 0 ? void 0 : activePeriod.questionnaires) !== null && _a !== void 0 ? _a : [] }) }))] })));
    }
    return _jsx(React.Fragment, {});
};
export default AssignmentPanels;
