/**
 * Gets correct sort string for filtered periods endpoint from column id.
 * @param id column name
 * @param language users language
 * @returns string | string[] sort string for filtered periods endpoint.
 */
export var getSortParam = function (id) {
    switch (id) {
        case 'name':
            return ['patient.firstName', 'patient.lastName'];
        case 'content':
            return "name";
        case 'unread_message':
            return 'messages.created';
        case 'unread_received':
            return 'messages.created';
        case 'last_message':
            return 'messages.created';
        case 'phase':
            return 'sessions';
        case 'start_questionnaire_done':
            return 'startQuestionnaire.filledOut';
        default:
            return '';
    }
};
/**
 * /api/therapists-patients/filtered
 * Returns data in bit different format than whats used before with getSortParam
 * so this function returns correct field names for said endpoint.
 */
export function getPatientPeriodSortParam(id) {
    switch (id) {
        case "name":
            return ["firstName", "lastName"];
        case "content":
            return "periods.moduleName.fi";
        case "unread_message":
        case "unread_received":
        case "last_message":
            return "periods.latestMessageDate";
        case "phase":
            return "periods.sessions";
        case "start_questionnaire_done":
            return "startQuestionnaireFillDate";
        case "period_count":
            return "periodsCount";
        default:
            return '';
    }
}
