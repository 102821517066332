var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Translate } from '../../translation/Translate';
import { validateEmail } from '../../../functions/validations';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ConfirmExistingUserAccount from './ConfirmExistingUserAccount';
var Phase2 = function (_a) {
    var adult = _a.adult, account = _a.account, ssn = _a.ssn, next = _a.next;
    var _b = __read(useState(false), 2), valid = _b[0], setValid = _b[1];
    var _c = __read(useState(false), 2), validEmail = _c[0], setValidEmail = _c[1];
    var fields = [
        {
            label: Translate({ term: 'profile.first_name' }),
            key: 'firstName',
        },
        {
            label: Translate({ term: 'profile.last_name' }),
            key: 'lastName',
        },
        {
            label: Translate({ term: 'profile.phone' }),
            key: 'phone',
        },
        {
            label: Translate({ term: 'profile.email' }),
            key: 'email',
        },
    ];
    var defaultValues = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
    };
    if (!adult || adult !== true) {
        defaultValues.patientFirstName = '';
        defaultValues.patientLastName = '';
        fields[0].label = Translate({ term: 'account.parent_first_name' });
        fields[1].label = Translate({ term: 'account.parent_last_name' });
        fields.unshift({
            label: Translate({ term: 'account.child_last_name' }),
            key: 'patientLastName',
        });
        fields.unshift({
            label: Translate({ term: 'account.child_first_name' }),
            key: 'patientFirstName',
        });
    }
    var _d = __read(useState(defaultValues), 2), user = _d[0], setUser = _d[1];
    function submitInfo() {
        if (next)
            next(user);
    }
    function handleUserChange(s, f) {
        var _a, e_1, _b;
        var validation = true;
        var validEmail = false;
        var userInfo = __assign(__assign({}, user), (_a = {}, _a[f] = s, _a));
        try {
            for (var _c = __values(Object.entries(userInfo)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), key = _e[0], value = _e[1];
                if (value.length < 3)
                    validation = false;
                if (key === 'phone' && value.length < 7)
                    validation = false;
                if (key === 'email') {
                    validEmail = validateEmail(value);
                    if (validEmail !== true)
                        validation = false;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        setUser(userInfo);
        setValid(validation);
        setValidEmail(validEmail);
    }
    if (account) {
        return _jsx(ConfirmExistingUserAccount, { ssn: ssn });
    }
    else {
        return (_jsxs(React.Fragment, { children: [_jsx("h2", __assign({ className: "text-xl" }, { children: Translate({ term: 'account.enter_contact_info' }) })), _jsxs("div", __assign({ className: "grid gap-4" }, { children: [fields.map(function (f, key) {
                            if (f.key === 'email') {
                                return (_jsxs("label", __assign({ className: "grid gap-y-2" }, { children: [_jsx("div", __assign({ className: "text-left" }, { children: Translate({ term: 'profile.email' }) })), _jsxs("div", __assign({ className: "relative block" }, { children: [validEmail && (_jsx("span", __assign({ className: "absolute inset-y-0 left-0 flex items-center pl-2" }, { children: _jsx("div", __assign({ className: "h-5 w-5 text-gray-500 -mt-1" }, { children: _jsx(CheckCircleIcon, { className: "text-green" }) })) }))), _jsx("input", { className: validEmail ? 'icon-start' : 'w-full', type: "text", onChange: function (e) { return handleUserChange(e.target.value, 'email'); } })] }))] }), key));
                            }
                            return (_jsxs("label", __assign({ className: "grid gap-y-2" }, { children: [_jsx("div", __assign({ className: "text-left" }, { children: f.label })), _jsx("input", { type: "text", onChange: function (e) { return handleUserChange(e.target.value, f.key); } })] }), key));
                        }), _jsx("div", __assign({ className: "text-center" }, { children: _jsx("button", __assign({ disabled: !valid, className: !valid ? 'opacity-25 cursor-not-allowed' : '', onClick: function () { return submitInfo(); } }, { children: Translate({ term: 'actions.continue' }) })) }))] }))] }));
    }
};
export default Phase2;
