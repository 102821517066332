var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var LogoLink = function (_a) {
    var title = _a.title, url = _a.url, logo = _a.logo, size = _a.size;
    return (_jsxs("a", __assign({ href: url, target: "_blank", className: "p-8 bg-lightBlue rounded-md shadow-md flex flex-col justify-items-center items-center gap-4", rel: "noreferrer" }, { children: [_jsx("div", __assign({ className: "bg-white h-24 w-24 rounded-full overflow-hidden" }, { children: _jsx("img", { alt: title, src: logo }) })), _jsx("div", __assign({ className: "text-center" }, { children: title }))] })));
};
export default LogoLink;
