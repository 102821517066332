var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ListQuery from '../../components/therapist/query/ListQuery';
import Header from '../../components/licenseController/Header';
import { Translate } from '../../components/translation/Translate';
var Query = function () {
    var questionnairesTranslate = Translate({
        term: 'admin.inquiry.questionnaires',
    });
    return (_jsxs(_Fragment, { children: [_jsx(Header, { title: questionnairesTranslate }), _jsx("main", __assign({ className: "px-9 py-4" }, { children: _jsx("div", __assign({ className: "mt-3 flex" }, { children: _jsx(ListQuery, {}) })) }))] }));
};
export default Query;
